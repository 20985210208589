import React, { useState, useEffect, useCallback } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Accordion,
  Row,
  Spinner,
  Table,
  Form,
  Alert as AlertBS,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  DatePicker,
  Input,
  Select,
  Image,
} from "../../../../components/Components";
import {
  useGetDetailBonTahanan,
  useGetTahtiBonList,
  useGetDetailTahti,
  usePostBonTahanan,
  usePutBonTahanan,
} from "../../../../utils/apis/query";
import { useQuery } from "react-query";
import { format, parse } from "date-fns";
import moment from "moment";
import { debounce } from "lodash";
import Alert from "../../../../libraries/Alert";
import {
  encodeToHex,
  pangkat,
  trimFileUrl,
  trimUrlPath,
} from "../../../../utils";

function FormBonTahanan() {
  const onSuccess = () => {
    setTimeout(() => {
      navigate("/tahanan/bon/list");
    }, 1500);
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [payloadCariTahanann, setPayloadCariTahanan] = useState({
    search: "",
  });
  const [namaTahanan, setNamaTahanan] = useState("");
  const [idTahanan, setIdTahanan] = useState("");
  const mutateAdd = usePostBonTahanan(onSuccess);
  const mutateEdit = usePutBonTahanan(onSuccess);
  const [penyidik, setPenyidik] = useState([]);
  const [isFormEditAble, setIsFormEditAble] = useState(true);
  const [imageTahananDipinjam, setImageTahananDipinjam] = useState(null);

  const [payloadDate, setPayloadDate] = useState({
    tanggal_bon: "",
    awal_bon: "",
    akhir_bon: "",
  });
  const [payloadForm, setPayloadForm] = useState({
    no_tahanan: "",
    nama_tahanan: "",
    no_bon: "",
    tanggal_bon: "",
    awal_bon: "",
    akhir_bon: "",
    kondisi: "",
    alasan: "",
    keterangan: "",
    penyidik_ids: [],
    arsip: [],
    lampiran: [],
    status_dokumen: "",
    foto_tahanan_dipinjam: "",
  });
  const [payloadFormDataTahanan, setPayloadFormDataTahanan] = useState({
    no_tahanan: "",
    nama_tahanan: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    umur: "",
    jenis_kelamin: "",
    agama: "",
    kebangsaan: "",
    asal_negara: "",
    pekerjaan: "",
    alamat: "",
    perkara: "",
    nama_rutan: "",
    alamat_rutan: "",
    rumus_wajah: "",
    rumus_sidikjari: "",
  });

  const {
    data: dataTahanan,
    isFetching: tahananFetching,
    isError: tahananError,
    error: errorTahanan,
  } = useGetDetailTahti(idTahanan, { enabled: !!idTahanan });

  const {
    data: dataBon,
    isFetching: bonIsFetching,
    isError: bonIsError,
    error: bonError,
  } = useGetDetailBonTahanan(id, { enabled: !!id });

  const { data: dataTahananList, isFetching: modalFetching } =
    useGetTahtiBonList(payloadCariTahanann);

  const handleDebounce = useCallback(
    debounce((newPayload) => {
      setPayloadCariTahanan(newPayload);
    }, 500),
    []
  );

  const handleChange = (e) => {
    setIsFormDirty(true);
    const { name, value } = e.target;
    setPayloadForm({ ...payloadForm, [name]: value });
  };

  const handleDatePicker = (date, name) => {
    if (name === "awal_bon" || name === "akhir_bon") {
      const formattedDateTime = moment(date).format("YYYY-MM-DD HH:mm:ss");
      // const akhirBon = moment(date)
      //   .add(24, "hours")
      //   .format("YYYY-MM-DD HH:mm:ss");
      setPayloadForm((prevPayload) => ({
        ...prevPayload,
        [name]: formattedDateTime,
      }));
    } else {
      const formattedDateTime = moment(date).format("YYYY-MM-DD");
      setPayloadForm({
        ...payloadForm,
        [name]: formattedDateTime,
      });
    }
  };

  const handleCheckbox = (data, isChecked) => {
    if (isChecked) {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: [...payloadForm.penyidik_ids, data.id_penyidik],
      });
    } else {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: payloadForm.penyidik_ids.filter(
          (selectedId) => selectedId !== data.id_penyidik
        ),
      });
    }
  };

  const handleSubmit = (status) => {
    payloadForm.status_dokumen = status;
    let formData = new FormData();
    Object.keys(payloadForm).forEach((key) => {
      if (
        typeof payloadForm[key] === "string" ||
        typeof payloadForm[key] === "number" ||
        key === "foto_tahanan_dipinjam"
      ) {
        formData.append(key, payloadForm[key]);
      }
    });
    payloadForm.arsip?.forEach((file, idx) => {
      formData.append(`arsip`, file);
    });
    payloadForm.lampiran?.forEach((file, idx) => {
      formData.append(`lampiran`, file);
    });
    payloadForm.penyidik_ids?.forEach((ids, idx) => {
      formData.append(`penyidik_ids`, ids);
    });

    if (isEdit) {
      mutateEdit.mutate({ id, formData });
    } else {
      mutateAdd.mutate(formData);
    }
  };

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      setIsFormEditAble(false);
    }
    if (dataTahanan) {
      if ((dataTahanan.status_tahanan === "Bon tahanan") & (isEdit === false)) {
        Alert.showMessage("Tahanan dalam peminjaman !");
      } else {
        const _ = dataTahanan;
        setPayloadFormDataTahanan({
          no_tahanan: _.no_tahanan,
          nama_tahanan: _.nama_tersangka,
          tempat_lahir: _.tempat_lahir,
          tanggal_lahir: _.tanggal_lahir,
          umur: _.umur,
          jenis_kelamin: _.jenis_kelamin,
          agama: _.agama,
          kebangsaan: _.kebangsaan,
          asal_negara: _.asal_negara,
          pekerjaan: _.pekerjaan,
          alamat: _.alamat,
          perkara: _.perkara,
          nama_rutan: _.nama_rutan,
          alamat_rutan: _.alamat_rutan,
          rumus_wajah: _.rumus_wajah,
          rumus_sidikjari: _.rumus_sidikjari,
        });
        setPayloadForm({
          ...payloadForm,
          nama_tahanan: _.nama_tersangka,
          no_tahanan: _.no_tahanan,
        });
        setPenyidik(_?.penyidik ?? []);
      }
    }
    if (dataBon) {
      const penyidikIds = dataBon.penyidik.map(
        (penyidik) => penyidik.id_penyidik
      );
      setPayloadForm({
        ...payloadForm,
        nama_tahanan: dataBon.nama_tahanan,
        no_tahanan: dataBon.no_tahanan,
        no_bon: dataBon.no_bon,
        tanggal_bon: dataBon.tanggal_bon,
        awal_bon: dataBon.awal_bon,
        akhir_bon: dataBon.akhir_bon,
        kondisi: dataBon.kondisi,
        alasan: dataBon.alasan,
        keterangan: dataBon.keterangan,
        id_tahanan: dataBon.id_tahanan,
        arsip: [],
        lampiran: [],
        penyidik_ids: penyidikIds,
      });
      setIdTahanan(dataBon.id_tahanan);
      setPayloadDate({
        ...payloadDate,
        tanggal_bon: dataBon?.tanggal_bon
          ? moment(dataBon?.tanggal_bon).toDate()
          : null,
        awal_bon: dataBon?.awal_bon ? moment(dataBon?.awal_bon).toDate() : null,
        akhir_bon: dataBon?.akhir_bon
          ? moment(dataBon?.akhir_bon).toDate()
          : null,
      });
      setImageTahananDipinjam(dataBon?.foto_bon_tahanan?.foto_tahanan_dipinjam);
    }
  }, [dataTahanan, dataBon]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  useEffect(() => {
    handleDebounce({ ...payloadCariTahanann, search: namaTahanan });
  }, [namaTahanan, handleDebounce, payloadCariTahanann]);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/tahanan">Tahanan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/tahanan/bon/list">List Bon Tahanan</Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  {isEdit ? "Detail Bon Tahanan" : "Form Tambah Bon Tahanan"}
                </li>
              </ol>
            </div>
            {isEdit && (
              <Button
                className="d-flex align-items-center gap-2"
                variant="primary"
                size="normal"
                onClick={() => setIsFormEditAble(!isFormEditAble)}
              >
                <i className="ri-edit-2-line"></i>
                {isFormEditAble ? "Batalkan Edit" : "Edit Form"}
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            {
              <Col sm="12">
                {tahananFetching && !tahananError && (
                  <div className="d-flex justify-content-center">
                    <Spinner variant="primary" size="small" />
                  </div>
                )}
                {!tahananFetching && tahananError && (
                  <div className="d-flex justify-content-center">
                    <span className="text-danger text-center">
                      <b>Error</b>{" "}
                      {errorTahanan?.response?.data?.message ??
                        "please refresh the page"}
                    </span>
                  </div>
                )}
                {mutateAdd.isError && (
                  <AlertBS variant="danger">
                    <ul>
                      {mutateAdd?.error?.response?.data?.detail?.map(
                        (errEdit) => (
                          <li>
                            <b>{errEdit.loc[1]} </b>
                            {errEdit.msg}
                          </li>
                        )
                      ) ?? (
                        <li>
                          <b>Error</b>Sesuatu Bermasalah
                        </li>
                      )}
                    </ul>
                  </AlertBS>
                )}
                {mutateAdd.isSuccess && (
                  <AlertBS variant="success">
                    {mutateAdd.data.data.message}
                  </AlertBS>
                )}
              </Col>
            }
            {
              <Col sm="12">
                {mutateEdit.isError && (
                  <AlertBS variant="danger">
                    <ul>
                      {mutateEdit?.error?.response?.data?.detail?.map(
                        (errEdit) => (
                          <li>
                            <b>{errEdit.loc[1]} </b>
                            {errEdit.msg}
                          </li>
                        )
                      ) ?? (
                        <li>
                          <b>Error</b>Sesuatu Bermasalah
                        </li>
                      )}
                    </ul>
                  </AlertBS>
                )}
                {mutateEdit.isSuccess && (
                  <AlertBS variant="success">
                    {mutateEdit.data.data.message}
                  </AlertBS>
                )}
              </Col>
            }
            <Col sm="12">
              <Accordion
                defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                flush
                alwaysOpen
              >
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="custom-header">
                    Informasi / Profil Tahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Header className="bg-white d-flex justify-content-end">
                        {tahananFetching && !tahananError ? (
                          <Button
                            variant="primary"
                            size="small"
                            className="d-flex align-items-center gap-2"
                            disabled
                          >
                            <Spinner size="sm" variant="light" />
                            Sedang Menerapkan Tahanan
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            size="small"
                            className="d-flex align-items-center gap-2"
                            onClick={() => setModalShow(true)}
                            disabled={!isFormEditAble}
                          >
                            <i className="ri-user-search-line fs-6"></i>
                            {dataTahanan ? "Ubah " : "Cari "}
                            Profil Tahanan
                          </Button>
                        )}
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col sm="4" className="d-flex flex-column gap-3">
                            <div className="d-flex flex-column gap-2">
                              <span className="fs-6 fw-medium">
                                Foto Tahanan
                              </span>
                              <Image
                                src={payloadFormDataTahanan.rumus_wajah}
                                height="250px"
                                width="100%"
                              />
                            </div>
                            <div className="d-flex flex-column gap-2">
                              <span className="fs-6 fw-medium">
                                Foto Sidik Jari
                              </span>
                              <Image
                                src={payloadFormDataTahanan.rumus_sidikjari}
                                height="250px"
                                width="100%"
                              />
                            </div>
                          </Col>
                          <Col sm="8">
                            <Row className="g-2">
                              <Col sm="6">
                                <Input
                                  label="No. Tahanan"
                                  name="no_tahanan"
                                  value={payloadFormDataTahanan.no_tahanan}
                                  onChange={handleChange}
                                  disabled
                                />
                              </Col>
                              <Col sm="6">
                                <Input
                                  label="Nama"
                                  name="nama_tahanan"
                                  value={payloadFormDataTahanan.nama_tahanan}
                                  onChange={handleChange}
                                  disabled
                                />
                              </Col>
                              <Col sm="6">
                                <Input
                                  label="Tempat Lahir"
                                  name="tempat_lahir"
                                  value={payloadFormDataTahanan.tempat_lahir}
                                  disabled
                                />
                              </Col>
                              <Col sm="6">
                                <Input
                                  label="Tanggal Lahir"
                                  name="tanggal_lahir"
                                  value={payloadFormDataTahanan.tanggal_lahir}
                                  disabled
                                />
                              </Col>
                              <Col sm="6">
                                <Input
                                  type="number"
                                  label="Umur Saat Ini"
                                  name="umur"
                                  value={payloadFormDataTahanan.umur}
                                  disabled
                                />
                              </Col>
                              <Col sm="6">
                                <Input
                                  label="Kelamin"
                                  name="jenis_kelamin"
                                  value={payloadFormDataTahanan.jenis_kelamin}
                                  disabled
                                />
                              </Col>
                              <Col sm="6">
                                <Input
                                  label="Kewarganegaraan"
                                  name="kebangsaan"
                                  value={payloadFormDataTahanan.kebangsaan}
                                  disabled
                                />
                              </Col>
                              <Col sm="6">
                                <Input
                                  label="Asal Negara"
                                  name="asal_negara"
                                  value={payloadFormDataTahanan.asal_negara}
                                  disabled
                                />
                              </Col>
                              <Col sm="6">
                                <Input
                                  label="Agama"
                                  name="agama"
                                  value={payloadFormDataTahanan.agama}
                                  disabled
                                />
                              </Col>
                              <Col sm="6">
                                <Input
                                  label="Pekerjaan"
                                  name="pekerjaan"
                                  value={payloadFormDataTahanan.pekerjaan}
                                  type="text"
                                  disabled
                                />
                              </Col>
                              <Col sm="12">
                                <Input
                                  as="textarea"
                                  label="Alamat"
                                  name="alamat"
                                  value={payloadFormDataTahanan.alamat}
                                  disabled
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="custom-header">
                    Informasi Dokumen Penahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="12">
                            <Row className="g-2 mt-1">
                              <Col sm="5">
                                <Input
                                  label="Nomor Nota Bon Tahanan"
                                  name="no_bon"
                                  value={payloadForm.no_bon}
                                  onChange={handleChange}
                                  required
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="3">
                                <DatePicker
                                  label="Tanggal Bon"
                                  name="tanggal_bon"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  selected={payloadDate.tanggal_bon}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_bon: date,
                                    });
                                    handleDatePicker(date, "tanggal_bon");
                                  }}
                                  required
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="4">
                                <Input
                                  type="file"
                                  label="Unggah Arsip"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip: [files],
                                    });
                                  }}
                                  required
                                  accepts={[".pdf"]}
                                  disabled={!isFormEditAble}
                                />
                                {dataBon?.bon_tahanan_files[0] &&
                                  (() => {
                                    const { arsip } =
                                      dataBon.bon_tahanan_files[0];
                                    if (arsip) {
                                      const { p: path, k: kategori } =
                                        trimUrlPath(arsip);
                                      const file = trimFileUrl(arsip);

                                      return (
                                        <Link
                                          target="_blank"
                                          to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                            path
                                          )}`}
                                          className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                        >
                                          <i className="ri-link"></i>
                                          File Yang Sudah Terupload
                                        </Link>
                                      );
                                    } else {
                                      return null;
                                    }
                                  })()}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="custom-header">
                    Isi Keperluan Bon Tahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row>
                          <Col sm="4">
                            <div className="d-flex flex-column gap-2">
                              <span className="fs-6 fw-medium">
                                Foto Tahanan Dipinjam
                              </span>
                              <Image
                                src={imageTahananDipinjam}
                                height="250px"
                                width="100%"
                              />
                            </div>
                            <Input
                              type="file"
                              label="Unggah Foto Tahanan Dipinjam"
                              name="foto_tahanan_dipinjam"
                              accepts={[".jpg", ".jpeg", ".png"]}
                              disabled={!isFormEditAble}
                              onChange={(e) => {
                                const files = e.target.files[0];
                                if (files) {
                                  setPayloadForm({
                                    ...payloadForm,
                                    foto_tahanan_dipinjam: files,
                                  });
                                  setImageTahananDipinjam(
                                    URL.createObjectURL(files)
                                  );
                                }
                              }}
                            />
                          </Col>
                          <Col sm="8">
                            <Row className="g-2">
                              <Col sm="12">
                                <Select
                                  label="Kondisi Fisik Tahanan"
                                  name="kondisi"
                                  value={payloadForm.kondisi}
                                  onChange={handleChange}
                                  required
                                  default="Pilih"
                                  options={[
                                    { value: "Sehat", text: "Sehat" },
                                    {
                                      value: "Tidak Sehat",
                                      text: "Tidak Sehat",
                                    },
                                  ]}
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="12">
                                <DatePicker
                                  label="Tanggal Mulai Pinjam"
                                  name="awal_bon"
                                  showTimeSelect
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy HH:mm:ss"
                                  maxDate={new Date()}
                                  selected={payloadDate.awal_bon}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      awal_bon: date,
                                      // akhir_bon: moment(date)
                                      //   .add(24, "hours")
                                      //   .toDate(),
                                    });
                                    handleDatePicker(date, "awal_bon");
                                  }}
                                  required
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="12">
                                <DatePicker
                                  label="Tanggal Selesai Pinjam"
                                  name="akhir_bon"
                                  showTimeSelect
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy HH:mm:ss"
                                  minDate={payloadDate.awal_bon}
                                  selected={payloadDate.akhir_bon}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      akhir_bon: date,
                                    });
                                    handleDatePicker(date, "akhir_bon");
                                  }}
                                  required
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                              <Col sm="12">
                                <Input
                                  as="textarea"
                                  label="Keperluan"
                                  name="alasan"
                                  value={payloadForm.alasan}
                                  onChange={handleChange}
                                  disabled={!isFormEditAble}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="custom-header">
                    Informasi Tambahan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="mt-2 g-2">
                          <Col sm="4">
                            <Input
                              type="file"
                              label="Unggah Lampiran"
                              accepts={[".pdf"]}
                              onChange={(e) => {
                                const files = e.target.files[0];
                                setPayloadForm({
                                  ...payloadForm,
                                  lampiran: [files],
                                });
                              }}
                              disabled={!isFormEditAble}
                            />
                            {dataBon?.bon_tahanan_files[1] &&
                              (() => {
                                const { lampiran } =
                                  dataBon.bon_tahanan_files[1];

                                if (lampiran) {
                                  const { p: path, k: kategori } =
                                    trimUrlPath(lampiran);
                                  const file = trimFileUrl(lampiran);

                                  return (
                                    <Link
                                      target="_blank"
                                      to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                        path
                                      )}`}
                                      className="text-primary fs-6 fw-medium   d-flex align-items-center gap-2"
                                    >
                                      <i className="ri-link"></i>
                                      File Yang Sudah Terupload
                                    </Link>
                                  );
                                } else {
                                  return null;
                                }
                              })()}
                          </Col>
                          <Col sm="8">
                            <Input
                              as="textarea"
                              col="4"
                              label="Keterangan"
                              name="keterangan"
                              value={payloadForm.keterangan}
                              onChange={handleChange}
                              disabled={!isFormEditAble}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header className="custom-header">
                    Informasi Penahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2 mt-2">
                          <Col sm="4">
                            <Input
                              label="Perkara"
                              name="perkara"
                              value={payloadFormDataTahanan.perkara}
                              required
                              disabled
                            />
                          </Col>
                          <Col sm="4">
                            <Input
                              label="Penempatan Rutan"
                              name="nama_rutan"
                              value={payloadFormDataTahanan.nama_rutan}
                              required
                              disabled
                            />
                          </Col>
                          <Col sm="4">
                            <Input
                              label="Lokasi Rutan"
                              name="alamat_rutan"
                              value={payloadFormDataTahanan.alamat_rutan}
                              required
                              disabled
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header className="custom-header">
                    Informasi Pembuat Pinjaman Bon Penahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="12">
                            <Card className="card bg-white">
                              <Card.Body>
                                <Row className="g-1 mb-2 d-flex align-items-center">
                                  <Col sm="12" md="5">
                                    <h6>Daftar Penyidik</h6>
                                  </Col>
                                  <Col sm="12" md="7">
                                    <Link className="d-flex align-items-center justify-content-end"></Link>
                                  </Col>
                                </Row>
                                <Table className="table dems-table">
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>NRP</th>
                                      <th>Nama Penyidik</th>
                                      <th>Asal Satker</th>
                                      <th>Pangkat</th>
                                      <th>Jabatan</th>
                                      <th>&nbsp;</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {penyidik.map((data, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.nrp}</td>
                                        <td>{data.nama_penyidik}</td>
                                        <td>{data.nama_satker}</td>
                                        <td>{data.pangkat}</td>
                                        <td>{data.jabatan}</td>
                                        <td>
                                          <Form.Check
                                            type="checkbox"
                                            checked={payloadForm.penyidik_ids.some(
                                              (item) =>
                                                item === data.id_penyidik
                                            )}
                                            onChange={(e) => {
                                              handleCheckbox(
                                                data,
                                                e.target.checked
                                              );
                                            }}
                                            disabled={!isFormEditAble}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          {/* <div className="d-flex justify-content-end align-items-center gap-3">
            <Button variant="warning" as={Link} to="/tahanan/bon/list">
              BATAL
            </Button>
            {!isEdit && !mutateAdd.isLoading && !mutateEdit.isLoading && (
              <Button
                variant="secondary"
                onClick={() => handleSubmit("DRAFT")}
                disabled={!isFormEditAble}
              >
                SIMPAN DRAFT
              </Button>
            )}
            {!mutateAdd.isLoading && !mutateEdit.isLoading && (
              <Button
                variant="primary"
                onClick={() => handleSubmit("FINAL")}
                disabled={!isFormEditAble}
              >
                SIMPAN
              </Button>
            )}
            {(mutateAdd.isLoading || mutateEdit.isLoading) && (
              <div className="d-flex justify-content-end align-items-center gap-3">
                <Button
                  variant="primary"
                  className="d-flex align-items-center gap-2"
                  disabled
                >
                  Sedang Menyimpan <Spinner variant="light" size="sm" />
                </Button>
              </div>
            )}
          </div> */}
          <div className="d-flex justify-content-end align-items-center gap-3">
            {!mutateAdd.isLoading && !mutateEdit.isLoading && (
              <Button variant="warning" as={Link} to="/barbuk/penerimaan/list">
                BATAL
              </Button>
            )}
            {/* {!mutateAdd.isLoading && !mutateEdit.isLoading && (
              <Button
                variant="secondary"
                onClick={() => handleSubmit("DRAFT")}
                disabled={!isFormEditAble}
                hidden={!isFormEditAble}
              >
                SIMPAN DRAFT
              </Button>
            )} */}
            {!mutateAdd.isLoading && !mutateEdit.isLoading && (
              <Button
                variant="primary"
                onClick={() => handleSubmit("FINAL")}
                disabled={!isFormEditAble}
                hidden={!isFormEditAble}
              >
                SIMPAN
              </Button>
            )}
            {(mutateAdd.isLoading || mutateEdit.isLoading) && (
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                disabled
              >
                Sedang Menyimpan <Spinner variant="light" size="sm" />
              </Button>
            )}
          </div>
        </Card.Footer>
      </Card>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Cari Tahanan</Modal.Title>
          <Button
            className="btn btn-danger"
            onClick={() => setModalShow(false)}
          >
            <i class="ri-close-large-fill"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-4">
            <Col sm="12">
              <Input
                label="Nama Tahanan"
                onChange={(e) => {
                  setNamaTahanan(e.target.value);
                }}
              ></Input>
            </Col>
            <Col sm="12">
              <Table className="table dems-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Tahanan</th>
                    <th>Asal Satker</th>
                    <th>Perkara</th>
                    <th>Lokasi</th>
                    <th>Pilih</th>
                  </tr>
                </thead>
                <tbody>
                  {modalFetching && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="small"
                        />
                      </td>
                    </tr>
                  )}
                  {!modalFetching &&
                    dataTahananList.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.nama_tersangka}</td>
                        <td>{data.nama_satker}</td>
                        <td>{data.perkara}</td>
                        <td>{data.nama_rutan}</td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            onClick={() => {
                              setIdTahanan(data.id);
                              setModalShow(false);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default FormBonTahanan;
