import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDeleteTahti, useGetTahtiList } from "../../../../utils/apis/query";
import { Input, MyPagination, Select } from "../../../../components/Components";
import { debounce } from "lodash";
import FormBonTahanan from "./Form";

function List() {
  const [payloadDebounced, setPayloadDebounced] = useState({
    nama_tersangka: "",
    page: 1,
    size: 10,
  });
  const [search, setSearch] = useState("");
  const {
    data: dataList,
    pagination,
    isFetching: dataFetching,
    isError: dataIsError,
    error: dataError,
  } = useGetTahtiList(payloadDebounced);

  const handleDebounce = useCallback(
    debounce((newPayload) => {
      setPayloadDebounced(newPayload);
    }, 300),
    []
  );
  //   const handlePagination = (page) =>
  //     setPayloadDebounced({ ...payloadDebounced, page: page });

  useEffect(() => {
    handleDebounce({ ...payloadDebounced, menu: search });
  }, [search, handleDebounce, payloadDebounced]);
  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/tahanan/bon/list">Tahanan</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/tahanan/bon/list">Bon Tahanan List</Link>
            </li>
            <li className="breadcrumb-item active fw-bold">Tahanan List</li>
          </ol>
        </div>
      </div>
      <Row>
        <Col xl="12">
          <Card className="card bg-white">
            <Card.Body>
              <Row className="g-1 mb-2">
                <Col sm="12" md="5">
                  <Input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Col>
                <Col sm="12" md="7">
                  <Row className="g-2">
                    <Col sm="3">
                      <Select
                        required
                        default="Pilih Satker"
                        options={[
                          { text: "POLRES JAKSEL", value: "Laki-Laki" },
                        ]}
                      />
                    </Col>
                    <Col sm="3">
                      <Select
                        required
                        default="Pilih Perkara"
                        options={[
                          { text: "Pengalihan Tahanan", value: "Laki-Laki" },
                        ]}
                      />
                    </Col>
                    <Col sm="3">
                      <Select
                        required
                        default="Pilih Status"
                        options={[{ text: "Ditahan", value: "Laki-Laki" }]}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Table className="table dems-table">
                <thead>
                  <tr>
                    <th>ID Tahanan</th>
                    <th>Nama Tahanan</th>
                    <th>Asal Satker</th>
                    <th>Perkara</th>
                    <th>Lokasi</th>
                    <th>Status</th>
                    <th style={{ textAlign: "right" }}>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {dataFetching && (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        <Spinner animation="border" variant="primary" />
                      </td>
                    </tr>
                  )}
                  {dataIsError &&
                    (dataError?.response?.data?.detail?.map((err, idx) => (
                      <tr key={idx}>
                        <td
                          colSpan="4"
                          style={{ textAlign: "center" }}
                          className="text-danger"
                        >
                          <span>
                            <b>{err.loc[1]} </b>
                            {err.msg}
                          </span>
                        </td>
                      </tr>
                    )) ?? (
                      <tr>
                        <td
                          colSpan="4"
                          style={{ textAlign: "center" }}
                          className="text-danger"
                        >
                          <span>
                            <b>Opps Error </b>Reload page or please wait until
                            we fix this issue
                          </span>
                        </td>
                      </tr>
                    ))}
                  {dataList.length && !dataFetching && !dataIsError
                    ? dataList.map((data, index) => (
                        <tr key={index}>
                          <td>{data.no_tahanan}</td>
                          <td>{data.nama_tersangka}</td>
                          <td>{data.nama_satker}</td>
                          <td>{data.perkara}</td>
                          <td>{data.alamat}</td>
                          <td>{data.status_tahanan}</td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end gap-2">
                              <Link
                                to={"/tahanan/bon/form?id_tahanan=" + data.id}
                              >
                                <Badge className="btn bg-primary" as="button">
                                  Buat Bon
                                </Badge>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))
                    : !dataFetching &&
                      !dataList.length &&
                      !dataIsError && (
                        <tr>
                          <td colSpan="4" style={{ textAlign: "center" }}>
                            <span>Tidak ada data tahanan titipan!</span>
                          </td>
                        </tr>
                      )}
                </tbody>
              </Table>
            </Card.Body>
            {/* <Card.Footer className="bg-white">
              <MyPagination
                currentPage={payloadDebounced.page}
                totalPages={pagination.total_pages}
                onPageChange={handlePagination}
              />
            </Card.Footer> */}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default List;
