import React, { useEffect } from "react";
import { Row, Col, Tabs, Tab, Card } from "react-bootstrap";
import { DashboardMap } from "../module";

const markersMap = [
  { latLng: [5.54829, 95.323753], name: "Banda Aceh (ACEH)" },
  { latLng: [3.5952, 98.6722], name: "Medan (SUMUT)" },
  { latLng: [-0.9471, 100.4172], name: "Padang (SUMBAR)" },
  { latLng: [0.5071, 101.4478], name: "Pekanbaru (RIAU)" },
  { latLng: [-3.8004, 102.2655], name: "Bengkulu (BENGKULU)" },
  { latLng: [-1.4855, 103.7615], name: "Jambi (JAMBI)" },
  { latLng: [0.9186, 104.4713], name: "Tanjung Pinang (KEPRI)" },
  { latLng: [-2.1318, 106.1166], name: "Pangkal Pinang (BABEL)" },
  { latLng: [-2.9909, 104.7566], name: "Palembang (SUMSEL)" },
  { latLng: [-5.45, 105.2667], name: "Bandar Lampung (LAMPUNG)" },
  { latLng: [-6.2088, 106.8456], name: "Jakarta (METRO JAYA)" },
  { latLng: [-6.1209, 106.1502], name: "Serang (BANTEN)" },
  { latLng: [-6.9175, 107.6191], name: "Bandung (JABAR)" },
  { latLng: [-6.9667, 110.4167], name: "Semarang (JATENG)" },
  { latLng: [-7.7975, 110.3705], name: "Yogyakarta (DIY)" },
  { latLng: [-7.2575, 112.7521], name: "Surabaya (JATIM)" },
  { latLng: [-8.6705, 115.2126], name: "Denpasar (BALI)" },
  { latLng: [-8.5833, 116.1167], name: "Mataram (NTB)" },
  { latLng: [-10.1667, 123.5833], name: "Kupang (NTT)" },
  { latLng: [-0.0205, 109.3414], name: "Pontianak (KALBAR)" },
  { latLng: [-3.3236, 114.5908], name: "Banjarmasin (KALSEL)" },
  { latLng: [-2.2083, 113.9167], name: "Palangkaraya (KALTENG)" },
  { latLng: [-0.5022, 117.1536], name: "Samarinda (KALTIM)" },
  { latLng: [2.8405, 117.3643], name: "Tanjung Selor (KALTARA)" },
  { latLng: [-5.1477, 119.4327], name: "Makassar (SULSEL)" },
  { latLng: [-3.9953, 122.515], name: "Kendari (SULTRA)" },
  { latLng: [-0.8974, 119.8707], name: "Palu (SULTENG)" },
  { latLng: [1.4748, 124.8428], name: "Manado (SULUT)" },
  { latLng: [-2.6786, 118.8871], name: "Mamuju (SULBAR)" },
  { latLng: [0.5469, 123.0595], name: "Gorontalo (GORONTALO)" },
  { latLng: [-3.6557, 128.19], name: "Ambon (MALUKU)" },
  { latLng: [0.7361, 127.5444], name: "Sofifi (MALUT)" },
  { latLng: [-2.5337, 140.7181], name: "Jayapura (PAPUA)" },
  { latLng: [-0.8615, 134.0625], name: "Manokwari (PAPUA BARAT)" },
];
function Index() {
  return (
    <React.Fragment>
      <div className="mb-3">
        <h4 className="main-title mb-0">Dashboard Arsip Mindik</h4>
      </div>
      <Row>
        <Col sm="12">
          <Card className="card border-0 shadow bg-white">
            <Card.Body>
              <Row>
                <Col sm="12">
                  <div className="w-100">
                    <Card className="card bg-white">
                      <Card.Body>
                        <DashboardMap markers={markersMap} dataMapMarker={{}} />
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
                <Col sm="12">
                  <Tabs
                    className="dems-tabs nav-line py-3"
                    defaultActiveKey="insight"
                  >
                    {/* <Tab eventKey="statistik" title="Statistik">
                        <Row className='g-3'>
                          <Col sm="12">
                              <Card className='card bg-white border-0'>
                                <Card.Header className='bg-white'>
                                  <Card.Title as="h6">Monthly Growth Revenue</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                  <Row>
                                    <Col sm="6" md="4">
                                      <Card className="card-one card-danger">
                                        <Card.Body>
                                          <h3 className="card-value mb-1 text-white">{'83,239'}</h3>
                                          <small className='text-white'>Total Kasus</small>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                    <Col sm="6" md="4">
                                      <Card className="card-one card-warning">
                                        <Card.Body>
                                          <h3 className="card-value mb-1 text-white">{'8299'}</h3>
                                          <small className='text-white'>Penanganan</small>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                    <Col sm="6" md="4">
                                      <Card className="card-one card-primary">
                                        <Card.Body>
                                          <h3 className="card-value mb-1 text-white">{'839'}</h3>
                                          <small className='text-white'>Selesai</small>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                          </Col>
                          <Col sm="12">
                              <Row>
                                <Col md="6">
                                    <Card className='card bg-white border-0'>
                                      <Card.Header className='bg-white'>
                                        <Card.Title as="h6">Arsip Mindik</Card.Title>
                                      </Card.Header>
                                      <Card.Body>
                                        <Row>
                                          <Col sm="6" md="6">
                                            <Card className="card-one card-secondary">
                                              <Card.Body>
                                                <h3 className="card-value mb-1 text-white">{'83,239'}</h3>
                                                <small className='text-white'>Total Arsip</small>
                                              </Card.Body>
                                            </Card>
                                          </Col>
                                          <Col sm="6" md="6">
                                            <Card className="card-one card-secondary">
                                              <Card.Body>
                                                <h3 className="card-value mb-1 text-white">{'239 MB'}</h3>
                                                <small className='text-white'>Ukuran File</small>
                                              </Card.Body>
                                            </Card>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Card>
                                </Col>
                                <Col md="6">
                                    <Card className='card bg-white border-0'>
                                      <Card.Header className='bg-white'>
                                        <Card.Title as="h6">Statistik Barang Bukti</Card.Title>
                                      </Card.Header>
                                      <Card.Body>
                                        <Row>
                                          <Col sm="6" md="6">
                                            <Card className="card-one card-secondary">
                                              <Card.Body>
                                                <h3 className="card-value mb-1 text-white">{'83,239'}</h3>
                                                <small className='text-white'>File Barang Bukti</small>
                                              </Card.Body>
                                            </Card>
                                          </Col>
                                          <Col sm="6" md="6">
                                            <Card className="card-one card-secondary">
                                              <Card.Body>
                                                <h3 className="card-value mb-1 text-white">{'292 GB'}</h3>
                                                <small className='text-white'>Ukuran File</small>
                                              </Card.Body>
                                            </Card>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Card>
                                </Col>
                              </Row>
                          </Col>
                          <Col sm="12">
                            <Card className='card bg-white border-0'>
                              <Card.Header className='bg-white'>
                                <Card.Title as="h6">Statistik Tahanan</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <Row>
                                  <Col sm="6" md="4">
                                    <Card className="card-one card-primary bg-opacity-75">
                                      <Card.Body>
                                        <h3 className="card-value mb-1 text-white">{'83,239'}</h3>
                                        <small className='text-white'>Total Kasus</small>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col sm="6" md="4">
                                    <Card className="card-one card-primary">
                                      <Card.Body>
                                        <h3 className="card-value mb-1 text-white">{'8299'}</h3>
                                        <small className='text-white'>Penanganan</small>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col sm="6" md="4">
                                    <Card className="card-one card-primary">
                                      <Card.Body>
                                        <h3 className="card-value mb-1 text-white">{'839'}</h3>
                                        <small className='text-white'>Selesai</small>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col sm="12">
                            <Card className='card bg-white border-0'>
                              <Card.Header className='bg-white'>
                                <Card.Title as="h6">Statistik Asal Satuan Kerja</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <Row>
                                  <Col sm="6" md="3">
                                    <Card className="card-one card-secondary">
                                      <Card.Body>
                                        <h3 className="card-value mb-1 text-white">{'83,239'}</h3>
                                        <small className='text-white'>Total Satker</small>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col sm="6" md="3">
                                    <Card className="card-one card-secondary">
                                      <Card.Body>
                                        <h3 className="card-value mb-1 text-white">{'239'}</h3>
                                        <small className='text-white'>Satker Polda</small>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col sm="6" md="3">
                                    <Card className="card-one card-secondary">
                                      <Card.Body>
                                        <h3 className="card-value mb-1 text-white">{'239'}</h3>
                                        <small className='text-white'>Satker Polres</small>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col sm="6" md="3">
                                    <Card className="card-one card-secondary">
                                      <Card.Body>
                                        <h3 className="card-value mb-1 text-white">{'239'}</h3>
                                        <small className='text-white'>Satker Polsek</small>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col sm="12">
                            <Card className='card bg-white border-0'>
                              <Card.Header className='bg-white'>
                                <Card.Title as="h6">Statistik Penyidik</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <Row>
                                  <Col sm="6" md="3">
                                    <Card className="card-one card-secondary">
                                      <Card.Body>
                                        <h3 className="card-value mb-1 text-white">{'83,239'}</h3>
                                        <small className='text-white'>Total Penyidik</small>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col sm="6" md="3">
                                    <Card className="card-one card-secondary">
                                      <Card.Body>
                                        <h3 className="card-value mb-1 text-white">{'239'}</h3>
                                        <small className='text-white'>Penyidik Polda</small>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col sm="6" md="3">
                                    <Card className="card-one card-secondary">
                                      <Card.Body>
                                        <h3 className="card-value mb-1 text-white">{'239'}</h3>
                                        <small className='text-white'>Penyidik Polres</small>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col sm="6" md="3">
                                    <Card className="card-one card-secondary">
                                      <Card.Body>
                                        <h3 className="card-value mb-1 text-white">{'239'}</h3>
                                        <small className='text-white'>Penyidik Polsek</small>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Tab> */}
                    <Tab eventKey="insight" title="Insight">
                      <Row className="mt-2 g-2">
                        <Col sm="12" md="12">
                          Chart Arsip Mindik
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Index;
