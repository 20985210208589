import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import userAvatar from "./../../assets/img/logopolri.webp";
import State from "../../libraries/State";
import { useDeleteTahti, useGetNotification } from "../../utils/apis/query";
import { Button } from "react-bootstrap";

export default function Header() {
  const navigate = useNavigate();
  const state = State.getState();
  const profileData = state.getItem("profileData", true);
  const [payloadDebounced, setPayloadDebounced] = useState({
    search: "",
    status_tahanan: "",
    nama_satker: "",
    perkara: "",
    page: 1,
    size: 3,
  });

  const {
    data: dataList,
    pagination,
    isFetching: dataFetching,
    isError: dataIsError,
    error: dataError,
    refetch: refetchNotification,
  } = useGetNotification(payloadDebounced);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  function NotificationList() {
    /**
     * Render view
     */

    if (!dataFetching && !dataList.length && !dataIsError) {
      return (
        <div className="d-flex justify-content-center">
          <span>Tidak ada notifikasi!</span>
        </div>
      );
    }

    const notiList = dataList.map((item, key) => {
      let link = "";
      if (item.menu === "tahanan") {
        link = "/tahanan/form/";
      } else if (item.menu === "tahanan_lapas") {
        link = "/tahanan/lapas/form/";
      } else if (item.menu === "bon_tahanan") {
        link = "/tahanan/bon/form/";
      } else if (item.menu === "perpanjangan") {
        link = "/tahanan/perpanjangan/edit/";
      } else if (item.menu === "pengeluaran") {
        link = "/tahanan/pengeluaran/edit/";
      } else if (item.menu === "penerimaan") {
        link = "/barbuk/penerimaan/form/";
      } else if (item.menu === "penyerahan") {
        link = "/barbuk/pengeluaran/edit/";
      } else if (item.menu === "pemusnahan") {
        link = "/barbuk/pemusnahan/edit/";
      } else if (item.menu === "pengembalian") {
        link = "/barbuk/pengembalian/edit/";
      } else {
        link = "";
      }
      return (
        <li className="list-group-item" key={key}>
          <div
            className="d-flex align-items-center justify-content-start gap-1 border-bottom"
            style={{ flex: "2" }}
          >
            <div className="d-flex align-items-center me-3">
              <i className="ri-user-line" style={{ fontSize: "1.5rem" }}></i>
            </div>
            <Link
              to={link + item.reff_id}
              style={{
                color: "black",
                textDecoration: "none",
              }}
            >
              <div className="d-flex flex-column" style={{ fontSize: "12px" }}>
                <span>
                  <strong>
                    {item.nama_lengkap} ({item.satker})
                  </strong>{" "}
                  telah membuat <strong>{item.menu}</strong> atas :
                </span>
                <span>No. Tahanan : {item.no_tahanan}</span>
                <span>Nama Tahanan : {item.nama_tersangka}</span>
                <span style={{ fontSize: "10px" }}>
                  {new Date(item.created_date).toLocaleString()}
                </span>
              </div>
            </Link>
          </div>
        </li>
      );
    });

    return <ul className="list-group">{notiList}</ul>;
  }

  /**
   * Action handlers
   */
  function doLogout() {
    State.getState().removeItem("accessToken", true); // remove access token
    localStorage.clear();
    /**
     * Redirect to login
     */
    navigate("/login");
  }

  useEffect(() => {
    const interval = setInterval(() => {
      refetchNotification();
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [refetchNotification]);

  /**
   * Render view
   */
  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
        <i className="ri-menu-2-fill"></i>
      </Link>
      <div className="me-auto"></div>

      <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          {dataList.length > 0 && <small>{dataList.length}</small>}
          <i className="ri-notification-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f me--10-f">
          <div className="dropdown-menu-header">
            <h6 className="dropdown-menu-title">Notifications</h6>
          </div>
          {NotificationList()}
          <div className="dropdown-menu-footer">
            <Link to="/notification/all">Show all Notifications</Link>
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <img src={userAvatar} alt="" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3">
              <img src={userAvatar} alt="" />
            </div>
            <h5 className="mb-1 text-dark fw-semibold">
              {profileData?.profile?.nama_lengkap ?? "-"}
            </h5>
            <p className="fs-sm text-secondary">
              {profileData?.profile?.email ?? "-"}
            </p>

            <nav className="nav">
              <Link to="/profile">
                <i className="ri-edit-2-line"></i>Profile
              </Link>
            </nav>
            <hr />
            <nav className="nav">
              {/* <Link to=""><i className="ri-question-line"></i> Help Center</Link> */}
              <Link onClick={doLogout}>
                <i className="ri-logout-box-r-line"></i> Log Out
              </Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
