import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Input,
  MyPagination,
  Select,
  Select2,
  DatePicker,
} from "../../../components/Components";
import { debounce } from "lodash";
import {
  useGetRiwayatLogList,
  useSatkerTahtiList,
  useGetPoldaPolres,
} from "../../../utils/apis/query";
import Alert from "../../../libraries/Alert";
import moment from "moment";
import State from "../../../libraries/State";
import { methodList } from "../../../utils/dummy/variable";

function List() {
  const state = State.getState();
  const profileData = state.getItem("profileData", true);
  const satker = profileData?.satker?.nama_satker ?? "";
  const isBareskrim = /bareskrim/gi.test(satker);
  const isPolres = /polres/gi.test(satker);
  const [namaSatkerPoldaFilter, setNamaSatkerPoldaFilter] = useState("");
  const [namaSatkerPolresFilter, setNamaSatkerPolresFilter] = useState("");
  const [namaSatkerPoldaPayloadFilter, setNamaSatkerPoldaPayloadFilter] =
    useState("");
  const [namaSatkerPolresPayloadFilter, setNamaSatkerPolresPayloadFilter] =
    useState("");
  const [fetchedOnce, setFetchedOnce] = useState(true);
  const [search, setSearch] = useState("");
  const [formDate, setFormDate] = useState({
    start_date: "",
    end_date: "",
  });
  const [payload, setPayload] = useState({
    search: "",
    start_date: "",
    end_date: "",
    fungsi: "",
    nama_satker_polda: namaSatkerPoldaPayloadFilter,
    nama_satker_polres: namaSatkerPolresPayloadFilter,
    page: 1,
    size: 10,
  });
  const [filters, setFilters] = useState({
    start_date: "",
    end_date: "",
    fungsi: "",
  });

  const { data: dataSatker } = useGetPoldaPolres(
    {
      polda: namaSatkerPoldaFilter,
      polres:
        namaSatkerPolresFilter === "satker_sendiri"
          ? ""
          : namaSatkerPolresFilter,
    },
    {
      enabled: !!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresFilter,
    }
  );

  const optionPolda = [
    { label: "SEMUA POLDA", value: "semua_polda" },
    ...dataSatker.polda.map((o) => ({ label: o.value, value: o.value })),
  ];
  const optionPolres = [
    { label: "SEMUA", value: "semua" },
    { label: "SATKER SENDIRI", value: "satker_sendiri" },
    ...dataSatker.polres.map((o) => ({ label: o.value, value: o.value })),
  ];

  const {
    data: dataList,
    pagination: dataListPagination,
    isFetching: isDataListFetching,
    isError: isDataListError,
    error: dataListError,
    refetch: refetchDataList,
  } = useGetRiwayatLogList(payload);

  const handleDebounce = useCallback(
    debounce(({ field, val }) => {
      setPayload((prev) => ({ ...prev, [field]: val, page: 1 }));
    }, 500),
    []
  );

  const handlePagination = (page) => setPayload({ ...payload, page: page });

  const handleFilters = () => {
    setPayload((prev) => ({
      ...prev,
      nama_satker_polda: namaSatkerPoldaPayloadFilter,
      nama_satker_polres: namaSatkerPolresPayloadFilter,
      start_date: filters.start_date,
      end_date: filters.end_date,
      fungsi: filters.fungsi,
      page: 1,
    }));
  };

  useEffect(() => {
    if (profileData) {
      moment.locale();
      const satker = profileData?.satker?.nama_satker ?? "";
      const username = profileData?.user?.username ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      if (isBareskrim) {
        if (isBareskrim) {
          if (["kabareskrim", "kapusiknas"].includes(username)) {
            setNamaSatkerPoldaFilter("semua_polda");
            setNamaSatkerPoldaPayloadFilter("semua_polda");
            setPayload((prev) => ({
              ...prev,
              nama_satker_polda: "semua_polda",
              // nama_satker_polres: "semua_polres",
            }));
          } else {
            setNamaSatkerPoldaFilter(satker);
            setNamaSatkerPoldaPayloadFilter(satker);
            setPayload((prev) => ({
              ...prev,
              nama_satker_polda: satker,
              // nama_satker_polres: "semua_polres",
            }));
          }
        }
        setNamaSatkerPolresFilter("satker_sendiri");
      } else {
        if (isPolres) {
          let namaPolda = profileData?.profile?.nama_satker_parent_polres ?? "";
          setNamaSatkerPoldaFilter(namaPolda);
          setNamaSatkerPoldaPayloadFilter(namaPolda);
          setPayload((prev) => ({
            ...prev,
            nama_satker_polda: namaPolda,
          }));
        } else {
          setNamaSatkerPoldaFilter(satker);
          setNamaSatkerPoldaPayloadFilter(satker);
          setNamaSatkerPolresFilter("satker_sendiri");
          setPayload((prev) => ({
            ...prev,
            nama_satker_polda: satker,
            nama_satker_polres: "satker_sendiri",
          }));
        }
        // setNamaSatkerPoldaFilter(!isPolres ? satker : "");
        // setNamaSatkerPolresFilter(isPolres ? satker : "satker_sendiri");
        // setPayload((prev) => ({
        //   ...prev,
        //   nama_satker_polda: !isPolres ? satker : "",
        //   nama_satker_polres: isPolres ? satker : "satker_sendiri",
        // }));
      }
    }
  }, [profileData]);
  useEffect(() => {
    if (profileData) {
      const satker = profileData?.satker?.nama_satker ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      const isfilterPoldaAll = namaSatkerPoldaFilter === "semua_polda";

      if (isBareskrim || isfilterPoldaAll) {
        const filterBareskrim = /bareskrim/gi.test(namaSatkerPoldaFilter);
        if (!namaSatkerPolresFilter)
          setNamaSatkerPolresPayloadFilter("satker_sendiri");
        if (
          isfilterPoldaAll &&
          namaSatkerPolresPayloadFilter === "satker_sendiri"
        ) {
          setNamaSatkerPoldaPayloadFilter(
            dataSatker.polda.map((o) => o.value).join(",")
          );
        }
        if (isfilterPoldaAll && namaSatkerPolresPayloadFilter === "semua") {
          setNamaSatkerPoldaPayloadFilter("semua_polda");
        }
        if (namaSatkerPolresFilter === "semua") {
          let payload = filterBareskrim
            ? ""
            : dataSatker.polres.map((o) => o.value).join(",");
          setNamaSatkerPolresPayloadFilter(payload);
        }
      } else {
        if (isPolres) {
          let namaPolres = profileData?.profile?.nama_satker_tahti ?? "";
          setNamaSatkerPolresFilter(namaPolres);
          setNamaSatkerPolresPayloadFilter(namaPolres);
        } else {
          if (!namaSatkerPolresFilter)
            setNamaSatkerPolresPayloadFilter("satker_sendiri");
          if (namaSatkerPolresFilter === "semua") {
            setNamaSatkerPolresPayloadFilter(
              dataSatker.polres.map((o) => o.value).join(",")
            );
          }
        }
      }
    }
  }, [profileData, dataSatker, namaSatkerPolresFilter, namaSatkerPoldaFilter]);

  useEffect(() => {
    if (isDataListFetching) {
      if (fetchedOnce) setFetchedOnce(false);
    }
  }, [fetchedOnce]);

  // useEffect(() => {
  //   if (namaSatkerPolresPayloadFilter && fetchedOnce) {
  //     refetchDataList();
  //   }
  // }, [namaSatkerPolresPayloadFilter, refetchDataList]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (namaSatkerPoldaFilter !== null || !!namaSatkerPoldaFilter) {
  //       refetchDataList();
  //       setFetchedOnce(false);
  //     }
  //   }, 2000);
  // }, []);

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/pengaturan/pengguna/list">Pengaturan</Link>
            </li>
            <li className="breadcrumb-item active fw-bold" aria-current="page">
              List Riwayat Log
            </li>
          </ol>
        </div>
      </div>
      <Row>
        <Col xl="12">
          <Card className="card bg-white">
            <Card.Body>
              <Row className="g-1 mb-2">
                <Col sm="12" md="6">
                  <Input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      handleDebounce({ field: "search", val: e.target.value });
                    }}
                  />
                </Col>
                <Col
                  sm="12"
                  md="6"
                  className="d-flex justify-content-end align-items-center"
                >
                  <Button
                    variant="danger"
                    onClick={handleFilters}
                    className="btn"
                  >
                    Filter
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12">
                  <Row className="g-2 d-flex justify-content-between align-items-center flex-wrap">
                    <Col>
                      <Select2
                        defaultValue={"Semua Kesatuan"}
                        options={optionPolda}
                        value={namaSatkerPoldaFilter}
                        name="nama_satker_polda"
                        placeholder="Semua Kesatuan"
                        required
                        isClearable
                        isSearchable
                        disabled={!isBareskrim || isPolres}
                        onChange={(e) => {
                          setNamaSatkerPoldaFilter(e.value);
                          setNamaSatkerPoldaPayloadFilter(e.value);
                          setNamaSatkerPolresFilter((prev) => "satker_sendiri");
                          setNamaSatkerPolresPayloadFilter(
                            (prev) => "satker_sendiri"
                          );
                        }}
                      />
                    </Col>
                    <Col>
                      <Select2
                        defaultValue={"Semua Kesatuan"}
                        options={optionPolres}
                        value={namaSatkerPolresFilter}
                        name="nama_satker_polres"
                        placeholder="SEMUA"
                        required
                        isClearable
                        isSearchable
                        disabled={isPolres}
                        onChange={(e) => {
                          setNamaSatkerPolresFilter(e.value);
                          setNamaSatkerPolresPayloadFilter(e.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        default="Target Fungsi"
                        options={methodList}
                        value={filters.fungsi}
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            fungsi: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col>
                      <DatePicker
                        placeholderText="Pilih Tanggal"
                        selectsRange={true}
                        selected={formDate.start_date}
                        startDate={formDate.start_date}
                        endDate={formDate.end_date}
                        dateFormat="dd/MM/yyyy"
                        onChange={(dates) => {
                          const [start, end] = dates || [];
                          const tglAwal = start
                            ? moment(start).format("YYYY-MM-DD")
                            : "";
                          const tglAkhir = end
                            ? moment(end).format("YYYY-MM-DD")
                            : "";

                          setFilters((prev) => ({
                            ...prev,
                            start_date: tglAwal,
                            end_date: tglAkhir,
                          }));
                          setFormDate((prev) => ({
                            ...prev,
                            start_date: start || "",
                            end_date: end || "",
                          }));
                        }}
                        isClearable={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-5">
                <div className="d-flex justify-content-end align-items-center flex-row gap-1">
                  <label className="text-muted">Items per page</label>
                  <Select
                    name="size"
                    options={[
                      { text: "10", value: 10 },
                      { text: "20", value: 20 },
                      { text: "50", value: 50 },
                      { text: "100", value: 100 },
                    ]}
                    value={payload.size}
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        size: e.target.value,
                        page: 1,
                      });
                    }}
                    style={{
                      padding: "2px 4px",
                      fontSize: "0.875rem",
                      width: "60px",
                    }}
                  />
                  <div className="text-muted d-flex justify-content-end">
                    {`${(payload.page - 1) * payload.size + 1}–${Math.min(
                      payload.page * payload.size,
                      dataListPagination.total_items
                    )} of ${dataListPagination.total_items}`}
                  </div>
                </div>
              </Row>
              <div className="table-responsive">
                <Table className="table dems-table">
                  <thead>
                    <tr>
                      <th>Aksi</th>
                      <th>No</th>
                      <th>Username</th>
                      <th>Satker</th>
                      <th>IP Address</th>
                      <th>Target Fungsi</th>
                      <th style={{ width: "250px" }}>Keterangan</th>
                      <th>Waktu Akses</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isDataListFetching && (
                      <tr>
                        <td colSpan="9" style={{ textAlign: "center" }}>
                          <Spinner animation="border" variant="primary" />
                        </td>
                      </tr>
                    )}
                    {isDataListError &&
                      (dataListError?.response?.data?.detail?.map(
                        (err, idx) => (
                          <tr key={idx}>
                            <td
                              colSpan="9"
                              style={{ textAlign: "center" }}
                              className="text-danger"
                            >
                              <span>
                                <b>{err.loc[1]} </b>
                                {err.msg}
                              </span>
                            </td>
                          </tr>
                        )
                      ) ?? (
                        <tr>
                          <td
                            colSpan="9"
                            style={{ textAlign: "center" }}
                            className="text-danger"
                          >
                            <span>
                              <b>Opps Error </b>Reload page or please wait until
                              we fix this issue
                            </span>
                          </td>
                        </tr>
                      ))}
                    {dataList.length && !isDataListFetching && !isDataListError
                      ? dataList.map((data, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center justify-content-start gap-2">
                                <Link
                                  to={"/pengaturan/riwayat/detail/" + data?.id}
                                >
                                  <Badge className="btn bg-info" as="button">
                                    <i className="ri-eye-line"></i>
                                  </Badge>
                                </Link>
                              </div>
                            </td>
                            <td>
                              {(payload.page - 1) * payload.size + index + 1}
                            </td>
                            <td>{data?.username ?? "-"}</td>
                            <td>{data?.satker ?? "-"}</td>
                            <td>{data?.ip_address ?? "-"}</td>
                            <td>{data?.method ?? "-"}</td>
                            <td>{data?.keterangan ?? "-"}</td>
                            <td>
                              {moment(data?.access_time).format(
                                "DD/MM/YYYY HH-mm"
                              ) ?? "-"}
                            </td>
                          </tr>
                        ))
                      : !isDataListFetching &&
                        !dataList.length &&
                        !isDataListError && (
                          <tr>
                            <td colSpan="10" style={{ textAlign: "center" }}>
                              <span>Tidak Ada Data !</span>
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Card.Footer className="bg-white">
              <MyPagination
                currentPage={payload.page}
                totalPages={dataListPagination.total_pages}
                onPageChange={handlePagination}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default List;
