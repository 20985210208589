import React from 'react';
import { Pagination } from 'react-bootstrap';

const DynamicPagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPageItems = () => {
    const items = [];
    const pageNeighbours = 2;
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2; 

    if (totalPages > totalBlocks) {
      let startPage = Math.max(2, currentPage - pageNeighbours);
      let endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let hasLeftSpill = startPage > 2;
      let hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (endPage - startPage + 1);

      if (hasLeftSpill && !hasRightSpill) {
        startPage -= spillOffset;
      } else if (!hasLeftSpill && hasRightSpill) {
        endPage += spillOffset;
      }

      for (let page = startPage; page <= endPage; page++) {
        items.push(
          <Pagination.Item key={page} active={page === currentPage} onClick={() => onPageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }

      if (hasLeftSpill) {
        items.unshift(<Pagination.Ellipsis key="start-ellipsis" />);
        items.unshift(
          <Pagination.Item key={1} onClick={() => onPageChange(1)}>
            1
          </Pagination.Item>
        );
      }

      if (hasRightSpill) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
        items.push(
          <Pagination.Item key={totalPages} onClick={() => onPageChange(totalPages)}>
            {totalPages}
          </Pagination.Item>
        );
      }
    } else {
      for (let page = 1; page <= totalPages; page++) {
        items.push(
          <Pagination.Item key={page} active={page === currentPage} onClick={() => onPageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }
    }

    return items;
  };

  return (
    <Pagination className="justify-content-end pagination-circled mb-0">
      <Pagination.Item disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)}>
        <i className="ri-arrow-left-s-line"></i>
      </Pagination.Item>
      {getPageItems()}
      <Pagination.Item disabled={currentPage === totalPages} onClick={() => onPageChange(currentPage + 1)}>
        <i className="ri-arrow-right-s-line"></i>
      </Pagination.Item>
    </Pagination>
  );
};

export default DynamicPagination;