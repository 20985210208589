import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { Card, Col, Row } from 'react-bootstrap'
import Select from '../../../../../components/inputs/Select';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as d3 from "d3-shape";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function ChartTahti({dataSeries}) {
  const chartRef = useRef(null)
  // Helper function to generate data for the current month
  
  useLayoutEffect(() => {
    const root = am5.Root.new(chartRef.current)
    
    root.setThemes([
        am5themes_Animated.new(root)
    ])

    // Create chart
    const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true
        })
    );

    chart.set("cursor", am5xy.XYCursor.new(root, {}));
    chart.set("wheelY", "none");

    // Create axes
    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 30 })
    }));

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
    }));

    // Rotate labels on X-axis for better readability
    xAxis.get("renderer").labels.template.setAll({});

    // Create masuk series
    const masukSeries = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Masuk",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "masuk",
        categoryXField: "category",
        sequencedInterpolation: true,
        clustered: false,
        tooltip: am5.Tooltip.new(root, {
            labelText: "[bold]Tgl:[/] {date}\n[bold]Tahanan Masuk:[/] {masuk}\n[bold]Tahanan Keluar:[/] {tahananKeluarJumlah}\n[bold]",
            pointerOrientation: "down", // Atur orientasi pointer ke bawah
            pointerLength: 10 // Panjang pointer
        })
    }));
  
    masukSeries.columns.template.setAll({
        tooltipText: "{categoryX}: {valueY}",
        width: am5.percent(40),
        strokeOpacity: 0,
        fillOpacity: 0.8,
    });
  
    masukSeries.columns.template.adapters.add("fill", (fill, target) => {
        return target.dataItem.get("valueY") > 0 ? am5.color(0x355798) : am5.color(0xBADDDD); // Blue for positive, Tomato for negative
    });
  
      // Create keluar series
    const keluarSeries = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Keluar",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "keluar",
        categoryXField: "category",
        sequencedInterpolation: true,
        clustered: false,
        // tooltip: am5.Tooltip.new(root, {
        //     labelText: "[bold]Tgl:[/] {categoryX}\n[bold]Masuk:[/] {masuk}\n[bold]Keluar:[/] {keluar}\n[bold]Jumlah:[/] {jumlah}",
        //     pointerOrientation: "down", // Atur orientasi pointer ke bawah
        //     pointerLength: 10 // Panjang pointer
        // })
    }));
  
    keluarSeries.columns.template.setAll({
        tooltipText: "{categoryX}: {valueY}",
        width: am5.percent(40),
        strokeOpacity: 0,
        fillOpacity: 0.8,
    });
  
    keluarSeries.columns.template.adapters.add("fill", (fill, target) => {
        return target.dataItem.get("valueY") > 0 ? am5.color(0x355798) : am5.color(0xBADDDD); // Blue for positive, Tomato for negative
    });

     // Create jumlah series with smoother line
    const jumlahSeries = chart.series.push(am5xy.LineSeries.new(root, {
        name: "Jumlah",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "jumlah",
        categoryXField: "category",
        stroke: am5.color(0x355798),
        curveFactory: d3.curveBumpX,
        tensionX: 1, // Tension for line curvature (0 to 1)
        tooltip: am5.Tooltip.new(root, { labelText: "[bold]Tgl: {date}[/]\n[bold]Jumlah Tahanan:[/] {jumlah} Orang" })
    }));
  
      jumlahSeries.strokes.template.setAll({
        strokeWidth: 2
      });
  
      jumlahSeries.bullets.push(() => {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 5,
            stroke: am5.color(0x355798),
            strokeWidth: 3,
            fill: am5.color("#fff")
          })
        });
      });

    // Get today's date and filter out future dates
    const today = new Date();
    const filteredData = dataSeries.filter(item => {
      const itemDate = new Date(item.date);
      return itemDate <= today;
    });
    // Set data
    const data = dataSeries;
    root._logo.setAll({
        height: -100000000  // Adjust the height as needed
    });
    
    xAxis.data.setAll(data);
    masukSeries.data.setAll(data);
    keluarSeries.data.setAll(data);
    jumlahSeries.data.setAll(filteredData);

    return () => {
        root.dispose();
    };
  }, [dataSeries])
    
  return (
    <Card className='card-one bg-white'>
      <Card.Body className='d-flex flex-column gap-2'>
        {/* <Card.Text as="label" style={{paddingLeft: 28}} className='fs-5 fw-bold'>Jumlah Tahanan Bareskrim Polri Dan Polda Jajaran</Card.Text>
        <Row style={{paddingLeft: 28}}>
          <Col sm="3">
            <Select default="Select Kesatuan" options={[{value: 'Test', text: 'Test'},{value: 'Test', text: 'Test'}]}/>
          </Col>
          <Col sm="3">
            <Select default="Select Bulan" options={[{value: 'Test', text: 'Test'},{value: 'Test', text: 'Test'}]}/>
          </Col>
        </Row> */}
        <div className="chart-box" ref={chartRef}>
            
        </div>
      </Card.Body>
    </Card>
  );
}

export default ChartTahti;