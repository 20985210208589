import React, { useCallback, useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconPicker, Input, Select } from "../../../../components/Components";
import {
  useGetMasterBarbukDetail,
  usePutMasterBarbuk,
} from "../../../../utils/apis/query";

function Form() {
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isFormEditAble, setIsFormEditAble] = useState(true);
  const [barbuk, setBarbuk] = useState({
    jenis: "",
    tipe: "",
    sub_jenis: "",
    nama: "",
    unit: "",
    keterangan: "",
  });
  const onSuccess = () => {
    setTimeout(() => {
      navigate("/evidences");
    }, 1500);
  };
  const mutateEdit = usePutMasterBarbuk(onSuccess);
  const {
    data: detailData,
    isFetching: detailIsFetching,
    isError: detailIsError,
    error: detailError,
  } = useGetMasterBarbukDetail(id, { enabled: !!id });

  const handleChange = (e) => {
    let { name, value } = e.target;
    setBarbuk({ ...barbuk, [name]: value });
  };
  const handleSubmit = () => {
    if (isEdit) {
      mutateEdit.mutate({ id, barbuk });
    }
  };
  useEffect(() => {
    setIsEdit(false);
    if (id) {
      setIsEdit(true);
      setIsFormEditAble(false);
    }
  }, [id]);

  useEffect(() => {
    if (detailData) {
      setBarbuk({
        ...barbuk,
        jenis: detailData.jenis,
        tipe: detailData.tipe,
        sub_jenis: detailData.sub_jenis,
        nama: detailData.nama,
        unit: detailData.unit,
        keterangan: detailData.keterangan,
      });
    }
  }, [detailData]);

  useEffect(() => {
    return () => {
      setBarbuk({
        jenis: "",
        tipe: "",
        sub_jenis: "",
        nama: "",
        unit: "",
        keterangan: "",
      });
    };
  }, []);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/master/barang-bukti/list">
                    Master Barang Bukti
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/master/barang-bukti/list">
                    List Master Barang Bukti
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form {isEdit ? "Edit" : "Add"} Master Barang Bukti
                </li>
              </ol>
            </div>
            {isEdit && (
              <Button
                className="d-flex align-items-center gap-2"
                variant="primary"
                size="normal"
                onClick={() => setIsFormEditAble(!isFormEditAble)}
              >
                <i className="ri-edit-2-line"></i>
                {isFormEditAble ? "Batalkan Edit" : "Edit Form"}
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {mutateEdit.isError && (
                <Alert variant="danger">
                  <ul>
                    {mutateEdit?.error?.response?.data?.detail?.map(
                      (errEdit) => (
                        <li>
                          <b>{errEdit.loc[0]} </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error</b>Sesuatu Bermasalah
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateEdit.isSuccess && (
                <Alert variant="success">{mutateEdit.data.data.message}</Alert>
              )}
            </Col>
            <Col sm="6">
              <Input
                label="Jenis"
                name="jenis"
                value={barbuk.jenis}
                onChange={handleChange}
                required
                disabled={!isFormEditAble}
              />
            </Col>
            <Col sm="6">
              <Input
                label="Sub Jenis"
                name="sub_jenis"
                value={barbuk.sub_jenis}
                onChange={handleChange}
                required
                disabled={!isFormEditAble}
              />
            </Col>
            <Col sm="6">
              <Input
                label="Tipe"
                name="tipe"
                value={barbuk.tipe}
                onChange={handleChange}
                required
                disabled={!isFormEditAble}
              />
            </Col>
            <Col sm="6">
              <Input
                label="Unit"
                name="unit"
                value={barbuk.unit}
                onChange={handleChange}
                required
                disabled={!isFormEditAble}
              />
            </Col>
            {/* <Col sm="6">
              <Input
                label="Keterangan"
                name="keterangan"
                value={barbuk.keterangan}
                onChange={handleChange}
                required
                disabled={!isFormEditAble}
              />
            </Col> */}
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center">
            {!mutateEdit.isLoading ? (
              <Button variant="primary" onClick={handleSubmit}>
                {isEdit ? "Save" : "Submit"}
              </Button>
            ) : (
              <Button variant="primary" disabled>
                Loading
              </Button>
            )}
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default Form;
