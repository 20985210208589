import { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteMenu,
  getExtrackedFileAll,
  getExtractedFile,
  getMenuDetail,
  getMenuList,
  getProfile,
  updateProfilePassword,
  getSatkerList,
  getSharedFileAll,
  getUsers,
  postMenu,
  postSharedFile,
  putMenu,
  getTahtiList,
  getTahtiBonList,
  postTahti,
  putTahti,
  getDetailTahti,
  deleteTahti,
  getBonTahanan,
  postBonTahanan,
  putBonTahanan,
  getDetailBonTahanan,
  deleteBonTahanan,
  getRutanList,
  getPenyidikList,
  getPenyidik,
  postPenyidik,
  deletePenyidik,
  postPenangguhan,
  getPembantaran,
  getDetailPembantaran,
  postPembantaran,
  putPembantaran,
  deletePembantaran,
  postPerpanjangan,
  getPerpanjangan,
  delPerpanjangan,
  getPerpanjanganDetail,
  putPerpanjangan,
  delPengeluaran,
  putPengeluaran,
  postPengeluaran,
  getPengeluaranDetail,
  getPengeluaran,
  getTypeDetail,
  getTypeList,
  postType,
  putType,
  deleteType,
  getUnitDetail,
  getUnitList,
  putUnit,
  postUnit,
  deleteUnit,
  getSatkerDetail,
  getSatkersList,
  putSatker,
  postSatker,
  deleteSatker,
  getPoldaList,
  getUserList,
  getUserDetail,
  postUser,
  putUser,
  deleteUser,
  getPenyerahan,
  getPemusnahan,
  getPengembalian,
  getPenyerahanDetail,
  postPenyerahan,
  putPenyerahan,
  delPenyerahan,
  getDetailTahananBarbuk,
  getPenerimaanBarbukList,
  putPenerimaanBarbuk,
  postPenerimaanBarbuk,
  deletePenerimaanBarbuk,
  getDetailPenerimaanBarbuk,
  getMasterBarbukList,
  getMasterBarbukDetail,
  uploadMasterBarbuk,
  putMasterBarbuk,
  deleteMasterBarbuk,
  getMasterBarbukListNew,
  getBarbukTahananList,
  getDetailBarbukTahanan,
  putTagsBarbukTahanan,
  getSatkersTypeList,
  getTahtiPenyerahanList,
  getMasterRoleDetail,
  putMasterRole,
  deleteMasterRole,
  postMasterRole,
  getMasterRoleList,
  getMasterDaerah,
  getSatkersTahtiList,
  getDashboardTahanan,
  getTahananBelumPerpanjang,
  getDashboardBarbuk,
  getLaporanTahanan,
  getLaporanBarbuk,
  getRiwayatLogDetail,
  getRiwayatLogList,
  getLokasiBarbukDetail,
  getLokasiBarbukList,
  putLokasiBarbuk,
  postLokasiBarbuk,
  deleteLokasiBarbuk,
  getPenyimpananBarbukList,
  getDashboardTahananMap,
  getDashboardBarbukMap,
  getMasterBarangBuktiJenis,
  postMasterBarangBuktiJenis,
  putMasterBarangBuktiJenis,
  delMasterBarangBuktiJenis,
  getMasterBarangBuktiSubJenis,
  delMasterBarangBuktiSubJenis,
  putMasterBarangBuktiSubJenis,
  postMasterBarangBuktiSubJenis,
  getMasterBarangBuktiTipe,
  delMasterBarangBuktiTipe,
  putMasterBarangBuktiTipe,
  postMasterBarangBuktiTipe,
  getMasterBarangBuktiUnit,
  postMasterBarangBuktiUnit,
  putMasterBarangBuktiUnit,
  delMasterBarangBuktiUnit,
  getMasterDetailBarangBuktiSubJenis,
  getMasterDetailBarangBuktiJenis,
  getMasterDetailBarangBuktiTipe,
  getMasterDetailBarangBuktiUnit,
  postTahtiLapas,
  putTahtiLapas,
  getDetailTahtiLapas,
  getBarbukByNoLP,
  getNotificationList,
  deleteNotification,
  getExtrackedFile,
  getDashboardTindakPidana,
  getPoldaPolres,
  getLokasiBarbukListNew,
  getBerkas,
  postMasterMeasure,
  getMasterMeasure,
  getDashboardAdmindik,
  getDashboardBarbukList,
  getDashboardTahananBulanan,
  getMasterBarangBuktiSubJenisNew,
  putMasterMeasure,
  getRoles,
  getRegisterList,
  getMenuParentList,
} from "../all";
import { encodeToHex, encryptAES, trimFileUrl, trimUrlPath } from "../../utils";

// master scope
export const useUsersAll = (payload) => {
  const res = useQuery(["getUsers", payload], getUsers, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  return res;
};
export const useSatkerList = () => {
  const res = useQuery(["getSatker"], getSatkerList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? [];
  return res;
};
export const useSatkerTahtiList = () => {
  const res = useQuery(["getSatkerTahti"], getSatkersTahtiList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? [];
  return res;
};
export const useRutanList = () => {
  const res = useQuery(["getRutan"], getRutanList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? [];
  return res;
};
export const usePenyidikList = () => {
  const res = useQuery(["getPenyidik"], getPenyidikList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? [];
  return res;
};

// Profile scope
export const useGetProfile = (onSuccess, onError) => {
  const queryClient = useQueryClient();

  return useMutation(getProfile, {
    onSuccess: (data) => {
      // queryClient.invalidateQueries('postSharedFile');
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },

    retry: 3, // Set the number of retries
    // retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
  });
};

export const useUpdatePasswordProfile = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(updateProfilePassword, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getProfile");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
// extracked file scope
export const useExtrackedFile = (payload) => {
  const res = useQuery(["extrackedFile", payload], getExtractedFile, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  if (!res.isFetching && !res.isError) {
    res.data = res.data.data.value;
    const format = res?.data?.filename?.split(".")?.pop();
    if (["jpg", "jpeg", "gif", "png"].includes(format))
      res.data.typeFormat = "image";
    if (
      [
        "xls",
        "xlsx",
        "xlsm",
        "xlsb",
        "csv",
        "xlt",
        "xltx",
        "xltm",
        "xml",
        "ods",
        "doc",
        "docx",
        "dot",
        "dotx",
        "dotm",
        "rtf",
        "txt",
        "odt",
        "xml",
        "pdf",
        "ppt",
      ].includes(format)
    )
      res.data.typeFormat = "docs";
    if (
      [
        "mp4",
        "avi",
        "mov",
        "wmv",
        "flv",
        "mkv",
        "webm",
        "mpg",
        "3gp",
        "m4v",
        "m2ts",
        "m3u8",
        "mp3",
        "wav",
        "aac",
        "flac",
        "ogg",
        "wma",
        "m4a",
        "alac",
        "aiff",
        "opus",
      ].includes(format)
    )
      res.data.typeFormat = "media";
  }

  return res;
};

export const useExtrackedFileAll = (payload) => {
  const res = useQuery(["extrackedFileAll", payload], getExtrackedFileAll, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
  });
  res.data = res.data?.data?.value
    ?.filter((e) => !!e)
    .map((dSearch, idxDSearch) => {
      const format = dSearch?.minio_url?.split(".")?.pop();
      if (["jpg", "jpeg", "gif", "png"].includes(format)) {
        dSearch.iconClass = "ri-image-2-line text-primary";
      } else if (
        [
          "xls",
          "xlsx",
          "xlsm",
          "xlsb",
          "csv",
          "xlt",
          "xltx",
          "xltm",
          "xml",
          "ods",
        ].includes(format)
      ) {
        dSearch.iconClass = "ri-file-excel-line text-success";
      } else if (
        [
          "doc",
          "docx",
          "dot",
          "dotx",
          "dotm",
          "rtf",
          "txt",
          "odt",
          "xml",
        ].includes(format)
      ) {
        dSearch.iconClass = "ri-image-2-line text-primary";
      } else if (
        [
          "mp4",
          "avi",
          "mov",
          "wmv",
          "flv",
          "mkv",
          "webm",
          "mpg",
          "3gp",
          "m4v",
          "m2ts",
          "m3u8",
        ].includes(format)
      ) {
        dSearch.iconClass = "ri-file-video-line text-primary";
      } else if (
        [
          "mp3",
          "wav",
          "aac",
          "flac",
          "ogg",
          "wma",
          "m4a",
          "alac",
          "aiff",
          "opus",
        ].includes(format)
      ) {
        dSearch.iconClass = "ri-file-music-line text-primary";
      } else if (format === "ppt") {
        dSearch.iconClass = "ri-file-ppt-line text-orange";
      } else if (format === "pdf") {
        dSearch.iconClass = "ri-file-pdf-2-line text-danger";
      } else {
        dSearch.iconClass = "ri-file-line";
      }
      dSearch.link = `/arsip/view/${dSearch.file_name}?kategori=${
        trimUrlPath(dSearch?.minio_url).k
      }&p=${encodeToHex(dSearch.file_path)}`;
      return dSearch;
    });

  return res;
};
export const useExtrackedFileNew = (payload, options = {}) => {
  const res = useQuery(["extrackedFileNew", payload], getExtrackedFile, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
  res.data = res.data?.data?.value ?? [];

  return res;
};
export const useGetBerkasFolder = (payload, options = {}) => {
  const res = useQuery(["getBerkasFolder", payload], getBerkas, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
  res.data = res.data?.data?.value;

  return res;
};
// Shared file scope
export const useSharedFileAll = (payload) => {
  const res = useQuery(["sharedFileAll", payload], getSharedFileAll, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
  });

  res.data =
    res?.data?.data?.value?.map((data) => {
      const format = data?.file_name?.split(".")?.pop();
      if (["jpg", "jpeg", "gif", "png"].includes(format)) {
        data.iconClass = "ri-image-2-line text-primary";
      } else if (
        [
          "xls",
          "xlsx",
          "xlsm",
          "xlsb",
          "csv",
          "xlt",
          "xltx",
          "xltm",
          "xml",
          "ods",
        ].includes(format)
      ) {
        data.iconClass = "ri-file-excel-line text-success";
      } else if (
        [
          "doc",
          "docx",
          "dot",
          "dotx",
          "dotm",
          "rtf",
          "txt",
          "odt",
          "xml",
        ].includes(format)
      ) {
        data.iconClass = "ri-image-2-line text-primary";
      } else if (
        [
          "mp4",
          "avi",
          "mov",
          "wmv",
          "flv",
          "mkv",
          "webm",
          "mpg",
          "3gp",
          "m4v",
          "m2ts",
          "m3u8",
        ].includes(format)
      ) {
        data.iconClass = "ri-file-video-line text-primary";
      } else if (
        [
          "mp3",
          "wav",
          "aac",
          "flac",
          "ogg",
          "wma",
          "m4a",
          "alac",
          "aiff",
          "opus",
        ].includes(format)
      ) {
        data.iconClass = "ri-file-music-line text-primary";
      } else if (format === "ppt") {
        data.iconClass = "ri-file-ppt-line text-orange";
      } else if (format === "pdf") {
        data.iconClass = "ri-file-pdf-2-line text-danger";
      } else {
        data.iconClass = "ri-file-line";
      }

      data.link = `/arsip/view/${data.file_name}?kategori=${
        trimUrlPath(data?.minio_url).k
      }&p=${encodeToHex(data.file_path)}`;
      return data;
    }) ?? [];
  return res;
};
export const usePostSharedFile = (onSuccess, onError) => {
  const queryClient = useQueryClient();

  return useMutation(postSharedFile, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("postSharedFile");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
// Menus scope
export const useGetMenuDetail = (payload, opt = {}) => {
  const res = useQuery(["getMenuDetail", payload], getMenuDetail, {
    ...opt,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const useGetMenuList = (payload) => {
  const res = useQuery(["getMenuList", payload], getMenuList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const usePostMenus = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postMenu, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMenuList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const usePutMenus = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putMenu, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMenuList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useDeleteMenu = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteMenu, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMenuList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

export const useGetMenuParentList = (payload) => {
  const res = useQuery(["getMenuParentList", payload], getMenuParentList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value ?? []
  return res;
};

// TAHANAN TITIPAN SCOPE
export const useGetTahtiList = (payload, option = {}) => {
  const res = useQuery(["getTahtiList", payload], getTahtiList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetRegisterList = (payload, option = {}) => {
  const res = useQuery(["getTahtiList", payload], getRegisterList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetTahtiBonList = (payload, option = {}) => {
  const res = useQuery(["getTahtiList", payload], getTahtiBonList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const usePostTahti = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postTahti, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getTahtiList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const usePostTahtiLapas = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postTahtiLapas, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getTahtiList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const usePutTahti = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putTahti, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getTahtiList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const usePutTahtiLapas = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putTahtiLapas, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getTahtiList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useGetDetailTahti = (payload, opt = {}) => {
  const res = useQuery(["getTahtiDetail", payload], getDetailTahti, {
    ...opt,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const useGetDetailTahtiLapas = (payload, opt = {}) => {
  const res = useQuery(["getTahtiLapasDetail", payload], getDetailTahtiLapas, {
    ...opt,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const useGetDetailTahananBarbuk = (payload, opt = {}) => {
  const res = useQuery(
    ["getTahananBarbukDetail", payload],
    getDetailTahananBarbuk,
    {
      ...opt,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    }
  );
  res.data = res?.data?.data?.value ?? null;
  // console.log(res);

  return res;
};
export const useDeleteTahti = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteTahti, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getTahtiList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

// BON TAHANAN SCOPE
export const useGetBonTahananList = (payload) => {
  const res = useQuery(["getBonTahananList", payload], getBonTahanan, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const usePostBonTahanan = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postBonTahanan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getBonTahananList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const usePutBonTahanan = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putBonTahanan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getBonTahananList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useGetDetailBonTahanan = (payload, opt = {}) => {
  const res = useQuery(["getDetailBonTahanan", payload], getDetailBonTahanan, {
    ...opt,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const useDeleteBonTahanan = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteBonTahanan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getBonTahananList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

// PENYIDIK SCOPE
export const useGetPenyidikList = (payload) => {
  const res = useQuery(["getPenyidikList", payload], getPenyidik, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const usePostPenyidik = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postPenyidik, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPenyidikList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useDeletePenyidik = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deletePenyidik, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPenyidikList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

// PENANGGUHAN TAHANAN SCOPe
export const usePostPenangguhan = (onSuccess, onError) => {
  return useMutation(postPenangguhan, {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

// PEMBANTARAN TAHANAN SCOPE
export const useGetPembantaranList = (payload) => {
  const res = useQuery(["getPembantaranList", payload], getPembantaran, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const usePostPembantaran = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postPembantaran, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPembantaranList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const usePutPembantaran = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putPembantaran, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPembantaranList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useGetDetailPembantaran = (payload, opt = {}) => {
  const res = useQuery(
    ["getDetailPembantaran", payload],
    getDetailPembantaran,
    {
      ...opt,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    }
  );
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const useDeletePembantaran = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deletePembantaran, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPembantaranList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

// PERPANJANGAN TAHANAN SCOPe
export const useGetPerpanjangan = (payload) => {
  const res = useQuery(["getListPerpanjangan", payload], getPerpanjangan, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetPerpanjanganDetail = (payload) => {
  const res = useQuery(
    ["getPerpanjanganDetail", payload],
    getPerpanjanganDetail,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    }
  );
  res.data = res?.data?.data?.value ?? {};
  res.data.perpanjangan_files =
    res.data?.perpanjangan_files?.map((f) => {
      let path = "";
      let file = "";
      let kategori = "";
      if (f.arsip) {
        path = trimUrlPath(f.arsip).p;
        kategori = trimUrlPath(f.arsip).k;
        file = trimFileUrl(f.arsip);
      }
      if (f.lampiran) {
        path = trimUrlPath(f.lampiran).p;
        kategori = trimUrlPath(f.lampiran).k;
        file = trimFileUrl(f.lampiran);
      }
      f.path = path;
      f.file = file;
      f.kategori = kategori;
      return f;
    }) ?? [];
  return res;
};
export const usePostPerpanjangan = (onSuccess, onError) => {
  return useMutation(postPerpanjangan, {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const usePutPerpanjangan = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putPerpanjangan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPerpanjanganDetail");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const useDeletePerpanjangann = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(delPerpanjangan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getListPerpanjangan");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

// PENGELUARAN TAHANAN SCOPe
export const useGetPengeluaran = (payload) => {
  const res = useQuery(["getListPengeluaran", payload], getPengeluaran, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetPengeluaranDetail = (payload) => {
  const res = useQuery(
    ["getPengeluaranDetail", payload],
    getPengeluaranDetail,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    }
  );
  res.data = res?.data?.data?.value ?? {};
  res.data.pengeluaran_files =
    res.data?.pengeluaran_files?.map((f) => {
      let path = "";
      let file = "";
      let kategori = "";
      if (f.arsip) {
        path = trimUrlPath(f.arsip).p;
        kategori = trimUrlPath(f.arsip).k;
        file = trimFileUrl(f.arsip);
      }
      if (f.lampiran) {
        path = trimUrlPath(f.lampiran).p;
        kategori = trimUrlPath(f.lampiran).k;
        file = trimFileUrl(f.lampiran);
      }
      f.path = path;
      f.file = file;
      f.kategori = kategori;
      return f;
    }) ?? [];

  return res;
};
export const usePostPengeluaran = (onSuccess, onError) => {
  return useMutation(postPengeluaran, {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const usePutPengeluaran = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putPengeluaran, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPengeluaranDetail");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const useDeletePengeluaran = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(delPengeluaran, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getListPengeluaran");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

// Scope barbuk penyerahan
export const useGetPenyerahan = (payload) => {
  const res = useQuery(["getListPenyerahan", payload], getPenyerahan, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetPemusnahan = (payload) => {
  const res = useQuery(["getListPemusnahan", payload], getPemusnahan, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetPengembalian = (payload) => {
  const res = useQuery(["getListPengembalian", payload], getPengembalian, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetPenyerahanDetail = (payload) => {
  const res = useQuery(["getPenyerahanDetail", payload], getPenyerahanDetail, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });

  res.data = res?.data?.data?.value ?? {};
  return res;
};
export const usePostPenyerahan = (onSuccess, onError) => {
  return useMutation(postPenyerahan, {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const usePutPenyerahan = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putPenyerahan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPenyerahanDetail");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const useDeletePenyerahan = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(delPenyerahan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getListPenyerahan");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useDeletePemusnahan = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(delPenyerahan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getListPemusnahan");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useDeletePengembalian = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(delPenyerahan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getListPengembalian");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

export const useGetTahtiPenyerahanList = (payload, option = {}) => {
  const res = useQuery(
    ["getTahtiPenyerahanList", payload],
    getTahtiPenyerahanList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};

// TIPE BARANG BUKTI SCOPE
export const usePostType = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postType, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getTypeList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useGetTypeDetail = (payload, opt = {}) => {
  const res = useQuery(["getTypeDetail", payload], getTypeDetail, {
    ...opt,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const useGetTypeList = (payload) => {
  const res = useQuery(["getTypeList", payload], getTypeList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const usePutType = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putType, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getTypeList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useDeleteType = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteType, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getTypeList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

// UNIT BARANG BUKTI SCOPE
export const usePostUnit = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postUnit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getUnitList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useGetUnitDetail = (payload, opt = {}) => {
  const res = useQuery(["getUnitDetail", payload], getUnitDetail, {
    ...opt,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const useGetUnitList = (payload) => {
  const res = useQuery(["getUnitList", payload], getUnitList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const usePutUnit = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putUnit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getUnitList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useDeleteUnit = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteUnit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getUnitList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

// SATKERS TAHTI SCOPE
export const useGetSatkerDetail = (payload, opt = {}) => {
  const res = useQuery(["getSatkerDetail", payload], getSatkerDetail, {
    ...opt,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const useGetSatkerList = (payload) => {
  const res = useQuery(["getSatkerList", payload], getSatkersList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });

  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetSatkerTypeList = (payload, opt ={}) => {
  const res = useQuery(["getSatkerTypeList", payload], getSatkersTypeList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...opt
  });

  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value?.map((e) => e) ?? [];
  return res;
};
export const usePostSatkers = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postSatker, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getSatkerList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const usePutSatkers = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putSatker, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getSatkerList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useDeleteSatker = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteSatker, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getSatkerList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useGetPoldaList = (payload) => {
  const res = useQuery(["getSatkerList", payload], getPoldaList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });

  res.data =
    res?.data?.data?.value?.map((e) => ({
      value: e.nama_satker,
      text: e.nama_satker,
    })) ?? [];
  return res;
};
export const useGetSatkerPoldaList = (payload) => {
  const res = useQuery(["getSatkerPoldaList", payload], getPoldaList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });

  res.data = res?.data?.data?.value ?? [];
  return res;
};
export const useGetPoldaPolres = (payload, opt = {}) => {
  const res = useQuery(["getPoldaPolres", payload], getPoldaPolres, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...opt,
  });

  const polda =
    res?.data?.data?.value?.polda?.map((e) => ({
      value: e.nama_satker,
      text: e.nama_satker,
    })) ?? [];
  const polres =
    res?.data?.data?.value?.polres?.map((e) => ({
      value: e.nama_satker,
      text: e.nama_satker,
    })) ?? [];

  res.data = {
    polda: polda,
    polres: polres,
  };
  return res;
};

// USERS SCOPE
export const useGetUserDetail = (payload, opt = {}) => {
  const res = useQuery(["getUserDetail", payload], getUserDetail, {
    ...opt,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const useGetUserList = (payload, opt = {}) => {
  const res = useQuery(["getUserList", payload], getUserList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...opt
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const usePostUsers = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getUserList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const usePutUsers = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getUserList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useDeleteUser = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getUserList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

// PENERIMAAN BARBUK SCOPE
export const useGetPenerimaanBarbukList = (payload, option = {}) => {
  const res = useQuery(
    ["getPenerimaanBarbukList", payload],
    getPenerimaanBarbukList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const usePostPenerimaanBarbuk = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postPenerimaanBarbuk, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPenerimaanBarbukList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const usePutPenerimaanBarbuk = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putPenerimaanBarbuk, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPenerimaanBarbukList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const useGetDetailPenerimaanBarbuk = (payload, opt = {}) => {
  const res = useQuery(
    ["getPenerimaanBarbukDetail", payload],
    getDetailPenerimaanBarbuk,
    {
      ...opt,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    }
  );
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const useDeletePenerimaanBarbuk = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deletePenerimaanBarbuk, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPenerimaanBarbukList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useGetBarbukTahananList = (payload, option = {}) => {
  const res = useQuery(
    ["getPenerimaanBarbukList", payload],
    getBarbukTahananList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetDetailBarbukTahanan = (payload, opt = {}) => {
  const res = useQuery(
    ["getPenerimaanBarbukList", payload],
    getDetailBarbukTahanan,
    {
      ...opt,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    }
  );

  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const usePutTagsBarbukTahanan = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putTagsBarbukTahanan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getPenerimaanBarbukList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useBarbukByNoLP = (payload, option = {}) => {
  const res = useQuery(["getBarbukByNoLP", payload], getBarbukByNoLP, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });

  res.pagination = res?.data?.data?.value?.pagination ?? {};

  const data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];

  const penyidikSet = new Set();
  const penyidik = data.flatMap((item) =>
    item.penyidik.filter((p) => {
      if (!penyidikSet.has(p.id_penyidik)) {
        penyidikSet.add(p.id_penyidik);
        return true;
      }
      return false;
    })
  );
  const result = {
    penyidik: penyidik,
    barang_bukti: data.flatMap((item) => item.transaksi_barang_bukti),
    tanggal_dokumen_no_laporan_polisi:
      data[0]?.tanggal_dokumen_no_laporan_polisi ?? "-",
  };
  res.data = result;
  return res;
};
export const useListNoLP = (payload, option = {}) => {
  const res = useQuery(["getListyNoLP", payload], getBarbukByNoLP, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value?.data ?? [];
  return res;
};
// MASTER BARBUK SCOPE
export const useGetMasterBarbukList = (payload, option = {}) => {
  const res = useQuery(["getMasterBarbukList", payload], getMasterBarbukList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};

// BARANG BUKTI SCOPE
export const useGetMasterBarbukListNew = (payload, option = {}) => {
  const res = useQuery(
    ["getMasterBarbukListNew", payload],
    getMasterBarbukListNew,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );

  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value;
  return res;
};
export const useGetMasterBarbukDetail = (payload, opt = {}) => {
  const res = useQuery(
    ["getMasterBarbukDetail", payload],
    getMasterBarbukDetail,
    {
      ...opt,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    }
  );
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const useUploadMasterBarbuk = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(uploadMasterBarbuk, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterBarbukList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const usePutMasterBarbuk = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putMasterBarbuk, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterBarbukList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useDeleteMasterBarbuk = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteMasterBarbuk, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterBarbukList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

// MASTER ROLE SCOPE
export const useGetMasterRoleList = (payload, option = {}) => {
  const res = useQuery(["getMasterRoleList", payload], getMasterRoleList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetMasterRoleDetail = (payload, opt = {}) => {
  const res = useQuery(["getMasterRoleDetail", payload], getMasterRoleDetail, {
    ...opt,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const usePostMasterRole = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postMasterRole, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterRoleList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const usePutMasterRole = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putMasterRole, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterRoleList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useDeleteMasterRole = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteMasterRole, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterRoleList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

export const useGetMasterDaerah = (payload, option = {}) => {
  const res = useQuery(["getMasterDaerah", payload], getMasterDaerah, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });

  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value;
  return res;
};

// Scope Dashboard
export const useGetDashboardTahanan = (payload, option = {}) => {
  const res = useQuery(["getDashboardTahanan", payload], getDashboardTahanan, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });

  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value;
  return res;
};
export const useGetDashboardTahananBulanan = (payload, option = {}) => {
  const res = useQuery(
    ["getDashboardTahananBulanan", payload],
    getDashboardTahananBulanan,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );

  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const useGetDashboardTahananMap = (payload, option = {}) => {
  const res = useQuery(
    ["getDashboardTahananMap", payload],
    getDashboardTahananMap,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5000,
      ...option,
    }
  );

  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value ?? [];
  return res;
};
export const useGetDashboardBarbukMap = (payload, option = {}) => {
  const res = useQuery(
    ["getDashboardBarbukMap", payload],
    getDashboardBarbukMap,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );

  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value ?? [];
  return res;
};
export const useGetDashboardAdmindik = (payload, option = {}) => {
  const res = useQuery(
    ["getDashboardAdmindik", payload],
    getDashboardAdmindik,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );

  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value;
  return res;
};
export const useGetTahananBelumPerpanjang = (payload, option = {}) => {
  const res = useQuery(
    ["getTahananBelumPerpanjang", payload],
    getTahananBelumPerpanjang,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      // cacheTime: 0,
      // staleTime: 0,

      ...option,
    }
  );

  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value ?? [];
  return res;
};

// DASHBOARD
export const useDashboardBarbuk = (payload, opt = {}) => {
  const res = useQuery(["getDashboardBarbuk", payload], getDashboardBarbuk, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...opt,
  });

  res.data = res?.data?.data?.value;
  return res;
};
export const useDashboardBarbukList = (payload, opt = {}) => {
  const res = useQuery(
    ["getDashboardBarbukList", payload],
    getDashboardBarbukList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...opt,
    }
  );

  res.data = res?.data?.data?.value;
  return res;
};

// LAPORAN
export const useGetLaporanTahanan = (payload, option = {}) => {
  const res = useQuery(["getLaporanTahanan", payload], getLaporanTahanan, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });
  res.data = res?.data?.data?.value ?? [];

  return res;
};
export const useGetLaporanBarbuk = (payload, option = {}) => {
  const res = useQuery(["getLaporanBarbuk", payload], getLaporanBarbuk, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });
  res.data = res?.data?.data?.value ?? [];

  return res;
};

//RIWAYAT LOG
export const useGetRiwayatLogList = (payload) => {
  const res = useQuery(["getRiwayatLogList", payload], getRiwayatLogList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });

  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetRiwayatLogDetail = (payload, opt = {}) => {
  const res = useQuery(["getRiwayatLogDetail", payload], getRiwayatLogDetail, {
    ...opt,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.data = res?.data?.data?.value ?? null;
  return res;
};

//MASTER LOKASI PENYIMPANAN BARANG BUKTI
export const useGetLokasiBarbukDetail = (payload, opt = {}) => {
  const res = useQuery(
    ["getLokasiBarbukDetail", payload],
    getLokasiBarbukDetail,
    {
      ...opt,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    }
  );
  res.data = res?.data?.data?.value ?? null;
  return res;
};
export const useGetLokasiBarbukList = (payload) => {
  const res = useQuery(["getLokasiBarbukList", payload], getLokasiBarbukList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetLokasiBarbukListNew = (payload) => {
  const res = useQuery(
    ["getLokasiBarbukListNew", payload],
    getLokasiBarbukListNew,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    }
  );
  res.data = res?.data?.data?.value ?? [];
  return res;
};
export const usePostLokasiBarbuk = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postLokasiBarbuk, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getLokasiBarbukList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const usePutLokasiBarbuk = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putLokasiBarbuk, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getLokasiBarbukList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};
export const useDeleteLokasiBarbuk = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteLokasiBarbuk, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getLokasiBarbukList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

//PENYIMPANAN BARANG BUKTI
export const useGetPenyimpananBarbukList = (payload, option = {}) => {
  const res = useQuery(
    ["getPenerimaanBarbukList", payload],
    getPenyimpananBarbukList,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};

// Master Barang Bukti
// Jenis
export const useGetMasterBarangBuktiJenis = (payload, option = {}) => {
  const res = useQuery(
    ["getMasterBarangBuktiJenis", payload],
    getMasterBarangBuktiJenis,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetDetailMasterBarangBuktiJenis = (payload, option = {}) => {
  let res = useQuery(
    ["getMasterDetailBarangBuktiJenis", payload],
    getMasterDetailBarangBuktiJenis,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value ?? {};
  return res;
};
export const usePostMasterBarangBuktiJenis = (onSuccess, onError) => {
  return useMutation(postMasterBarangBuktiJenis, {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const usePutMasterBarangBuktiJenis = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putMasterBarangBuktiJenis, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterBarangBuktiJenis");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const useDeleteMasterBarangBuktiJenis = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(delMasterBarangBuktiJenis, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterBarangBuktiJenis");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
// Sub Jenis
export const useGetMasterBarangBuktiSubJenis = (payload, option = {}) => {
  const res = useQuery(
    ["getMasterBarangBuktiSubJenis", payload],
    getMasterBarangBuktiSubJenis,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetMasterBarangBuktiSubJenisNew = (payload, option = {}) => {
  const res = useQuery(
    ["getMasterBarangBuktiSubJenisNew", payload],
    getMasterBarangBuktiSubJenisNew,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value ?? {};
  return res;
};
export const useGetDetailMasterBarangBuktiSubJenis = (payload, option = {}) => {
  let res = useQuery(
    ["getMasterDetailBarangBuktiSubJenis", payload],
    getMasterDetailBarangBuktiSubJenis,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value ?? {};
  return res;
};
export const usePostMasterBarangBuktiSubJenis = (onSuccess, onError) => {
  return useMutation(postMasterBarangBuktiSubJenis, {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const usePutMasterBarangBuktiSubJenis = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putMasterBarangBuktiSubJenis, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterBarangBuktiSubJenis");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const useDeleteMasterBarangBuktiSubJenis = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(delMasterBarangBuktiSubJenis, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterBarangBuktiSubJenis");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
// Tipe
export const useGetMasterBarangBuktiTipe = (payload, option = {}) => {
  const res = useQuery(
    ["getMasterBarangBuktiTipe", payload],
    getMasterBarangBuktiTipe,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetDetailMasterBarangBuktiTipe = (payload, option = {}) => {
  let res = useQuery(
    ["getMasterDetailBarangBuktiTipe", payload],
    getMasterDetailBarangBuktiTipe,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value ?? {};
  return res;
};
export const usePostMasterBarangBuktiTipe = (onSuccess, onError) => {
  return useMutation(postMasterBarangBuktiTipe, {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const usePutMasterBarangBuktiTipe = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putMasterBarangBuktiTipe, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterBarangBuktiTipe");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const useDeleteMasterBarangBuktiTipe = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(delMasterBarangBuktiTipe, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterBarangBuktiTipe");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
// Unit
export const useGetMasterBarangBuktiUnit = (payload, option = {}) => {
  const res = useQuery(
    ["getMasterBarangBuktiUnit", payload],
    getMasterBarangBuktiUnit,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useGetDetailMasterBarangBuktiUnit = (payload, option = {}) => {
  let res = useQuery(
    ["getMasterDetailBarangBuktiUnit", payload],
    getMasterDetailBarangBuktiUnit,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...option,
    }
  );
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value ?? {};
  return res;
};
export const useGetMasterMeasure = (payload, option = {}) => {
  let res = useQuery(["getMasterMeasure", payload], getMasterMeasure, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.map((e, idx) => {
      e.no =
        ((res?.pagination?.page ?? 0) - 1) * (res?.pagination?.size ?? 0) +
        idx +
        1;
      return e;
    }) ?? [];
  return res;
};
export const usePostMasterMeasure = (onSuccess, onError) => {
  return useMutation(postMasterMeasure, {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const usePutMasterMeasure = (onSuccess, onError) => {
  return useMutation(putMasterMeasure, {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const usePostMasterBarangBuktiUnit = (onSuccess, onError) => {
  return useMutation(postMasterBarangBuktiUnit, {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const usePutMasterBarangBuktiUnit = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(putMasterBarangBuktiUnit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterBarangBuktiUnit");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
export const useDeleteMasterBarangBuktiUnit = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(delMasterBarangBuktiUnit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getMasterBarangBuktiUnit");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
    onSettled: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  });
};
// NOTIFICATION
export const useGetNotification = (payload, option = {}) => {
  const res = useQuery(["getNotificationList", payload], getNotificationList, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data =
    res?.data?.data?.value?.data?.map((e, idx) => {
      e.no = (res.pagination.page - 1) * res.pagination.size + idx + 1;
      return e;
    }) ?? [];
  return res;
};
export const useDeleteNotification = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteNotification, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getNotificationList");
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (data) => {
      if (onError) {
        onError(data);
      }
    },
  });
};

// Get Roles
export const useGetRoles = (payload, option = {}) => {
  const res = useQuery(["getRoles", payload], getRoles, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    ...option,
  });
  res.pagination = res?.data?.data?.value?.pagination ?? {};
  res.data = res?.data?.data?.value?.data ?? [];
  return res;
};
