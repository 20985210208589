import React from 'react'

function EmptyData({children}) {
  return (
    <div className='empty-data-dems'>
        <i className="ri-menu-search-line"></i>
        <span>Tidak Ada Data Ditemukam</span>
        {children}
    </div>
  )
}

export default EmptyData