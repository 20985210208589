import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  Button,
  Alert as AlertBS,
  Badge,
  Form,
  Spinner,
} from "react-bootstrap";
import { Input } from "../../../../components/Components";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Alert from "../../../../libraries/Alert";
import {
  useGetDetailBarbukTahanan,
  usePutTagsBarbukTahanan,
} from "../../../../utils/apis/query";

function View() {
  const onSuccess = () => {
    setTimeout(() => {
      navigate("#");
    }, 1500);
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const mutateEdit = usePutTagsBarbukTahanan(onSuccess);
  const [photoBarbuk, setPhotoBarbuk] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const currentPhoto = photoBarbuk[currentImageIndex];
  const [newTag, setNewTag] = useState("");
  const {
    data: dataBarbuk,
    isFetching: barbukIsFetching,
    isError: barbukIsError,
    error: barbukError,
  } = useGetDetailBarbukTahanan(id, { enabled: !!id });

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === photoBarbuk.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? photoBarbuk.length - 1 : prevIndex - 1
    );
  };

  const addTag = () => {
    if (newTag.trim()) {
      const idBarbuk = currentPhoto.id;
      const updatedTags = {
        tags: [...(currentPhoto.tags || []), newTag.trim()],
      };

      mutateEdit.mutate({
        idBarbuk,
        data: {
          tags: updatedTags.tags,
          keterangan: "",
        },
      });

      setPhotoBarbuk((prevPhotos) => {
        const updatedPhotos = [...prevPhotos];
        updatedPhotos[currentImageIndex] = {
          ...currentPhoto,
          tags: updatedTags,
        };
        return updatedPhotos;
      });

      setNewTag("");
    }
  };

  useEffect(() => {
    if (dataBarbuk) {
      setPhotoBarbuk(dataBarbuk?.foto_detail ?? []);
    }
  }, [dataBarbuk]);

  return (
    <React.Fragment>
      <Row className="g-3">
        <Col sm="5">
          <Card className="card-one bg-white">
            <Card.Header>Informasi Barang Bukti dan Tahanan</Card.Header>
            <Card.Body>
              <Table className="bg-white">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "40%" }}>
                      Name
                    </th>
                    <th scope="col">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Tanggal Disimpan</td>
                    <td>{dataBarbuk?.tanggal_penerimaan || "-"}</td>
                  </tr>
                  <tr>
                    <td>Jenis Barang Bukti</td>
                    <td>{dataBarbuk?.jenis || "-"}</td>
                  </tr>
                  <tr>
                    <td>Nama Barang Bukti</td>
                    <td>{dataBarbuk?.nama_barbuk || "-"}</td>
                  </tr>
                  <tr>
                    <td>Jumlah</td>
                    <td>{dataBarbuk?.jumlah || "-"}</td>
                  </tr>
                  <tr>
                    <td>Kondisi Baik</td>
                    <td>{dataBarbuk?.baik || "-"}</td>
                  </tr>
                  <tr>
                    <td>Kondisi Buruk</td>
                    <td>{dataBarbuk?.tidak_baik || "-"}</td>
                  </tr>
                  <tr>
                    <td>Measure</td>
                    <td>{dataBarbuk?.measure || "-"}</td>
                  </tr>
                  <tr>
                    <td>No.Dokumen</td>
                    <td>{dataBarbuk?.no_dokumen || "-"}</td>
                  </tr>
                  <tr>
                    <td>No.LP</td>
                    <td>{dataBarbuk?.no_laporan || "-"}</td>
                  </tr>
                  <tr>
                    <td>No.Tahanan</td>
                    <td>{dataBarbuk?.no_tahanan || "-"}</td>
                  </tr>
                  <tr>
                    <td>Nama Tahanan</td>
                    <td>{dataBarbuk?.nama_tahanan || "-"}</td>
                  </tr>
                  <tr>
                    <td>Tindak Pidana</td>
                    <td>{dataBarbuk?.perkara || "-"}</td>
                  </tr>
                  <tr>
                    <td>Satker</td>
                    <td>{dataBarbuk?.satker || "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="7">
          <Card className="card-one bg-white">
            <Card.Body>
              <div className="img-ocr-wrapper" style={{ textAlign: "center" }}>
                {currentPhoto && (
                  <>
                    <img
                      src={currentPhoto.foto_url}
                      alt="img"
                      style={{ width: "100%" }}
                    />
                    {photoBarbuk.length > 1 && (
                      <div className="mt-3">
                        <Button
                          variant="secondary"
                          onClick={handlePreviousImage}
                        >
                          <i class="ri-skip-left-line"></i> Previous
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={handleNextImage}
                          className="ms-2"
                        >
                          Next <i class="ri-skip-right-line"></i>
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
              {/* {payload.payload.length ? (
                  <div className="control-img">
                    <div className="control"> */}
              {/* <input type='checkbox' checked={showPrediction} onChange={handlePrediction}/> */}
              {/* <span className="text-dark fw-bold">Show Detection</span>
                    </div>
                  </div>
                ) : ( */}
              {/* <span>Data Prediksi Tidak Diketahui</span>
                )} */}
              {/* <canvas ref={canvasRef}/> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mt-1">
        <Col sm="5">
          <Card className="card-one bg-white">
            <Card.Header>Metadata File</Card.Header>
            <Card.Body>
              <Table className="bg-white">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "40%" }}>
                      Name
                    </th>
                    <th scope="col">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Frame Width</td>
                    <td>{currentPhoto?.metadata?.width || "-"}</td>
                  </tr>
                  <tr>
                    <td>Frame Height</td>
                    <td>{currentPhoto?.metadata?.height || "-"}</td>
                  </tr>
                  <tr>
                    <td>File Name</td>
                    <td>{currentPhoto?.metadata?.filename || "-"}</td>
                  </tr>
                  <tr>
                    <td>File Type</td>
                    <td>{currentPhoto?.metadata?.file_type || "-"}</td>
                  </tr>
                  <tr>
                    <td>File Size</td>
                    <td>{currentPhoto?.metadata?.size || "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="7">
          <Card className="card-one bg-white">
            <Card.Header>Informasi Lokasi Penyimpanan</Card.Header>
            <Card.Body>
              <Table className="bg-white">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "40%" }}>
                      Name
                    </th>
                    <th scope="col">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lokasi</td>
                    <td>{dataBarbuk?.lokasi_penyimpanan?.lokasi || "-"}</td>
                  </tr>
                  <tr>
                    <td>Alamat</td>
                    <td>{dataBarbuk?.lokasi_penyimpanan?.alamat || "-"}</td>
                  </tr>
                  <tr>
                    <td>Keterangan</td>
                    <td>{dataBarbuk?.lokasi_penyimpanan?.keterangan || "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default View;
