import React, { useRef } from 'react'
import { useLayoutEffect } from 'react'
import { Card } from 'react-bootstrap'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as d3 from "d3-shape";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function ChartBarTindakPidana(props) {
  const chartRef = useRef(null)
  useLayoutEffect(() => {
    const root = am5.Root.new(chartRef.current)
    
    root.setThemes([
        am5themes_Animated.new(root)
    ])
    root._logo.setAll({
        height: -100000000  
    });
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        paddingLeft:0,
        paddingRight:1
    }));

    chart.set("cursor", am5xy.XYCursor.new(root, {}));
    chart.set("wheelY", "none");
    chart.set("height", 400);

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);
    
    
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, { 
        minGridDistance: 10, 
        minorGridEnabled: true
    });
    
    xRenderer.labels.template.setAll({
        rotation: -45, // Miringkan label sebesar -45 derajat
        centerY: am5.p50,
        centerX: am5.p100,
        paddingTop: 7,
        fontSize: 14,
        maxWidth: 100,  // Maximum width before truncating
        oversizedBehavior: "truncate",
    });
    
    xRenderer.grid.template.setAll({
        location: 1
    })
    
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "label",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
    }));
    
    let yRenderer = am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1
    })
    
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: yRenderer
    }));
    
    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        sequencedInterpolation: true,
        categoryXField: "label",
        tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
        })
    }));
    
    series.columns.template.setAll(
        { 
            cornerRadiusTL: 5, 
            cornerRadiusTR: 5, 
            strokeOpacity: 0 ,
            width: am5.percent(50),
            maxWidth: 40,
        });
    series.columns.template.adapters.add("fill", function (fill, target) {
        if (props.title === 'Perkara') return am5.color(0xA10E1E);        
        return am5.color(0x355798);
    });
    
    series.columns.template.adapters.add("stroke", function (stroke, target) {
        return chart.get("colors").getIndex(series.columns.indexOf(target));
    });
    
    
    // Set data
    let data = props?.dataSeries ?? []
    
    xAxis.data.setAll(data);
    series.data.setAll(data);
    
    
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);
    return () => {
        root.dispose();
    };
  }, [props.dataSeries])
  return (
    <Card className='card-one bg-white'>
      <Card.Body className='d-flex flex-column gap-2'>
        <div className="d-flex justify-content-between">
        <Card.Text as="label" style={{paddingLeft: 0}} className='fs-5 fw-bold'>{props?.title ?? ''}</Card.Text>
        {/* <Card.Text as="label" style={{paddingLeft: 0}} className='fs-6 '>Jumlah {props?.title ?? ''} {props?.dataSeries?.length ?? 0}</Card.Text> */}
        </div>
        <div className="chart-box" ref={chartRef}>
            
        </div>
      </Card.Body>
    </Card>
  )
}

export default ChartBarTindakPidana