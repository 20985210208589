import React, { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Accordion,
  Row,
  Spinner,
  Table,
  Form,
  Modal,
  Placeholder,
  Alert as AlertBS,
} from "react-bootstrap";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import {
  DatePicker,
  Image,
  Input,
  Select,
  Select2,
} from "../../../../components/Components";
import {
  useGetDetailTahananBarbuk,
  useGetDetailTahti,
  useGetTahtiList,
  useGetTahtiPenyerahanList,
  usePostPenyerahan,
  useRutanList,
  useBarbukByNoLP,
  useListNoLP,
  useGetPenyerahanDetail,
} from "../../../../utils/apis/query";
import { debounce } from "lodash";
import { useQueryClient } from "react-query";
import moment from "moment";
import { removeEmptyFields } from "../../../../utils";
import State from "../../../../libraries/State";
import Alert from "../../../../libraries/Alert";
import {
  encodeToHex,
  encryptAES,
  trimFileUrl,
  trimUrlPath,
} from "../../../../utils";
import { data } from "jquery";

function FormCreate() {
  const location = useLocation();
  const isPemusnahan = location.pathname.includes("/pemusnahan");
  const isPengembalian = location.pathname.includes("/pengembalian");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const state = State.getState();
  const userData = state.getItem("profileData", true);
  const [modalTahananShow, setModalTahananShow] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [formErrorMesssage, setFormErrorMesssage] = useState([]);
  const [payloadListTahanan, setPayloadListTahanan] = useState({
    nama_tersangka: "",
    kategori_penyerahan: "",
  });
  const [namaTahanan, setNamaTahanan] = useState("");
  const [idTahanan, setIdTahanan] = useState("");
  const [barbukModalShow, setBarbukModalShow] = useState(false);
  const [penyidik, setPenyidik] = useState([]);
  const [barbukData, setBarbukData] = useState([]);
  const [payloadBarbuk, setPayloadBarbuk] = useState([]);
  const [isAddingRow, setIsAddingRow] = useState(false);
  const [saksiData, setSaksiData] = useState([]);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [saksi, setSaksi] = useState({
    nama: "",
    pekerjaan: "",
    alamat: "",
    keterangan: "",
    no_hp: "",
  });
  const [payloadDate, setPayloadDate] = useState({
    tanggal_penyerahan: "",
    tanggal_pengembalian: "",
    tanggal_tf_kasnegara: "",
    tanggal_lelang: "",
    tanggal_dokumen: "",
    tanggal_pemusnahan: "",
    tanggal_kembali: "",
    jadwal_kembali: "",
    tanggal_pelimpahan_p21: "",
    tanggal_penghentian_sp3: "",
  });

  const [payloadForm, setPayloadForm] = useState({
    tanggal_penyerahan: "",
    tanggal_pengembalian: "",
    tanggal_tf_kasnegara: "",
    tanggal_lelang: "",
    tanggal_dokumen: "",
    tanggal_pemusnahan: "",
    tanggal_kembali: "",
    jadwal_kembali: "",
    no_dokumen: "",
    lokasi: "",
    petugas: "",
    kategori_penyerahan: "",
    saksi_barbuk: "",
    uraian: "",
    keterangan: "",
    kondisi: "",
    nama_tahanan: "",
    no_tahanan: "",
    barang_bukti_ids: [],
    penyidik_ids: [],
    status_dokumen: "",

    tanggal_pelimpahan_p21: "",
    tanggal_penghentian_sp3: "",
    alasan_pelimpahan_p21: "",
    alasan_penghentian_sp3: "",

    no_laporan_polisi: "",
    nomor_nota_dinas_permohonan_pengeluaran_bb: "",
    tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb: "",
    arsip_nota_dinas_permohonan_pengeluaran_bb: [],
    nomor_disposisi_pimpinan_karorenmin_kabagtahti: "",
    tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti: "",
    arsip_disposisi_pimpinan_karorenmin_kabagtahti: [],
    nomor_ba_penitipan_bb: "",
    tanggal_dokumen_ba_penitipan_bb: "",
    arsip_ba_penitipan_bb: [],
    nomor_dokumen_p21_atau_sp3: "",
    tanggal_dokumen_p21_atau_sp3: "",
    arsip_dokumen_p21_atau_sp3: [],
    nomor_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2: "",
    tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2: "",
    arsip_penyerahan_barang_bukti_dan_tersangka_tahap_2: [],
    nomor_dokumen_hasil_gelar_perkara: "",
    tanggal_dokumen_hasil_gelar_perkara: "",
    arsip_dokumen_hasil_gelar_perkara: [],
  });
  const [payloadFormDataTahanan, setPayloadFormDataTahanan] = useState({
    no_tahanan: "",
    no_laporan: "",
    nama: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    umur: "",
    kelamin: "",
    kewarganegaraan: "",
    asal_negara: "",
    agama: "",
    pekerjaan: "",
    alamat: "",
    foto_tahanan: "",
    foto_sidikjari: "",
    perkara: "",
    penempatan_rutan: "",
    lokasi_rutan: "",
    riwayat: [],
    satker: "",
    barang_bukti: [],
  });
  const [payloadLP, setPayloadLP] = useState({
    nama_satker: userData?.profile?.nama_satker_tahti,
    no_laporan_polisi: "",
    page: 1,
    size: 100,
  });

  // END OF STATE GROUP

  const {
    data: dataTahanan,
    isFetching: tahananFetching,
    isError: tahananError,
    error: errorTahanan,
  } = useGetDetailTahananBarbuk(idTahanan, { enabled: !!idTahanan });

  const { data: dataTahananList, isFetching: modalFetching } =
    useGetTahtiPenyerahanList(payloadListTahanan, { enabled: !!namaTahanan });

  const {
    data: dataTransaksi,
    pagination,
    isFetching: dataFetching,
    isError: dataIsError,
    error: dataError,
  } = useBarbukByNoLP(payloadLP, {
    enabled: !!payloadLP.no_laporan_polisi,
  });
  const {
    data: listPenerimaan,
    paginationLP,
    isFetching: dataFetchingLP,
    isError: dataIsErrorLP,
    error: dataErrorLP,
  } = useListNoLP({
    nama_satker: userData?.profile?.nama_satker_tahti,
    size: 100,
    page: 1,
  });

  const listNoLP = Array.from(
    new Set(listPenerimaan?.map((item) => item.no_laporan_polisi))
  ).map((no_laporan_polisi) => ({
    label: no_laporan_polisi,
    value: no_laporan_polisi,
  }));

  const [searchTerm, setSearchTerm] = useState("");
  const filteredBarangBukti = dataTransaksi.barang_bukti.filter((data) =>
    data.no_label_barbuk.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCheckbox = (type, data, isChecked) => {
    if (type === "penyidik") {
      if (isChecked) {
        setPayloadForm({
          ...payloadForm,
          penyidik_ids: [...payloadForm.penyidik_ids, data.id_penyidik],
        });
      } else {
        setPayloadForm({
          ...payloadForm,
          penyidik_ids: payloadForm.penyidik_ids.filter(
            (selectedId) => selectedId !== data.id_penyidik
          ),
        });
      }
    }
    if (type === "barbuk") {
      if (isChecked) {
        setPayloadForm({
          ...payloadForm,
          barang_bukti_ids: [
            ...payloadForm.barang_bukti_ids,
            data.id_transaksi_barbuk,
          ],
        });
      } else {
        setPayloadForm({
          ...payloadForm,
          barang_bukti_ids: payloadForm.barang_bukti_ids.filter(
            (selectedId) => selectedId !== data.id_transaksi_barbuk
          ),
        });
      }
    }
  };
  const handleCheckboxBarbuk = (data, isChecked) => {
    if (isChecked) {
      setPayloadBarbuk([...payloadBarbuk, data]);
    } else {
      setPayloadBarbuk(
        payloadBarbuk.filter(
          (item) => item.id_transaksi_barbuk !== data.id_transaksi_barbuk
        )
      );
    }
  };

  // SAKSI
  const handleAddRow = () => {
    setIsAddingRow(true);
  };
  const closeRow = () => {
    setSaksi({
      nama: "",
      pekerjaan: "",
      alamat: "",
      keterangan: "",
      no_hp: "",
    });
    setIsAddingRow(false);
  };

  const handleSaveRow = () => {
    const inputs = document.querySelectorAll("#addSaksiRow");
    let allValid = true;
    inputs.forEach((input) => {
      if (input.type === "number" && input.value.length > 13) {
        input.setCustomValidity(
          "Panjang nomor tidak boleh lebih dari 13 digit."
        );
        input.reportValidity();
        allValid = false;
      } else if (input.value.length > 50) {
        input.setCustomValidity(
          "Panjang text tidak boleh lebih dari 50 karakter."
        );
      } else {
        input.setCustomValidity("");
      }

      if (!input.reportValidity()) {
        allValid = false;
      }
    });

    if (allValid) {
      setSaksiData([...saksiData, saksi]);
      setSaksi({
        nama: "",
        pekerjaan: "",
        alamat: "",
        keterangan: "",
        no_hp: "",
      });
      setIsAddingRow(false);
    }
  };

  const handleNewRowChange = (e) => {
    const { name, value } = e.target;
    setSaksi({ ...saksi, [name]: value });
  };

  const handleDelete = (index) => {
    const newSaksiData = saksiData.filter((_, idx) => idx !== index);
    setSaksiData(newSaksiData);
  };
  // --------------------------

  const handleChange = (e) => {
    setIsFormDirty(true);
    const { name, value } = e.target;
    setPayloadForm({ ...payloadForm, [name]: value });
  };

  const handleDebounce = useCallback(
    debounce((newPayload) => {
      setPayloadListTahanan(newPayload);
    }, 500),
    []
  );

  const onSuccess = (response) => {
    setIsFormDirty(false);
    setTimeout(() => {
      navigate(
        `/barbuk/${
          isPemusnahan
            ? "pemusnahan"
            : isPengembalian
            ? "pengembalian"
            : "pengeluaran"
        }/list`
      );
    }, 2500);
  };

  const mutateSubmit = usePostPenyerahan(onSuccess);
  const handleSubmit = (status) => {
    setFormErrorMesssage([]);
    const inputs = document.querySelectorAll("input");
    let allValid = true;
    let errFormMsg = [];

    inputs.forEach((input) => {
      if (input.id !== "addSaksiRow") {
        if (!input.reportValidity()) {
          if (input.type === "file") {
            errFormMsg.push("File Input Unggah Arsip / Lampiran");
          } else {
            errFormMsg.push(
              (input?.name ?? "") || "Input Mandatori / Kondisional"
            );
          }
          allValid = false;
        }
      }
    });
    setFormErrorMesssage(errFormMsg);
    if (allValid) {
      payloadForm.status_dokumen = status;
      let formData = new FormData();
      let payload = payloadForm;

      if (payload.kategori_penyerahan !== "Pinjam Pakai") {
        payload = {
          ...payload,
          jadwal_kembali: "",
        };
      }
      if (payload.kategori_penyerahan !== "Pemusnahan") {
        payload = {
          ...payload,
          tanggal_pemusnahan: "",
        };
      }
      if (payload.kategori_penyerahan !== "Lelang") {
        payload = {
          ...payload,
          tanggal_lelang: "",
        };
      }
      if (payload.kategori_penyerahan !== "TF Kas Negara") {
        payload = {
          ...payload,
          tanggal_tf_kasnegara: "",
        };
      }
      if (payload.kategori_penyerahan !== "Pengembalian") {
        payload = {
          ...payload,
          tanggal_pengembalian: "",
        };
      }
      if (payload.kategori_penyerahan !== "Penghentian(SP3)") {
        payload = {
          ...payload,
          tanggal_penghentian_sp3: "",
        };
      }
      if (payload.kategori_penyerahan !== "Pelimpahan(P21)") {
        payload = {
          ...payload,
          tanggal_pelimpahan_p21: "",
        };
      }
      if (payload.kategori_penyerahan !== "Titip Rawat BB") {
        payload = {
          ...payload,
          tanggal_pelimpahan_p21: "",
        };
      }
      if (payload.kategori_penyerahan !== "Penyisihan BB") {
        payload = {
          ...payload,
          tanggal_pelimpahan_p21: "",
        };
      }
      if (payload.kategori_penyerahan !== "Penitipan BB") {
        payload = {
          ...payload,
          tanggal_pelimpahan_p21: "",
        };
      }
      if (payload.kategori_penyerahan !== "Pengembalian BB") {
        payload = {
          ...payload,
          tanggal_pelimpahan_p21: "",
        };
      }
      payload = removeEmptyFields(payload);
      payload.saksi_barbuk = JSON.stringify(saksiData);
      Object.keys(payload).forEach((key) => {
        if (
          typeof payload[key] === "string" ||
          typeof payload[key] === "number"
        ) {
          formData.append(key, payload[key]);
        }
      });
      payload.arsip_nota_dinas_permohonan_pengeluaran_bb?.forEach(
        (file, idx) => {
          formData.append(`arsip_nota_dinas_permohonan_pengeluaran_bb`, file);
        }
      );
      payload.arsip_disposisi_pimpinan_karorenmin_kabagtahti?.forEach(
        (file, idx) => {
          formData.append(
            `arsip_disposisi_pimpinan_karorenmin_kabagtahti`,
            file
          );
        }
      );
      payload.arsip_ba_penitipan_bb?.forEach((file, idx) => {
        formData.append(`arsip_ba_penitipan_bb`, file);
      });
      payload.arsip_dokumen_p21_atau_sp3?.forEach((file, idx) => {
        formData.append(`arsip_dokumen_p21_atau_sp3`, file);
      });
      payload.arsip_penyerahan_barang_bukti_dan_tersangka_tahap_2?.forEach(
        (file, idx) => {
          formData.append(
            `arsip_penyerahan_barang_bukti_dan_tersangka_tahap_2`,
            file
          );
        }
      );
      payload.arsip_dokumen_hasil_gelar_perkara?.forEach((file, idx) => {
        formData.append(`arsip_dokumen_hasil_gelar_perkara`, file);
      });
      payload?.penyidik_ids?.forEach((ids, idx) => {
        formData.append(`penyidik_ids`, ids);
      });
      payload?.barang_bukti_ids?.forEach((obj, idx) => {
        formData.append(`barang_bukti_ids`, obj);
      });
      mutateSubmit.mutate(formData);
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  // USE EFFECT GROUP

  useEffect(() => {
    handleDebounce({
      ...payloadListTahanan,
      nama_tersangka: namaTahanan,
      kategori_penyerahan: payloadForm.kategori_penyerahan,
    });
  }, [namaTahanan, handleDebounce, payloadListTahanan]);

  useEffect(() => {
    if (dataTahanan) {
      const _ = dataTahanan;
      setPayloadFormDataTahanan({
        no_tahanan: _.no_tahanan,
        no_laporan: _.no_laporan,
        nama: _.nama_tersangka,
        tempat_lahir: _.tempat_lahir,
        tanggal_lahir: _.tanggal_lahir,
        umur: _.umur,
        kelamin: _.jenis_kelamin,
        kewarganegaraan: _.kebangsaan,
        asal_negara: _.asal_negara,
        agama: _.agama,
        perkara: _.perkara,
        pekerjaan: _.pekerjaan,
        alamat: _.alamat,
        foto_tahanan: _.rumus_wajah,
        foto_sidikjari: _.rumus_sidikjari,
        penempatan_rutan: _.nama_rutan,
        lokasi_rutan: _.alamat_rutan,
        riwayat: _.riwayat_penyerahan,
        satker: _.nama_satker,
        barang_bukti: _.barang_bukti,
      });
      setPayloadForm({
        ...payloadForm,
        // kondisi: _.ko,
        status_dokumen: _.status_dokumen,
        no_tahanan: _.no_tahanan,
        nama_tahanan: _.nama_tersangka,
      });
      setPenyidik(_?.penyidik ?? []);
    }
  }, [dataTahanan]);

  useEffect(() => {
    if (isPemusnahan) {
      payloadForm.kategori_penyerahan = "Pemusnahan";
    } else if (isPengembalian) {
      payloadForm.kategori_penyerahan = "Pengembalian";
    } else {
      payloadForm.kategori_penyerahan = "Pinjam Pakai";
    }
  }, [isPemusnahan, isPengembalian]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  useEffect(() => {
    if (userData) {
      setPayloadForm({
        ...payloadForm,
        petugas: userData?.profile?.nama_lengkap,
        lokasi: userData?.profile?.nama_satker_tahti,
      });
    }
  }, [userData]);

  const one =
    payloadForm.nomor_nota_dinas_permohonan_pengeluaran_bb &&
    payloadForm.tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb &&
    payloadForm.arsip_nota_dinas_permohonan_pengeluaran_bb.length > 0;
  const two =
    payloadForm.nomor_disposisi_pimpinan_karorenmin_kabagtahti &&
    payloadForm.tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti &&
    payloadForm.arsip_disposisi_pimpinan_karorenmin_kabagtahti.length > 0;
  const three =
    payloadForm.nomor_ba_penitipan_bb &&
    payloadForm.tanggal_dokumen_ba_penitipan_bb &&
    payloadForm.arsip_ba_penitipan_bb.length > 0;
  const four =
    payloadForm.nomor_dokumen_p21_atau_sp3 &&
    payloadForm.tanggal_dokumen_p21_atau_sp3 &&
    payloadForm.arsip_dokumen_p21_atau_sp3.length > 0;
  const five =
    payloadForm.nomor_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2 &&
    payloadForm.tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2 &&
    payloadForm.arsip_penyerahan_barang_bukti_dan_tersangka_tahap_2.length > 0;
  const six =
    payloadForm.nomor_dokumen_hasil_gelar_perkara &&
    payloadForm.tanggal_dokumen_hasil_gelar_perkara &&
    payloadForm.arsip_dokumen_hasil_gelar_perkara.length > 0;

  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/barbuk">Barang Bukti</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    to={`/barbuk/${
                      isPemusnahan
                        ? "pemusnahan"
                        : isPengembalian
                        ? "pengembalian"
                        : "pengeluaran"
                    }/list`}
                  >
                    List{" "}
                    {isPemusnahan
                      ? "Pemusnahan"
                      : isPengembalian
                      ? "Pengembalian"
                      : "Pengeluaran"}{" "}
                    Barang Bukti
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form{" "}
                  {isPemusnahan
                    ? "Pemusnahan"
                    : isPengembalian
                    ? "Pengembalian"
                    : "Pengeluaran"}{" "}
                  Barang Bukti
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {mutateSubmit.isError && (
                <AlertBS variant="danger">
                  <ul className="m-0">
                    {mutateSubmit?.error?.response?.data?.detail?.map(
                      (errEdit, idx) => (
                        <li key={idx}>
                          <b>{errEdit.loc[1]} : </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error : </b>
                        {mutateSubmit?.error?.response?.data?.message ??
                          "Bermasalah"}
                      </li>
                    )}
                  </ul>
                </AlertBS>
              )}
              {mutateSubmit.isSuccess && (
                <AlertBS variant="success">
                  {mutateSubmit.data.data.message}
                </AlertBS>
              )}
            </Col>
            <Col sm="12">
              <Accordion
                defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                flush
                alwaysOpen
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="custom-header">
                    Informasi{" "}
                    {isPemusnahan
                      ? "Pemusnahan"
                      : isPengembalian
                      ? "Pengembalian"
                      : "Pengeluaran"}{" "}
                    Barang Bukti
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="3">
                            {isPemusnahan || isPengembalian ? (
                              <Input
                                label="Kategori Pengeluaran"
                                name="kategori_penyerahan"
                                defaultValue={payloadForm.kategori_penyerahan}
                                onChange={handleChange}
                                disabled
                                required
                              />
                            ) : (
                              <Select
                                label="Kategori Pengeluaran"
                                default=""
                                name="kategori_penyerahan"
                                required
                                value={payloadForm.kategori_penyerahan}
                                onChange={handleChange}
                                options={[
                                  {
                                    value: "Pinjam Pakai",
                                    text: "Pengeluaran Sementara (Pinjam Pakai)",
                                  },
                                  {
                                    value: "Lelang",
                                    text: "Lelang",
                                  },
                                  {
                                    value: "Pelimpahan(P21)",
                                    text: "Tahap 2 (P21)",
                                  },
                                  {
                                    value: "Penghentian(SP3)",
                                    text: "Penghentian(SP3)",
                                  },
                                  {
                                    value: "Titip Rawat BB",
                                    text: "Titip Rawat BB",
                                  },
                                  {
                                    value: "Penyisihan BB",
                                    text: "Penyisihan BB (untuk uji lab)",
                                  },
                                  {
                                    value: "Penitipan BB",
                                    text: "Penitipan BB ke Rupbasan",
                                  },
                                  {
                                    value: "Pengembalian BB",
                                    text: "Pengembalian BB kepada yang berhak",
                                  },
                                ]}
                              />
                            )}
                          </Col>
                          <Col sm="12">
                            <Card className="card bg-white">
                              <Card.Header className="bg-white">
                                <span className="fs-6 fw-medium">Petugas</span>
                              </Card.Header>
                              <Card.Body>
                                <Row className="g-2 mt-1">
                                  <Col sm="3">
                                    <Input
                                      label="Petugas (Serah)"
                                      name="petugas"
                                      required
                                      onChange={handleChange}
                                      defaultValue={payloadForm.petugas}
                                    />
                                  </Col>
                                  <Col sm="3">
                                    <Input
                                      required
                                      label={`
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Pinjam Pakai"
                                                                ? "Lokasi Peminjaman"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Pemusnahan"
                                                                ? "Lokasi Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Lelang"
                                                                ? "Lokasi Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Pengembalian"
                                                                ? "Lokasi Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "TF Kas Negara"
                                                                ? "Lokasi Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Pelimpahan(P21)"
                                                                ? "Lokasi Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Penghentian(SP3)"
                                                                ? "Lokasi Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Titip Rawat BB"
                                                                ? "Lokasi Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Penyisihan BB"
                                                                ? "Lokasi Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Penitipan BB"
                                                                ? "Lokasi Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Pengembalian BB"
                                                                ? "Lokasi Penyerahan"
                                                                : ""
                                                            }
                                                            `}
                                      name="lokasi"
                                      onChange={handleChange}
                                      defaultValue={payloadForm.lokasi}
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="3">
                                    <DatePicker
                                      label={`
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Pinjam Pakai"
                                                                ? "Tanggal Pinjam"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Pemusnahan"
                                                                ? "Tanggal Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Lelang"
                                                                ? "Tanggal Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Pengembalian"
                                                                ? "Tanggal Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "TF Kas Negara"
                                                                ? "Tanggal Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Pelimpahan(P21)"
                                                                ? "Tanggal Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Penghentian(SP3)"
                                                                ? "Tanggal Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Titip Rawat BB"
                                                                ? "Tanggal Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Penyisihan BB"
                                                                ? "Tanggal Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Penitipan BB"
                                                                ? "Tanggal Penyerahan"
                                                                : ""
                                                            }
                                                            ${
                                                              payloadForm.kategori_penyerahan ===
                                                              "Pengembalian BB"
                                                                ? "Tanggal Penyerahan"
                                                                : ""
                                                            }
                                                            `}
                                      timeFormat="HH:mm:ss"
                                      dateFormat="dd/MM/yyyy"
                                      maxDate={new Date()}
                                      onChange={(date) => {
                                        setPayloadDate({
                                          ...payloadDate,
                                          tanggal_penyerahan: date,
                                        });
                                        setPayloadForm({
                                          ...payloadForm,
                                          tanggal_penyerahan:
                                            moment(date).format("YYYY-MM-DD"),
                                        });
                                      }}
                                      selected={payloadDate.tanggal_penyerahan}
                                      required
                                    />
                                  </Col>
                                  <Col sm="3">
                                    {payloadForm.kategori_penyerahan ===
                                      "Pinjam Pakai" && (
                                      <DatePicker
                                        label="Jadwal Kembali"
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => {
                                          setPayloadDate({
                                            ...payloadDate,
                                            jadwal_kembali: date,
                                          });
                                          setPayloadForm({
                                            ...payloadForm,
                                            jadwal_kembali:
                                              moment(date).format("YYYY-MM-DD"),
                                          });
                                        }}
                                        minDate={payloadDate.tanggal_penyerahan}
                                        selected={payloadDate.jadwal_kembali}
                                        required
                                      />
                                    )}
                                    {payloadForm.kategori_penyerahan ===
                                      "Pemusnahan" && (
                                      <DatePicker
                                        label="Tanggal Pemusnahan"
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => {
                                          setPayloadDate({
                                            ...payloadDate,
                                            tanggal_pemusnahan: date,
                                          });
                                          setPayloadForm({
                                            ...payloadForm,
                                            tanggal_pemusnahan:
                                              moment(date).format("YYYY-MM-DD"),
                                          });
                                        }}
                                        selected={
                                          payloadDate.tanggal_pemusnahan
                                        }
                                        minDate={payloadDate.tanggal_penyerahan}
                                        required
                                      />
                                    )}
                                    {payloadForm.kategori_penyerahan ===
                                      "Lelang" && (
                                      <DatePicker
                                        label="Tanggal Lelang"
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => {
                                          setPayloadDate({
                                            ...payloadDate,
                                            tanggal_lelang: date,
                                          });
                                          setPayloadForm({
                                            ...payloadForm,
                                            tanggal_lelang:
                                              moment(date).format("YYYY-MM-DD"),
                                          });
                                        }}
                                        selected={payloadDate.tanggal_lelang}
                                        minDate={payloadDate.tanggal_penyerahan}
                                        required
                                      />
                                    )}
                                    {payloadForm.kategori_penyerahan ===
                                      "Pengembalian" && (
                                      <DatePicker
                                        label="Tanggal Pengembalian"
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => {
                                          setPayloadDate({
                                            ...payloadDate,
                                            tanggal_pengembalian: date,
                                          });
                                          setPayloadForm({
                                            ...payloadForm,
                                            tanggal_pengembalian:
                                              moment(date).format("YYYY-MM-DD"),
                                          });
                                        }}
                                        selected={
                                          payloadDate.tanggal_pengembalian
                                        }
                                        minDate={payloadDate.tanggal_penyerahan}
                                        required
                                      />
                                    )}
                                    {payloadForm.kategori_penyerahan ===
                                      "TF Kas Negara" && (
                                      <DatePicker
                                        label="Tanggal TF Kas Negara"
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => {
                                          setPayloadDate({
                                            ...payloadDate,
                                            tanggal_tf_kasnegara: date,
                                          });
                                          setPayloadForm({
                                            ...payloadForm,
                                            tanggal_tf_kasnegara:
                                              moment(date).format("YYYY-MM-DD"),
                                          });
                                        }}
                                        selected={
                                          payloadDate.tanggal_tf_kasnegara
                                        }
                                        minDate={payloadDate.tanggal_penyerahan}
                                        required
                                      />
                                    )}
                                    {(payloadForm.kategori_penyerahan ===
                                      "Pelimpahan(P21)" ||
                                      payloadForm.kategori_penyerahan ===
                                        "Titip Rawat BB" ||
                                      payloadForm.kategori_penyerahan ===
                                        "Penyisihan BB" ||
                                      payloadForm.kategori_penyerahan ===
                                        "Penitipan BB" ||
                                      payloadForm.kategori_penyerahan ===
                                        "Pengembalian BB") && (
                                      <DatePicker
                                        label={
                                          payloadForm.kategori_penyerahan ===
                                          "Pelimpahan(P21)"
                                            ? "Tanggal Tahap 2 (P21)"
                                            : payloadForm.kategori_penyerahan ===
                                              "Titip Rawat BB"
                                            ? "Tanggal Titip Rawat"
                                            : payloadForm.kategori_penyerahan ===
                                              "Penyisihan BB"
                                            ? "Tanggal Penyisihan"
                                            : payloadForm.kategori_penyerahan ===
                                              "Penitipan BB"
                                            ? "Tanggal Penitipan"
                                            : payloadForm.kategori_penyerahan ===
                                              "Pengembalian BB"
                                            ? "Tanggal Pengembalian"
                                            : ""
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => {
                                          setPayloadDate({
                                            ...payloadDate,
                                            tanggal_pelimpahan_p21: date,
                                          });
                                          setPayloadForm({
                                            ...payloadForm,
                                            tanggal_pelimpahan_p21:
                                              moment(date).format("YYYY-MM-DD"),
                                          });
                                        }}
                                        selected={
                                          payloadDate.tanggal_pelimpahan_p21
                                        }
                                        minDate={payloadDate.tanggal_penyerahan}
                                        required
                                      />
                                    )}
                                    {payloadForm.kategori_penyerahan ===
                                      "Penghentian(SP3)" && (
                                      <DatePicker
                                        label="Tanggal Penghentian(SP3)"
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => {
                                          setPayloadDate({
                                            ...payloadDate,
                                            tanggal_penghentian_sp3: date,
                                          });
                                          setPayloadForm({
                                            ...payloadForm,
                                            tanggal_penghentian_sp3:
                                              moment(date).format("YYYY-MM-DD"),
                                          });
                                        }}
                                        selected={
                                          payloadDate.tanggal_penghentian_sp3
                                        }
                                        minDate={payloadDate.tanggal_penyerahan}
                                        required
                                      />
                                    )}
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      as="textarea"
                                      name="uraian"
                                      onChange={handleChange}
                                      defaultValue={payloadForm.uraian}
                                      label={`
                                                          ${
                                                            payloadForm.kategori_penyerahan ===
                                                            "Pinjam Pakai"
                                                              ? "Alasan/Dasar Pinjam"
                                                              : ""
                                                          }
                                                          ${
                                                            payloadForm.kategori_penyerahan ===
                                                            "Pemusnahan"
                                                              ? "Alasan/Dasar Pemusnahan"
                                                              : ""
                                                          }
                                                          ${
                                                            payloadForm.kategori_penyerahan ===
                                                            "Lelang"
                                                              ? "Alasan/Dasar Lelang"
                                                              : ""
                                                          }
                                                          ${
                                                            payloadForm.kategori_penyerahan ===
                                                            "Pengembalian"
                                                              ? "Alasan/Dasar Penyerahan"
                                                              : ""
                                                          }
                                                          ${
                                                            payloadForm.kategori_penyerahan ===
                                                            "TF Kas Negara"
                                                              ? "Alasan/Dasar Penyerahan"
                                                              : ""
                                                          }
                                                          ${
                                                            payloadForm.kategori_penyerahan ===
                                                            "Pelimpahan(P21)"
                                                              ? "Alasan/Dasar Tahap 2 (P21)"
                                                              : ""
                                                          }
                                                          ${
                                                            payloadForm.kategori_penyerahan ===
                                                            "Penghentian(SP3)"
                                                              ? "Alasan/Dasar Penghentian(SP3)"
                                                              : ""
                                                          }
                                                          ${
                                                            payloadForm.kategori_penyerahan ===
                                                            "Titip Rawat BB"
                                                              ? "Alasan/Dasar Titip Rawat"
                                                              : ""
                                                          }
                                                          ${
                                                            payloadForm.kategori_penyerahan ===
                                                            "Penyisihan BB"
                                                              ? "Alasan/Dasar Penyisihan"
                                                              : ""
                                                          }
                                                          ${
                                                            payloadForm.kategori_penyerahan ===
                                                            "Penitipan BB"
                                                              ? "Alasan/Dasar Penitipan"
                                                              : ""
                                                          }
                                                          ${
                                                            payloadForm.kategori_penyerahan ===
                                                            "Pengembalian BB"
                                                              ? "Alasan/Dasar Pengembalian"
                                                              : ""
                                                          }
                                                          `}
                                    />
                                  </Col>
                                  <Col sm="3">
                                    {payloadForm.kategori_penyerahan !==
                                      "Pinjam Pakai" && (
                                      <Select
                                        required
                                        label="Kondisi Barang Bukti"
                                        default="Pilih"
                                        options={[
                                          {
                                            value: "Baik",
                                            text: "Baik",
                                          },
                                          {
                                            value: "Tidak Baik",
                                            text: "Tidak Baik",
                                          },
                                        ]}
                                        name="kondisi"
                                        onChange={handleChange}
                                        defaultValue={payloadForm.kondisi}
                                      />
                                    )}
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col sm="12">
                            <Card className="card bg-white">
                              <Card.Body>
                                <Row className="g-1 mb-2 d-flex align-items-center">
                                  <Col sm="12" md="5">
                                    <h6>
                                      Saksi{" "}
                                      {isPemusnahan
                                        ? "Pemusnahan"
                                        : isPengembalian
                                        ? "Pengembalian"
                                        : "Penyerahan"}{" "}
                                      Barang Bukti
                                    </h6>
                                  </Col>
                                  <Col sm="12" md="7">
                                    <Link className="d-flex align-items-center justify-content-end">
                                      <Button
                                        className="btn btn-primary"
                                        onClick={handleAddRow}
                                      >
                                        Tambah Saksi
                                      </Button>
                                    </Link>
                                  </Col>
                                </Row>
                                <Table className="table dems-table">
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>Nama</th>
                                      <th>Pekerjaan</th>
                                      <th>Alamat</th>
                                      <th>Keterangan</th>
                                      <th>No. HP</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {saksiData.length
                                      ? saksiData.map((data, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{data.nama}</td>
                                            <td>{data.pekerjaan}</td>
                                            <td>{data.alamat}</td>
                                            <td>{data.keterangan}</td>
                                            <td>{data.no_hp}</td>
                                            <td>
                                              <div className="d-flex align-items-center justify-content-end gap-2">
                                                <Badge
                                                  onClick={() =>
                                                    handleDelete(index)
                                                  }
                                                  className="btn bg-danger"
                                                  as="button"
                                                >
                                                  <i className="ri-delete-bin-line"></i>
                                                </Badge>
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                      : !saksiData.length && (
                                          <tr>
                                            <td
                                              colSpan="4"
                                              style={{ textAlign: "center" }}
                                            >
                                              <span>Tidak ada data saksi!</span>
                                            </td>
                                          </tr>
                                        )}
                                    {isAddingRow && (
                                      <tr>
                                        <td></td>
                                        <td>
                                          <Input
                                            type="text"
                                            name="nama"
                                            value={saksi.nama}
                                            onChange={handleNewRowChange}
                                            placeholder="Nama"
                                            required={true}
                                            id="addSaksiRow"
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            name="pekerjaan"
                                            value={saksi.pekerjaan}
                                            onChange={handleNewRowChange}
                                            placeholder="Pekerjaan"
                                            required={true}
                                            id="addSaksiRow"
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            name="alamat"
                                            value={saksi.alamat}
                                            onChange={handleNewRowChange}
                                            placeholder="Alamat"
                                            required={true}
                                            id="addSaksiRow"
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            name="keterangan"
                                            value={saksi.keterangan}
                                            onChange={handleNewRowChange}
                                            placeholder="Keterangan"
                                            required={true}
                                            id="addSaksiRow"
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="number"
                                            name="no_hp"
                                            value={saksi.no_hp}
                                            onChange={handleNewRowChange}
                                            placeholder="No. HP"
                                            required={true}
                                            id="addSaksiRow"
                                          />
                                        </td>
                                        <td className="d-flex gap-1">
                                          <Button
                                            className="btn bg-danger"
                                            onClick={closeRow}
                                          >
                                            <i className="ri-close-large-line"></i>
                                          </Button>
                                          <Button
                                            className="btn bg-success"
                                            onClick={handleSaveRow}
                                          >
                                            <i className="ri-check-line"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col sm="12">
                            <Card className="card bg-white">
                              <Card.Body>
                                <Col sm="6">
                                  <Col sm="12" md="5">
                                    <h6>Informasi Tambahan</h6>
                                  </Col>
                                  <Input
                                    as="textarea"
                                    onChange={handleChange}
                                    defaultValue={payloadForm.keterangan}
                                    col="4"
                                    name="keterangan"
                                    label="Keterangan"
                                  />
                                </Col>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="custom-header">
                    Informasi Dokumen Barang Bukti
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="12">
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                1
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="No Nota Dinas Permohonan Pengeluaran/Pengembalian/Pemindahbukuan BB"
                                  name="nomor_nota_dinas_permohonan_pengeluaran_bb"
                                  value={
                                    payloadForm.nomor_nota_dinas_permohonan_pengeluaran_bb
                                  }
                                  onChange={handleChange}
                                  required
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal Dokumen"
                                  name="tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb:
                                        date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb
                                  }
                                  required
                                />
                              </Col>
                              <Col sm="4" className="">
                                <Input
                                  label="Unggah Arsip"
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip_nota_dinas_permohonan_pengeluaran_bb:
                                        [files],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                  required
                                />
                                <Col className="mt-4"> </Col>
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center ms-3"
                              >
                                {one ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    <i className="ri-check-line"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontSize: "1.5rem" }}
                                  >
                                    <i className="ri-close-large-line"></i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="g-2">
                          <Col sm="12">
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                2
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="No Disposisi Pimpinan Karorenmin, Kabag TAHTI"
                                  name="nomor_disposisi_pimpinan_karorenmin_kabagtahti"
                                  value={
                                    payloadForm.nomor_disposisi_pimpinan_karorenmin_kabagtahti
                                  }
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal Dokumen"
                                  name="tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti:
                                        date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti
                                  }
                                />
                              </Col>
                              <Col sm="4" className="">
                                <Input
                                  label="Unggah Arsip"
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip_disposisi_pimpinan_karorenmin_kabagtahti:
                                        [files],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                />
                                <Col className="mt-4"> </Col>
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center ms-3"
                              >
                                {two ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    <i className="ri-check-line"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontSize: "1.5rem" }}
                                  >
                                    <i className="ri-close-large-line"></i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="g-2">
                          <Col sm="12">
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                3
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="No BA Penitipan BB"
                                  name="nomor_ba_penitipan_bb"
                                  value={payloadForm.nomor_ba_penitipan_bb}
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal Dokumen"
                                  name="tanggal_dokumen_ba_penitipan_bb"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_dokumen_ba_penitipan_bb: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_dokumen_ba_penitipan_bb:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_dokumen_ba_penitipan_bb
                                  }
                                />
                              </Col>
                              <Col sm="4" className="">
                                <Input
                                  label="Unggah Arsip"
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip_ba_penitipan_bb: [files],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                />
                                <Col className="mt-4"> </Col>
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center ms-3"
                              >
                                {three ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    <i className="ri-check-line"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontSize: "1.5rem" }}
                                  >
                                    <i className="ri-close-large-line"></i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="g-2">
                          <Col sm="12">
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                4
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="No Dokumen P21/SP3 (jika ada)"
                                  name="nomor_dokumen_p21_atau_sp3"
                                  value={payloadForm.nomor_dokumen_p21_atau_sp3}
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal Dokumen"
                                  name="tanggal_dokumen_p21_atau_sp3"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_dokumen_p21_atau_sp3: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_dokumen_p21_atau_sp3:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_dokumen_p21_atau_sp3
                                  }
                                />
                              </Col>
                              <Col sm="4" className="">
                                <Input
                                  label="Unggah Arsip"
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip_dokumen_p21_atau_sp3: [files],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                />
                                <Col className="mt-4"> </Col>
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center ms-3"
                              >
                                {four ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    <i className="ri-check-line"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontSize: "1.5rem" }}
                                  >
                                    <i className="ri-close-large-line"></i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {payloadForm.kategori_penyerahan ===
                          "Pelimpahan(P21)" && (
                          <Row className="g-2">
                            <Col sm="12">
                              <Row className="g-2 mt-1">
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center me-3"
                                >
                                  5
                                </Col>
                                <Col sm="5">
                                  <Input
                                    label="No Surat Penyerahan BB dan Tersangka (tahap 2)"
                                    name="nomor_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2"
                                    value={
                                      payloadForm.nomor_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2
                                    }
                                    onChange={handleChange}
                                    required
                                  />
                                </Col>
                                <Col sm="2">
                                  <DatePicker
                                    label="Tanggal Dokumen"
                                    name="tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                      setPayloadDate({
                                        ...payloadDate,
                                        tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2:
                                          date,
                                      });
                                      setPayloadForm({
                                        ...payloadForm,
                                        tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2:
                                          moment(date).format("YYYY-MM-DD"),
                                      });
                                    }}
                                    selected={
                                      payloadDate.tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2
                                    }
                                    required
                                  />
                                </Col>
                                <Col sm="4" className="">
                                  <Input
                                    label="Unggah Arsip"
                                    type="file"
                                    onChange={(e) => {
                                      const files = e.target.files[0];
                                      setPayloadForm({
                                        ...payloadForm,
                                        arsip_penyerahan_barang_bukti_dan_tersangka_tahap_2:
                                          [files],
                                      });
                                    }}
                                    accepts={[".pdf"]}
                                    required
                                  />
                                  <Col className="mt-4"> </Col>
                                </Col>
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center ms-3"
                                >
                                  {five ? (
                                    <span
                                      style={{
                                        color: "green",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-check-line"></i>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-close-large-line"></i>
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}
                        {payloadForm.kategori_penyerahan ===
                          "Penghentian(SP3)" && (
                          <Row className="g-2">
                            <Col sm="12">
                              <Row className="g-2 mt-1">
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center me-3"
                                >
                                  5
                                </Col>
                                <Col sm="5">
                                  <Input
                                    label="No Dokumen Penetapan/Penghentian Penyidikan/Hasil Gelar Perkara"
                                    name="nomor_dokumen_hasil_gelar_perkara"
                                    value={
                                      payloadForm.nomor_dokumen_hasil_gelar_perkara
                                    }
                                    onChange={handleChange}
                                    required
                                  />
                                </Col>
                                <Col sm="2">
                                  <DatePicker
                                    label="Tanggal Dokumen"
                                    name="tanggal_dokumen_hasil_gelar_perkara"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                      setPayloadDate({
                                        ...payloadDate,
                                        tanggal_dokumen_hasil_gelar_perkara:
                                          date,
                                      });
                                      setPayloadForm({
                                        ...payloadForm,
                                        tanggal_dokumen_hasil_gelar_perkara:
                                          moment(date).format("YYYY-MM-DD"),
                                      });
                                    }}
                                    selected={
                                      payloadDate.tanggal_dokumen_hasil_gelar_perkara
                                    }
                                    required
                                  />
                                </Col>
                                <Col sm="4" className="">
                                  <Input
                                    label="Unggah Arsip"
                                    type="file"
                                    onChange={(e) => {
                                      const files = e.target.files[0];
                                      setPayloadForm({
                                        ...payloadForm,
                                        arsip_dokumen_hasil_gelar_perkara: [
                                          files,
                                        ],
                                      });
                                    }}
                                    accepts={[".pdf"]}
                                    required
                                  />
                                  <Col className="mt-4"> </Col>
                                </Col>
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center ms-3"
                                >
                                  {six ? (
                                    <span
                                      style={{
                                        color: "green",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-check-line"></i>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-close-large-line"></i>
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="custom-header">
                    Informasi Laporan Polisi
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="mt-2 g-2">
                          <Col sm="4">
                            <Select2
                              options={listNoLP}
                              defaultValue={"-"}
                              name="no_laporan_polisi"
                              value={payloadForm.no_laporan_polisi}
                              label="No Laporan Polisi"
                              required
                              onChange={(selectedOption) => {
                                setPayloadLP({
                                  ...payloadLP,
                                  no_laporan_polisi: selectedOption.value,
                                });
                                setPayloadForm({
                                  ...payloadForm,
                                  no_laporan_polisi: selectedOption.value,
                                });
                              }}
                              isClearable={isClearable}
                              isSearchable={isSearchable}
                            />
                          </Col>
                          {/* <Col sm="4">
                            <Input
                              label="Tanggal Dokumen"
                              name="tanggal_dokumen_no_laporan_polisi"
                              value={
                                dataTransaksi?.tanggal_dokumen_no_laporan_polisi ||
                                "-"
                              }
                              disabled
                            />
                          </Col> */}
                          {/* <Col sm="4" className="">
                            <Input
                              label="Unggah Arsip"
                              type="file"
                              onChange={(e) => {
                                const files = e.target.files[0];
                                setPayloadForm({
                                  ...payloadForm,
                                  arsip_nomor_laporan_polisi: [files],
                                });
                              }}
                              accepts={[".pdf"]}
                              required
                            />
                            {(
                              dataPenerimaanBarbuk?.penerimaan_barbuk_files ??
                              []
                            ).length > 0 ? (
                              (() => {
                                const { arsip_nomor_laporan_polisi } =
                                  dataPenerimaanBarbuk
                                    .penerimaan_barbuk_files[0];
                                const { p: path, k: kategori } = trimUrlPath(
                                  arsip_nomor_laporan_polisi
                                );
                                const file = trimFileUrl(
                                  arsip_nomor_laporan_polisi
                                );

                                return (
                                  <Link
                                    target="_blank" to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                      path
                                    )}`}
                                    className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                  >
                                    <i className="ri-link"></i>
                                    File Yang Sudah Terupload
                                  </Link>
                                );
                              })()
                            ) : (
                              <Col className="mt-4"> </Col>
                            )}
                          </Col> */}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="3">
                  <Accordion.Header className="custom-header">
                    Informasi Tahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Header className="bg-white d-flex justify-content-end">
                        {tahananFetching && !tahananError ? (
                          <Button
                            variant="primary"
                            size="small"
                            className="d-flex align-items-center gap-2"
                            disabled
                          >
                            <Spinner size="sm" variant="light" />
                            Sedang Menerapkan Tahanan
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            size="small"
                            className="d-flex align-items-center gap-2"
                            onClick={() => setModalTahananShow(true)}
                          >
                            <i className="ri-user-search-line fs-6"></i>
                            {dataTahanan ? "Ubah " : "Cari "}
                            Profil Tahanan
                          </Button>
                        )}
                      </Card.Header>
                      <Card.Body>
                        {tahananFetching && !tahananError && (
                          <Placeholder
                            as="p"
                            animation="wave"
                            className="m-0 d-flex flex-column gap-2"
                          >
                            <Placeholder sm="12" />
                            <Placeholder sm="12" />
                            <Placeholder sm="12" />
                            <Placeholder sm="12" />
                            <Placeholder sm="12" />
                          </Placeholder>
                        )}
                        {!tahananFetching && tahananError && (
                          <div className="d-flex justify-content-center">
                            <span className="fs-5 text-danger">
                              <b>Error : </b>{" "}
                              {errorTahanan?.response?.value?.message ??
                                "Masalah Teknis"}
                            </span>
                          </div>
                        )}
                        {!tahananFetching && !tahananError && (
                          <Table className="table dems-table">
                            <thead>
                              <tr>
                                <th>No.LP</th>
                                <th>No.Tahanan</th>
                                <th>Nama Tahanan</th>
                                <th>Asal Satker</th>
                                <th>Perkara</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{payloadFormDataTahanan.no_laporan}</td>
                                <td>{payloadFormDataTahanan.no_tahanan}</td>
                                <td>{payloadFormDataTahanan.nama}</td>
                                <td>{payloadFormDataTahanan.satker}</td>
                                <td>{payloadFormDataTahanan.perkara}</td>
                              </tr>
                            </tbody>
                          </Table>
                        )}
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item> */}
                <Accordion.Item eventKey="4">
                  <Accordion.Header className="custom-header">
                    Informasi Penyidik
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="12">
                            <Card className="card bg-white">
                              <Card.Body>
                                <Row className="g-1 mb-2 d-flex align-items-center">
                                  <Col sm="12" md="5">
                                    <h6>Daftar Penyidik</h6>
                                  </Col>
                                  <Col sm="12" md="7">
                                    <Link className="d-flex align-items-center justify-content-end"></Link>
                                  </Col>
                                </Row>
                                <Table className="table dems-table">
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>NRP</th>
                                      <th>Nama Penyidik</th>
                                      <th>Asal Satker</th>
                                      <th>Pangkat</th>
                                      <th>Jabatan</th>
                                      <th>Pilih</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dataTransaksi.penyidik.map(
                                      (data, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{data.nrp}</td>
                                          <td>{data.nama_penyidik}</td>
                                          <td>{data.nama_satker}</td>
                                          <td>{data.pangkat}</td>
                                          <td>Penyidik</td>
                                          <td>
                                            <Form.Check
                                              type="checkbox"
                                              onChange={(e) => {
                                                handleCheckbox(
                                                  "penyidik",
                                                  data,
                                                  e.target.checked
                                                );
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header className="custom-header">
                    Informasi Rincian Barang Bukti
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="12">
                            <Card className="card bg-white">
                              <Card.Body>
                                <Row className="g-1 mb-2 d-flex align-items-center">
                                  <Col sm="12" md="5">
                                    <h6>Daftar Barang Bukti</h6>
                                  </Col>
                                  <Col sm="12" md="7">
                                    <Link className="d-flex align-items-center justify-content-end">
                                      <Button
                                        className="btn btn-primary"
                                        onClick={() => setBarbukModalShow(true)}
                                      >
                                        PILIH
                                      </Button>
                                    </Link>
                                  </Col>
                                  <Col sm="12" md="7">
                                    <Link className="d-flex align-items-center justify-content-end"></Link>
                                  </Col>
                                </Row>
                                <Table className="table dems-table">
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>Jenis</th>
                                      <th>Sub Jenis</th>
                                      <th>Tipe</th>
                                      <th>No. Label</th>
                                      <th>Jumlah</th>
                                      <th>Unit</th>
                                      <th>Keterangan</th>
                                      <th>Pilih</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {payloadBarbuk.map((data, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data?.tipe || "-"}</td>
                                        <td>{data?.sub_jenis || "-"}</td>
                                        <td>{data?.tipe || "-"}</td>
                                        <td>{data?.no_label_barbuk || "-"}</td>
                                        <td>
                                          {data?.jumlah_penerimaan || "-"}
                                        </td>
                                        <td>{data?.unit || "-"}</td>
                                        <td>{data?.keterangan || "-"}</td>
                                        <td>
                                          <Badge
                                            onClick={() => {
                                              setPayloadBarbuk(
                                                payloadBarbuk.filter(
                                                  (_, idx) => idx !== index
                                                )
                                              );
                                            }}
                                            className="btn bg-danger"
                                            as="button"
                                          >
                                            <i className="ri-delete-bin-line"></i>
                                          </Badge>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          {!mutateSubmit.isLoading || !mutateSubmit.isLoading ? (
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Link
                to={`/barbuk/${
                  isPemusnahan
                    ? "pemusnahan"
                    : isPengembalian
                    ? "pengembalian"
                    : "pengeluaran"
                }/list`}
              >
                <Button variant="warning">BATAL</Button>
              </Link>
              {/* <Button variant="secondary" onClick={() => handleSubmit("DRAFT")}>
                SIMPAN DRAFT
              </Button> */}
              <Button variant="primary" onClick={() => handleSubmit("FINAL")}>
                SIMPAN
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Link>
                <Button variant="warning" disabled>
                  BATAL
                </Button>
              </Link>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                disabled
              >
                Sedang Menyimpan <Spinner variant="light" size="sm" />
              </Button>
            </div>
          )}
        </Card.Footer>
      </Card>
      <Modal
        show={modalTahananShow}
        onHide={() => setModalTahananShow(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Cari Tahanan</Modal.Title>
          <Button
            className="btn btn-danger"
            onClick={() => setModalTahananShow(false)}
          >
            <i className="ri-close-large-fill"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-4">
            <Col sm="12">
              <Input
                label="Nama Tahanan"
                onChange={(e) => {
                  setNamaTahanan(e.target.value);
                }}
              ></Input>
            </Col>
            <Col sm="12">
              <Table className="table dems-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Tahanan</th>
                    <th>Asal Satker</th>
                    <th>Perkara</th>
                    <th>Lokasi</th>
                    <th>Pilih</th>
                  </tr>
                </thead>
                <tbody>
                  {modalFetching && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="small"
                        />
                      </td>
                    </tr>
                  )}
                  {!modalFetching &&
                    (dataTahananList.length ? (
                      dataTahananList.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.nama_tersangka}</td>
                          <td>{data.nama_satker}</td>
                          <td>{data.perkara}</td>
                          <td>{data.nama_rutan}</td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              onClick={() => {
                                setIdTahanan(data.id);
                                setModalTahananShow(false);
                                queryClient.resetQueries("getTahtiList");
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <span>Tidak Ada Tahanan !</span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={barbukModalShow}
        onHide={() => setBarbukModalShow(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Cari Barang Bukti</Modal.Title>
          <Button
            className="btn btn-danger"
            onClick={() => {
              setBarbukModalShow(false);
            }}
          >
            <i className="ri-close-large-fill"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-1 mb-2">
            <Col sm="12" md="5">
              <Input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Col>
            <Col sm="12" md="7">
              <Link className="d-flex align-items-center justify-content-end">
                <Button
                  className="btn btn-primary"
                  onClick={() => setBarbukModalShow(false)}
                >
                  TAMBAH
                </Button>
              </Link>
            </Col>
          </Row>
          <Row className="g-4">
            <Col sm="12">
              <Table className="table dems-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>ID Label</th>
                    <th>Tipe</th>
                    <th>Jenis</th>
                    <th>Sub Jenis</th>
                    <th>Pilih</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredBarangBukti.length > 0 ? (
                    filteredBarangBukti.map((data, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            backgroundColor:
                              data.jumlah_penerimaan <= 0 ? "pink" : 0,
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              data.jumlah_penerimaan <= 0 ? "pink" : 0,
                          }}
                        >
                          {data.no_label_barbuk}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              data.jumlah_penerimaan <= 0 ? "pink" : 0,
                          }}
                        >
                          {data.tipe}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              data.jumlah_penerimaan <= 0 ? "pink" : 0,
                          }}
                        >
                          {data.jenis}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              data.jumlah_penerimaan <= 0 ? "pink" : 0,
                          }}
                        >
                          {data.sub_jenis}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              data.jumlah_penerimaan <= 0 ? "pink" : 0,
                          }}
                        >
                          <Form.Check
                            type="checkbox"
                            checked={payloadBarbuk.some(
                              (item) =>
                                item.id_transaksi_barbuk ===
                                data.id_transaksi_barbuk
                            )}
                            onChange={(e) => {
                              if (data.jumlah_penerimaan <= 0) {
                                Alert.showMessage(
                                  "Barang bukti telah dikeluarkan !"
                                );
                              } else {
                                handleCheckboxBarbuk(data, e.target.checked);
                                handleCheckbox(
                                  "barbuk",
                                  data,
                                  e.target.checked
                                );
                              }
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} style={{ textAlign: "center" }}>
                        <span>Tidak ada barang bukti!</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default FormCreate;
