import React, { useCallback, useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconPicker, Input, Select } from "../../../components/Components";
import {
  useGetUserDetail,
  usePostUsers,
  usePutUsers,
} from "../../../utils/apis/query";

function Form() {
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    username: "",
    password: "",
    role: "",
    nama_lengkap: "",
    nrp: "",
    jabatan: "",
    handphone: "",
    email: "",
    pangkat: "",
    nama_satker: "",
    nama_satker_tahti: "",
  });
  const onSuccess = () => {
    setTimeout(() => {
      navigate("/users");
    }, 1500);
  };
  const mutateAdd = usePostUsers(onSuccess);
  const mutateEdit = usePutUsers(onSuccess);
  const {
    data: detailData,
    isFetching: detailIsFetching,
    isError: detailIsError,
    error: detailError,
  } = useGetUserDetail(id, { enabled: !!id });

  const handleChange = (e) => {
    let { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
  const handleSubmit = () => {
    if (isEdit) {
      mutateEdit.mutate({ id, user });
    } else {
      mutateAdd.mutate(user);
    }
  };

  const role = [
    {
      text: "Super admin",
      value: "super admin",
    },
    {
      text: "Admin penyidik",
      value: "admin penyidik",
    },
    {
      text: "Penyidik",
      value: "penyidik",
    },
    {
      text: "TAHTI",
      value: "tahti",
    },
    {
      text: "Eksekutif",
      value: "eksekutif",
    },
    {
      text: "Umum",
      value: "umum",
    },
  ];

  const status = [
    {
      text: "Active",
      value: "true",
    },
    {
      text: "Non Active",
      value: "false",
    },
  ];

  useEffect(() => {
    setIsEdit(false);
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  useEffect(() => {
    if (detailData) {
      setUser({
        ...user,
        username: detailData.username,
        password: detailData.password,
        role: detailData.role,
        nama_lengkap: detailData.nama_lengkakp,
        nrp: detailData.nrp,
        jabatan: detailData.jabatan,
        handphone: detailData.handphone,
        email: detailData.email,
        pangkat: detailData.pangkat,
        nama_satker: detailData.nama_satker,
        nama_satker_tahti: detailData.nama_satker_tahti,
      });
    }
  }, [detailData]);

  useEffect(() => {
    return () => {
      setUser({
        username: "",
        password: "",
        role: "",
        nama_lengkap: "",
        nrp: "",
        jabatan: "",
        handphone: "",
        email: "",
        pangkat: "",
        nama_satker: "",
        nama_satker_tahti: "",
      });
    };
  }, []);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/users">Users</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users">List Users</Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form {isEdit ? "Edit" : "Tambah"} User
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {mutateAdd.isError && (
                <Alert variant="danger">
                  <ul>
                    {mutateAdd?.error?.response?.data?.detail?.map(
                      (errEdit) => (
                        <li>
                          <b>{errEdit.loc[0]} </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error</b>Sesuatu Bermasalah
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateAdd.isSuccess && (
                <Alert variant="success">{mutateAdd.data.data.message}</Alert>
              )}
            </Col>
            <Col sm="12">
              {mutateEdit.isError && (
                <Alert variant="danger">
                  <ul>
                    {mutateEdit?.error?.response?.data?.detail?.map(
                      (errEdit) => (
                        <li>
                          <b>{errEdit.loc[0]} </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error</b>Sesuatu Bermasalah
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateEdit.isSuccess && (
                <Alert variant="success">{mutateEdit.data.data.message}</Alert>
              )}
            </Col>
            <Col sm="4">
              <Input
                placeholder="Username"
                label="Username"
                name="username"
                value={user.username}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="4">
              <Input
                placeholder="Password"
                label="Password"
                name="password"
                value={user.password}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="4">
              <Select
                label="Role"
                name="role"
                value={user.role}
                onChange={handleChange}
                default="Pilih"
                options={role}
                required
              />
            </Col>
            <Col sm="4">
              <Input
                placeholder="Nama Lengkap"
                label="Nama Lengkap"
                name="nama_lengkap"
                value={user.nama_lengkap}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="4">
              <Input
                placeholder="NRP"
                label="NRP"
                name="nrp"
                value={user.nrp}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="4">
              <Input
                placeholder="Jabatan"
                label="Jabatan"
                name="jabatan"
                value={user.jabatan}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="4">
              <Input
                placeholder="No. Handphone"
                label="No. Handphone"
                name="handphone"
                value={user.handphone}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="4">
              <Input
                placeholder="Email"
                label="Email"
                name="email"
                value={user.email}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="4">
              <Input
                placeholder="Pangkat"
                label="Pangkat"
                name="pangkat"
                value={user.pangkat}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="4">
              <Input
                placeholder="Nama Satker"
                label="Nama Satker"
                name="nama_satker"
                value={user.nama_satker}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="4">
              <Input
                placeholder="Nama Satker Tahthi"
                label="Nama Satker Tahthi"
                name="nama_satker_tahti"
                value={user.nama_satker_tahti}
                onChange={handleChange}
                required
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center">
            {!mutateAdd.isLoading || !mutateEdit.isLoading ? (
              <Button variant="primary" onClick={handleSubmit}>
                Simpan
              </Button>
            ) : (
              <Button variant="primary" disabled>
                Loading
              </Button>
            )}
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default Form;
