import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useGetDashboardTahanan, useGetPenerimaanBarbukList } from '../utils/apis/query';
// import axios from 'axios';
import { get, axios } from '../libraries/Axios';
import { useQuery } from 'react-query';
import { getPenerimaanBarbukList } from '../utils/apis';

function DebugDems() {

  // const hit  = async () => {
  //   try {
  //     axios.get('https://backend.dems.co.id/api/v1/satker-tahti/?page=1&size=10', {
  //       headers: {
  //         Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxZDZhMWJhLTc0NGUtNDRjZC1iZTZjLTZlYjdkMGRiNGRiYiIsImV4cGlyZXMiOjE3Mjk0MTQzNTcuODQ2NzA1fQ.jVbSZPT2oPY_fRYxIAwFSUxenq_lV4HK_8nhCIjXpNA',
  //       }
  //     })
  //   } catch (error) {
  //     console.log(error);
      
  //   }
  // }

  // const {
  //   data: dataList,
  //   pagination,
  //   isFetching: dataFetching,
  //   isError: dataIsError,
  //   error: dataError,
  // } = useGetPenerimaanBarbukList(
  //   {
  //     search: "",
  //     page: 1,
  //     size: 10,
  //   }
  // );
  const hit  = async () => {
    try {
      return await get('penerimaan/barang-bukti/?page=1&size=10')
    } catch (error) {
      console.log(error);
      return Error('error')
      
    }
  }
  const getPenerimaanBarbukList = async ({ queryKey }) => {
    const [_, query] = queryKey;
    let spring = Object.fromEntries(
      Object.entries(query || {}).filter(([_, v]) => v !== "")
    );
    const response = await get("penerimaan/barang-bukti", {
      params: Object.keys(spring).length ? spring : undefined,
    });
    return response;
  };
  // const {data} = useQuery(
  //   ["getPenerimaanBarbukList", {}],
  //   getPenerimaanBarbukList,
  //   {
  //     keepPreviousData: false,
  //     refetchOnWindowFocus: false,
  //     refetchOnMount: true,
  //     refetchOnReconnect: false,
  //     retry: false
  //   }
  // );

  useEffect(() => {
    getPenerimaanBarbukList({queryKey: ['foo', {page: 1, size: 10}]})
  }, [])
  return (
    <>
      <Button
        onClick={() => {
          // hit()
        }}
        >
          Klik aku
        </Button>
    </>
  )
}

export default DebugDems