import React, { useState } from 'react'
import { Badge, Button, Card, Col, Accordion, Row, Spinner, Table, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { DatePicker, Input, Select } from '../../../../components/Components'


function FormMenu() {
  const [select, setselect] = useState([{text: 'Value1', value: 'Value1'},{text: 'Value2', value: 'Value2'},{text: 'Value3', value: 'Value3'}])
  return (
    <React.Fragment>
        <Card className='bg-white mb-2'>
            <Card.Body className='p-2'>
            <div className="d-flex align-items-center justify-content-between m-0">
                <div>
                    <ol className="breadcrumb fs-sm mb-0">
                        <li className="breadcrumb-item">
                        <Link to="/tahanan">Tahanan</Link>
                        </li>
                        <li className="breadcrumb-item">
                        <Link to="/tahanan/pengalihan/list">List Pengalihan Jenis Tahanan</Link>
                        </li>
                        <li className="breadcrumb-item active fw-bold" aria-current="page">
                          Form Pengalihan Jenis Tahanan
                        </li>
                    </ol>
                </div>
            </div>
            </Card.Body>
        </Card>
        <Card className='bg-white'>
        <Card.Body>
            <Row className="g-2">
              {/* <Col sm="12">
                {
                  mutateAdd.isError && (
                    <Alert variant='danger'>
                      <ul>
                        {
                          mutateAdd?.error?.response?.data?.detail
                          ?.map((errEdit) => (
                            <li><b>{errEdit.loc[0]} </b>{errEdit.msg}</li>
                          )) ?? (
                            <li><b>Error</b>Sesuatu Bermasalah</li>
                          )
                        }
                      </ul>
                    </Alert>
                  )
                }
                {
                  mutateAdd.isSuccess && (
                    <Alert variant='success'>
                      {mutateAdd.data.data.message}
                    </Alert>
                  )
                }
              </Col> */}
              {/* <Col sm="12">
                {
                  mutateEdit.isError && (
                    <Alert variant='danger'>
                      <ul>
                        {
                          mutateEdit?.error?.response?.data?.detail
                          ?.map((errEdit) => (
                            <li><b>{errEdit.loc[0]} </b>{errEdit.msg}</li>
                          )) ?? (
                            <li><b>Error</b>Sesuatu Bermasalah</li>
                          )
                        }
                      </ul>
                    </Alert>
                  )
                }
                {
                  mutateEdit.isSuccess && (
                    <Alert variant='success'>
                      {mutateEdit.data.data.message}
                    </Alert>
                  )
                }
              </Col> */}
              <Col sm="12">
                <Accordion defaultActiveKey="0" flush alwaysOpen>
                  <Accordion.Item eventKey="0">
                      <Accordion.Header>Informasi Dokumen Penahanan</Accordion.Header>
                      <Accordion.Body className='py-2 px-0'>
                        <Card className='card bg-white'>
                          <Card.Body>
                            <Row className='g-2'>
                                <Col sm="12">
                                    <Row className='g-2 mt-1'>
                                      <Col sm="5">
                                        <Input 
                                            label="Nomor Sprint Pengalihan Jenis Tahanan"
                                            required
                                        />
                                      </Col>
                                      <Col sm="3">
                                        <DatePicker 
                                            label="Tanggal"
                                            name=""
                                            timeFormat="HH:mm:ss"
                                            dateFormat="dd/MM/yyyy HH:mm:ss"
                                            required
                                        />
                                      </Col>
                                      <Col sm="4">
                                        <Input 
                                            label="Unggah Arsip"
                                            type="file"
                                            required
                                          />
                                      </Col>
                                    </Row>
                                </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                      <Accordion.Header>Informasi Penyidik</Accordion.Header>
                      <Accordion.Body className='py-2 px-0'>
                        <Card className='card bg-white'>
                          <Card.Body>
                            <Row className='g-2'>
                                <Col sm="12">
                                  <Card className="card bg-white">
                                    <Card.Body>
                                      <Row className='g-1 mb-2 d-flex align-items-center'>
                                          <Col sm="12" md="5">
                                            <h6>Daftar Penyidik</h6>
                                          </Col>
                                          <Col sm="12" md="7">
                                              <Link className="d-flex align-items-center justify-content-end">
                                                
                                              </Link>
                                          </Col>
                                      </Row>
                                      <Table className="table dems-table">
                                          <thead>
                                          <tr>    
                                              <th>No</th>
                                              <th>NRP</th>
                                              <th>Nama Penyidik</th>
                                              <th>Asal Satker</th>
                                              <th>Pangkat</th>
                                              <th>Jabatan</th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {
                                            [1,1,1,1].map((data, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td>
                                                        12345678910
                                                    </td>
                                                    <td>
                                                        Mr. Jhon Dhol
                                                    </td>
                                                    <td>
                                                        Polres Jaksel
                                                    </td>
                                                    <td>
                                                        AKBP
                                                    </td>
                                                    <td>
                                                        Penyidik
                                                    </td>
                                                </tr>
                                            ))
                                          }
                                          </tbody>
                                      </Table>
                                    </Card.Body>
                                  </Card>
                                </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                      <Accordion.Header>Informasi / Profil Tahanan</Accordion.Header>
                      <Accordion.Body className='py-2 px-0'>
                        <Card className='card bg-white'>
                          <Card.Body>
                            <Row className='g-2'>
                                <Col sm="4">
                                    <Input 
                                      label="No. Tahanan"
                                      required
                                    />
                                </Col>
                                <Col sm="4">
                                    <Input 
                                      label="Nama"
                                      required
                                    />
                                </Col>
                                <Col sm="2">
                                    <Input 
                                      label="Tampat Lahir"
                                      required
                                    />
                                </Col>
                                <Col sm="2">
                                    <DatePicker 
                                        label="Tgl Lahir"
                                        name=""
                                        timeFormat="HH:mm:ss"
                                        dateFormat="dd/MM/yyyy HH:mm:ss"
                                        required
                                    />
                                </Col>
                                <Col sm="2">
                                  <Input 
                                    label="Umur Saat Ini"
                                    type="number"
                                    required
                                  />
                                </Col>
                                <Col sm="2">
                                  <Select 
                                    required
                                    label="Kelamin"
                                    default="Pilih"
                                    options={[{value: 'Laki-Laki', text: 'Laki-Laki'}]}
                                  />
                                </Col>
                                <Col sm="2">
                                  <Select 
                                    required
                                    label="Kewarganegaraan"
                                    default="Pilih"
                                    options={[{value: 'WNI', text: 'WNI'}]}
                                  />
                                </Col>
                                <Col sm="2">
                                  <Select 
                                    required
                                    label="Asal Negara"
                                    default="Pilih"
                                    options={[{value: 'Indonesia', text: 'Indonesia'}]}
                                  />
                                </Col>
                                <Col sm="2">
                                  <Select 
                                    required
                                    label="Agama"
                                    default="Pilih"
                                    options={[{value: 'Islam', text: 'Islam'}]}
                                  />
                                </Col>
                                <Col sm="2">
                                  <Input 
                                    label="Pekerjaan"
                                    type="text"
                                    required
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input 
                                    as="textarea"
                                    label="Alamat"
                                    required
                                  />
                                </Col>
                                <Col sm="3">
                                  <Input 
                                    type="file"
                                    label="Unggah Foto"
                                    required
                                  />
                                </Col>
                                <Col sm="3">
                                  <Input 
                                    type="file"
                                    label="Unggah Rumus Sidik Jari"
                                    required
                                  />
                                </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Informasi Penahanan</Accordion.Header>
                    <Accordion.Body className='py-2 px-0'>
                      <Card className='card bg-white'>
                        <Card.Body>
                          <Row className='g-2 mt-2'>
                            <Col sm="4">
                              <Select 
                                required
                                label="Perkara"
                                default="Pilih"
                                options={[{value: 'Perampokan', text: 'Perampokan'}]}
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Informasi Pengalihan Jenis Tahanan</Accordion.Header>
                    <Accordion.Body className='py-2 px-0'>
                      <Card className='card bg-white'>
                        <Card.Body>
                          <Row className='g-2 mt-2'>
                            <Col sm="3">
                              <Select 
                                required
                                label="Asal Rutan"
                                default="Pilih"
                                options={[{value: '', text: 'Rutan Polresta Depok'}]}
                              />
                            </Col>
                            <Col sm="3">
                              <Input 
                                label="Lokasi Rutan"
                                required
                              />
                            </Col>
                            <Col sm="3">
                              <Select 
                                required
                                label="Tujuan Rutan"
                                default="Pilih"
                                options={[{value: '', text: 'Rutan Polresta Jaksel'}]}
                              />
                            </Col>
                            <Col sm="3">
                              <Input 
                                label="Lokasi Rutan"
                                required
                              />
                            </Col>
                            <Col sm="4">
                              <DatePicker 
                                  label="Tgl Mulai Pengalihan"
                                  name=""
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy HH:mm:ss"
                                  required
                              />
                            </Col>
                            <Col sm="4">
                              <DatePicker 
                                  label="Tgl Selesai Pengalihan"
                                  name=""
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy HH:mm:ss"
                                  required
                              />
                            </Col>
                            <Col sm="4">
                              <Select 
                                required
                                label="Kondisi Fisik Tahanan"
                                default="Pilih"
                                options={[{value: 'Sehat', text: 'Sehat'}]}
                              />
                            </Col>
                            <Col sm="5">
                              <Input 
                                as="textarea"
                                label="Alasan Pengalihan"
                                required
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Informasi Tambahan</Accordion.Header>
                    <Accordion.Body className='py-2 px-0'>
                      <Card className='card bg-white'>
                        <Card.Body>
                          <Row className='mt-2 g-2'>
                            <Col sm="4">
                              <Input
                                label="Lampiran Pendukung"
                                type="file"
                                required
                              />
                            </Col>
                            <Col sm="6">
                              <Input 
                                as="textarea"
                                col="4"
                                label="Keterangan"
                                required
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
        </Card.Body>
        <Card.Footer className='bg-white'>
          <div className="d-flex justify-content-end align-items-center gap-3">
            {/* {
              (!mutateAdd.isLoading) || (!mutateEdit.isLoading) ? (
                <Button variant='primary' onClick={handleSubmit}>{isEdit ? 'Save' : 'Submit'}</Button>
              ) : (
                <Button variant='primary' disabled>Loading</Button>
              )
            } */}
            <Button variant='warning' >BATAL</Button>
            <Button variant='primary' >SIMPAN</Button>
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  )
}

export default FormMenu