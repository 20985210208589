import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Table,
  Form,
  Image,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Input, Select } from "../../../../components/Components";
import {
  useGetDetailBonTahanan,
  usePutBonTahanan,
} from "../../../../utils/apis/query";
import moment from "moment";

function PengembalianTahanan() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [penyidik, setPenyidik] = useState([]);
  const [imageTahananDipinjam, setImageTahananDipinjam] = useState(null);
  const [imageTahananDikembalikan, setImageTahananDikembalikan] =
    useState(null);
  const [payloadDate, setPayloadDate] = useState({
    tanggal_kembali: "",
    awal_bon: "",
    akhir_bon: "",
  });
  const [payload, setPayload] = useState({
    no_tahanan: "",
    nama_tahanan: "",
    no_bon: "",
    tanggal_bon: "",
    awal_bon: "",
    akhir_bon: "",
    kondisi: "",
    alasan: "",
    keterangan: "",
    id_tahanan: "",
    penyidik_ids: [],
    tanggal_kembali: "",
    status_dokumen: "FINAL",
    foto_tahanan_dikembalikan: "",
  });
  const onSuccess = () => {
    setTimeout(() => {
      navigate("/tahanan/bon/list");
    }, 1500);
  };
  const mutateEdit = usePutBonTahanan(onSuccess);
  const {
    data: dataBon,
    isFetching: bonIsFetching,
    isError: bonIsError,
    error: bonError,
  } = useGetDetailBonTahanan(id, { enabled: !!id });

  const handleChange = (e) => {
    let { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleDatePicker = (date, name) => {
    const formattedDateTime = moment(date).format("YYYY-MM-DD HH:mm:ss");
    setPayload({
      ...payload,
      [name]: formattedDateTime,
    });
  };

  const handleSubmit = () => {
    let formData = new FormData();
    Object.keys(payload).forEach((key) => {
      if (
        typeof payload[key] === "string" ||
        typeof payload[key] === "number" ||
        key === "foto_tahanan_dikembalikan"
      ) {
        formData.append(key, payload[key]);
      }
    });
    payload.penyidik_ids?.forEach((ids, idx) => {
      formData.append(`penyidik_ids`, ids);
    });
    mutateEdit.mutate({ id, formData });
  };

  useEffect(() => {
    if (dataBon) {
      setPayload({
        ...payload,
        nama_tahanan: dataBon.nama_tahanan,
        no_tahanan: dataBon.no_tahanan,
        no_bon: dataBon.no_bon,
        tanggal_bon: dataBon.tanggal_bon,
        awal_bon: dataBon.awal_bon,
        akhir_bon: dataBon.akhir_bon,
        kondisi: dataBon.kondisi,
        alasan: dataBon.alasan,
        keterangan: dataBon.keterangan,
        id_tahanan: dataBon.id_tahanan,
        penyidik_ids: dataBon.penyidik.map((p) => p.id_penyidik),
        tanggal_kembali: dataBon.tanggal_kembali,
      });
      setPayloadDate({
        ...payloadDate,
        awal_bon: dataBon.awal_bon
          ? moment(dataBon.awal_bon).format("DD/MM/YYYY HH:mm:ss")
          : null,
        akhir_bon: dataBon.akhir_bon
          ? moment(dataBon.akhir_bon).format("DD/MM/YYYY HH:mm:ss")
          : null,
        tanggal_kembali: dataBon.tanggal_kembali
          ? moment(dataBon.tanggal_kembali).toDate()
          : null,
      });
      setPenyidik(dataBon.penyidik);
      setImageTahananDipinjam(dataBon?.foto_bon_tahanan?.foto_tahanan_dipinjam);
      setImageTahananDikembalikan(
        dataBon?.foto_bon_tahanan?.foto_tahanan_dikembalikan
      );
    }
  }, [dataBon]);

  useEffect(() => {
    return () => {
      setPayload({
        no_tahanan: "",
        nama_tahanan: "",
        no_bon: "",
        tanggal_bon: "",
        awal_bon: "",
        akhir_bon: "",
        kondisi: "",
        alasan: "",
        keterangan: "",
        id_tahanan: "",
        penyidik_ids: [],
        tanggal_kembali: "",
      });
    };
  }, []);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/tahanan">Tahanan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/tahanan/bon/list">List Bon Tahanan</Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form Pengembalian Tahanan
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {
                <Col sm="12">
                  {mutateEdit.isError && (
                    <Alert variant="danger">
                      <ul>
                        {mutateEdit?.error?.response?.data?.detail?.map(
                          (errEdit) => (
                            <li>
                              <b>{errEdit.loc[1]} </b>
                              {errEdit.msg}
                            </li>
                          )
                        ) ?? (
                          <li>
                            <b>Error</b>Sesuatu Bermasalah
                          </li>
                        )}
                      </ul>
                    </Alert>
                  )}
                  {mutateEdit.isSuccess && (
                    <Alert variant="success">
                      {mutateEdit.data.data.message}
                    </Alert>
                  )}
                </Col>
              }
            </Col>
            <Col sm="6">
              <Input
                label="Tanggal pinjam"
                name="awal_bon"
                value={payloadDate.awal_bon}
                required
                disabled
              />
            </Col>
            <Col sm="6">
              <Input
                label="Jadwal Kembali"
                name="akhir_bon"
                value={payloadDate.akhir_bon}
                required
                disabled
              />
            </Col>
            <Col sm="6">
              <Input
                label="No. Bon"
                name="no_bon"
                value={payload.no_bon}
                onChange={handleChange}
                required
                disabled
              />
              <Input
                label="No. Tahanan"
                name="no_tahanan"
                value={payload.no_tahanan}
                onChange={handleChange}
                required
                disabled
              />
              <Input
                label="Nama. Tahanan"
                name="nama_tahanan"
                value={payload.nama_tahanan}
                onChange={handleChange}
                required
                disabled
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm="12">
              <Card className="card bg-white">
                <Card.Body>
                  <Row className="g-1 mb-2 d-flex align-items-center">
                    <Col sm="12" md="5">
                      <h6>Peminjaman Oleh (Penyidik) :</h6>
                    </Col>
                  </Row>
                  <Table className="table dems-table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>NRP</th>
                        <th>Nama Penyidik</th>
                        <th>Asal Satker</th>
                        <th>Pangkat</th>
                        <th>No. HP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {penyidik.length
                        ? penyidik.map((data, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.nrp}</td>
                              <td>{data.nama_penyidik}</td>
                              <td>{data.nama_satker}</td>
                              <td>{data.pangkat}</td>
                              <td>{data.no_hp}</td>
                            </tr>
                          ))
                        : !penyidik.length && (
                            <tr>
                              <td colSpan="4" style={{ textAlign: "center" }}>
                                <span>Tidak ada data penyidik!</span>
                              </td>
                            </tr>
                          )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm="12">
              <Card className="card bg-white">
                <Card.Body>
                  <Row className="g-1 mb-2 d-flex align-items-center">
                    <Col sm="12" md="5">
                      <h6>Pengembalian Oleh (Penyidik) :</h6>
                    </Col>
                  </Row>
                  <Table className="table dems-table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>NRP</th>
                        <th>Nama Penyidik</th>
                        <th>Asal Satker</th>
                        <th>Pangkat</th>
                        <th>No. HP</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {penyidik.length
                        ? penyidik.map((data, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.nrp}</td>
                              <td>{data.nama_penyidik}</td>
                              <td>{data.nama_satker}</td>
                              <td>{data.pangkat}</td>
                              <td>{data.no_hp}</td>
                              <td>
                                <Form.Check type="checkbox" required />
                              </td>
                            </tr>
                          ))
                        : !penyidik.length && (
                            <tr>
                              <td colSpan="4" style={{ textAlign: "center" }}>
                                <span>Tidak ada data penyidik!</span>
                              </td>
                            </tr>
                          )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="4">
              <DatePicker
                label="Tanggal Kembali"
                name="tanggal_kembali"
                selected={payloadDate.tanggal_kembali}
                onChange={(date) => {
                  setPayloadDate({
                    ...payloadDate,
                    tanggal_kembali: date,
                  });
                  handleDatePicker(date, "tanggal_kembali");
                }}
                showTimeSelect
                maxDate={new Date()}
                timeFormat="HH:mm:ss"
                dateFormat="dd/MM/yyyy HH:mm:ss"
                required
              />
            </Col>
            <Col sm="4">
              <Select
                label="Kondisi Fisik Tahanan"
                name="kondisi"
                value={payload.kondisi}
                onChange={handleChange}
                required
                default="Pilih"
                options={[
                  { value: "Sehat", text: "Sehat" },
                  {
                    value: "Tidak Sehat",
                    text: "Tidak Sehat",
                  },
                ]}
              />
            </Col>
            <Col sm="4">
              <Input
                as="textarea"
                label="Keterangan"
                name="keterangan"
                value={payload.keterangan}
                onChange={handleChange}
              />
            </Col>
            <Col sm="4">
              <div className="d-flex flex-column gap-2">
                <span className="fs-6 fw-medium">Foto Tahanan Dipinjam</span>
                <Image src={imageTahananDipinjam} height="250px" width="100%" />
              </div>
            </Col>
            <Col sm="4">
              <div className="d-flex flex-column gap-2">
                <span className="fs-6 fw-medium">
                  Foto Tahanan Dikembalikan
                </span>
                <Image
                  src={imageTahananDikembalikan}
                  height="250px"
                  width="100%"
                />
              </div>
              <Input
                type="file"
                label="Unggah Foto Tahanan Dikembalikan"
                name="foto_tahanan_dikembalikan"
                required
                accepts={[".jpg", ".jpeg", ".png"]}
                onChange={(e) => {
                  const files = e.target.files[0];
                  if (files) {
                    setPayload({
                      ...payload,
                      foto_tahanan_dikembalikan: files,
                    });
                    setImageTahananDikembalikan(URL.createObjectURL(files));
                  }
                }}
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center">
            {!mutateEdit.isLoading ? (
              <Button variant="primary" onClick={handleSubmit}>
                Save
              </Button>
            ) : (
              <Button variant="primary" disabled>
                Loading
              </Button>
            )}
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default PengembalianTahanan;
