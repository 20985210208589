import PerfectScrollbar from "react-perfect-scrollbar"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Link, NavLink } from "react-router-dom"
import { labelMenuGroup } from "../data/Menu"
import State from "../../libraries/State"
import { isNotEmptyObject } from "../../utils"

export const Sidebar = () => {
    const scrollBarRef = useRef(null);
    const state = State.getState()
    const profile = useMemo(() => state?.getItem('profileData', true) ?? {}, [state])
    const [isReady, setIsReady] = useState(false)
    
    useEffect(() => {
        if (labelMenuGroup && isNotEmptyObject(profile)) {
            setIsReady(true)
            
        }
    }, [profile])

    const handleUpdateSize = () => {
      if (scrollBarRef.current) {
        scrollBarRef.current.updateScroll();
      }
    };
  
    return (
      <div className="sidebar">
        <div className="sidebar-header">
          <Link className="sidebar-logo">SIMPATI</Link>
          <small className="fs-sm text-secondary">
            SISTEM MANAJEMEN TERPUSAT<br />TAHANAN DAN BARANG BUKTI
          </small>
        </div>
        <PerfectScrollbar
          className="sidebar-body"
          ref={scrollBarRef}
        >
         {
            isReady &&  <SidebarMenu profile={profile} onUpdateSize={handleUpdateSize} />
         }
        </PerfectScrollbar>
      </div>
    );
  };

const SidebarMenu = ({profile}) => {

    const toggleMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest(".nav-group");
        parent.classList.toggle("show");
    };
    
    const toggleSubMenu = (e) => {
        e.preventDefault();
    
        let parent = e.target.closest(".nav-item");
        let node = parent.parentNode.firstChild;
    
        while (node) {
          if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
            node.classList.remove("show");
          node = node.nextElementSibling || node.nextSibling;
        }
    
        parent.classList.toggle("show");
    };
    return (
        <React.Fragment> 
            {
            labelMenuGroup.map((labelMenu, idxLabel) => (
                labelMenu.role &&
                (
                    labelMenu.role.includes(profile.role.role) &&
                    (
                        <div className="nav-group show" key={idxLabel}>
                        <div className="nav-label" onClick={toggleMenu}>
                            {labelMenu.label}
                        </div>
                            <ul className="nav nav-sidebar">
                                {
                                    labelMenu.menu.map((menu, key) => (
                                        menu.role &&
                                        (
                                            menu.role.includes(profile.role.role) &&
                                            (
                                                <li key={key} className="nav-item">
                                                    {
                                                        !menu.submenu ?
                                                        (
                                                            <NavLink to={menu.link} className="nav-link">
                                                                <i className={menu.icon}></i> <span>{menu.label}</span>
                                                            </NavLink>
                                                        ) :
                                                        (
                                                            <div onClick={toggleSubMenu} className="nav-link has-sub">
                                                                <i className={menu.icon}></i> <span>{menu.label}</span>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        menu?.submenu && 
                                                        (
                                                            <nav className="nav nav-sub">
                                                                {
                                                                    menu.submenu.map((sm, keySm) => (
                                                                       sm.role &&
                                                                       (
                                                                        sm.role.includes(profile.role.role) &&
                                                                            <NavLink to={sm.link} className="nav-sub-link" key={keySm}>
                                                                                {sm.label}
                                                                            </NavLink>
                                                                       )
                                                                    ))
                                                                }
                                                            </nav>
                                                        )
                                                    }
                                                </li>
                                            )
                                        )
                                    ))
                                }
                            </ul>
                        </div>
                    )
                )
            ))
            }
        </React.Fragment>
    )
}