import React, { useLayoutEffect, useRef } from 'react';
import { Card, Col, Row } from 'react-bootstrap'
// import Select from '../../../../components/inputs/Select';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { formatNumberIndo } from '../../../../../utils';

function ChartDonut({data, title}) {
  const chartRef = useRef(null)
  useLayoutEffect(() => {
    
    const totalValue = data.reduce((sum, item) => sum + item.value, 0);
    const allValuesAreZero = data.every(item => item.value === 0);
    let dataChart = data
    let disableLabelsAndTooltips = false;

    if (allValuesAreZero) {
      dataChart = [
        {
          category: "Tidak ada data untuk ditampilkan",
          value: 1, // Dummy value for rendering
          color: am5.color(0xcccccc) // Grey color
        }
      ];
      disableLabelsAndTooltips = true; // Set flag to disable labels and tooltips
    } 

    let root = am5.Root.new(chartRef.current);
    root.setThemes([
        am5themes_Animated.new(root)
    ]);

    // Create chart
    let chart = root.container.children.push(am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: am5.percent(30)
    }));

    // Create series
    let series = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        innerRadius: am5.percent(30)
    }));
    // series.labels.template.set('visible', false)
    series.labels.template.setAll({
        radius: -10, // Adjust this value to place labels inside the pie
        relativeRadius: 0.8, // Relative size of the label radius
        textType: "radial",
        fontSize: 14,
    });
    series.slices.template.adapters.add("fill", function(fill, target) {
      let color = target?.dataItem?.dataContext?.color ?? null
          return color ? target.dataItem.dataContext.color : fill
    });
    series.slices.template.adapters.add("stroke", function(stroke, target) {
      return am5.color(0xFFFFFF); // Set stroke color for better visual separation
    });
    series.slices.template.adapters.add("tooltipText", function(fill, target) {
      if (disableLabelsAndTooltips) {
        return 0
      }
    });
    // Disable labels if it's "No Data"
    if (disableLabelsAndTooltips) {
      series.labels.template.set("visible", false); // Hide labels
      series.ticks.template.set("visible", false);  // Hide ticks
    }
    series.data.setAll(dataChart);

    // Add label
    let total = dataChart.reduce((sum, item) => sum + item.value, 0);
    chart.seriesContainer.children.push(am5.Label.new(root, {
        text: disableLabelsAndTooltips ? '0' : formatNumberIndo(total),
        fontSize: 30,
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        fill: am5.color(0x000000)
    }));
    var legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        // layout: root.horizontalLayout,
        // y: am5.percent(100),  // Mengatur posisi legend di bawah
        // centerY: am5.percent(100),
        // nameField: '{categoryX}',
        // marginTop: 10,
    }));
    legend.labels.template.adapters.add("text",(text, target) => {
        const dataCtx = target?.dataItem?.dataContext
        if (disableLabelsAndTooltips) {
            return text
        } else {
          if (dataCtx) {
              return `${text} (${formatNumberIndo((dataCtx?.value ?? 0))})`
          }
        }

        return text
    })
    legend.data.setAll(series.dataItems);
    legend.valueLabels.template.set("forceHidden", true);
    root._logo.setAll({
        height: -100000000  // Adjust the height as needed
    });
    series.appear(1000, 100);
    return () => {
        root.dispose();
    };
  }, [data])
  return (
    <Card className='card-one bg-white'>
      <Card.Body className='d-flex flex-column gap-2'>
        <Card.Text as="label" style={{paddingLeft: 0}} className='fs-5 fw-bold'>{title}</Card.Text>
        <div className="chart-box" ref={chartRef}>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ChartDonut;