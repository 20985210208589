import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Accordion,
  Row,
  Spinner,
  Table,
  Form,
  Modal,
  Alert,
  Placeholder,
  Badge,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  DatePicker,
  Image,
  Input,
  Select,
} from "../../../../components/Components";
import { useQueryClient } from "react-query";
import { useGetMasterBarbukListNew } from "../../../../utils/apis/query";

function FormMenu() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [payloadList, setPayloadList] = useState(
    {
      kategori: '',
      tipe: '',
      subtipe: '',
      nama: '',
      unit: '',
      search: ''
    }
  )
  const [selected, setSelected] = useState(
    {
      kategori: '',
      tipe: '',
      subtipe: '',
      nama: '',
      unit: '',
    }
  )

  const {
    data: dataList,
    isFetching: isDataListFetching,
    isError: isDataListError
  } = useGetMasterBarbukListNew(payloadList)

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/tahanan">Tahanan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/tahanan/perpanjangan/list">
                    List Perpanjangan Tahanan
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form Perpanjangan Tahanan
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            {/* <Col sm="12">
              {mutateSubmit.isError && (
                <Alert variant="danger">
                  <ul className="m-0">
                    {mutateSubmit?.error?.response?.data?.detail?.map(
                      (errEdit, idx) => (
                        <li key={idx}>
                          <b>{errEdit.loc[1]} : </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error : </b>
                        {mutateSubmit?.error?.response?.data?.message ??
                          "Bermasalah"}
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateSubmit.isSuccess && (
                <Alert variant="success">
                  {mutateSubmit.data.data.message}
                </Alert>
              )}
            </Col> */}
            {
              isDataListFetching && (
                <Col>
                  <div className="w-100 d-flex justify-content-center">
                    <Spinner variant="primary" size="sm" />
                  </div>
                </Col>
              )
            }
            {
              isDataListError && (
                <Col>
                  Error
                </Col>
              )
            }
            {
              !isDataListError && !isDataListFetching && (
                  <Col sm="12">
                    <Row className="g-2">
                      <Col>
                          <Card className="bg-white w-100">
                              <Card.Header className="bg-white">
                                  <Card.Title className="m-0 p-0">Kategori</Card.Title>
                              </Card.Header>
                              <Card.Body className="p-2">
                                <div className="d-flex flex-column gap-2">
                                  <div className="d-flex align-items-center justify-content-between gap-1">
                                    <Input 
                                        placeholder="Tambah Baru"
                                        style={{height: 30}}
                                    ></Input>
                                    <Badge 
                                      style={{height: 27}}
                                      className="btn bg-secondary" 
                                      as="button"
                                    >
                                        <i className="ri-add-line"></i>
                                    </Badge>
                                  </div>
                                    {
                                      dataList.kategori && (
                                        dataList.kategori.map((k, idx) => (
                                          <Card 
                                            className={
                                              `p-1
                                              ${payloadList.kategori === k.kategori ? 'card-success text-light': ''}
                                              `
                                            } 
                                            key={idx}
                                          >
                                              <div 
                                                className="d-flex align-items-center justify-content-between"
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                  // setSelected({...selected, kategori: k.kategori})
                                                  setPayloadList({...payloadList, kategori: k.kategori, tipe: '', subtipe: '', nama: '', unit: ''})
                                                }}
                                              >
                                                  <Card.Text className="p-0 m-0">{k?.kategori ?? '-'}</Card.Text>
                                                  {
                                                    payloadList.kategori === k.kategori &&
                                                    (
                                                      <i className="ri-arrow-right-line"></i>
                                                    )
                                                  }
                                              </div>
                                          </Card>
                                        ))
                                      )
                                    }
                                  </div>
                              </Card.Body>
                          </Card>
                      </Col>
                      <Col>
                        <Card className="bg-white w-100">
                            <Card.Header className="bg-white">
                                <Card.Title className="m-0 p-0">Tipe</Card.Title>
                            </Card.Header>
                            <Card.Body className="p-2">
                              <div className="d-flex flex-column gap-2">
                                <div className="d-flex align-items-center justify-content-between gap-1">
                                  <Input 
                                      placeholder="Tambah Baru"
                                      style={{height: 30}}
                                  ></Input>
                                  <Badge 
                                    style={{height: 27}}
                                    className="btn bg-secondary" 
                                    as="button"
                                  >
                                      <i className="ri-add-line"></i>
                                  </Badge>
                                </div>
                                  {
                                    dataList.tipe && (
                                      dataList.tipe.map((k, idx) => (
                                        <Card 
                                          className={
                                            `p-1
                                            ${payloadList.tipe === k.tipe ? 'card-success text-light': ''}
                                            `
                                          } 
                                          key={idx}
                                        >
                                            <div 
                                              className="d-flex align-items-center justify-content-between"
                                              style={{cursor: 'pointer'}}
                                              onClick={() => {
                                                // setSelected({...selected, tipe: k.tipe})
                                              setPayloadList({...payloadList, tipe: k.tipe, subtipe: '', nama: '', unit: ''})
                                              }}
                                            >
                                                <Card.Text className="p-0 m-0">{k?.tipe ?? '-'}</Card.Text>
                                                {
                                                  payloadList.tipe === k.tipe &&
                                                  (
                                                    <i className="ri-arrow-right-line"></i>
                                                  )
                                                }
                                            </div>
                                        </Card>
                                      ))
                                    )
                                  }
                                </div>
                            </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="bg-white w-100">
                          <Card.Header className="bg-white">
                              <Card.Title className="m-0 p-0">Subtipe</Card.Title>
                          </Card.Header>
                          <Card.Body className="p-2">
                              <div className="d-flex flex-column gap-2">
                                <div className="d-flex align-items-center justify-content-between gap-1">
                                  <Input 
                                      placeholder="Tambah Baru"
                                      style={{height: 30}}
                                  ></Input>
                                  <Badge 
                                    style={{height: 27}}
                                    className="btn bg-secondary" 
                                    as="button"
                                  >
                                      <i className="ri-add-line"></i>
                                  </Badge>
                                </div>
                                {
                                  dataList.subtipe && (
                                    dataList.subtipe.map((k, idx) => (
                                      <Card 
                                        className={
                                          `p-1
                                          ${payloadList.subtipe === k.subtipe ? 'card-success text-light': ''}
                                          `
                                        } 
                                        key={idx}
                                      >
                                          <div 
                                            className="d-flex align-items-center justify-content-between"
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                              // setSelected({...selected, subtipe: k.subtipe})
                                          setPayloadList({...payloadList, subtipe: k.subtipe, nama: '', unit: ''})
                                            }}
                                          >
                                              <Card.Text className="p-0 m-0">{k?.subtipe ?? '-'}</Card.Text>
                                              {
                                                payloadList.subtipe === k.subtipe &&
                                                (
                                                  <i className="ri-arrow-right-line"></i>
                                                )
                                              }
                                          </div>
                                      </Card>
                                    ))
                                  )
                                }
                              </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="bg-white w-100">
                          <Card.Header className="bg-white">
                              <Card.Title className="m-0 p-0">Nama</Card.Title>
                          </Card.Header>
                          <Card.Body className="p-2">
                              <div className="d-flex flex-column gap-2">
                                <div className="d-flex align-items-center justify-content-between gap-1">
                                  <Input 
                                      placeholder="Tambah Baru"
                                      style={{height: 30}}
                                  ></Input>
                                  <Badge 
                                    style={{height: 27}}
                                    className="btn bg-secondary" 
                                    as="button"
                                  >
                                      <i className="ri-add-line"></i>
                                  </Badge>
                                </div>
                                {
                                  dataList.nama && (
                                    dataList.nama.map((k, idx) => (
                                      <Card 
                                        className={
                                          `p-1
                                          ${payloadList.nama === k.nama ? 'card-success text-light': ''}
                                          `
                                        } 
                                        key={idx}
                                      >
                                          <div 
                                            className="d-flex align-items-center justify-content-between"
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                              // setSelected({...selected, nama: k.nama})
                                          setPayloadList({...payloadList, nama: k.nama, unit: ''})
                                            }}
                                          >
                                              <Card.Text className="p-0 m-0">{k?.nama ?? '-'}</Card.Text>
                                              {
                                                payloadList.nama === k.nama &&
                                                (
                                                  <i className="ri-arrow-right-line"></i>
                                                )
                                              }
                                          </div>
                                      </Card>
                                    ))
                                  )
                                }
                              </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="bg-white w-100">
                          <Card.Header className="bg-white">
                              <Card.Title className="m-0 p-0">Unit</Card.Title>
                          </Card.Header>
                          <Card.Body className="p-2">
                              <div className="d-flex flex-column gap-2">
                                <div className="d-flex align-items-center justify-content-between gap-1">
                                  <Input 
                                      placeholder="Tambah Baru"
                                      style={{height: 30}}
                                  ></Input>
                                  <Badge 
                                    style={{height: 27}}
                                    className="btn bg-secondary" 
                                    as="button"
                                  >
                                      <i className="ri-add-line"></i>
                                  </Badge>
                                </div>
                                {
                                  dataList.unit && (
                                    dataList.unit.map((k, idx) => (
                                      <Card 
                                        className={
                                          `p-1
                                          ${payloadList.unit === k.unit ? 'card-success text-light': ''}
                                          `
                                        } 
                                        key={idx}
                                      >
                                          <div 
                                            className="d-flex align-items-center justify-content-between"
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                              // setSelected({...selected, unit: k.unit})
                                            // setPayloadList({...payloadList, unit: k.unit})
                                            }}
                                          >
                                              <Card.Text className="p-0 m-0">{k?.unit ?? '-'}</Card.Text>
                                              {
                                                payloadList.unit === k.unit &&
                                                (
                                                  <i className="ri-arrow-right-line"></i>
                                                )
                                              }
                                          </div>
                                      </Card>
                                    ))
                                  )
                                }
                              </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                )
            }
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Button
                variant="warning"
                as={Link}
              >
                BATAL
              </Button>
              <Button variant="primary">
                APPLY / SIMPAN
              </Button>
            </div>
        </Card.Footer>
      </Card>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Cari Tahanan</Modal.Title>
          <Button
            className="btn btn-danger"
            onClick={() => setModalShow(false)}
          >
            <i class="ri-close-large-fill"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-4">
            <Col>
                syllble
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default FormMenu;
