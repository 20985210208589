import React from 'react'
import State from "../../libraries/State";
import ReCAPTCHA from "react-google-recaptcha";
import { Input } from '../../components/Components'
import { useNavigate } from "react-router-dom";
import { Alert, Form, Spinner } from "react-bootstrap";
import { useMutation } from "react-query";
import { useEffect, useState } from "react";
import { authLogin } from "../../utils/apis";
import { useGetProfile } from "../../utils/apis/query";
import { useQueryPage, validateToPath } from "../../utils";
import useRecaptcha from '../../utils/hooks/useRecaptcha';
function Login() {
  const navigate = useNavigate();
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();
  const qp = useQueryPage()
  const to = qp.get('to') || ''
  const state = State.getState();
  const [isSuccess, setIsSuccess] = useState(false) 
  const [form, setForm] = useState({
    username: '',
    password: '',
    recaptcha_token: ''
  })
  const onSuccess = (data) => {
    state.setItem("profileData", data, true)
    setIsSuccess(true)
    if (validateToPath(to)) {
      window.location.href = '/'+validateToPath(to)
    } else {
      window.location.href = '/dashboard'
    }
  }
  const mutateProfile = useGetProfile(onSuccess)

  const loginMutation = useMutation(authLogin, {
    onSuccess: (data) => {
      const sessionData = {
        token: data?.data?.value ?? null,
        username: form?.username ?? null
      }
      if (sessionData.token && sessionData.username) {
        state.setItem("accessToken", sessionData, true);
        mutateProfile.mutate()
      }
    }
  })

  const handleChange = (e) => {
    const {name, value} = e.target
    setForm({...form, [name]: value})
  }
  
  const doLogin = async (e) => {
    e.preventDefault()
    
    if (capchaToken && form.username && form.password) {
      // loginMutation.mutate({...form})
      loginMutation.mutate({...form, recaptcha_token: capchaToken})
    } else {
      alert('Periksa kembali form inputan anda')
    }
  };

  useEffect(() => { 
    // Fetch IP address
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        state.setItem("ipUser", data.ip, true)
      })
      .catch((error) => console.error('Error fetching IP:', error));
  }, []);

  useEffect(() => {
    if (loginMutation.isError) {
      if ((loginMutation?.error?.response?.data?.message ?? '') === 'Invalid reCAPTCHA') {
        handleRecaptcha('')
        if (recaptchaRef.current) {
          recaptchaRef.current.reset()
        }
      } else {
        window.location.reload()
      }
    }
  }, [loginMutation.isError, loginMutation.error, recaptchaRef, handleRecaptcha])

  useEffect(() => {
    let token = state.getItem('accessToken', true)?.token?.access_token ?? null;
    if (token) navigate('/dashboard')
  }, [])

  return (
    <div className="page-auth">
       <div className="container-auth">
          <div className="logo">
            <img className="logo-tahti" src="/assets/img/Logo TAHTI Login Screen.png" alt="Logi tahti" />
            <img className="logo-pusiknas" src="/assets/img/Logo Pusiknas Login Screen.png" alt="Logi pusiknas" />
          </div>

          <div className="card card-sign">
            <div className="card-header">
              <a
                href="/login"
                className="d-flex justify-content-center align-items-center mb-2"
              >
                <img className="box-logo-auth" src="/assets/img/SIMPATI Logo.png" alt="Logo SIMPATI" />
              </a>
              <h3 className="card-title">Sign In</h3>
              <p className="card-text">
                Selamat datang! Silahkan login untuk melanjutkan
              </p>
            </div>
      
            <Form onSubmit={doLogin} className="card-body d-flex flex-column gap-3">
              {
                !loginMutation.isLoading && loginMutation.isError ? (
                  <Alert variant="danger" className="m-0">
                    {
                      loginMutation?.error?.response?.data?.message ?? 'Server Error'
                    }
                  </Alert>
                ): ''
              }
              {
                !loginMutation.isLoading && !loginMutation.isError && isSuccess ? (
                  <Alert variant="success" className="m-0">
                    Login Berhasil
                  </Alert>
                ): ''
              }
              <Input
                type="text"
                name="username"
                label="Username"
                value={form.username}
                onChange={handleChange}
                placeholder="Masukan username anda"
              />
              <Input
                type="password"
                name="password"
                label="Password"
                value={form.password}
                onChange={handleChange}
                placeholder="Masukan password anda "
              />
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LdopoQqAAAAAJTmNmLgsmZF5rAQsOM5KtsPCCNt"
                onChange={handleRecaptcha}
              />
              {
                !loginMutation.isLoading ? (
                  <button type="submit" className="btn btn-primary btn-sign">
                    Masuk
                  </button>
                ) : (
                  <button disabled className="btn btn-primary btn-sign">
                    <Spinner size="sm" animation="border" variant="light"/>
                  </button>
                )
              }
            </Form>
          </div>
        </div>
    </div>
  )
}

export default Login