import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Badge,
  Button,
  Placeholder,
  Modal,
  Alert,
} from "react-bootstrap";
import { DatePicker, EmptyData, Input, Select } from "../../../components/Components";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import {
  useExtrackedFileNew,
  useGetBerkasFolder,
  usePostSharedFile,
  useUsersAll,
} from "../../../utils/apis/query";
import { encodeToHex, trimFileUrl, trimUrlPath } from "../../../utils";

function Search() {
  const [modalShow, setModalShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [payloadShared, setPayloadShared] = useState({
    filename: "",
    expired_time: "",
    id_user: "",
  });
  const [search, setSearch] = useState('');
  const [debounceParams, setDebounceParams] = useState({
    keyword: "",
  });

  
  let { data: dataUsers } = useUsersAll();
  dataUsers =
    dataUsers?.data?.value?.data?.map((usr) => ({
      text: usr.profile.nama_lengkap,
      value: usr.user.id,
    })) ?? [];

  const onSuccessHandler = (data) => {
    setModalShow(false)
  };
  let mutationShare = usePostSharedFile(onSuccessHandler);

  const {
    data: dataSearch,
    isFetching,
    isError,
    error: fetchError,
  } = useExtrackedFileNew(debounceParams, {enabled: !!debounceParams.keyword});

  const {
    data: dataFolder,
    isFetching: folderFetching,
    isError: folderIsError,
    error: folderError,
  } = useGetBerkasFolder(debounceParams, {enabled: !!debounceParams.keyword});

  const handleDebounce = useCallback(
    debounce((newForm) => {
      setDebounceParams({keyword: newForm});
    }, 300),
    []
  );
  const handleShowModalShare = (datas) => {
    setModalShow(true);
    setSelectedFile(datas);
    setPayloadShared({
      ...payloadShared,
      filename: datas.filename,
    });
  };
  const handleShare = () => {
    mutationShare.mutate(payloadShared);
  };

  useEffect(() => {
    if (modalShow === false) {
      mutationShare.reset();
      setPayloadShared({
        filename: "",
        expired_time: "",
        id_user: "",
      });
    }
  }, [modalShow]);

  // useEffect(() => {
  //   handleDebounce(search);
  // }, [search, handleDebounce]);
  return (
    <React.Fragment>
      <Card className="card bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              <Card.Title as="label" className="fs-5 fw-medium">
                Pencarian File Berkas
              </Card.Title>
              <Input
                placeholder="Search Berkas"
                name="keyword"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.code === 'Enter' ? handleDebounce(search) : ''}
              />
            </Col>
            {
              !!((dataFolder?.length ?? null) && (dataSearch?.length ?? null)) &&
              (
                <Col sm="12">
                  <div className="d-flex align-items-center gap-3">
                    <Badge bg="light">
                      <p
                        className="font-monospace text-dark mb-0"
                        style={{ fontSize: "12px" }}
                      >
                        Ditemukan: {' '} 
                        {
                          (dataSearch?.length ?? 0)
                          + 
                          dataFolder?.reduce((acc, item) => {
                            return acc + item.dir.length;
                          }, 0) ?? 0} Hasil Pencarian
                      </p>
                    </Badge>
                  </div>
                </Col>
              )
            }
            {
              ((isFetching && folderFetching) && (!isError && !folderIsError)) && 
              (
                <Col sm="12">
                  <Card className="card-one">
                    <Card.Body>
                      <Placeholder
                        as="p"
                        animation="wave"
                        className="m-0 d-flex flex-column gap-2"
                      >
                        <Placeholder sm="2" />
                        <Placeholder sm="3" />
                        <Placeholder sm="12" />
                        <Placeholder sm="12" />
                        <Placeholder sm="12" />
                      </Placeholder>
                    </Card.Body>
                  </Card>
                </Col>
              ) 
            }
            {
              ((!isFetching && !folderFetching) && isError && (!(dataFolder?.length ?? []).length && !(dataSearch?.length ?? []).length)) && (<EmptyData />)
            }
            {
              (!folderFetching && !folderIsError) &&
              (
                dataFolder?.map((folder, idx) => (
                    (folder?.dir ?? []).map((dir, idxDir) => (
                      <Col sm="12" key={idx+'key'+idx}>
                        <Card className="card-one bg-white">
                          <Card.Body style={{padding: 10}}>
                            <Row>
                              <Col sm="11">
                                <Link
                                  target="_blank" to={`/arsip/list?path=${encodeToHex(folder.name+'/'+dir.name)}`}
                                  className="advance-search-item"
                                >
                                  <div className="item-header">
                                    <div className="icon">
                                      <i className="ri-folder-5-line text-primary" />
                                    </div>
                                    <div className="header-text">
                                      {/* <span className="title fw-medium">
                                        {folder.name}
                                      </span> */}
                                      <div className="title fw-medium text-dark opacity-75">
                                        { dir.name.replaceAll('_', '/') }
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                )) ?? []
              ) 
            }
            {
              (!isFetching && !isError) && 
              (
                (dataSearch || []).map((data, idx) => {
                  const {file_url} = data
                  const {p, k} = trimUrlPath(file_url) 
                  const file = trimFileUrl(file_url)
                  const format = file.split(".")?.pop()
                  let icon = ''
                  if (["jpg", "jpeg", "gif", "png"].includes(format)) {
                    icon = "ri-image-2-line text-primary";
                  } else if (
                    [
                      "xls",
                      "xlsx",
                      "xlsm",
                      "xlsb",
                      "csv",
                      "xlt",
                      "xltx",
                      "xltm",
                      "xml",
                      "ods",
                    ].includes(format)
                  ) {
                    icon = "ri-file-excel-line text-success";
                  } else if (
                    [
                      "doc",
                      "docx",
                      "dot",
                      "dotx",
                      "dotm",
                      "rtf",
                      "txt",
                      "odt",
                      "xml",
                    ].includes(format)
                  ) {
                    icon = "ri-image-2-line text-primary";
                  } else if (
                    [
                      "mp4",
                      "avi",
                      "mov",
                      "wmv",
                      "flv",
                      "mkv",
                      "webm",
                      "mpg",
                      "3gp",
                      "m4v",
                      "m2ts",
                      "m3u8",
                    ].includes(format)
                  ) {
                    icon = "ri-file-video-line text-primary";
                  } else if (
                    [
                      "mp3",
                      "wav",
                      "aac",
                      "flac",
                      "ogg",
                      "wma",
                      "m4a",
                      "alac",
                      "aiff",
                      "opus",
                    ].includes(format)
                  ) {
                    icon = "ri-file-music-line text-primary";
                  } else if (format === "ppt") {
                    icon = "ri-file-ppt-line text-orange";
                  } else if (format === "pdf") {
                    icon = "ri-file-pdf-2-line text-danger";
                  } else {
                    icon = "ri-file-line";
                  }
                  return (
                    <Col sm="12" key={idx}>
                      <Card className="card-one bg-white">
                        <Card.Body>
                          <Row>
                            <Col sm="11">
                              <Link
                                target="_blank" to={`/arsip/view/${file}?kategori=tahti&p=${encodeToHex(p)}`}
                                className="advance-search-item"
                              >
                                <div className="item-header">
                                  <div className="icon">
                                    <i className={icon} />
                                  </div>
                                  <div className="header-text">
                                    <span className="title fw-medium">
                                      {file}
                                    </span>
                                    <div className="title fw-medium text-dark opacity-75">
                                      { data.path_file.replaceAll('_', '/') }
                                    </div>
                                  </div>
                                </div>
                                <div className="item-body">
                                  <div className="description description-dems text-secondary">
                                    {data.snippet_text}
                                  </div>
                                  {
                                    format === 'pdf' ? (<span className='text-secondary'>{data?.total_page ?? 0} Halaman</span>) : ''                                 
                                  }
                                </div>
                              </Link>
                            </Col>
                            <Col sm="1" className="d-flex align-items-center">
                              <Badge
                                onClick={() => handleShowModalShare(data)}
                                role="button"
                                bg="secondary"
                                className="p-2 cursor-pointer"
                                pill
                              >
                                <i className="ri-share-box-line fs-15"></i>
                              </Badge>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                })
              )
            }
          </Row>
        </Card.Body>
      </Card>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-2">
            {mutationShare.isSuccess ? (
              <Alert variant="success" className="d-flex align-items-center">
                Berhasil share file
              </Alert>
            ) : (
              ""
            )}
            {mutationShare.isError ? (
              <Alert variant="danger" className="d-flex align-items-center">
                {mutationShare.error.response.data.message}
              </Alert>
            ) : (
              ""
            )}
            <Alert variant="info" className="d-flex align-items-center">
              <i className={selectedFile?.iconClass ?? ""}></i>
              <span className="text-dark">{selectedFile?.filename ?? ""}</span>
            </Alert>
            <Select
              default="Pilih User"
              name="id_user"
              options={dataUsers}
              value={payloadShared.id_user}
              onChange={(e) =>
                setPayloadShared({
                  ...payloadShared,
                  [e.target.name]: e.target.value,
                })
              }
            />
            <DatePicker
              placeholderText="Pilih jangka maksimal"
              name="expired_time"
              selected={payloadShared.expired_time}
              showTimeSelect
              showTimeSelectOnly={false}
              timeFormat="HH:mm:ss"
              minDate={new Date()}
              dateFormat="dd/MM/yyyy HH:mm:ss"
              onChange={(date) =>
                setPayloadShared({ ...payloadShared, expired_time: date })
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
          {!mutationShare.isLoading ? (
            <Button variant="primary" onClick={() => handleShare()}>
              Share
            </Button>
          ) : (
            <Button variant="primary" disabled>
              Loading
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Search;
