import React, { useEffect } from 'react'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useQueryPage } from '../../utils'

function SessionExpired() {
    const navigate = useNavigate()
    const qp = useQueryPage()
    const from = qp.get('from') || ''

    useEffect(() => {
        const timeOut = setTimeout(() => {
            navigate('/login?to='+from)
        }, 2000)

        return () => {
            clearTimeout(timeOut)
        }
    }, [])
  return (
    <React.Fragment>
        <Row className='g-2 p-2'>
            <Col sm="12">
                <Card className='card bg-whote'>
                    <Card.Body className='text-align-center gap-3 d-flex flex-column justify-content-center align-items-center'>
                        <Spinner variant='primary' size='medium' />
                        <span>Session Expired, Redirecting to the login page...</span>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </React.Fragment>
  )
}

export default SessionExpired