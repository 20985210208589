import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Placeholder,
    Spinner,
    Button,
  } from "react-bootstrap";
import { ChartBarTindakPidana } from '../module';
import { useGetDashboardAdmindik, useGetDashboardTindakPidana, useGetPoldaList, useGetPoldaPolres } from '../../../utils/apis/query';
import { Select2 } from '../../../components/Components';
import { direktorat, formatNumberIndo, formatNumberShort, jenisTindakPidana } from '../../../utils';
import State from '../../../libraries/State';
import moment from 'moment';

function Index() {
  const state = State.getState();
  const profileData = state.getItem("profileData", true);
  const [seriesPerkara, setSeriesPerkara] = useState([])
  const [seriesJTP, setSeriesJTP] = useState([])
  const [namaSatkerPoldaFilter, setNamaSatkerPoldaFilter] = useState("");
  const [namaSatkerPolresFilter, setNamaSatkerPolresFilter] = useState("");
  const [namaSatkerPoldaPayloadFilter, setNamaSatkerPoldaPayloadFilter] = useState("");
  const [namaSatkerPolresPayloadFilter, setNamaSatkerPolresPayloadFilter] = useState("");
  const [jenisTindakPidanaFilter, setJenisTindakPidanaFilter] = useState("");
  const [fetchedOnce, setFetchedOnce] = useState(true);


  const satker = profileData?.satker?.nama_satker ?? ''
  const isPolres = /polres/gi.test(satker)
  const isPolda = /polda/gi.test(satker)
  const isBareskrim = /bareskrim/gi.test(satker)

  const {
    data: dataSatker
  } = useGetPoldaPolres(
    {
      polda: namaSatkerPoldaFilter,
      polres:
        namaSatkerPolresFilter === "satker_sendiri"
          ? ""
          : namaSatkerPolresFilter,
    },
    {
      enabled: !!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresFilter,
    }
  );

  const optionPolda = [{label: 'SEMUA POLDA', value: 'semua_polda'},...dataSatker.polda.map((o) => ({label: o.value, value: o.value}))]
  const optionPolres= [{label: 'SEMUA', value: 'semua'}, {label: 'SATKER SENDIRI', value: 'satker_sendiri'},...dataSatker.polres.map((o) => ({label: o.value, value: o.value}))]

  const {
    data: dataChart,
    isFetching: chartIsFetching,
    isError: chartIsError,
    refetch: refetchDataDashboard
  } = useGetDashboardAdmindik(
    {
        nama_satker_polda: namaSatkerPoldaPayloadFilter,
        nama_satker_polres: namaSatkerPolresPayloadFilter,
        jenis_tindak_pidana: jenisTindakPidanaFilter
    },
    {
        enabled: (!!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresPayloadFilter) && fetchedOnce
    }
  )

  //   SET DATA 
  useEffect(() => {
    if (dataChart) {
        if (fetchedOnce) setFetchedOnce(false)
        const seriesPerkara = Object.keys(dataChart?.perkara ?? {}).map(key => ({
            label: key,
            value: dataChart?.perkara[key]
        }));
        
        const seriesJTP = Object.keys(dataChart?.jenis_tindak_pidana).map(key => ({
            label: key,
            value: dataChart?.jenis_tindak_pidana[key]
        }));

        setSeriesPerkara(seriesPerkara)
        setSeriesJTP(seriesJTP)
    }
  }, [dataChart])

  // BAGIAN KRUSIAL SET FILTER A I U E O
  useEffect(() => {
    if (profileData) {
      moment.locale();
      const satker = profileData?.satker?.nama_satker ?? "";
      const username = profileData?.user?.username ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      if (isBareskrim) {
        if (['kabareskrim', 'kapusiknas'].includes(username)) {
          setNamaSatkerPoldaFilter('semua_polda');
          setNamaSatkerPoldaPayloadFilter('semua_polda');
        } else {
          setNamaSatkerPoldaFilter(satker);
          setNamaSatkerPoldaPayloadFilter(satker);
        }
        setNamaSatkerPolresFilter("satker_sendiri");
      } else {
        if (isPolres) {
          let namaPolda = profileData?.profile?.nama_satker_parent_polres ?? "";
          setNamaSatkerPoldaFilter(namaPolda);
          setNamaSatkerPoldaPayloadFilter(namaPolda);
        } else {
          setNamaSatkerPoldaFilter(satker);
          setNamaSatkerPoldaPayloadFilter(satker);
          setNamaSatkerPolresFilter("satker_sendiri");
        }
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (profileData) {
      const satker = profileData?.satker?.nama_satker ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      const isfilterPoldaAll = namaSatkerPoldaFilter === 'semua_polda'

      if (isBareskrim || isfilterPoldaAll) {
        const filterBareskrim = /bareskrim/gi.test(namaSatkerPoldaFilter);
        if (!namaSatkerPolresFilter) setNamaSatkerPolresPayloadFilter('satker_sendiri')
        if (isfilterPoldaAll && namaSatkerPolresPayloadFilter === 'satker_sendiri') {
          setNamaSatkerPoldaPayloadFilter(dataSatker.polda.map((o) => o.value).join(","))
        }
        if (isfilterPoldaAll && namaSatkerPolresPayloadFilter === 'semua') {
          setNamaSatkerPoldaPayloadFilter('semua_polda')
        } 
        if (namaSatkerPolresFilter === 'semua') {
          let payload = filterBareskrim  ? '' : dataSatker.polres.map((o) => o.value).join(",");
          setNamaSatkerPolresPayloadFilter(payload);
        }
        
      } else {
        if (isPolres) {
          let namaPolres = profileData?.profile?.nama_satker_tahti ?? "";
          setNamaSatkerPolresFilter(namaPolres);
          setNamaSatkerPolresPayloadFilter(namaPolres);
        } else {
          if (!namaSatkerPolresFilter) setNamaSatkerPolresPayloadFilter('satker_sendiri')
          if (namaSatkerPolresFilter === 'semua') {
            setNamaSatkerPolresPayloadFilter(
              dataSatker.polres.map((o) => o.value).join(",")
            )
          }
        }
      }
    }
  }, [profileData, dataSatker, namaSatkerPolresFilter, namaSatkerPoldaFilter])
  
  return (
    <React.Fragment>
      <div className="mb-3">
        <h4 className="main-title mb-0">Dashboard Admindik</h4>
      </div>
      <Card className='card-one bg-white'>
        <Card.Body>
            <Row className='g-2'>
                <Col sm="12">
                    <Row className='g-2'>
                        <Col sm="2">
                            <Select2
                                options={optionPolda}
                                value={namaSatkerPoldaFilter}
                                name="nama_satker_polda"
                                placeholder="Semua Kesatuan"
                                required
                                isClearable
                                isSearchable
                                disabled={!isBareskrim || isPolres}
                                onChange={(e) => {
                                    setNamaSatkerPoldaFilter(e.value)
                                    setNamaSatkerPoldaPayloadFilter(e.value)
                                    setNamaSatkerPolresFilter((prev) => ('satker_sendiri'))
                                    setNamaSatkerPolresPayloadFilter((prev) => ('satker_sendiri'))
                                }}
                            />
                        </Col>
                        <Col sm="2">
                            <Select2
                                options={optionPolres}
                                value={namaSatkerPolresFilter}
                                name="nama_satker_polres"
                                placeholder="SEMUA"
                                required
                                isClearable
                                disabled={isPolres}
                                isSearchable
                                onChange={(e) => {
                                setNamaSatkerPolresFilter(e.value);
                                setNamaSatkerPolresPayloadFilter(e.value);
                                }}
                            />
                        </Col>
                        <Col sm="2">
                            <Select2
                                default="Semua Jenis Tindak Pidana"
                                name="jenis_tindak_pidana"
                                options={[{value: '', label: 'SEMUA'}, ...jenisTindakPidana]}
                                value={jenisTindakPidanaFilter}
                                onChange={(e) => {
                                    setJenisTindakPidanaFilter(e.value)
                                }}
                            />
                        </Col>
                        <Col sm="2">
                          <Button
                            variant="danger"
                            size="small"
                            onClick={() => {
                              refetchDataDashboard()
                            }}
                          >
                            Filter
                          </Button>
                        </Col>
                    </Row>
                </Col>
                <Col sm="12">
                {
                        (chartIsFetching && !chartIsError) && 
                        (
                            <div className="d-flex justify-content-center align-items-center">
                                <Spinner variant="primary" size="small" />
                            </div>
                        )
                    }
                    {
                        (!chartIsFetching && chartIsError) &&
                        (
                            <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                                <i className="ri-bar-chart-box-line fs-1"></i>
                                <div className="fs-6 fw-medium">Tidak Ada Data</div>
                            </div>
                        )
                    }
                    {
                        (!chartIsError && !chartIsFetching) &&
                        (
                            <Row className='g-2'>
                                <Col sm="6">
                                    <ChartBarTindakPidana 
                                        title="Jenis Tindak Pidana"
                                        dataSeries={seriesJTP}
                                    />
                                </Col>
                                <Col sm="6">
                                    <div className="d-flex gap-2 h-100">
                                        <Card className='card-one card-box-info-dashboard bg-white text-align-center'>
                                            <div style={{flex: 1}} className="d-flex justify-content-between">
                                                <Card.Text as="label" style={{paddingLeft: 0}} className='fs-5 fw-bold'>Jumlah Laporan Polisi</Card.Text>
                                            </div>
                                            <div  style={{height: '60%'}} className="d-flex flex-1 justify-content-center">
                                                <span className='text-info-number-mindik fw-bold'>{formatNumberIndo(dataChart?.jumlah_lp ?? 0)}</span>
                                            </div>
                                        </Card>
                                        <Card className='card-one card-box-info-dashboard bg-white text-align-center'>
                                            <div  style={{flex: 1}} className="d-flex justify-content-between">
                                                <Card.Text as="label" style={{paddingLeft: 0}} className='fs-5 fw-bold'>Jumlah Barang Bukti</Card.Text>
                                            </div>
                                            <div  style={{height: '60%'}} className="d-flex justify-content-center">
                                                <span className='text-info-number-mindik fw-bold'>{formatNumberIndo(dataChart?.total_barbuk ?? 0)}</span>
                                            </div>
                                        </Card>
                                    </div>
                                </Col>
                                <Col sm="12">
                                    <ChartBarTindakPidana 
                                        title="Perkara"
                                        dataSeries={seriesPerkara}
                                    />
                                </Col>
                            </Row>
                        )
                    }
                </Col>
            </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

export default Index