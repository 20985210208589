import moment from 'moment'
import React, { useRef, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import ReactPlayer from 'react-player'

function ViewMedia({payload}) {
  const videoRef = useRef(null)
  const canvasRef = useRef(null)
  const [showPrediction, setShowPrediction] = useState({
    showPrediction: false,
    showPerson: false,
    showOther: false,
    showAll: false,
  })
  const [metaData, setMetaData] = useState({
    frameWidth: 0,
    frameHeight: 0,
  })

  const handleOnPlay = () => {
    const video = videoRef.current.getInternalPlayer()
    if (video && canvasRef.current) {
      canvasRef.current.width = video.videoWidth
      canvasRef.current.height = video.videoHeight

      setMetaData({frameWidth: video.videoWidth, frameHeight: video.videoHeight})
    }
  }
  const drawPredictions = (predictions) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    predictions.forEach(prediction => {
      if (
        (prediction.name === 'person' && showPrediction.showPerson)||
        (prediction.name !== 'person' && showPrediction.showOther)
      ) {
        const {x1, y1, x2, y2} = prediction.box;
        const w = x2 - x1
        const h = y2 - y1
        ctx.strokeStyle = 'lime';
        ctx.lineWidth = 1.5;
        ctx.strokeRect(x1, y1, w, h);
        ctx.fillStyle = 'red';
        ctx.font = '10px Arial';
        if (w>30 && h>30) {
          ctx.fillText(
            `${prediction.name} (${Math.round(prediction.score * 100)}%)`,
            x1,
            y1 > 10 ? y1 - 5 : 10
          );
        }
      }
    });
  };

  const handleProgress = (state) => {
    if (payload.payload.length && canvasRef.current) {
      const closestData = payload.payload.reduce((prev, curr) => {
        return Math.abs(curr.time - state.playedSeconds) < Math.abs(prev.time - state.playedSeconds) ? curr : prev
      })

      // console.log(closestData, currentTime);
      if (showPrediction.showPrediction) {
        drawPredictions(closestData.objects)
      } else {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }

    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm="4">
          <Card className='card-one'>
            <Card.Header>
              Metadata File
            </Card.Header>
            <Card.Body>
                <Table>
                  <thead>
                    <tr>
                      <th scope="col" style={{width: '40%'}}>Name</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Frame Width</td>
                      <td>{metaData.frameWidth}</td>
                    </tr>
                    <tr>
                      <td>Frame Height</td>
                      <td>{metaData.frameHeight}</td>
                    </tr>
                    <tr>
                        <td>File Name</td>
                        <td>{payload.filename}</td>
                    </tr>
                    <tr>
                        <td>File Type</td>
                        <td>{payload.file_type}</td>
                    </tr>
                    <tr>
                        <td>File Size</td>
                        <td>{payload.size}</td>
                    </tr>
                    <tr>
                        <td>Date</td>
                        <td>{moment(payload.last_modified).format('DD-MM-YYYY HH:MM:SS')}</td>
                    </tr>
                  </tbody>
                </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="8" className='mediaBox'>
          <Card className="card-one">
            <Card.Body>
              <div className="media-wrapper">
                <ReactPlayer
                  ref={videoRef}
                  url={payload.minio_url}
                  onPlay={handleOnPlay}
                  onProgress={handleProgress}
                  controls
                  playing
                />
                {
                  payload.payload.length ? (
                    <div className="control-media">
                      <div className="control">
                        <input 
                          type='checkbox' 
                          checked={showPrediction.showPrediction} 
                          onChange={(e) => 
                            setShowPrediction((prev) => ({...prev, showPrediction: e.target.checked}))}
                        />
                        <span className="text-dark fw-bold">Show Detection</span>
                      </div>
                      <div className="control">
                        <input 
                          type='checkbox' 
                          checked={showPrediction.showPerson} 
                          onChange={(e) => 
                            setShowPrediction((prev) => ({...prev, showPerson: e.target.checked}))}
                        />
                        <span className="text-dark fw-bold">Show Person</span>
                      </div>
                      <div className="control">
                        <input 
                          type='checkbox' 
                          checked={showPrediction.showOther} 
                          onChange={(e) => 
                            setShowPrediction((prev) => ({...prev, showOther: e.target.checked}))}
                        />
                        <span className="text-dark fw-bold">Show Other</span>
                      </div>
                      {/* <div className="control">
                        <input 
                          type='checkbox' 
                          checked={showPrediction.showAll} 
                          onChange={(e) => 
                            setShowPrediction((prev) => ({...prev, showAll: e.target.checked}))}
                        />
                        <span className="text-dark fw-bold">Show All</span>
                      </div> */}
                    </div>
                  ) : (<span>Data Prediksi Tidak Diketahui</span>) 
                }
                <canvas ref={canvasRef}></canvas>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ViewMedia