import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Spinner, Badge } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Input, MyPagination } from "../../../components/Components";
import { useDeleteUser, useGetUserList } from "../../../utils/apis/query";
import { debounce } from "lodash";
import Alert from "../../../libraries/Alert";

function List() {
  const [payloadDebounced, setPayloadDebounced] = useState({
    username: "",
    page: 1,
    size: 10,
  });
  const [search, setSearch] = useState("");
  const {
    data: dataList,
    pagination,
    isFetching: dataFetching,
    isError: dataIsError,
    error: dataError,
  } = useGetUserList(payloadDebounced);

  const mutateDelete = useDeleteUser();

  const handleDebounce = useCallback(
    debounce((newPayload) => {
      setPayloadDebounced(newPayload);
    }, 300),
    []
  );
  const handleDelete = (id) => {
    Alert.showConfirm("warning", "Hapus").then(({ isConfirmed }) => {
      if (isConfirmed) {
        mutateDelete.mutate(id);
      }
    });
  };
  const handlePagination = (page) =>
    setPayloadDebounced({ ...payloadDebounced, page: page });

  useEffect(() => {
    handleDebounce({ ...payloadDebounced, username: search });
  }, [search, handleDebounce, payloadDebounced]);
  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/users">Konfigurasi</Link>
            </li>
            <li className="breadcrumb-item active fw-bold" aria-current="page">
              List Menu
            </li>
          </ol>
        </div>
      </div>
      <Row>
        <Col xl="12">
          <Card className="card bg-white">
            <Card.Body>
              <Row className="g-1 mb-2">
                <Col sm="12" md="5">
                  <Input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Col>
                <Col sm="12" md="7" className="d-flex justify-content-end">
                  <Button
                    as={Link}
                    to="/users/form"
                    className="btn btn-primary"
                  >
                    Tambah
                  </Button>
                </Col>
              </Row>
              <Table className="table dems-table">
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>Nama Lengkap</th>
                    <th>NRP</th>
                    <th>Jabatan</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {dataFetching && (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        <Spinner animation="border" variant="primary" />
                      </td>
                    </tr>
                  )}
                  {dataIsError &&
                    (dataError?.response?.data?.detail?.map((err, idx) => (
                      <tr key={idx}>
                        <td
                          colSpan="4"
                          style={{ textAlign: "center" }}
                          className="text-danger"
                        >
                          <span>
                            <b>{err.loc[1]} </b>
                            {err.msg}
                          </span>
                        </td>
                      </tr>
                    )) ?? (
                      <tr>
                        <td
                          colSpan="4"
                          style={{ textAlign: "center" }}
                          className="text-danger"
                        >
                          <span>
                            <b>Opps Error </b>Reload page or please wait until
                            we fix this issue
                          </span>
                        </td>
                      </tr>
                    ))}
                  {dataList.length && !dataFetching && !dataIsError
                    ? dataList.map((data, index) => (
                        <tr key={index}>
                          <td>{data.user.username}</td>
                          <td>{data.profile.nama_lengkap}</td>
                          <td>{data.profile.nrp}</td>
                          <td>{data.profile.jabatan}</td>
                          <td>{data.role.role}</td>
                          <td>
                            {data.user.is_active === true
                              ? "Aktif"
                              : "Tidak Aktif"}
                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end gap-2">
                              <Link to={"/users/form/" + data.user.id}>
                                <Badge className="btn bg-success" as="button">
                                  <i className="ri-edit-2-line"></i>
                                </Badge>
                              </Link>
                              <Badge
                                onClick={() => handleDelete(data.user.id)}
                                className="btn bg-danger"
                                as="button"
                              >
                                <i className="ri-delete-bin-line"></i>
                              </Badge>
                            </div>
                          </td>
                        </tr>
                      ))
                    : !dataFetching &&
                      !dataList.length &&
                      !dataIsError && (
                        <tr>
                          <td colSpan="4" style={{ textAlign: "center" }}>
                            <span>Tidak Ada Data !</span>
                          </td>
                        </tr>
                      )}
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer className="bg-white">
              <MyPagination
                currentPage={payloadDebounced.page}
                totalPages={pagination.total_pages}
                onPageChange={handlePagination}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default List;
