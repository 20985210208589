import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Alert from "../../../libraries/Alert";
import {
  Input,
  MyPagination,
  Select,
  Select2,
} from "../../../components/Components";
import { useGetMenuList } from "../../../utils/apis/query";

function FormCreate() {
    const [size, setSize] = useState(10)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')

    const [payload, setPayload] = useState([])


    const {
        data: dataList,
        pagination: dataListPagination,
        isFetching: isDataListFetching,
        isError: isDataListError,
        error: dataListError,
        refetch: refetchDataList,
      } = useGetMenuList(
        {
          search: search,
          page: page,
          size: size,
        },
        {
          enabled: true
        }
      );
    return (
        <React.Fragment>
        <div className="d-flex align-items-center justify-content-between mb-4">
            <div>
            <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                <Link to="/role">Role</Link>
                </li>
                <li className="breadcrumb-item active fw-bold" aria-current="page">
                Role Akses
                </li>
            </ol>
            </div>
        </div>
        <Row>
            <Col xl="12">
            <Card className="card bg-white">
                <Card.Body>
                <Row className="g-1 mb-2">
                    <Col sm="12" md="6">
                        <Input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                        />  
                    </Col>
                    <Col sm="12" md="6" className="d-flex justify-content-end align-items-center gap-2">
                        <Button
                            variant="primary"
                            className="btn"
                        >
                            Simpan
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <div className="d-flex justify-content-end align-items-center flex-row gap-1">
                    <label className="text-muted">Items per page</label>
                    <Select
                        name="size"
                        options={[
                        { text: "10", value: 10 },
                        { text: "20", value: 20 },
                        { text: "50", value: 50 },
                        { text: "100", value: 100 },
                        ]}
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                        style={{
                        padding: "2px 4px",
                        fontSize: "0.875rem",
                        width: "60px",
                        }}
                    />
                    <div className="text-muted d-flex justify-content-end">
                        {`${(page - 1) * size + 1}–${Math.min(
                        page * size,
                        dataListPagination.total_items
                        )} of ${dataListPagination.total_items}`}
                    </div>
                    </div>
                </Row>
                <div className="table-responsive">
                    <Table className="table dems-table">
                    <thead>
                        <tr>
                            <th
                                style={{width: '50%'}}
                            >
                                Menu
                            </th>
                            <th
                                style={{width: 10}}
                            >
                                Create
                            </th>
                            <th
                                style={{width: 10}}
                            >
                                Read
                            </th>
                            <th
                                style={{width: 10}}
                            >
                                Update
                            </th>
                            <th
                                style={{width: 10}}
                            >
                                Delete
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isDataListFetching && (
                        <tr>
                            <td colSpan="9" style={{ textAlign: "center" }}>
                            <Spinner animation="border" variant="primary" />
                            </td>
                        </tr>
                        )}
                        {isDataListError &&
                        (dataListError?.response?.data?.detail?.map(
                            (err, idx) => (
                            <tr key={idx}>
                                <td
                                colSpan="9"
                                style={{ textAlign: "center" }}
                                className="text-danger"
                                >
                                <span>
                                    <b>{err.loc[1]} </b>
                                    {err.msg}
                                </span>
                                </td>
                            </tr>
                            )
                        ) ?? (
                            <tr>
                            <td
                                colSpan="9"
                                style={{ textAlign: "center" }}
                                className="text-danger"
                            >
                                <span>
                                <b>Opps Error </b>Reload page or please wait until
                                we fix this issue
                                </span>
                            </td>
                            </tr>
                        ))}
                        {dataList.length && !isDataListFetching && !isDataListError
                        ? dataList.map((data, index) => (
                            <tr key={index}>
                                <td>{data?.nama_menu ?? data?.nama_parent ?? '-'}</td>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        onClick={() => {
                                            //
                                        }}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        onClick={() => {
                                            //
                                        }}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        onClick={() => {
                                            //
                                        }}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        onClick={() => {
                                            //
                                        }}
                                    />
                                </td>
                            </tr>
                            ))
                        : !isDataListFetching &&
                            !dataList.length &&
                            !isDataListError && (
                            <tr>
                                <td colSpan="10" style={{ textAlign: "center" }}>
                                <span>Tidak Ada Data !</span>
                                </td>
                            </tr>
                            )}
                    </tbody>
                    </Table>
                </div>
                </Card.Body>
                <Card.Footer className="bg-white">
                <MyPagination
                    currentPage={page}
                    totalPages={dataListPagination.total_pages}
                    onPageChange={(page) => setPage(page)}
                />
                </Card.Footer>
            </Card>
            </Col>
        </Row>
        </React.Fragment>
    )
}

export default FormCreate