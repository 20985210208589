import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form, Alert } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Input, Select } from "../../../components/Components";
import { kesatuan, pangkat, roles } from "../../../utils";
import { useGetRiwayatLogDetail } from "../../../utils/apis/query";
import moment from "moment";

function Detail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formState, setFormState] = useState({
    username: "",
    id: "",
    user_id: "",
    ip_address: "",
    satker: "",
    method: "",
    access_time: "",
    keterangan: "",
  });

  const {
    data: dataDetail,
    isFetching: isDataDetailFetching,
    isError: isDataDetailError,
    error: dataDetailError,
  } = useGetRiwayatLogDetail(id);

  useEffect(() => {
    if (dataDetail) {
      const detail = dataDetail;
      setFormState({
        username: detail.username,
        id: detail.id,
        user_id: detail.user_id,
        ip_address: detail.ip_address,
        satker: detail.satker,
        method: detail.method,
        access_time: detail.access_time,
        keterangan: detail.keterangan,
      });
    }
  }, [dataDetail]);

  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/pengaturan">Pengaturan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/pengaturan/pengguna/list">List Riwayat Log</Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Detail Riwayat Log
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              <Row className="g-2">
                <Col sm="8">
                  <Input
                    label="ID"
                    name="id"
                    defaultValue={formState.id}
                    disabled
                  />
                </Col>
                <Col sm="4"></Col>
                <Col sm="8">
                  <Input
                    label="User ID"
                    name="user_id"
                    defaultValue={formState.user_id}
                    disabled
                  />
                </Col>
                <Col sm="4"></Col>
                <Col sm="8">
                  <Input
                    label="Username"
                    name="username"
                    defaultValue={formState.username}
                    disabled
                  />
                </Col>
                <Col sm="4"></Col>
                <Col sm="8">
                  <Input
                    label="Satker"
                    name="satker"
                    defaultValue={formState.satker}
                    disabled
                  />
                </Col>
                <Col sm="4"></Col>
                <Col sm="8">
                  <Input
                    label="IP Address"
                    name="ip_address"
                    defaultValue={formState.ip_address}
                    disabled
                  />
                </Col>
                <Col sm="4"></Col>
                <Col sm="8">
                  <Input
                    label="Target Fungsi"
                    name="method"
                    defaultValue={formState.method}
                    disabled
                  />
                </Col>
                <Col sm="4"></Col>
                <Col sm="8">
                  <Input
                    label="Waktu Akses"
                    name="access_time"
                    defaultValue={formState.access_time}
                    disabled
                  />
                </Col>
                <Col sm="4"></Col>
                <Col sm="8">
                  <Input
                    as="textarea"
                    label="keterangan"
                    name="keterangan"
                    defaultValue={formState.keterangan}
                    disabled
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center gap-3">
            <Button variant="warning" as={Link} to="/pengaturan/riwayat/list">
              TUTUP
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default Detail;
