import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useRef } from "react-router-dom";
import State from "../../../libraries/State";
import * as Components from "../../../components/Components";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Row,
  Table,
  Badge,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Grid, _ } from "gridjs-react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Input, MyPagination } from "../../../components/Components";

function UserRole() {
  const navigate = useNavigate();
  const state = State.getState(); // load core state from library

  const TableAction = (type, id) => {
    if (type?.toLowerCase() === "edit") {
      /**
       * Redirect to the edit page
       */
      navigate(`/user_role/form/${id}`);
    } else if (type?.toLowerCase() === "delete") {
      /**
       * action delete here
       */
      withReactContent(Swal)
        .fire({
          title: "Apa anda yakin?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Simpan",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "Berhasil",
              icon: "success",
              text: "Data berhasil dihapus",
            });
          }
        });
    }
  };
  /**
   * Render view
   */
  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/user_role">Role User</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              List Role User
            </li>
          </ol>
        </div>
      </div>

      <Row className="g-3">
        <Col xl="12">
          <Card className="card bg-white">
            <Card.Body className="bg-white">
              <Row className="g-1 mb-2">
                <Col sm="12" md="5">
                  <Input type="text" placeholder="Search" />
                </Col>
                <Col sm="12" md="7">
                  <Link
                    to="/user_role/form"
                    className="d-flex align-items-center justify-content-end"
                  >
                    <Button className="btn btn-primary">Tambah</Button>
                  </Link>
                </Col>
              </Row>
              <Table className="table dems-table">
                <thead>
                  <tr>
                    <th>Role</th>
                    <th>User</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {dataFetching && (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        <Spinner animation="border" variant="primary" />
                      </td>
                    </tr>
                  )}
                  {dataIsError &&
                    (dataError?.response?.data?.detail?.map((err, idx) => (
                      <tr key={idx}>
                        <td
                          colSpan="4"
                          style={{ textAlign: "center" }}
                          className="text-danger"
                        >
                          <span>
                            <b>{err.loc[1]} </b>
                            {err.msg}
                          </span>
                        </td>
                      </tr>
                    )) ?? ( */}
                  {/* <tr>
                    <td
                      colSpan="4"
                      style={{ textAlign: "center" }}
                      className="text-danger"
                    >
                      <span>
                        <b>Opps Error </b>Reload page or please wait until we
                        fix this issue
                      </span>
                    </td>
                  </tr> */}
                  {/* ))}
                  {dataList.length && !dataFetching && !dataIsError
                    ? dataList.map((data, index) => ( */}
                  <tr>
                    <td>Dummy Data</td>
                    <td>Dummy Data</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-end gap-2">
                        <Link to={"/user_role/form/"}>
                          <Badge className="btn bg-success" as="button">
                            <i className="ri-edit-2-line"></i>
                          </Badge>
                        </Link>
                        <Badge className="btn bg-danger" as="button">
                          <i className="ri-delete-bin-line"></i>
                        </Badge>
                      </div>
                    </td>
                  </tr>
                  {/* ))
                    : !dataFetching &&
                      !dataList.length &&
                      !dataIsError && (
                        <tr>
                          <td colSpan="4" style={{ textAlign: "center" }}>
                            <span>Tidak ada unit barang bukti!</span>
                          </td>
                        </tr>
                      )} */}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default UserRole;
