import { useNavigate, useRef } from "react-router-dom";
import State from "../../../libraries/State";
import * as Components from "../../../components/Components";
import { Button, Card, CardTitle, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Grid, _ } from "gridjs-react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function UserProfile() {
  const navigate = useNavigate();
  const state = State.getState(); // load core state from library

  const TableAction = (type, id) => {
    if (type?.toLowerCase() === "edit") {
      /**
       * Redirect to the edit page
       */
      navigate(`/user_profile/form/${id}`);
    } else if (type?.toLowerCase() === "delete") {
      /**
       * action delete here
       */
      withReactContent(Swal)
        .fire({
          title: "Apa anda yakin?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Simpan",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "Berhasil",
              icon: "success",
              text: "Data berhasil dihapus",
            });
          }
        });
    }
  };
  /**
   * Render view
   */
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              List user profile
            </li>
          </ol>
        </div>
      </div>

      <Row className="g-3">
        <Col xl="12">
          <Card className="no-border">
            <Card.Header className="bg-primary">
              <Card.Title
                as="label"
                className="fs-sm fw-medium mb-1 text-white"
              >
                List user profile
              </Card.Title>
            </Card.Header>
            <Card.Body className="bg-white">
              <Link to="/user_profile/form" className="btn btn-sm btn-primary">
                <i className="ri-add-line"></i> Tambah
              </Link>
              <br />
              <br />
              <Grid
                className={{ table: "table table-bordered mb-0" }}
                columns={[
                  "Username",
                  "Nama Lengkap",
                  "NRP",
                  "Jabatan",
                  "No Handphone",
                  "Email",
                  "Aksi",
                ]}
                server={{
                  url: "https://pokeapi.co/api/v2/pokemon",
                  then: (data) =>
                    data.results.map((r, i) => [
                      r.name,
                      r.name,
                      "1",
                      "Staff",
                      "-",
                      r.name + "@gmail.com",
                      _(
                        <div>
                          <button
                            onClick={() => TableAction("edit", i)}
                            className="btn btn-sm btn-info text-white"
                          >
                            <i className="ri-pencil-line"></i>
                          </button>
                          &nbsp;
                          <button
                            onClick={() => TableAction("delete", i)}
                            className="btn btn-sm btn-danger text-white"
                          >
                            <i className="ri-delete-bin-line"></i>
                          </button>
                        </div>
                      ),
                    ]),
                  total: (data) => data.count,
                }}
                pagination={{
                  limit: 10,
                  server: {
                    url: (prev, page, limit) => {
                      const operator = prev.includes("?") ? "&" : "?";
                      return `${prev}${operator}limit=${limit}&offset=${
                        page * limit
                      }`;
                    },
                  },
                }}
                search={{
                  server: {
                    url: (prev, keyword) => `${prev}?search=${keyword}`,
                  },
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default UserProfile;
