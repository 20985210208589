import React, { useCallback, useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Row, Placeholder } from 'react-bootstrap'
import { DatePicker, Input, Select } from '../../../components/Components'
import { Link } from 'react-router-dom'
import { useSatkerList, useSharedFileAll } from '../../../utils/apis/query'
import { debounce } from 'lodash'
import moment from 'moment'

function Index() {
  const [klasifikasi, setKlasifikasi] = useState([
    { value: 'Arsip', text: 'Arsip'},
    { value: 'Barang Bukti', text: 'Barang Bukti'},
  ])
  const [tipeFile, setTipeFile] = useState([
    {val: "image/jpeg", text:"image/jpeg"},
    {val: "video/mp4", text:"video/mp4"},
    {val: "document/pdf", text:"document/pdf"},
  ])

  const [payload, setPayload] = useState({
    keyword: '',
    file_type: '',
    nama_satker: '',
    klasifikasi: '',
    start_date: '',
    end_date: ''
})
  const [payloadDebounced, setPayloadDebounced] = useState({
    keyword: '',
    file_type: '',
    nama_satker: '',
    klasifikasi: '',
    start_date: '',
    end_date: ''
  })
  const {
    data: dataShared,
    isFetching: dataFetching,
    isError: dataError
  } = useSharedFileAll(payloadDebounced)

  let {
    data: dataSatker
  } = useSatkerList()

  dataSatker = dataSatker?.data?.value?.map((listSatker) => ({text: listSatker.nama_satker, value: listSatker.nama_satker})) ?? []

  const handleDebounce = useCallback(
    debounce((newPayload) => {
        setPayloadDebounced(newPayload)
    }, 300), []
  )

  const handleChange = (e) => {
    const {name, value} = e.target
    setPayload({...payload, [name]: value})
  }
  const handleDatePicker = (dates) => {
    const [start, end] = dates;
    setPayload({...payload, start_date: start, end_date: end})
  }
  const handleResetFiler = () => {
    setPayload({
        keyword: '',
        file_type: '',
        nama_satker: '',
        klasifikasi: '',
        start_date: '',
        end_date: ''
    })
  }

  useEffect(() => {
    const newPayload = {...payload}
    if (payload.start_date) {
        newPayload.start_date = moment(newPayload.start_date).format('YYYY-MM-DD')
    }
    if (payload.end_date) {
        newPayload.end_date = moment(newPayload.end_date).format('YYYY-MM-DD')
    }
    handleDebounce(newPayload)
  }, [payload, handleDebounce])

  return (
    <React.Fragment>
        <Card className='card bg-white'>
            <Card.Body>
                <Row className='g-2'>
                    <Col sm="12">
                        <Card.Title as="label" className='fs-5 fw-medium'>File Dibagikan</Card.Title>
                        <Input 
                            placeholder="Search Arsip" 
                            name="keyword"
                            value={payload.keyword}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col sm="12">
                        <div className="d-flex align-items-center gap-3">
                            <Badge bg='light'>
                                <p className='font-monospace text-dark mb-0' style={{fontSize: '12px'}}>Found: {dataShared.length} file</p>
                            </Badge>
                            <Badge bg='light'>
                                <Select 
                                    default="Tipe File"
                                    name="file_type"
                                    value={payload.file_type}
                                    onChange={handleChange}
                                    options={tipeFile}
                                />
                            </Badge>
                            <Badge bg='light'>
                                <Select 
                                    default="Pilih Satker"
                                    name="nama_satker"
                                    value={payload.nama_satker}
                                    onChange={handleChange}
                                    options={dataSatker}
                                />
                            </Badge>
                            <Badge bg='light'>
                                <Select 
                                    default="Pilih Klasifikasi"
                                    name="klasifikasi"
                                    value={payload.klasifikasi}
                                    onChange={handleChange}
                                    options={klasifikasi}
                                />
                            </Badge>
                            <Badge bg='light'>
                                <DatePicker 
                                    placeholderText="Atur Waktu Range" 
                                    selected={payload.start_date}
                                    startDate={payload.start_date}
                                    endDate={payload.end_date}
                                    onChange={handleDatePicker}
                                    name="rangeSearch"
                                    selectsRange
                                />
                            </Badge>
                            <Button className='btn btn-danger' onClick={handleResetFiler}>Reset Filter</Button>  
                            {/* <Button onClick={handleSubmitFilter} className='btn btn-primary'>Apply</Button>   */}
                        </div>
                    </Col>
                    {
                        dataFetching && (
                            <Col sm="12">
                                <Card className='card-one'>
                                    <Card.Body>
                                        <Placeholder as="p" animation="wave" className="m-0 d-flex flex-column gap-2">
                                            <Placeholder sm="2" />
                                            <Placeholder sm="3" />
                                            <Placeholder sm="12" />
                                            <Placeholder sm="12" />
                                            <Placeholder sm="12" />
                                        </Placeholder>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    }
                    {
                        dataError && (
                            <Col sm="12">
                                <Card className='card-one'>
                                    <Card.Body>
                                        <span>Error</span>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    }
                    {
                        dataShared.length && !dataFetching ? (
                            dataShared.map((datas, idx) => 
                            (
                                <Col sm="12" key={idx}>
                                    <Card className='card-one bg-white'>
                                        <Card.Body>
                                            <Row>
                                                <Col sm="11">
                                                    <Link to={datas.link} className="advance-search-item">
                                                            <div className="item-header">
                                                                <div className="icon">
                                                                    <i className={`${datas.iconClass}`} />
                                                                </div>
                                                                <div className="header-text">
                                                                    <span className="title fw-medium">{datas.file_name}</span>
                                                                    {/* <span className='text-secondary'>https://dems.polri.go.id{datas.link}</span> */}
                                                                    <div className="badges-x">
                                                                        <Badge bg='secondary' className='d-flex align-items-center gap-1'>
                                                                            <i class="ri-building-4-fill"></i>
                                                                            <span>{datas.satker_name}</span>
                                                                        </Badge>
                                                                        <Badge bg='secondary' className='d-flex align-items-center gap-1'>
                                                                            <i class="ri-file-line"></i>
                                                                            <span>{datas.klasifikasi}</span>
                                                                        </Badge>
                                                                        <Badge bg='secondary' className='d-flex align-items-center gap-1'>
                                                                            <i class="ri-file-user-line"></i>
                                                                            <span>{datas.sender}</span>
                                                                        </Badge>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="item-body">
                                                                {/* <div className="title fw-medium text-dark opacity-75">{ datas.file_name }</div> */}
                                                                {/* <div className="description text-secondary">
                                                                    Bagian awal Laporan Kuliah Kerja Lapangan mulai dari halaman pengesahan sampai dengan halaman daftar isi diberi nomor halaman dengan angka romawi kecil.
                                                                </div>
                                                                <span className='text-secondary'>8 Halaman</span> */}
                                                            </div>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card> 
                                </Col>
                            ))
                        ) : !dataFetching && 
                        (
                            <span>Tidak ada data</span>
                        )
                    }
                </Row>
            </Card.Body>
        </Card>
    </React.Fragment>
  )
}

export default Index