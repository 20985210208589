import React, { useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

function Index(props) {
  const [propsImg, setPropsImg] = useState({});

  return (
    <div
      className="d-flex flex-column img-dems"
      style={{
        height: props?.height ?? "auto",
        minHeight: props?.height ?? "auto",
        maxHeight: props?.height ?? "auto",
        width: props?.width ?? "auto",
        minWidth: props?.width ?? "auto",
        maxWidth: props?.width ?? "auto",
        cursor: "pointer",
      }}
    >
      <Zoom>
        <img
          {...props}
          alt={props?.alt ?? ""}
          style={{
            objectFit: "cover",
            cursor: "pointer",
            width: "100%",
            height: "100%",
            borderRadius: "2%",
          }}
          onError={(e) => {
            e.target.src = "/noimage.jpg";
            e.target.style = { objectFit: "none" };
          }}
        />
      </Zoom>
    </div>
  );
}

export default Index;
