import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row, Button, Alert as AlertBS } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { IconPicker, Input, Select } from "../../../../components/Components";
import State from "../../../../libraries/State";
import {
  useGetMasterRoleDetail,
  usePutMasterRole,
  usePostMasterRole,
} from "../../../../utils/apis/query";

function RoleForm() {
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isFormEditAble, setIsFormEditAble] = useState(true);
  const [role, setRole] = useState({
    role: "",
    deskripsi: "",
  });
  const onSuccess = () => {
    setTimeout(() => {
      navigate("/role");
    }, 1500);
  };
  const mutateAdd = usePostMasterRole(onSuccess);
  const mutateEdit = usePutMasterRole(onSuccess);
  const {
    data: detailData,
    isFetching: detailIsFetching,
    isError: detailIsError,
    error: detailError,
  } = useGetMasterRoleDetail(id, { enabled: !!id });

  const handleChange = (e) => {
    let { name, value } = e.target;
    setRole({ ...role, [name]: value });
  };
  const handleSubmit = () => {
    if (isEdit) {
      mutateEdit.mutate({ id, role });
    } else {
      mutateAdd.mutate(role);
    }
  };
  useEffect(() => {
    setIsEdit(false);
    if (id) {
      setIsEdit(true);
      setIsFormEditAble(false);
    }
  }, [id]);

  useEffect(() => {
    if (detailData) {
      setRole({
        ...role,
        role: detailData.role,
        deskripsi: detailData.deskripsi,
      });
    }
  }, [detailData]);

  useEffect(() => {
    return () => {
      setRole({
        role: "",
        deskripsi: "",
      });
    };
  }, []);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between mb-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/role">Pengaturan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/role">List Role</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Form {isEdit ? "Edit" : "Tambah"} Role
                </li>
              </ol>
            </div>
            {isEdit && (
                <Button
                  className="d-flex align-items-center gap-2"
                  variant="primary"
                  size="normal"
                  onClick={() => setIsFormEditAble(!isFormEditAble)}
                >
                  <i className="ri-edit-2-line"></i>
                  {isFormEditAble ? "Batalkan Edit" : "Edit Form"}
                </Button>
            )}
          </div>
        </Card.Body>
      </Card>

      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            {
              <Col sm="12">
                {mutateAdd.isError && (
                  <AlertBS variant="danger">
                    <ul>
                      {mutateAdd?.error?.response?.data?.detail?.map(
                        (errEdit) => (
                          <li>
                            <b>{errEdit.loc[1]} </b>
                            {errEdit.msg}
                          </li>
                        )
                      ) ?? (
                        <li>
                          <b>Error</b>Sesuatu Bermasalah
                        </li>
                      )}
                    </ul>
                  </AlertBS>
                )}
                {mutateAdd.isSuccess && (
                  <AlertBS variant="success">
                    {mutateAdd.data.data.message}
                  </AlertBS>
                )}
              </Col>
            }
            {
              <Col sm="12">
                {mutateEdit.isError && (
                  <AlertBS variant="danger">
                    <ul>
                      {mutateEdit?.error?.response?.data?.detail?.map(
                        (errEdit) => (
                          <li>
                            <b>{errEdit.loc[1]} </b>
                            {errEdit.msg}
                          </li>
                        )
                      ) ?? (
                        <li>
                          <b>Error</b>Sesuatu Bermasalah
                        </li>
                      )}
                    </ul>
                  </AlertBS>
                )}
                {mutateEdit.isSuccess && (
                  <AlertBS variant="success">
                    {mutateEdit.data.data.message}
                  </AlertBS>
                )}
              </Col>
            }
            <Col sm="4">
              <Input
                name="role"
                label="Role"
                value={role.role}
                onChange={handleChange}
                required
                disabled={!isFormEditAble}
              />
            </Col>
            <Col sm="4">
              <Input
                name="deskripsi"
                label="Deskripsi"
                value={role.deskripsi}
                onChange={handleChange}
                required
                disabled={!isFormEditAble}
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center gap-3">
            <Button as={Link} to="/role" variant="warning">
              Batal
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Simpan
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default RoleForm;
