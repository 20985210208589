import React from 'react'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import PdfViewer from './pdf_viewer';

function FileView({uri}) {
    if (!uri) return null
    let type = uri.split('.')
    type = type[type.length - 1]
    if (type === 'pdf') {
        return <PdfViewer uri={uri} />
    } else if (!['doc', 'docx', 'xls', 'xlsx', 'ppt'].includes(type)) return null

    const docs = [
        { 
            uri: uri,
            fileType: type
        }
    ];

    if (!uri) return null
    return (
        <div className="iframe-file">
            <DocViewer
                documents={docs}
                pluginRenderers={DocViewerRenderers}
                config={{
                    header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: false,
                    },
                }}
            ></DocViewer>
        </div>
    )
}

export default FileView