import React, { useEffect, useRef, useState } from 'react'
import { Badge, Card, Col, Row, Table } from 'react-bootstrap'
import { FileView } from '../../../../components/file_manager'
import moment from 'moment'
import { formatStringConvert } from '../../../../utils'

function ViewDoc({payload}) {
  const [showExtraks, setShowExtract] = useState(false)

    const handlePrediction = (event) => {
        setShowExtract(event.target.checked)
    }

  useEffect(() => {
  }, [])
  return (
    <React.Fragment>
        <Row>
            <Col sm="8">
                <Card className='card-one'>
                    <Card.Body>
                        <FileView uri={payload.minio_url}/>
                    </Card.Body>
                    <Card.Footer>
                        {
                            (payload?.payload ?? []).length ? 
                            (
                                <div className="control-img">
                                    <div className="control">
                                        <input type='checkbox' checked={showExtraks} onChange={handlePrediction}/>
                                        <span className="text-dark fw-bold"> Tampilkan Ektraksi File</span>
                                    </div>
                                </div>
                            ) :
                            (
                                (<span>Tidak ada data ektraksi</span>)
                            )
                        }
                    </Card.Footer>
                </Card>
            </Col>
            <Col sm="4">
                <Card className='card-one'>
                  <Card.Header>
                    Metadata File
                  </Card.Header>
                  <Card.Body>
                    <Table>
                        <thead>
                        <tr>
                            <th scope="col" style={{width: '40%'}}>Name</th>
                            <th scope="col">Value</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>File Name</td>
                                <td>{payload.filename}</td>
                            </tr>
                            <tr>
                                <td>File Type</td>
                                <td>{payload.file_type}</td>
                            </tr>
                            <tr>
                                <td>File Size</td>
                                <td>{payload.size}</td>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <td>{moment(payload.last_modified).format('DD-MM-YYYY HH:MM:SS')}</td>
                            </tr>
                        </tbody>
                    </Table>
                  </Card.Body>
                </Card>
            </Col>
            {
                showExtraks &&
                (
                    <Col 
                        sm="12"
                        style={{
                            height: 415,
                            overflow: 'auto'
                        }}
                    >
                        <Row className='g-2 mt-2'>
                            {
                                (payload?.payload ?? []).map((p, idx) => (
                                    <Col sm="12" key={idx}>
                                        <Card className='card bg-white'>
                                            <Card.Body>
                                                <Badge className='fs-6'>Page - {p.page_number}</Badge>
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: formatStringConvert(p?.text ?? '')}}
                                                >
                                                    
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                )
            }
        </Row>
    </React.Fragment>
  )
}

export default ViewDoc