import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Input,
  MyPagination,
  Select,
  Select2,
} from "../../../../components/Components";
import {
  useGetPenerimaanBarbukList,
  useDeletePenerimaanBarbuk,
  useGetPoldaPolres,
} from "../../../../utils/apis/query";
import { debounce } from "lodash";
import Alert from "../../../../libraries/Alert";
import moment from "moment";
import State from "../../../../libraries/State";

function ListPenerimaanBarangBukti() {
  const state = State.getState();
  const profileData = state.getItem("profileData", true);
  const satker = profileData?.satker?.nama_satker ?? "";
  const isBareskrim = /bareskrim/gi.test(satker);
  const isPolres = /polres/gi.test(satker);
  const [namaSatkerPoldaFilter, setNamaSatkerPoldaFilter] = useState("");
  const [namaSatkerPolresFilter, setNamaSatkerPolresFilter] = useState("");
  const [namaSatkerPolresPayloadFilter, setNamaSatkerPolresPayloadFilter] =
    useState("");
  const [namaSatkerPoldaPayloadFilter, setNamaSatkerPoldaPayloadFilter] =
    useState("");
  const [fetchedOnce, setFetchedOnce] = useState(true);
  const [search, setSearch] = useState("");
  const [payloadDebounced, setPayloadDebounced] = useState({
    search: "",
    nama_satker_polda: namaSatkerPoldaPayloadFilter,
    nama_satker_polres: namaSatkerPolresPayloadFilter,
    page: 1,
    size: 10,
  });

  const { data: dataSatker } = useGetPoldaPolres(
    {
      polda: namaSatkerPoldaFilter,
      polres:
        namaSatkerPolresFilter === "satker_sendiri"
          ? ""
          : namaSatkerPolresFilter,
    },
    {
      enabled: !!namaSatkerPoldaPayloadFilter || !!namaSatkerPolresFilter,
    }
  );

  const optionPolda = [
    { label: "SEMUA POLDA", value: "semua_polda" },
    ...dataSatker.polda.map((o) => ({ label: o.value, value: o.value })),
  ];
  const optionPolres = [
    { label: "SEMUA", value: "semua" },
    { label: "SATKER SENDIRI", value: "satker_sendiri" },
    ...dataSatker.polres.map((o) => ({ label: o.value, value: o.value })),
  ];

  const {
    data: dataList,
    pagination,
    isFetching: dataFetching,
    isError: dataIsError,
    error: dataError,
    refetch: refetchDataList,
  } = useGetPenerimaanBarbukList(payloadDebounced);

  const mutateDelete = useDeletePenerimaanBarbuk();

  const handleDebounce = useCallback(
    debounce(({ field, val }) => {
      setPayloadDebounced((prev) => ({ ...prev, [field]: val, page: 1 }));
    }, 500),
    []
  );
  const handlePagination = (page) =>
    setPayloadDebounced({ ...payloadDebounced, page: page });
  const handleDelete = (id) => {
    Alert.showConfirm("warning", "Hapus").then(({ isConfirmed }) => {
      if (isConfirmed) {
        mutateDelete.mutate(id);
      }
    });
  };

  const handleFilters = () => {
    setPayloadDebounced((prev) => ({
      ...prev,
      nama_satker_polda: namaSatkerPoldaPayloadFilter,
      nama_satker_polres: namaSatkerPolresPayloadFilter,
      page: 1,
    }));
  };

  useEffect(() => {
    if (profileData) {
      moment.locale();
      const satker = profileData?.satker?.nama_satker ?? "";
      const username = profileData?.user?.username ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      if (isBareskrim) {
        if (isBareskrim) {
          if (["kabareskrim", "kapusiknas"].includes(username)) {
            setNamaSatkerPoldaFilter("semua_polda");
            setNamaSatkerPoldaPayloadFilter("semua_polda");
            setPayloadDebounced((prev) => ({
              ...prev,
              nama_satker_polda: "semua_polda",
              // nama_satker_polres: "semua_polres",
            }));
          } else {
            setNamaSatkerPoldaFilter(satker);
            setNamaSatkerPoldaPayloadFilter(satker);
            setPayloadDebounced((prev) => ({
              ...prev,
              nama_satker_polda: satker,
              // nama_satker_polres: "semua_polres",
            }));
          }
        }
        setNamaSatkerPolresFilter("satker_sendiri");
      } else {
        if (isPolres) {
          let namaPolda = profileData?.profile?.nama_satker_parent_polres ?? "";
          setNamaSatkerPoldaFilter(namaPolda);
          setNamaSatkerPoldaPayloadFilter(namaPolda);
          setPayloadDebounced((prev) => ({
            ...prev,
            nama_satker_polda: namaPolda,
          }));
        } else {
          setNamaSatkerPoldaFilter(satker);
          setNamaSatkerPoldaPayloadFilter(satker);
          setNamaSatkerPolresFilter("satker_sendiri");
          setPayloadDebounced((prev) => ({
            ...prev,
            nama_satker_polda: satker,
            nama_satker_polres: "satker_sendiri",
          }));
        }
        // setNamaSatkerPoldaFilter(!isPolres ? satker : "");
        // setNamaSatkerPolresFilter(isPolres ? satker : "satker_sendiri");
        // setPayloadDebounced((prev) => ({
        //   ...prev,
        //   nama_satker_polda: !isPolres ? satker : "",
        //   nama_satker_polres: isPolres ? satker : "satker_sendiri",
        // }));
      }
    }
  }, [profileData]);
  useEffect(() => {
    if (profileData) {
      const satker = profileData?.satker?.nama_satker ?? "";
      const isPolres = /polres/gi.test(satker);
      const isBareskrim = /bareskrim/gi.test(satker);
      const isfilterPoldaAll = namaSatkerPoldaFilter === "semua_polda";

      if (isBareskrim || isfilterPoldaAll) {
        const filterBareskrim = /bareskrim/gi.test(namaSatkerPoldaFilter);
        if (!namaSatkerPolresFilter)
          setNamaSatkerPolresPayloadFilter("satker_sendiri");
        if (
          isfilterPoldaAll &&
          namaSatkerPolresPayloadFilter === "satker_sendiri"
        ) {
          setNamaSatkerPoldaPayloadFilter(
            dataSatker.polda.map((o) => o.value).join(",")
          );
        }
        if (isfilterPoldaAll && namaSatkerPolresPayloadFilter === "semua") {
          setNamaSatkerPoldaPayloadFilter("semua_polda");
        }
        if (namaSatkerPolresFilter === "semua") {
          let payload = filterBareskrim
            ? ""
            : dataSatker.polres.map((o) => o.value).join(",");
          setNamaSatkerPolresPayloadFilter(payload);
        }
      } else {
        if (isPolres) {
          let namaPolres = profileData?.profile?.nama_satker_tahti ?? "";
          setNamaSatkerPolresFilter(namaPolres);
          setNamaSatkerPolresPayloadFilter(namaPolres);
        } else {
          if (!namaSatkerPolresFilter)
            setNamaSatkerPolresPayloadFilter("satker_sendiri");
          if (namaSatkerPolresFilter === "semua") {
            setNamaSatkerPolresPayloadFilter(
              dataSatker.polres.map((o) => o.value).join(",")
            );
          }
        }
      }
    }
  }, [profileData, dataSatker, namaSatkerPolresFilter, namaSatkerPoldaFilter]);

  useEffect(() => {
    if (dataFetching) {
      if (fetchedOnce) setFetchedOnce(false);
    }
  }, [fetchedOnce]);

  // useEffect(() => {
  //   if (namaSatkerPolresPayloadFilter && fetchedOnce) {
  //     refetchDataList();
  //   }
  // }, [namaSatkerPolresPayloadFilter, refetchDataList]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (namaSatkerPoldaFilter !== null || !!namaSatkerPoldaFilter) {
  //       refetchDataList();
  //       setFetchedOnce(false);
  //     }
  //   }, 2000);
  // }, []);

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/barbuk/penerimaan/list">Barang Bukti</Link>
            </li>
            <li className="breadcrumb-item active fw-bold" aria-current="page">
              List Penerimaan Barang Bukti
            </li>
          </ol>
        </div>
      </div>
      <Row>
        <Col xl="12">
          <Card className="card bg-white">
            <Card.Body>
              <Row className="g-1 mb-2">
                <Col sm="12" md="6">
                  <Input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      handleDebounce({ field: "search", val: e.target.value });
                    }}
                  />
                </Col>
                <Col
                  sm="12"
                  md="6"
                  className="d-flex justify-content-end align-items-center gap-2"
                >
                  <Button
                    variant="danger"
                    onClick={handleFilters}
                    className="btn"
                  >
                    Filter
                  </Button>
                  <Button
                    as={Link}
                    to="/barbuk/penerimaan/form"
                    className="btn btn-primary"
                  >
                    TERIMA BARANG BUKTI BARU
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12">
                  <Row className="g-2 d-flex justify-content-between align-items-center flex-wrap">
                    <Col>
                      <Select2
                        defaultValue={"Semua Kesatuan"}
                        options={optionPolda}
                        value={namaSatkerPoldaFilter}
                        name="nama_satker_polda"
                        placeholder="Semua Kesatuan"
                        required
                        isClearable
                        isSearchable
                        disabled={!isBareskrim || isPolres}
                        onChange={(e) => {
                          setNamaSatkerPoldaFilter(e.value);
                          setNamaSatkerPoldaPayloadFilter(e.value);
                          setNamaSatkerPolresFilter((prev) => "satker_sendiri");
                          setNamaSatkerPolresPayloadFilter(
                            (prev) => "satker_sendiri"
                          );
                        }}
                      />
                    </Col>
                    <Col>
                      <Select2
                        defaultValue={"Semua Kesatuan"}
                        options={optionPolres}
                        value={namaSatkerPolresFilter}
                        name="nama_satker_polres"
                        placeholder="SEMUA"
                        required
                        isClearable
                        isSearchable
                        disabled={isPolres}
                        onChange={(e) => {
                          setNamaSatkerPolresFilter(e.value);
                          setNamaSatkerPolresPayloadFilter(e.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-5">
                <div className="d-flex justify-content-end align-items-center flex-row gap-1">
                  <label className="text-muted">Items per page</label>
                  <Select
                    name="size"
                    options={[
                      { text: "10", value: 10 },
                      { text: "20", value: 20 },
                      { text: "50", value: 50 },
                      { text: "100", value: 100 },
                    ]}
                    value={payloadDebounced.size}
                    onChange={(e) => {
                      setPayloadDebounced({
                        ...payloadDebounced,
                        size: e.target.value,
                        page: 1,
                      });
                    }}
                    style={{
                      padding: "2px 4px",
                      fontSize: "0.875rem",
                      width: "60px",
                    }}
                  />
                  <div className="text-muted d-flex justify-content-end">
                    {`${
                      (payloadDebounced.page - 1) * payloadDebounced.size + 1
                    }–${Math.min(
                      payloadDebounced.page * payloadDebounced.size,
                      pagination.total_items
                    )} of ${pagination.total_items}`}
                  </div>
                </div>
              </Row>
              <div className="table-responsive">
                <Table className="table dems-table">
                  <thead>
                    <tr>
                      <th>Aksi</th>
                      <th>No</th>
                      <th>Tanggal Terima</th>
                      <th>No. LP</th>
                      <th>Jenis Barang Bukti</th>
                      <th>Sub Jenis Barang Bukti</th>
                      <th>Tipe Barang Bukti</th>
                      <th>Satker</th>
                      <th>Status Dokumen</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataList.length && !dataFetching && !dataIsError
                      ? dataList.map((data, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center justify-content-start gap-2">
                                <Link to={"/barbuk/penerimaan/form/" + data.id}>
                                  <Badge className="btn bg-info" as="button">
                                    <i className="ri-eye-line"></i>
                                  </Badge>
                                </Link>
                                <Badge
                                  onClick={() => handleDelete(data.id)}
                                  className="btn bg-danger"
                                  as="button"
                                >
                                  <i className="ri-delete-bin-line"></i>
                                </Badge>
                              </div>
                            </td>
                            <td>
                              {(payloadDebounced.page - 1) *
                                payloadDebounced.size +
                                index +
                                1}
                            </td>
                            <td>
                              {(moment(data.tanggal_penerimaan).format(
                                "DD/MM/YYYY"
                              ) ??
                                "-") ||
                                "-"}
                            </td>
                            <td>{(data?.no_laporan_polisi ?? "-") || "-"}</td>
                            <td>{(data?.jenis_barbuk ?? "-") || "-"}</td>
                            <td>{(data?.sub_jenis_barbuk ?? "-") || "-"}</td>
                            <td>{(data?.tipe_barbuk ?? "-") || "-"}</td>
                            <td>{(data?.lokasi_penerimaan ?? "-") || "-"}</td>
                            <td>
                              <Badge
                                className={`
                                                ${
                                                  data.status_dokumen ===
                                                    "DRAFT" && "bg-secondary"
                                                }
                                                ${
                                                  data.status_dokumen ===
                                                    "FINAL" && "bg-success"
                                                }
                                                `}
                              >
                                {data.status_dokumen || "-"}
                              </Badge>
                            </td>
                          </tr>
                        ))
                      : !dataFetching &&
                        !dataList.length &&
                        !dataIsError && (
                          <tr>
                            <td colSpan="4" style={{ textAlign: "center" }}>
                              <span>Tidak ada penerimaan barang bukti !</span>
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Card.Footer className="bg-white">
              <MyPagination
                currentPage={payloadDebounced.page}
                totalPages={pagination.total_pages}
                onPageChange={handlePagination}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ListPenerimaanBarangBukti;
