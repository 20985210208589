import React, { useCallback, useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconPicker, Input, Select } from "../../../../components/Components";
import {
  usePostType,
  usePutType,
  useGetTypeDetail,
} from "../../../../utils/apis/query";

function Form() {
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [tipe, setTipe] = useState({
    tipe: "",
    keterangan: "",
  });
  const onSuccess = () => {
    setTimeout(() => {
      navigate("/evidence_types");
    }, 1500);
  };
  const mutateAdd = usePostType(onSuccess);
  const mutateEdit = usePutType(onSuccess);
  const {
    data: detailData,
    isFetching: detailIsFetching,
    isError: detailIsError,
    error: detailError,
  } = useGetTypeDetail(id, { enabled: !!id });

  const handleChange = (e) => {
    let { name, value } = e.target;
    setTipe({ ...tipe, [name]: value });
  };
  const handleSubmit = () => {
    if (isEdit) {
      mutateEdit.mutate({ id, tipe });
    } else {
      mutateAdd.mutate(tipe);
    }
  };

  useEffect(() => {
    setIsEdit(false);
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  useEffect(() => {
    if (detailData) {
      setTipe({
        ...tipe,
        tipe: detailData.tipe,
        keterangan: detailData.keterangan,
      });
    }
  }, [detailData]);

  useEffect(() => {
    return () => {
      setTipe({
        tipe: "",
        keterangan: "",
      });
    };
  }, []);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/evidence_types">Tipe</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/evidence_types">List Tipe</Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form {isEdit ? "Edit" : "Tambah"} Tipe Barang Bukti
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {mutateAdd.isError && (
                <Alert variant="danger">
                  <ul>
                    {mutateAdd?.error?.response?.data?.detail?.map(
                      (errEdit) => (
                        <li>
                          <b>{errEdit.loc[0]} </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error</b>Sesuatu Bermasalah
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateAdd.isSuccess && (
                <Alert variant="success">{mutateAdd.data.data.message}</Alert>
              )}
            </Col>
            <Col sm="12">
              {mutateEdit.isError && (
                <Alert variant="danger">
                  <ul>
                    {mutateEdit?.error?.response?.data?.detail?.map(
                      (errEdit) => (
                        <li>
                          <b>{errEdit.loc[0]} </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error</b>Sesuatu Bermasalah
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateEdit.isSuccess && (
                <Alert variant="success">{mutateEdit.data.data.message}</Alert>
              )}
            </Col>
            <Col sm="6">
              <Input
                placeholder="Tipe"
                label="Tipe"
                name="tipe"
                value={tipe.tipe}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="6">
              <Input
                placeholder="Keterangan"
                value={tipe.keterangan}
                name="keterangan"
                onChange={handleChange}
                label="Keterangan"
                required
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center">
            {!mutateAdd.isLoading || !mutateEdit.isLoading ? (
              <Button variant="primary" onClick={handleSubmit}>
                Simpan
              </Button>
            ) : (
              <Button variant="primary" disabled>
                Loading
              </Button>
            )}
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default Form;
