import React, { useState } from 'react'
import { Row, Col, Card, Table, Dropdown, Form, Button, Pagination } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Input, MyPagination, Select } from '../../../../components/Components';

function List() {
  const [currentPage, setCurrentPage] = useState(1)
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
        to=""
        ref={ref}
        onClick={(e) => {
        e.preventDefault();
        onClick(e);
        }}
        className="dropdown-link"
    >
        {children}
    </Link>
  ));
  const mockSelect = [
    {
        value: 'Test Select',
        text: 'Test Select',
    },
    {
        value: 'Test Select',
        text: 'Test Select',
    },
    {
        value: 'Test Select',
        text: 'Test Select',
    }
  ]
  const mockDatas = [
    {
        name: "Mr.Tobangado",
        satker: "Polres Jaktim",
        jenis: "Penganiayaan",
        date: "Fri, 28 Mei 2024 15:20:11",
        no_tahanan: 12345678,
        no_id: 73847586,
        no_laporan_polisi: 'LP/A/026/II/2024/Polres Jaksel',
    },
    {
        name: "Mr.Tobangado",
        satker: "Polres Jakbar",
        jenis: "pencurian",
        date: "Fri, 28 Mei 2024 15:20:11",
        no_tahanan: 12345678,
        no_id: 73847586,
        no_laporan_polisi: 'LP/A/026/II/2024/Polres Jaksel',
    },
    {
        name: "Mr.Tobangado",
        satker: "Polres Jabar",
        jenis: "Penganiayaan",
        date: "Fri, 28 Mei 2024 15:20:11",
        no_tahanan: 12345678,
        no_id: 73847586,
        no_laporan_polisi: 'LP/A/026/II/2024/Polres Jaksel',
    },
    {
        name: "Mr.Tobangado",
        satker: "Polres Jatim",
        jenis: "Pelecehan",
        date: "Fri, 28 Mei 2024 15:20:11",
        no_tahanan: 12345678,
        no_id: 73847586,
        no_laporan_polisi: 'LP/A/026/II/2024/Polres Jaksel',
    },
    {
        name: "Mr.Tobangado",
        satker: "Polres Jateng",
        jenis: "Penipuan",
        date: "Fri, 28 Mei 2024 15:20:11",
        no_tahanan: 12345678,
        no_id: 73847586,
        no_laporan_polisi: 'LP/A/026/II/2024/Polres Jaksel',
    },
    {
        name: "Mr.Tobangado",
        satker: "Polres Sumatra Utara",
        jenis: "Penganiayaan",
        date: "Fri, 28 Mei 2024 15:20:11",
        no_tahanan: 12345678,
        no_id: 73847586,
        no_laporan_polisi: 'LP/A/026/II/2024/Polres Jaksel',
    },
    {
        name: "Mr.Tobangado",
        satker: "Polres Sulawesi Tenggara",
        jenis: "Korupsi",
        date: "Fri, 28 Mei 2024 15:20:11",
        no_tahanan: 12345678,
        no_id: 73847586,
        no_laporan_polisi: 'LP/A/026/II/2024/Polres Jaksel',
    },
  ]

  const handlePagination = (page) => setCurrentPage(page)

  return (
    <React.Fragment>
        <div className="d-flex align-items-center justify-content-between mb-4">
            <div>
                <ol className="breadcrumb fs-sm mb-1">
                    <li className="breadcrumb-item">
                        <Link Link to="/menus">Tahanan</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Daftar Brang Bukti Tahanan (ALL)
                    </li>
                </ol>
            </div>
        </div>
        <Row>
            <Col xl="12">
                <Card className="card p-2">
                    <Row className='g-1'>
                        <Col sm="12" md="6">
                            <Input
                                type="text"
                                placeholder="Search"
                            />
                        </Col>
                        <Col sm="12" md="2">
                            <Select 
                                default="Satker"
                                options={mockSelect}
                                // name="satker"
                            />
                        </Col>
                        <Col sm="12" md="2">
                            <Select 
                                default="Jenis Pidana"
                                options={mockSelect}
                                // name="jenis"
                            />
                        </Col>
                        <Col sm="12" md="1">
                            <div className="d-grid">
                                <Button className='btn btn-danger'>Reset</Button>
                            </div>
                        </Col>
                        <Col sm="12" md="1">
                            <div className="d-grid">
                                <Button className='btn btn-primary'>Apply</Button>
                            </div>
                        </Col>
                    </Row>
                    <Table className="table dems-table-tahanan">
                        <thead>
                        <tr>    
                            <th><Form.Check type="checkbox" /></th>
                            <th>Name</th>
                            <th>Nomor Tahanan</th>
                            <th>Jenis</th>
                            <th>Nomor Laporan Polisi</th>
                            <th>Last Modified</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                        mockDatas.map((data, index) => (
                            <tr key={index}>
                                <td>
                                    <Form.Check type="checkbox" />
                                </td>
                                <td>
                                    <div className="media">
                                    <div className="media-icon primary"><i className="ri-folder-5-line"></i></div>
                                    <h6 className="file-name"><Link href="">{data.name}</Link></h6>
                                    </div>
                                </td>
                                <td>{'13737371'}</td>
                                <td>{data.jenis}</td>
                                <td>{'LP/A/026/II/2024/'+ data.satker}</td>
                                <td>{data.date}</td>
                                <td>
                                    <Dropdown align="end" className="dropdown-file">
                                    <Dropdown.Toggle as={CustomToggle}>
                                        <i className="ri-more-2-fill"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" className="details"><i class="ri-eye-line"></i> View Content</Dropdown.Item>
                                        <Dropdown.Item href="#" className="details"><i class="ri-share-box-line"></i> Share Folder</Dropdown.Item>
                                        <Dropdown.Item href="#" className="details"><i class="ri-key-2-line"></i> Manage Permission</Dropdown.Item>
                                        <Dropdown.Item href="#" className="details"><i class="ri-article-line"></i> View Audit Log</Dropdown.Item>
                                        <Dropdown.Item href="#" className="details"><i class="ri-delete-bin-line"></i> Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <Card.Footer className='bg-white'>
                        <MyPagination currentPage={currentPage} totalPages="20" onPageChange={handlePagination}/>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    </React.Fragment>
  )
}

export default List