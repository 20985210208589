// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-header {
  background-color: rgb(58, 58, 58);
  color: white;
}

.custom-header .accordion-button:not(.collapsed) {
  color: white; /* Warna teks saat accordion terbuka */
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/custom.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,YAAY;AACd;;AAEA;EACE,YAAY,EAAE,sCAAsC;AACtD","sourcesContent":[".custom-header {\n  background-color: rgb(58, 58, 58);\n  color: white;\n}\n\n.custom-header .accordion-button:not(.collapsed) {\n  color: white; /* Warna teks saat accordion terbuka */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
