import React, { useState } from "react";
import * as XLSX from "xlsx";
import {
  Badge,
  Button,
  Card,
  Col,
  Row,
  Form,
  Table,
  Accordion,
  Spinner,
  Alert as AlertBS,
} from "react-bootstrap";
import {
  DatePicker,
  Input,
  Select,
  Image,
} from "../../../../components/Components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUploadMasterBarbuk } from "../../../../utils/apis/query";

const ExcelUploadPreview = () => {
  const onSuccess = () => {
    setTimeout(() => {
      navigate("/tahanan/bon/list");
    }, 1500);
  };
  const navigate = useNavigate();
  const [excelData, setExcelData] = useState([]);
  const mutateImport = useUploadMasterBarbuk(onSuccess);
  const [payloadForm, setPayloadForm] = useState({
    file: "",
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    setPayloadForm((prevForm) => ({
      ...prevForm,
      file: file,
    }));

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = () => {
    let formData = new FormData();
    if (payloadForm.file) {
      formData.append("file", payloadForm.file);
    }
    mutateImport.mutate(formData);
  };

  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <Row className="g-1 mb-2">
            <Col sm="12" md="5">
              <Input
                type="file"
                label="Import File Excel"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
              />
            </Col>
            <Col sm="12" md="7" className="d-flex justify-content-end gap-3">
              <Button className="btn btn-success" onClick={handleSubmit}>
                Generate
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {excelData.length > 0 && (
        <Card
          className="bg-white mb-2"
          style={{ height: 500, overflow: "scroll" }}
        >
          <Card.Body className="p-2">
            <Table className="table dems-table">
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                }}
              >
                <tr>
                  {Object.keys(excelData[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {excelData.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, i) => (
                      <td key={i}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </React.Fragment>
  );
};

export default ExcelUploadPreview;
