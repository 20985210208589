import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useGetMasterBarangBuktiJenis, useGetMasterBarangBuktiSubJenis, useGetMasterBarangBuktiSubJenisNew, useGetMasterBarangBuktiTipe, useGetMasterBarangBuktiUnit, useGetMasterMeasure, usePostMasterBarangBuktiTipe } from '../../../../utils/apis/query';
import { AsyncSelectCreatable, Input, Select } from '../../../../components/Components';
import AsyncCreatableSelect from 'react-select/async-creatable'

function FormLayout() {
    const navigate = useNavigate();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [subJenisSearch, setSubJenisSearch] = useState('');
    const [jenisSearch, setJenisSearch] = useState('');
    const [unitSearch, setUnitSearch] = useState('');
    const [tipeSearch, setTipeSearch] = useState('');
    const [formState, setFormState] = useState({ 
      tipe: "",
      jenis: "",
      sub_jenis: "",
      unit: "",
      keterangan: "",
    //   keterangan: "",
    });

    const { data: listOptionsJenis } = useGetMasterBarangBuktiJenis({page: 1, size: 10, jenis: jenisSearch})
    let selecOptionsJenis = listOptionsJenis.map((e) => ({label: e.jenis, value: e.jenis}))

    const { data: listOptionsSubJenis } = useGetMasterBarangBuktiSubJenisNew({search: subJenisSearch, jenis: formState.jenis}, {enabled: !!formState.jenis})
    let selecOptionsSubJenis = listOptionsSubJenis?.sub_jenis?.map((e) => ({label: e?.sub_jenis ?? '-', value: e?.sub_jenis ?? '-'})) ?? []

    const { data: listOptionsUnit } = useGetMasterMeasure({page: 1, size: 10, measure: unitSearch})
    let selecOptionsUnit = listOptionsUnit.map((e) => ({label: e.measure, value: e.measure}))
    
    const onSuccess = (response) => {
        setIsFormDirty(false)
        navigate('/master/barang-bukti/tipe')
    }
    const formMutate = usePostMasterBarangBuktiTipe(onSuccess)
  
    const handleChange = (e) => {
      const name = e?.target?.name
      const value = e?.target?.value ?? ''
      setFormState({...formState, [name] : value})
      setIsFormDirty(true)
    } 
    const handleAsyncOptionsJenis = (input) => {
        return new Promise((resolve) => {
            setJenisSearch(input)
            resolve(selecOptionsJenis)
        })
    }
    const handleAsyncOptionsSubjenis = (input) => {
        return new Promise((resolve) => {
            setSubJenisSearch(input)
            resolve(selecOptionsSubJenis)
        })
    }
    const handleAsyncOptionsUnit = (input) => {
        return new Promise((resolve) => {
            setUnitSearch(input)
            resolve(selecOptionsUnit)
        })
    }
    const handleSubmit = (e) => {
      formMutate.mutate(formState)
    
    }
    
    useEffect(() => { 
      const handleBeforeUnload = (event) => {
        if (isFormDirty) {
          const message =
            "You have unsaved changes. Are you sure you want to leave?";
          event.returnValue = message;
          return message;
        }
      };
  
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, [isFormDirty]);

  return (
    <React.Fragment>
        <Card className="bg-white mb-2">
            <Card.Body className="p-2">
            <div className="d-flex align-items-center justify-content-between m-0">
                <div>
                <ol className="breadcrumb fs-sm mb-0">
                    <li className="breadcrumb-item">
                    <Link to="/master/barang-bukti/list">Barang Bukti</Link>
                    </li>
                    <li className="breadcrumb-item">
                    <Link to="/master/barang-bukti/tipe">
                        Barang Bukti Tipe
                    </Link>
                    </li>
                    <li
                    className="breadcrumb-item active fw-bold"
                    aria-current="page"
                    >
                        Form Barang Bukti Tipe
                    </li>
                </ol>
                </div>
            </div>
            </Card.Body>
        </Card>
        <Card className="bg-white">
            <Card.Body>
                <Row className="g-2">
                    <Col sm="12">
                    {formMutate.isError && (
                        <Alert variant="danger">
                        <ul className="m-0">
                            {formMutate?.error?.response?.data?.detail?.map(
                            (errEdit, idx) => (
                                <li key={idx}>
                                <b>{errEdit.loc[1]} : </b>
                                {errEdit.msg}
                                </li>
                            )
                            ) ?? (
                            <li>
                                <b>Error : </b>
                                {formMutate?.error?.response?.data?.message ??
                                "Bermasalah"}
                            </li>
                            )}
                        </ul>
                        </Alert>
                    )}
                    {formMutate.isSuccess && (
                        <Alert variant="success">
                        {formMutate.data.data.message}
                        </Alert>
                    )}
                    </Col>
                    <Col sm="12">
                        <Row className='g-2'>
                            <Col sm="3" className="d-flex flex-column">
                                <AsyncSelectCreatable 
                                    className="react-select-dems"
                                    cacheOptions
                                    label="Pilih Jenis"
                                    placeholder=""
                                    required
                                    defaultOptions={selecOptionsJenis || []}
                                    loadOptions={handleAsyncOptionsJenis}
                                    onChange={(e) => setFormState({...formState, jenis: e.value})}
                                />
                            </Col>
                            <Col sm="3" className="d-flex flex-column">
                                <AsyncSelectCreatable 
                                    className="react-select-dems"
                                    cacheOptions
                                    label="Pilih Sub Jenis"
                                    placeholder=""
                                    required
                                    defaultOptions={selecOptionsSubJenis || []}
                                    loadOptions={handleAsyncOptionsSubjenis}
                                    onChange={(e) => setFormState({...formState, sub_jenis: e.value})}
                                />
                            </Col>
                            <Col sm="3" className="d-flex flex-column">
                                <Input 
                                    label="Pilih Tipe"
                                    type="text"
                                    name="tipe"
                                    required
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col sm="3" className="d-flex flex-column">
                                <AsyncSelectCreatable 
                                    className="react-select-dems"
                                    cacheOptions
                                    label="Pilih Unit"
                                    placeholder=""
                                    required
                                    defaultOptions={selecOptionsUnit || []}
                                    loadOptions={handleAsyncOptionsUnit}
                                    onChange={(e) => setFormState({...formState, unit: e.value})}
                                />
                            </Col>
                            <Col sm="3">
                                <Input 
                                    label="Keterangan"
                                    type="text"
                                    name="keterangan"
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className="bg-white">
            <div className="d-flex justify-content-end align-items-center gap-3">
                <Button
                    variant="warning"
                    as={Link}
                    to="/master/barang-bukti/tipe"
                >
                    BATAL
                </Button>
                <Button 
                  variant="primary"
                  onClick={handleSubmit}
                >
                    TAMBAH
                </Button>
            </div>
        </Card.Footer>
        </Card>
    </React.Fragment>
  )
}

export default FormLayout