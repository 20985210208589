import { Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Components from "../../../components/Components";
import State from "../../../libraries/State";

function UserProfileForm() {
  const params = useParams();
  const state = State.getState();
  const navigate = useNavigate();

  const id = params?.id !== undefined ? params.id : null;
  const FormType = id ? "ubah" : "tambah";

  const doSubmit = () => {
    withReactContent(Swal)
      .fire({
        title: "Apa anda yakin?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
      })
      .then((result) => {
        if (result.isConfirmed) {
          /**
           * Preparing data
           */
          const data = {
            username: state.getItem("username"),
            nama_lengkap: state.getItem("nama_lengkap"),
            nrp: state.getItem("nrp"),
            jabatan: state.getItem("jabatan"),
            handphone: state.getItem("handphone"),
            email: state.getItem("email"),
          };
          state.flush(); // clean state

          /**
           * Send to APi
           */
          //   console.log(data);

          /**
           * Send notification
           */
          Swal.fire({
            title: "Berhasil",
            icon: "success",
            text: "Data berhasil tersimpan",
          });
          navigate(`/user_profile`);
        }
      });
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/user_profile">List user profile</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Form {FormType}
            </li>
          </ol>
        </div>
      </div>

      <Row className="g-3">
        <Col xl="12">
          <Card className="card">
            <Card.Header className="bg-primary">
              <Card.Title
                as="label"
                className="fs-sm fw-medium mb-1 text-white"
              >
                Form user profile
              </Card.Title>
            </Card.Header>
            <Card.Body className="bg-white">
              <Link
                to="/user_profile"
                className="btn btn-sm btn-warning text-white"
              >
                <i className="ri-arrow-left-line"></i> Kembali
              </Link>
              &nbsp;
              <button className="btn btn-sm btn-primary" onClick={doSubmit}>
                <i className="ri-check-line"></i> Simpan
              </button>
              <br />
              <br />
              <Row className="g-3">
                <Col sm="4">
                  <Components.Input
                    type="text"
                    name="username"
                    label="Username"
                    placeholder="Username"
                    readOnly={id ? true : false}
                  />
                </Col>
                <Col sm="4">
                  <Components.Input
                    type="nama_lengkap"
                    name="nama_lengkap"
                    label="Nama lengkap"
                    placeholder="Nama lengkap"
                  />
                </Col>
                <Col sm="4">
                  <Components.Input
                    type="number"
                    name="nrp"
                    label="NRP"
                    placeholder="NRP"
                  />
                </Col>
                <Col sm="4">
                  <Components.Input
                    type="text"
                    name="jabatan"
                    label="Jabatan"
                    placeholder="Jabatan"
                  />
                </Col>
                <Col sm="4">
                  <Components.Input
                    type="text"
                    name="handphone"
                    label="No handphone"
                    placeholder="No handphone"
                  />
                </Col>
                <Col sm="4">
                  <Components.Input
                    type="text"
                    name="email"
                    label="Email"
                    placeholder="Email"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default UserProfileForm;
