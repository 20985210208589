import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { DatePicker, Input, Select } from "../../../../components/Components";
import { useGetPenyerahanDetail, usePenyidikList } from "../../../../utils/apis/query";
import moment from "moment";

function FormCreate() {
  const { id } = useParams();
  const [formReady, setFormReady] = useState(false);
  const [penyidik, setPenyidik] = useState([]);
  const [barbukList, setBarbukList] = useState([]);
  const [payloadDate, setPayloadDate] = useState({
      tanggal_penyerahan: '',
      tanggal_pengembalian: '',
      tanggal_tf_kasnegara: '',
      tanggal_lelang: '',
      tanggal_dokumen: '',
      tanggal_pemusnahan: '',
      tanggal_kembali: '', // payload
      tanggal_pinjam: '',
  })
  const [payloadForm, setPayloadForm] = useState({
    tanggal_penyerahan: "",
    tanggal_pengembalian: "",
    tanggal_tf_kasnegara: "",
    tanggal_lelang: "",
    tanggal_dokumen: "",
    tanggal_pemusnahan: "",
    tanggal_kembali: "",
    tanggal_pinjam: "",
    tanggal_pinjam: "",
    jadwal_kembali: "",
    no_dokumen: "",
    lokasi: "",
    petugas: "",
    kategori_penyerahan: "Pinjam Pakai",
    saksi_barbuk: [],
    uraian: "",
    kondisi: "",
    nama_tahanan: "",
    no_tahanan: "",
    barang_bukti_ids: [],
    penyidik_ids: [],
    status_dokumen: "",
  })
  const {
    data: dataDetail,
    isFetching: isDataDetailFetching,
    isError: isDataDetailError,
    error: dataDetailError,
  } = useGetPenyerahanDetail(id);

  const {data:penyidikListMaster}  = usePenyidikList()

  const handleCheckbox = (data, isChecked) => {
    if (isChecked) {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: [...payloadForm.penyidik_ids, data.id],
      });
      setPenyidik([...penyidik, data])
    } else {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: payloadForm.penyidik_ids.filter(
          (selectedId) => selectedId !== data.id
        ),
      });
      setPenyidik(penyidik.filter((e) => e.id !== data.id))
    }
  };
  
  useEffect(() => {
    if (dataDetail) {
      const detail = dataDetail;

      setPayloadDate({
        tanggal_penyerahan: moment((detail?.tanggal_penyerahan ?? '') || '').toDate(),
        tanggal_pengembalian: moment((detail?.tanggal_pengembalian ?? '') || '').toDate(),
        tanggal_tf_kasnegara: moment((detail?.tanggal_tf_kasnegara ?? '') || '').toDate(),
        tanggal_lelang: moment((detail?.tanggal_lelang ?? '') || '').toDate(),
        tanggal_dokumen: moment((detail?.tanggal_dokumen ?? '') || '').toDate(),
        tanggal_pemusnahan: moment((detail?.tanggal_pemusnahan ?? '') || '').toDate(),
        tanggal_kembali: moment((detail?.tanggal_kembali ?? '') || '').toDate(), // payload
        tanggal_pinjam: moment((detail?.tanggal_pinjam ?? '') || '').toDate(),
        jadwal_kembali: moment((detail?.jadwal_kembali ?? '') || '').toDate(),
      })
      setPayloadForm({
        ...payloadForm,
        tanggal_penyerahan: '',
        tanggal_pengembalian: '',
        tanggal_tf_kasnegara: '',
        tanggal_lelang: '',
        tanggal_dokumen: '',
        tanggal_pemusnahan: '',
        tanggal_kembali: '', // payload
        jadwal_kembali: '',
        no_dokumen: detail.no_dokumen,
        lokasi: detail.lokasi,
        petugas: detail.petugas,
        kategori_penyerahan: "Pengembalian",
        saksi_barbuk: JSON.stringify(detail.saksi),
        uraian: detail.uraian,
        kondisi: "", // payload
        keterangan: "", // payload
        nama_tahanan: detail.nama_tahanan,
        no_tahanan: detail.no_tahanan,
        barang_bukti_ids: detail?.barang_bukti?.map((e) => e.barbuk_id),
        penyidik_ids: detail?.penyidik?.map((e) => e.id_penyidik),
      });
      setPenyidik(detail?.penyidik ?? [])
      setBarbukList(detail?.barang_bukti ?? [])
      setFormReady(true);
    }
  }, [dataDetail, setPayloadForm, payloadForm]);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/barbuk">Tahanan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/barbuk/penyerahan/list">
                    List Pengeluaran Barang Bukti
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form Pengembalian Barang Bukti
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            {/* <Col sm="12">
                {
                  mutateSubmit.isError && (
                    <Alert variant='danger'>
                      <ul className='m-0'>
                        {
                          mutateSubmit?.error?.response?.data?.detail
                          ?.map((errEdit, idx) => (
                            <li key={idx}><b>{errEdit.loc[1]} : </b>{errEdit.msg}</li>
                          )) ?? (
                            <li><b>Error : </b>{ mutateSubmit?.error?.response?.data?.message ?? 'Bermasalah' }</li>
                          )
                        }
                      </ul>
                    </Alert>
                  )
                }
                {
                  mutateSubmit.isSuccess && (
                    <Alert variant='success'>
                      {mutateSubmit.data.data.message}
                    </Alert>
                  )
                }
              </Col> */}
            <Col sm="12">
              {!formReady && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner variant="primary" size="sm" />
                </div>
              )}
              {
                formReady &&
                (
                  <Card className="card bg-white">
                    <Card.Body>
                      <Row className="g-2">
                        <Col sm="12">
                          <Card className="bg-white">
                            <Card.Body>
                              <Row className="g-2">
                                <Col sm="3">
                                  {/* <DatePicker
                                    label="Tanggal Pinjam"
                                    name="tanggal_sprin_perpanjangan"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    required
                                  /> */}
                                  <DatePicker
                                    label="Tanggal Pinjam"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      setPayloadDate({
                                        ...payloadDate,
                                        tanggal_pinjam: date,
                                      });
                                      setPayloadForm({
                                        ...payloadForm,
                                        tanggal_pinjam:
                                          moment(date).format("YYYY-MM-DD"),
                                      });
                                    }}
                                    // selected={
                                    //   payloadDate.tanggal_pinjam
                                    // }
                                    required
                                  />
                                </Col>
                                <Col sm="3">
                                  <DatePicker
                                    label="Jadwal Kembali"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      setPayloadDate({
                                        ...payloadDate,
                                        jadwal_kembali: date,
                                      });
                                      setPayloadForm({
                                        ...payloadForm,
                                        jadwal_kembali:
                                          moment(date).format("YYYY-MM-DD"),
                                      });
                                    }}
                                    // selected={
                                    //   payloadDate.jadwal_kembali
                                    // }
                                    required
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input label="No Dokumen" type="text" required />
                                </Col>
                                <Col sm="3">
                                  <Input label="No Tahanan" type="text" required />
                                </Col>
                                <Col sm="3">
                                  <Input
                                    label="Nama Tahanan"
                                    type="text"
                                    required
                                  />
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col sm="12">
                          <Card className="card bg-white">
                            <Card.Body>
                              <Row className="g-1 mb-2 d-flex align-items-center">
                                <Col sm="12" md="5">
                                  <h6>Peminjaman Oleh (Penyidik)</h6>
                                </Col>
                                <Col sm="12" md="7">
                                  <Link className="d-flex align-items-center justify-content-end"></Link>
                                </Col>
                              </Row>
                              <Table className="table dems-table">
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>NRP</th>
                                    <th>Nama Penyidik</th>
                                    <th>Asal Satker</th>
                                    <th>Pangkat</th>
                                    <th>Jabatan</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {
                                    penyidik.length && 
                                    (
                                      penyidik.map((data, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{data.nrp}</td>
                                          <td>{data.nama_penyidik}</td>
                                          <td>{data.nama_satker}</td>
                                          <td>{data.pangkat}</td>
                                          <td>{data.jabatan}</td>
                                        </tr>
                                      ))
                                    )
                                  }
                                </tbody>
                              </Table>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col sm="12">
                          <Card className="card bg-white">
                            <Card.Body>
                              <Row className="g-1 mb-2 d-flex align-items-center">
                                <Col sm="12" md="5">
                                  <h6>Pengembalian Oleh (Penyidik)</h6>
                                </Col>
                                <Col sm="12" md="7">
                                  <Link className="d-flex align-items-center justify-content-end"></Link>
                                </Col>
                              </Row>
                              <Table className="table dems-table">
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>NRP</th>
                                    <th>Nama Penyidik</th>
                                    <th>Asal Satker</th>
                                    <th>Pangkat</th>
                                    <th>Jabatan</th>
                                    <th>Pilih</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    penyidikListMaster && 
                                    (
                                      penyidikListMaster.map((data, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{data.nrp}</td>
                                          <td>{data.nama_penyidik}</td>
                                          <td>{data.nama_satker}</td>
                                          <td>{data.pangkat}</td>
                                          <td>{data.jabatan}</td>
                                          <td>
                                            <Form.Check
                                              type="checkbox"
                                              checked={payloadForm?.penyidik_ids?.some(
                                                (item) =>
                                                  item === data.id
                                              ) ?? false}
                                              onChange={(e) => {
                                                handleCheckbox(
                                                  data,
                                                  e.target.checked
                                                );
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))
                                    )
                                  }
                                </tbody>
                              </Table>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col sm="12">
                          <Card className="card bg-white">
                            <Card.Body>
                              <Row className="g-1 mb-2 d-flex align-items-center">
                                <Col sm="12" md="5">
                                  <h6>Daftar Rincian Barang Bukti</h6>
                                </Col>
                                <Col sm="12" md="7">
                                  <Link className="d-flex align-items-center justify-content-end"></Link>
                                </Col>
                              </Row>
                              <Table className="table dems-table">
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Tipe/Jenis</th>
                                    <th>Sub Jeis</th>
                                    <th>Nama</th>
                                    <th>Jumlah</th>
                                    <th>Unit</th>
                                    <th>Pilih</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    barbukList.map((data, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.tipe+'/'+data.jenis}</td>
                                        <td>{data.sub_jenis}</td>
                                        <td>{data?.nama ?? '-'}</td>
                                        <td>{data.jumlah}</td>
                                        <td>{data.unit}</td>
                                        <td>
                                            <Form.Check
                                              type="checkbox"
                                              checked={payloadForm?.barang_bukti_ids?.some(
                                                (item) =>
                                                  item === data.barbuk_id
                                              ) ?? false}
                                              onChange={(e) => {
                                                handleCheckbox(
                                                  data,
                                                  e.target.checked
                                                );
                                              }}
                                            />
                                        </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </Table>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col sm="12">
                          <Card className="card bg-white">
                            <Card.Body>
                              <Row className="g-2">
                                <Col sm="3">
                                  <DatePicker
                                    label="Tanggal Kembali"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      setPayloadDate({
                                        ...payloadDate,
                                        tanggal_kembali: date,
                                      });
                                      setPayloadForm({
                                        ...payloadForm,
                                        tanggal_kembali:
                                          moment(date).format("YYYY-MM-DD"),
                                      });
                                    }}
                                    // selected={
                                    //   payloadDate.tanggal_kembali
                                    // }
                                    required
                                  />
                                </Col>
                                <Col sm="3">
                                  <Select
                                    required
                                    label="Kondisi"
                                    default="Pilih"
                                    options={[
                                      {
                                        value: "Baik",
                                        text: "Baik",
                                      },
                                    ]}
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Keterangan"
                                    type="keterangan"
                                    required
                                  />
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )
              }
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          {!false || !false ? (
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Link to="/barbuk/penyerahan/list">
                <Button variant="warning">BATAL</Button>
              </Link>
              <Button variant="primary">SIMPAN</Button>
            </div>
          ) : (
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Link>
                <Button variant="warning" disabled>
                  BATAL
                </Button>
              </Link>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                disabled
              >
                Sedang Menyimpan <Spinner variant="light" size="sm" />
              </Button>
            </div>
          )}
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default FormCreate;
