import React, { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Accordion,
  Row,
  Spinner,
  Table,
  Form,
  Modal,
  Placeholder,
  Alert,
} from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  DatePicker,
  Image,
  Input,
  Select,
  Select2,
} from "../../../../components/Components";
import {
  useGetDetailTahananBarbuk,
  useGetDetailTahti,
  useGetPenyerahanDetail,
  useGetTahtiList,
  usePostPenyerahan,
  usePutPenyerahan,
  useListNoLP,
  useBarbukByNoLP,
} from "../../../../utils/apis/query";
import { debounce } from "lodash";
import { useQueryClient } from "react-query";
import moment from "moment";
import { removeEmptyFields } from "../../../../utils";
import { encodeToHex, trimFileUrl, trimUrlPath } from "../../../../utils";
import State from "../../../../libraries/State";

function Pengembalian() {
  const location = useLocation();
  const isPemusnahan = location.pathname.includes("/pemusnahan");
  const isPengembalian = location.pathname.includes("/pengembalian");
  const navigate = useNavigate();
  const { id } = useParams();
  const [isFormEditAble, setIsFormEditAble] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [payloadCariTahanann, setPayloadCariTahanan] = useState({
    nama_tersangka: "",
  });
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);
  const [six, setSix] = useState(false);
  const state = State.getState();
  const userData = state.getItem("profileData", true);
  const [namaTahanan, setNamaTahanan] = useState("");
  const [idTahanan, setIdTahanan] = useState("");
  const [penyidik, setPenyidik] = useState([]);
  const [barbukModalShow, setBarbukModalShow] = useState(false);
  const [payloadBarbuk, setPayloadBarbuk] = useState([]);
  const [isAddingRow, setIsAddingRow] = useState(false);
  const [saksiData, setSaksiData] = useState([]);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [formErrorMesssage, setFormErrorMesssage] = useState([]);
  const [saksi, setSaksi] = useState({
    nama: "",
    pekerjaan: "",
    alamat: "",
    keterangan: "",
    no_hp: "",
  });
  const [payloadDate, setPayloadDate] = useState({
    tanggal_penyerahan: "",
    tanggal_pengembalian: "",
    tanggal_tf_kasnegara: "",
    tanggal_lelang: "",
    tanggal_dokumen: "",
    tanggal_pemusnahan: "",
    tanggal_kembali: "",
    jadwal_kembali: "",
    tanggal_pelimpahan_p21: "",
    tanggal_penghentian_sp3: "",
    tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb: "",
    tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti: "",
    tanggal_dokumen_ba_penitipan_bb: "",
    tanggal_dokumen_p21_atau_sp3: "",
    tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2: "",
    tanggal_dokumen_hasil_gelar_perkara: "",
  });

  const [payloadForm, setPayloadForm] = useState({
    tanggal_penyerahan: "",
    tanggal_pengembalian: "",
    tanggal_tf_kasnegara: "",
    tanggal_lelang: "",
    tanggal_dokumen: "",
    tanggal_pemusnahan: "",
    tanggal_kembali: "",
    jadwal_kembali: "",
    no_dokumen: "",
    lokasi: "",
    petugas: "",
    kategori_penyerahan: "",
    saksi_barbuk: [],
    uraian: "",
    keterangan: "",
    kondisi: "",
    nama_tahanan: "",
    no_tahanan: "",
    barang_bukti_ids: [],
    penyidik_ids: [],
    status_dokumen: "",

    tanggal_pelimpahan_p21: "",
    tanggal_penghentian_sp3: "",
    alasan_pelimpahan_p21: "",
    alasan_penghentian_sp3: "",

    no_laporan_polisi: "",
    nomor_nota_dinas_permohonan_pengeluaran_bb: "",
    tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb: "",
    arsip_nota_dinas_permohonan_pengeluaran_bb: [],
    nomor_disposisi_pimpinan_karorenmin_kabagtahti: "",
    tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti: "",
    arsip_disposisi_pimpinan_karorenmin_kabagtahti: [],
    nomor_ba_penitipan_bb: "",
    tanggal_dokumen_ba_penitipan_bb: "",
    arsip_ba_penitipan_bb: [],
    nomor_dokumen_p21_atau_sp3: "",
    tanggal_dokumen_p21_atau_sp3: "",
    arsip_dokumen_p21_atau_sp3: [],
    nomor_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2: "",
    tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2: "",
    arsip_penyerahan_barang_bukti_dan_tersangka_tahap_2: [],
    nomor_dokumen_hasil_gelar_perkara: "",
    tanggal_dokumen_hasil_gelar_perkara: "",
    arsip_dokumen_hasil_gelar_perkara: [],
  });
  const [payloadLP, setPayloadLP] = useState({
    nama_satker: userData?.profile?.nama_satker_tahti,
    no_laporan_polisi: "",
    page: 1,
    size: 100,
  });

  // END OF STATE GROUP

  const {
    data: dataDetail,
    isFetching: isDataDetailFetching,
    isError: isDataDetailError,
    error: dataDetailError,
  } = useGetPenyerahanDetail(id, { enabled: !!id });

  const {
    data: dataTransaksi,
    pagination,
    isFetching: dataFetching,
    isError: dataIsError,
    error: dataError,
  } = useBarbukByNoLP(payloadLP, {
    enabled: !!payloadLP.no_laporan_polisi,
  });

  const {
    data: listPenerimaan,
    paginationLP,
    isFetching: dataFetchingLP,
    isError: dataIsErrorLP,
    error: dataErrorLP,
  } = useListNoLP({
    nama_satker: userData?.profile?.nama_satker_tahti,
    size: 100,
    page: 1,
  });
  const listNoLP = Array.from(
    new Set(listPenerimaan.map((item) => item.no_laporan_polisi))
  ).map((no_laporan_polisi) => ({
    label: no_laporan_polisi,
    value: no_laporan_polisi,
  }));

  const [searchTerm, setSearchTerm] = useState("");
  const filteredBarangBukti = dataTransaksi.barang_bukti.filter((data) =>
    data.no_label_barbuk.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const { data: dataTahananList, isFetching: modalFetching } = useGetTahtiList(
    payloadCariTahanann,
    { enabled: !!namaTahanan }
  );

  const handleCheckbox = (type, data, isChecked) => {
    if (type === "penyidik") {
      if (isChecked) {
        setPayloadForm({
          ...payloadForm,
          penyidik_ids: [...payloadForm.penyidik_ids, data.id_penyidik],
        });
      } else {
        setPayloadForm({
          ...payloadForm,
          penyidik_ids: payloadForm.penyidik_ids.filter(
            (selectedId) => selectedId !== data.id_penyidik
          ),
        });
      }
    }
    if (type === "barbuk") {
      if (isChecked) {
        setPayloadForm({
          ...payloadForm,
          barang_bukti_ids: [
            ...payloadForm.barang_bukti_ids,
            data.id_transaksi_barbuk,
          ],
        });
      } else {
        setPayloadForm({
          ...payloadForm,
          barang_bukti_ids: payloadForm.barang_bukti_ids.filter(
            (selectedId) => selectedId !== data.id_transaksi_barbuk
          ),
        });
      }
    }
  };

  const handleCheckboxBarbuk = (data, isChecked) => {
    if (isChecked) {
      setPayloadBarbuk([...payloadBarbuk, data]);
    } else {
      setPayloadBarbuk(
        payloadBarbuk.filter(
          (item) => item.id_transaksi_barbuk !== data.id_transaksi_barbuk
        )
      );
    }
  };

  // SAKSI
  const handleAddRow = () => {
    setIsAddingRow(true);
  };
  const closeRow = () => {
    setSaksi({
      nama: "",
      pekerjaan: "",
      alamat: "",
      keterangan: "",
      no_hp: "",
    });
    setIsAddingRow(false);
  };

  const handleSaveRow = () => {
    const inputs = document.querySelectorAll("#addSaksiRow");
    let allValid = true;
    inputs.forEach((input) => {
      if (input.type === "number" && input.value.length > 13) {
        input.setCustomValidity(
          "Panjang nomor tidak boleh lebih dari 13 digit."
        );
        input.reportValidity();
        allValid = false;
      } else if (input.value.length > 50) {
        input.setCustomValidity(
          "Panjang text tidak boleh lebih dari 50 karakter."
        );
      } else {
        input.setCustomValidity("");
      }

      if (!input.reportValidity()) {
        allValid = false;
      }
    });

    if (allValid) {
      setSaksiData([...saksiData, saksi]);
      setSaksi({
        nama: "",
        pekerjaan: "",
        alamat: "",
        keterangan: "",
        no_hp: "",
      });
      setIsAddingRow(false);
    }
  };

  const handleNewRowChange = (e) => {
    const { name, value } = e.target;
    setSaksi({ ...saksi, [name]: value });
  };

  const handleDelete = (index) => {
    const newSaksiData = saksiData.filter((_, idx) => idx !== index);
    setSaksiData(newSaksiData);
  };

  const handleChange = (e) => {
    setIsFormDirty(true);
    const { name, value } = e.target;
    setPayloadForm({ ...payloadForm, [name]: value });
  };

  const handleDebounce = useCallback(
    debounce((newPayload) => {
      setPayloadCariTahanan(newPayload);
    }, 500),
    []
  );

  const onSuccess = (response) => {
    setIsFormDirty(false);
    setTimeout(() => {
      navigate(
        `/barbuk/${
          isPemusnahan
            ? "pemusnahan"
            : isPengembalian
            ? "pengembalian"
            : "pengeluaran"
        }/list`
      );
    }, 2500);
  };

  const mutateSubmit = usePutPenyerahan(onSuccess);

  const handleSubmit = (status) => {
    setFormErrorMesssage([]);
    const inputs = document.querySelectorAll("input");
    let allValid = true;
    let errFormMsg = [];

    inputs.forEach((input) => {
      if (input.id !== "addSaksiRow") {
        if (!input.reportValidity()) {
          if (input.type === "file") {
            errFormMsg.push("File Input Unggah Arsip / Lampiran");
          } else {
            errFormMsg.push(
              (input?.name ?? "") || "Input Mandatori / Kondisional"
            );
          }
          allValid = false;
        }
      }
    });
    setFormErrorMesssage(errFormMsg);
    if (allValid) {
      payloadForm.status_dokumen = status;
      let formData = new FormData();
      let payload = payloadForm;

      if (payload.kategori_penyerahan !== "Pinjam Pakai") {
        payload = {
          ...payload,
          jadwal_kembali: "",
        };
      }
      if (payload.kategori_penyerahan !== "Pemusnahan") {
        payload = {
          ...payload,
          tanggal_pemusnahan: "",
        };
      }
      if (payload.kategori_penyerahan !== "Lelang") {
        payload = {
          ...payload,
          tanggal_lelang: "",
        };
      }
      if (payload.kategori_penyerahan !== "TF Kas Negara") {
        payload = {
          ...payload,
          tanggal_tf_kasnegara: "",
        };
      }
      if (payload.kategori_penyerahan !== "Pengembalian") {
        payload = {
          ...payload,
          tanggal_pemusnahan: "",
        };
      }
      if (payload.kategori_penyerahan !== "Penghentian(SP3)") {
        payload = {
          ...payload,
          tanggal_penghentian_sp3: "",
        };
      }
      if (payload.kategori_penyerahan !== "Pelimpahan(P21)") {
        payload = {
          ...payload,
          tanggal_pelimpahan_p21: "",
        };
      }
      payload = removeEmptyFields(payload);
      payload.saksi_barbuk = JSON.stringify(saksiData);
      Object.keys(payload).forEach((key) => {
        if (
          typeof payload[key] === "string" ||
          typeof payload[key] === "number"
        ) {
          formData.append(key, payload[key]);
        }
      });
      payload.arsip_nota_dinas_permohonan_pengeluaran_bb?.forEach(
        (file, idx) => {
          formData.append(`arsip_nota_dinas_permohonan_pengeluaran_bb`, file);
        }
      );
      payload.arsip_disposisi_pimpinan_karorenmin_kabagtahti?.forEach(
        (file, idx) => {
          formData.append(
            `arsip_disposisi_pimpinan_karorenmin_kabagtahti`,
            file
          );
        }
      );
      payload.arsip_ba_penitipan_bb?.forEach((file, idx) => {
        formData.append(`arsip_ba_penitipan_bb`, file);
      });
      payload.arsip_dokumen_p21_atau_sp3?.forEach((file, idx) => {
        formData.append(`arsip_dokumen_p21_atau_sp3`, file);
      });
      payload.arsip_penyerahan_barang_bukti_dan_tersangka_tahap_2?.forEach(
        (file, idx) => {
          formData.append(
            `arsip_penyerahan_barang_bukti_dan_tersangka_tahap_2`,
            file
          );
        }
      );
      payload.arsip_dokumen_hasil_gelar_perkara?.forEach((file, idx) => {
        formData.append(`arsip_dokumen_hasil_gelar_perkara`, file);
      });
      payload?.penyidik_ids?.forEach((ids, idx) => {
        formData.append(`penyidik_ids`, ids);
      });
      payload?.barang_bukti_ids?.forEach((obj, idx) => {
        formData.append(`barang_bukti_ids`, obj);
      });

      mutateSubmit.mutate({ id, formData });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  // USE EFFECT GROUP

  useEffect(() => {
    handleDebounce({ ...payloadCariTahanann, nama_tersangka: namaTahanan });
  }, [namaTahanan, handleDebounce, payloadCariTahanann]);

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      setIsFormEditAble(false);
    }
    if (dataDetail) {
      const penyidikIds = dataDetail?.penyidik?.map(
        (penyidik) => penyidik.id_penyidik
      );
      setPayloadForm({
        ...payloadForm,
        tanggal_penyerahan: dataDetail.tanggal_penyerahan,
        tanggal_pengembalian: dataDetail.tanggal_pengembalian,
        tanggal_tf_kasnegara: dataDetail.tanggal_tf_kasnegara,
        tanggal_lelang: dataDetail.tanggal_lelang,
        tanggal_dokumen: dataDetail.tanggal_dokumen,
        tanggal_pemusnahan: dataDetail.tanggal_pemusnahan,
        tanggal_kembali: dataDetail.tanggal_kembali,
        jadwal_kembali: dataDetail.jadwal_kembali,
        lokasi: dataDetail.lokasi,
        petugas: dataDetail.petugas,
        kategori_penyerahan: dataDetail.kategori_penyerahan,
        saksi_barbuk: dataDetail.saksi,
        uraian: dataDetail.uraian,
        keterangan: dataDetail.keterangan,
        kondisi: dataDetail.kondisi,
        nama_tahanan: dataDetail.nama_tahanan,
        no_tahanan: dataDetail.no_tahanan,
        barang_bukti_ids: (dataDetail.barang_bukti || []).map(
          (barbuk) => barbuk.id_barang_bukti
        ),
        penyidik_ids: penyidikIds,
        status_dokumen: dataDetail.status_dokumen,

        tanggal_pelimpahan_p21: dataDetail.tanggal_pelimpahan_p21,
        tanggal_penghentian_sp3: dataDetail.tanggal_penghentian_sp3,
        alasan_pelimpahan_p21: dataDetail.alasan_pelimpahan_p21,
        alasan_penghentian_sp3: dataDetail.alasan_penghentian_sp3,

        no_laporan_polisi: dataDetail.no_laporan_polisi,
        nomor_nota_dinas_permohonan_pengeluaran_bb:
          dataDetail.nomor_nota_dinas_permohonan_pengeluaran_bb,
        tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb:
          dataDetail.tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb,
        nomor_disposisi_pimpinan_karorenmin_kabagtahti:
          dataDetail.nomor_disposisi_pimpinan_karorenmin_kabagtahti,
        tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti:
          dataDetail.tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti,
        nomor_ba_penitipan_bb: dataDetail.nomor_ba_penitipan_bb,
        tanggal_dokumen_ba_penitipan_bb:
          dataDetail.tanggal_dokumen_ba_penitipan_bb,
        nomor_dokumen_p21_atau_sp3: dataDetail.nomor_dokumen_p21_atau_sp3,
        tanggal_dokumen_p21_atau_sp3: dataDetail.tanggal_dokumen_p21_atau_sp3,
        nomor_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2:
          dataDetail.nomor_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2,
        tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2:
          dataDetail.tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2,
        nomor_dokumen_hasil_gelar_perkara:
          dataDetail.nomor_dokumen_hasil_gelar_perkara,
        tanggal_dokumen_hasil_gelar_perkara:
          dataDetail.tanggal_dokumen_hasil_gelar_perkara,
      });
      setPayloadDate({
        ...payloadDate,
        tanggal_penyerahan: moment(dataDetail?.tanggal_penyerahan).toDate(),
        tanggal_pengembalian: dataDetail?.tanggal_pengembalian
          ? moment(dataDetail?.tanggal_pengembalian).toDate()
          : null,
        tanggal_tf_kasnegara: dataDetail?.tanggal_tf_kasnegara
          ? moment(dataDetail?.tanggal_tf_kasnegara).toDate()
          : null,
        tanggal_lelang: dataDetail?.tanggal_lelang
          ? moment(dataDetail?.tanggal_lelang).toDate()
          : null,
        tanggal_dokumen: dataDetail?.tanggal_dokumen
          ? moment(dataDetail?.tanggal_dokumen).toDate()
          : null,
        tanggal_pemusnahan: dataDetail?.tanggal_pemusnahan
          ? moment(dataDetail?.tanggal_pemusnahan).toDate()
          : null,
        tanggal_kembali: dataDetail?.tanggal_kembali
          ? moment(dataDetail?.tanggal_kembali).toDate()
          : null,
        jadwal_kembali: dataDetail?.jadwal_kembali
          ? moment(dataDetail?.jadwal_kembali).toDate()
          : null,
        tanggal_pelimpahan_p21: dataDetail?.tanggal_pelimpahan_p21
          ? moment(dataDetail?.tanggal_pelimpahan_p21).toDate()
          : null,
        tanggal_penghentian_sp3: dataDetail?.tanggal_penghentian_sp3
          ? moment(dataDetail?.tanggal_penghentian_sp3).toDate()
          : null,
        tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb:
          dataDetail?.tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb
            ? moment(
                dataDetail?.tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb
              ).toDate()
            : null,
        tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti:
          dataDetail?.tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti
            ? moment(
                dataDetail?.tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti
              ).toDate()
            : null,
        tanggal_dokumen_ba_penitipan_bb:
          dataDetail?.tanggal_dokumen_ba_penitipan_bb
            ? moment(dataDetail?.tanggal_dokumen_ba_penitipan_bb).toDate()
            : null,
        tanggal_dokumen_p21_atau_sp3: dataDetail?.tanggal_dokumen_p21_atau_sp3
          ? moment(dataDetail?.tanggal_dokumen_p21_atau_sp3).toDate()
          : null,
        tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2:
          dataDetail?.tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2
            ? moment(
                dataDetail?.tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2
              ).toDate()
            : null,
        tanggal_dokumen_hasil_gelar_perkara:
          dataDetail?.tanggal_dokumen_hasil_gelar_perkara
            ? moment(dataDetail?.tanggal_dokumen_hasil_gelar_perkara).toDate()
            : null,
      });
      setSaksiData(dataDetail.saksi ?? []);
      setPayloadBarbuk(
        (dataDetail.barang_bukti ?? []).map((item) => ({
          ...item,
          id_transaksi_barbuk: item.id_barang_bukti,
        }))
      );
      setPayloadLP({
        ...payloadLP,
        no_laporan_polisi: dataDetail.no_laporan_polisi,
      });
    }
  }, [dataDetail]);

  useEffect(() => {
    if (userData) {
      setPayloadForm({
        ...payloadForm,
        petugas: userData?.profile?.nama_lengkap,
        lokasi: userData?.profile?.nama_satker_tahti,
      });
    }
  }, [userData]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  useEffect(() => {
    if (payloadForm) {
      const one =
        payloadForm.nomor_nota_dinas_permohonan_pengeluaran_bb &&
        payloadForm.tanggal_dokumen_nota_dinas_permohonan_pengeluaran_bb &&
        (dataDetail?.penerimaan_barbuk_files[0]
          ?.arsip_nota_dinas_permohonan_pengeluaran_bb?.length > 0 ||
          payloadForm.arsip_nota_dinas_permohonan_pengeluaran_bb);
      setOne(one);
      const two =
        payloadForm.nomor_disposisi_pimpinan_karorenmin_kabagtahti &&
        payloadForm.tanggal_dokumen_disposisi_pimpinan_karorenmin_kabagtahti &&
        (dataDetail?.penerimaan_barbuk_files[0]
          ?.arsip_disposisi_pimpinan_karorenmin_kabagtahti?.length > 0 ||
          payloadForm.arsip_disposisi_pimpinan_karorenmin_kabagtahti);
      setTwo(two);
      const three =
        payloadForm.nomor_ba_penitipan_bb &&
        payloadForm.tanggal_dokumen_ba_penitipan_bb &&
        (dataDetail?.penerimaan_barbuk_files[0]?.arsip_ba_penitipan_bb?.length >
          0 ||
          payloadForm.arsip_ba_penitipan_bb);
      setThree(three);
      const four =
        payloadForm.nomor_dokumen_p21_atau_sp3 &&
        payloadForm.tanggal_dokumen_p21_atau_sp3 &&
        (dataDetail?.penerimaan_barbuk_files[0]?.arsip_dokumen_p21_atau_sp3
          ?.length > 0 ||
          payloadForm.arsip_dokumen_p21_atau_sp3);
      setFour(four);
      const five =
        payloadForm.nomor_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2 &&
        payloadForm.tanggal_dokumen_surat_penyerahan_barang_bukti_dan_tersangka_tahap_2 &&
        (dataDetail?.penerimaan_barbuk_files[0]
          ?.arsip_penyerahan_barang_bukti_dan_tersangka_tahap_2?.length > 0 ||
          payloadForm.arsip_penyerahan_barang_bukti_dan_tersangka_tahap_2);
      setFive(five);
      const six =
        payloadForm.nomor_dokumen_hasil_gelar_perkara &&
        payloadForm.tanggal_dokumen_hasil_gelar_perkara &&
        (dataDetail?.penerimaan_barbuk_files[0]
          ?.arsip_dokumen_hasil_gelar_perkara?.length > 0 ||
          payloadForm.arsip_dokumen_hasil_gelar_perkara);
      setSix(six);
    }
  }, [payloadForm]);

  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/barbuk">Barang Bukti</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    to={`/barbuk/${
                      isPemusnahan
                        ? "pemusnahan"
                        : isPengembalian
                        ? "pengembalian"
                        : "pengeluaran"
                    }/list`}
                  >
                    List{" "}
                    {isPemusnahan
                      ? "Pemusnahan"
                      : isPengembalian
                      ? "Pengembalian"
                      : "Pengeluaran"}{" "}
                    Barang Bukti
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form Pengembalian Barang Bukti Pinjam Pakai
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {mutateSubmit.isError && (
                <Alert variant="danger">
                  <ul className="m-0">
                    {mutateSubmit?.error?.response?.data?.detail?.map(
                      (errEdit, idx) => (
                        <li key={idx}>
                          <b>{errEdit.loc[1]} : </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error : </b>
                        {mutateSubmit?.error?.response?.data?.message ??
                          "Bermasalah"}
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateSubmit.isSuccess && (
                <Alert variant="success">
                  {mutateSubmit.data.data.message}
                </Alert>
              )}
            </Col>
            <Col sm="12">
              <Row>
                <Col sm="6">
                  <Row className="g-2">
                    <Col sm="12">
                      <Input
                        name="no_laporan_polisi"
                        label="No Laporan Polisi"
                        required
                        defaultValue={dataDetail.no_laporan_polisi}
                        // onChange={(selectedOption) => {
                        //   setPayloadBarbuk([]);
                        //   payloadForm.barang_bukti_ids = [];
                        //   payloadForm.penyidik_ids = [];
                        //   setPayloadLP({
                        //     ...payloadLP,
                        //     no_laporan_polisi: selectedOption.value,
                        //   });
                        //   setPayloadForm({
                        //     ...payloadForm,
                        //     no_laporan_polisi: selectedOption.value,
                        //   });
                        // }}
                        // isClearable={isClearable}
                        // isSearchable={isSearchable}
                        disabled={!isFormEditAble}
                      />
                    </Col>
                    <Col sm="6">
                      <DatePicker
                        label="Tanggal Pinjam"
                        timeFormat="HH:mm:ss"
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => {
                          setPayloadDate({
                            ...payloadDate,
                            tanggal_penyerahan: date,
                          });
                          setPayloadForm({
                            ...payloadForm,
                            tanggal_penyerahan:
                              moment(date).format("YYYY-MM-DD"),
                          });
                        }}
                        selected={payloadDate.tanggal_penyerahan}
                        maxDate={new Date()}
                        required
                        disabled={!isFormEditAble}
                      />
                    </Col>
                    <Col sm="6">
                      <DatePicker
                        label="Jadwal Kembali"
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => {
                          setPayloadDate({
                            ...payloadDate,
                            jadwal_kembali: date,
                          });
                          setPayloadForm({
                            ...payloadForm,
                            jadwal_kembali: moment(date).format("YYYY-MM-DD"),
                          });
                        }}
                        selected={payloadDate.jadwal_kembali}
                        minDate={payloadDate.tanggal_penyerahan}
                        required
                        disabled={!isFormEditAble}
                      />
                    </Col>
                    <Col sm="12">
                      <Input
                        as="textarea"
                        name="uraian"
                        onChange={handleChange}
                        defaultValue={payloadForm.uraian}
                        label="Alasan/Dasar Pinjam"
                        disabled={!isFormEditAble}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="6"></Col>
              </Row>
              <Row className="mt-2">
                <Col sm="12">
                  <Card className="card bg-white">
                    <Card.Body>
                      <Row className="g-1 mb-2 d-flex align-items-center">
                        <Col sm="12" md="5">
                          <h6>Saksi Pengembalian Pinjam Pakai Barang Bukti</h6>
                        </Col>
                        <Col sm="12" md="7">
                          <Link className="d-flex align-items-center justify-content-end">
                            <Button
                              className="btn btn-primary"
                              onClick={handleAddRow}
                            >
                              Tambah Saksi
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                      <Table className="table dems-table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Nama</th>
                            <th>Pekerjaan</th>
                            <th>Alamat</th>
                            <th>Keterangan</th>
                            <th>No. HP</th>
                            <th>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {saksiData.length
                            ? saksiData.map((data, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{data.nama}</td>
                                  <td>{data.pekerjaan}</td>
                                  <td>{data.alamat}</td>
                                  <td>{data.keterangan}</td>
                                  <td>{data.no_hp}</td>
                                  <td>
                                    <div className="d-flex align-items-center justify-content-end gap-2">
                                      <Badge
                                        onClick={() => handleDelete(index)}
                                        className="btn bg-danger"
                                        as="button"
                                      >
                                        <i className="ri-delete-bin-line"></i>
                                      </Badge>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            : !saksiData.length && (
                                <tr>
                                  <td
                                    colSpan="4"
                                    style={{ textAlign: "center" }}
                                  >
                                    <span>Tidak ada data saksi!</span>
                                  </td>
                                </tr>
                              )}
                          {isAddingRow && (
                            <tr>
                              <td></td>
                              <td>
                                <Input
                                  type="text"
                                  name="nama"
                                  value={saksi.nama}
                                  onChange={handleNewRowChange}
                                  placeholder="Nama"
                                  required={true}
                                  id="addSaksiRow"
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  name="pekerjaan"
                                  value={saksi.pekerjaan}
                                  onChange={handleNewRowChange}
                                  placeholder="Pekerjaan"
                                  required={true}
                                  id="addSaksiRow"
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  name="alamat"
                                  value={saksi.alamat}
                                  onChange={handleNewRowChange}
                                  placeholder="Alamat"
                                  required={true}
                                  id="addSaksiRow"
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  name="keterangan"
                                  value={saksi.keterangan}
                                  onChange={handleNewRowChange}
                                  placeholder="Keterangan"
                                  required={true}
                                  id="addSaksiRow"
                                />
                              </td>
                              <td>
                                <Input
                                  type="number"
                                  name="no_hp"
                                  value={saksi.no_hp}
                                  onChange={handleNewRowChange}
                                  placeholder="No. HP"
                                  required={true}
                                  id="addSaksiRow"
                                />
                              </td>
                              <td className="d-flex gap-1">
                                <Button
                                  className="btn bg-danger"
                                  onClick={closeRow}
                                >
                                  <i className="ri-close-large-line"></i>
                                </Button>
                                <Button
                                  className="btn bg-success"
                                  onClick={handleSaveRow}
                                >
                                  <i className="ri-check-line"></i>
                                </Button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm="12">
                  <Card className="card bg-white">
                    <Card.Body>
                      <Row className="g-1 mb-2 d-flex align-items-center">
                        <Col sm="12" md="5">
                          <h6>Penyidik yang Mengembalikan</h6>
                        </Col>
                        <Col sm="12" md="7">
                          <Link className="d-flex align-items-center justify-content-end"></Link>
                        </Col>
                      </Row>
                      <Table className="table dems-table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>NRP</th>
                            <th>Nama Penyidik</th>
                            <th>Asal Satker</th>
                            <th>Pangkat</th>
                            <th>Jabatan</th>
                            <th>Pilih</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataTransaksi?.penyidik?.map((data, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.nrp}</td>
                              <td>{data.nama_penyidik}</td>
                              <td>{data.nama_satker}</td>
                              <td>{data.pangkat}</td>
                              <td>Penyidik</td>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  checked={payloadForm?.penyidik_ids?.some(
                                    (item) => item === data.id_penyidik
                                  )}
                                  onChange={(e) => {
                                    handleCheckbox(
                                      "penyidik",
                                      data,
                                      e.target.checked
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm="12">
                  <Card className="card bg-white">
                    <Card.Body>
                      <Row className="g-1 mb-2 d-flex align-items-center">
                        <Col sm="12" md="5">
                          <h6>Daftar Barang Bukti</h6>
                        </Col>
                        <Col sm="12" md="7">
                          {/* <Link className="d-flex align-items-center justify-content-end">
                            <Button
                              className="btn btn-primary"
                              onClick={() => setBarbukModalShow(true)}
                              disabled={!isFormEditAble}
                            >
                              PILIH
                            </Button>
                          </Link> */}
                        </Col>
                        <Col sm="12" md="7">
                          <Link className="d-flex align-items-center justify-content-end"></Link>
                        </Col>
                      </Row>
                      <Table className="table dems-table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Jenis</th>
                            <th>Sub Jenis</th>
                            <th>Tipe</th>
                            <th>No. Label</th>
                            <th>Jumlah</th>
                            <th>Unit</th>
                            <th>Keterangan</th>
                            {/* <th>Pilih</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {payloadBarbuk?.map((data, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data?.tipe || "-"}</td>
                              <td>{data?.sub_jenis || "-"}</td>
                              <td>{data?.tipe || "-"}</td>
                              <td>{data?.no_label_barbuk || "-"}</td>
                              <td>{data?.jumlah_penerimaan || "-"}</td>
                              <td>{data?.unit || "-"}</td>
                              <td>{data?.keterangan || "-"}</td>
                              {/* <td>
                                <Badge
                                  onClick={() => {
                                    setPayloadBarbuk(
                                      payloadBarbuk.filter(
                                        (_, idx) => idx !== index
                                      )
                                    );
                                  }}
                                  className="btn bg-danger"
                                  as="button"
                                  disabled={!isFormEditAble}
                                >
                                  <i className="ri-delete-bin-line"></i>
                                </Badge>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm="6">
                  <DatePicker
                    label="Tanggal Kembali"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      setPayloadDate({
                        ...payloadDate,
                        tanggal_kembali: date,
                      });
                      setPayloadForm({
                        ...payloadForm,
                        tanggal_kembali: moment(date).format("YYYY-MM-DD"),
                      });
                    }}
                    selected={payloadDate.tanggal_kembali}
                    minDate={payloadDate.tanggal_penyerahan}
                    required
                  />
                </Col>
                <Col sm="6">
                  <Input
                    as="textarea"
                    onChange={handleChange}
                    value={payloadForm.keterangan}
                    col="4"
                    name="keterangan"
                    label="Keterangan"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          {!mutateSubmit.isLoading || !mutateSubmit.isLoading ? (
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Link
                to={`/barbuk/${
                  isPemusnahan
                    ? "pemusnahan"
                    : isPengembalian
                    ? "pengembalian"
                    : "pengeluaran"
                }/list`}
              >
                <Button variant="warning">BATAL</Button>
              </Link>
              {/* <Button variant="secondary" onClick={() => handleSubmit("DRAFT")}>
                SIMPAN DRAFT
              </Button> */}
              <Button variant="primary" onClick={() => handleSubmit("FINAL")}>
                SIMPAN
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Link>
                <Button variant="warning" disabled>
                  BATAL
                </Button>
              </Link>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                disabled
              >
                Sedang Menyimpan <Spinner variant="light" size="sm" />
              </Button>
            </div>
          )}
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default Pengembalian;
