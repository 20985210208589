import React from 'react'
import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';

function DatePicker(props) {
  return (
    <React.Fragment>
      <div className="d-flex flex-column">
        {
          props.label ? (
            <Form.Label className="form-label fs-sm" style={{marginBottom: '6px'}}>
              {
                props.required && (
                  <span style={{color: 'red'}}>* </span>
                )
              }
              {props.label}
            </Form.Label>
          ) : ''
        }
        <ReactDatePicker
          {...props}
          showMonthDropdown
          showYearDropdown
          autoComplete='off'
          className='form-control w-100'
        />
      </div>
    </React.Fragment>
  )
}

export default DatePicker