import React, { useLayoutEffect, useRef } from 'react'
import { Card } from 'react-bootstrap'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function ChartDashBarbuk(props) {
  const chartRef = useRef(null)
  useLayoutEffect(() => {
    let root = am5.Root.new(chartRef.current);
    root.setThemes([
        am5themes_Animated.new(chartRef.current)
    ])
    root._logo.setAll({
        height: -100000000  // Adjust the height as needed
    });

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        paddingLeft: 0,
        paddingRight: 0,
        layout: root.verticalLayout
    }))

    chart.set("wheelY", "none");
    // const data = props?.dataSeries ?? []
    var data = props?.dataChart ?? []

    // Create X Axis (Category Axis)
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "jenis",
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 30,  // Adjust to give space between labels
        }),
      })
    );

    // Rotate and truncate X-axis labels
    xAxis.get("renderer").labels.template.setAll({
      rotation: -45, // Miringkan label sebesar -45 derajat
      centerY: am5.p50,
      centerX: am5.p100,
      paddingTop: 7,
      fontSize: 10,
      maxWidth: 100,  // Maximum width before truncating
      oversizedBehavior: "truncate",
      textAlign: "center",
    });
    xAxis.data.setAll(data);

    // Create Y Axis (Value Axis)
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );
    
    
    // Dynamically create series based on keys
    const createSeries = (field, name) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name,
          stacked: true,
          xAxis,
          yAxis,
          valueYField: field,
          categoryXField: "jenis",
        })
      );

      series.columns.template.setAll({
        tooltipText: "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}",
      });

      series.data.setAll(data);
    };

    // Extract all unique keys from the objects (except 'jenis')
    const keys = Array.from(
      new Set(data.flatMap((item) => Object.keys(item).filter((key) => key !== "jenis")))
    );

    // Create series for each key
    keys.forEach((key) => {
      createSeries(key, key);
    });

    return () => {
        root.dispose();
    };
  }, [props.dataChart])
  return (
    <Card className='card-one bg-white'>
      <Card.Body className='d-flex flex-column gap-2'>
        <Card.Text as="label" style={{paddingLeft: 0}} className='fs-5 fw-bold'>{props?.title ?? ''}</Card.Text>
        <div className="chart-box" ref={chartRef}>
        </div>
      </Card.Body>
    </Card>
  )
}

export default ChartDashBarbuk