import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { MyPagination } from "../../components/Components";
import {
  useGetNotification,
  useDeleteNotification,
} from "../../utils/apis/query";
import Alert from "../../libraries/Alert";
import State from "../../libraries/State";

function List() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const state = State.getState();
  const navigate = useNavigate();
  const mutateDelete = useDeleteNotification();
  const [payloadDebounced, setPayloadDebounced] = useState({
    search: "",
    status_tahanan: "",
    nama_satker: "",
    perkara: "",
    page: 1,
    size: 10,
  });

  const {
    data: dataList,
    pagination,
    isFetching: dataFetching,
    isError: dataIsError,
    error: dataError,
  } = useGetNotification(payloadDebounced);

  const handleDelete = (id) => {
    Alert.showConfirm("warning", "Hapus").then(({ isConfirmed }) => {
      if (isConfirmed) {
        mutateDelete.mutate(id);
        state.flush();
      }
    });
  };

  const handlePagination = (page) =>
    setPayloadDebounced({ ...payloadDebounced, page: page });

  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Header>
          <Card.Title as="label" className="fs-5 fw-medium">
            Notifications
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-2">
          <Row>
            {dataList.length && !dataFetching && !dataIsError
              ? dataList.map((data, index) => {
                  let link = "";
                  if (data.menu === "tahanan") {
                    link = "/tahanan/form/";
                  } else if (data.menu === "tahanan_lapas") {
                    link = "/tahanan/lapas/form/";
                  } else if (data.menu === "bon_tahanan") {
                    link = "/tahanan/bon/form/";
                  } else if (data.menu === "perpanjangan") {
                    link = "/tahanan/perpanjangan/edit/";
                  } else if (data.menu === "pengeluaran") {
                    link = "/tahanan/pengeluaran/edit/";
                  } else if (data.menu === "penerimaan") {
                    link = "/barbuk/penerimaan/form/";
                  } else if (data.menu === "penyerahan") {
                    link = "/barbuk/pengeluaran/edit/";
                  } else if (data.menu === "pemusnahan") {
                    link = "/barbuk/pemusnahan/edit/";
                  } else if (data.menu === "pengembalian") {
                    link = "/barbuk/pengembalian/edit/";
                  } else {
                    link = "";
                  }
                  return (
                    <Col sm="12" key={index}>
                      <div className="d-flex border-bottom p-3">
                        <div
                          className="d-flex align-items-center"
                          style={{ flex: "1" }}
                        >
                          <Badge
                            onClick={() => handleDelete(data.id)}
                            className="btn bg-danger"
                            as="button"
                          >
                            <i className="ri-close-large-fill"></i>
                          </Badge>
                        </div>
                        <div
                          className="d-flex align-items-center justify-content-start gap-3"
                          style={{ flex: "2" }}
                        >
                          <div className="d-flex align-items-center me-3">
                            <i
                              className="ri-user-line"
                              style={{ fontSize: "1.5rem" }}
                            ></i>
                          </div>
                          <Link
                            to={link + data.reff_id}
                            style={{
                              color: hoveredIndex === index ? "grey" : "black",
                              textDecoration: "none",
                            }}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                          >
                            <div className="d-flex flex-column">
                              <span>
                                <strong>
                                  {data.nama_lengkap} ({data.satker})
                                </strong>{" "}
                                telah membuat <strong>{data.menu}</strong> atas
                                :
                              </span>
                              <span>No. Tahanan : {data.no_tahanan}</span>
                              <span>Nama Tahanan : {data.nama_tersangka}</span>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="d-flex align-items-center justify-content-end"
                          style={{ flex: "1" }}
                        >
                          <span>
                            {new Date(data.created_date).toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </Col>
                  );
                })
              : !dataFetching &&
                !dataList.length &&
                !dataIsError && (
                  <div className="d-flex justify-content-center">
                    <span>Tidak ada notifikasi!</span>
                  </div>
                )}

            {/* {dataList.length && !dataFetching && !dataIsError
              ? dataList.map((data, index) => (
                  <Col sm="12">
                    <h1>{data.nama_lengkap}</h1>
                  </Col>
                ))
              : !dataFetching &&
                !dataList.length &&
                !dataIsError && (
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>
                      <span>Tidak ada notifikasi!</span>
                    </td>
                  </tr>
                )} */}
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <MyPagination
            currentPage={payloadDebounced.page}
            totalPages={pagination.total_pages}
            onPageChange={handlePagination}
          />
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default List;
