import React, { useCallback, useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconPicker, Input, Select } from "../../../components/Components";
import {
  useGetSatkerDetail,
  usePostSatkers,
  usePutSatkers,
  useGetPoldaList,
} from "../../../utils/apis/query";

function Form() {
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isPolres, setPolres] = useState(false);
  const [polda, setPolda] = useState([]);
  const [payloadDebounced, setPayloadDebounced] = useState({
    nama_satker: "",
    page: 1,
    size: 10,
  });
  const [satker, setSatker] = useState({
    type: "",
    level: "",
    nama_satker: "",
    nama_tahti: "",
    provinsi: "",
    kota: "",
    telpon: "",
    keterangan: "",
    nama_rutan: "",
    kapasitas_rutan: "",
    nama_satker_polda: "",
    kode: "",
    alamat_rutan: "",
  });
  const onSuccess = () => {
    setTimeout(() => {
      navigate("/satker");
    }, 1500);
  };
  const mutateAdd = usePostSatkers(onSuccess);
  const mutateEdit = usePutSatkers(onSuccess);
  const {
    data: detailData,
    isFetching: detailIsFetching,
    isError: detailIsError,
    error: detailError,
  } = useGetSatkerDetail(id, { enabled: !!id });

  const {
    data: listPolda,
    isFetching: poldaIsFetching,
    isError: poldaIsError,
    error: poldaError,
  } = useGetPoldaList();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "type") {
      let level = "";

      if (value === "POLRI") {
        level = "1";
      } else if (value === "POLDA") {
        level = "2";
      } else if (value === "POLRES") {
        level = "3";
      }

      setSatker((prevSatker) => ({
        ...prevSatker,
        type: value,
        level: level,
      }));
    } else {
      setSatker((prevSatker) => ({
        ...prevSatker,
        [name]: value,
      }));
    }
  };
  const handleSubmit = () => {
    const updatedSatker = {
      ...satker,
      nama_satker_polda: isPolres ? satker.nama_satker_polda : "", // Set to empty string if not Polres
    };

    if (isEdit) {
      mutateEdit.mutate({ id, satker: updatedSatker });
    } else {
      mutateAdd.mutate(updatedSatker);
    }
  };

  useEffect(() => {
    setIsEdit(false);
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  useEffect(() => {
    if (detailData) {
      setSatker({
        ...satker,
        kode: detailData.kode,
        nama_satker: detailData.nama_satker,
        provinsi: detailData.provinsi,
        kota: detailData.kota,
        telpon: detailData.telpon,
        keterangan: detailData.keterangan,
        type: detailData.type,
        level: detailData.level,
        nama_tahti: detailData.nama_tahti,
        nama_rutan: detailData.nama_rutan,
        kapasitas_rutan: detailData.kapasitas_rutan,
        nama_satker_polda: detailData.nama_satker_polda,
        alamat_rutan: detailData.alamat_rutan,
      });
    }
  }, [detailData]);

  useEffect(() => {
    return () => {
      setSatker({
        type: "",
        level: "",
        nama_satker: "",
        nama_tahti: "",
        provinsi: "",
        kota: "",
        telpon: "",
        keterangan: "",
        nama_rutan: "",
        kapasitas_rutan: "",
        nama_satker_polda: "",
        kode: "",
        alamat_rutan: "",
      });
    };
  }, []);

  function getKotaByProvinsi(selectedProvinsi) {
    let provinsi = selectedProvinsi.replace(/ /g, "_");
    const cities = kotaByProvinsi[provinsi] || [];
    return cities.map((city) => ({ value: city, text: city }));
  }

  const provinsi = [
    "Bangka Belitung",
    "Lampung",
    "Jambi",
    "Kepulauan Riau",
    "Riau",
    "Bengkulu",
    "Sumatra Barat",
    "Sumatra Selatan",
    "Sumatra Utara",
    "Nanggroe Aceh Darussalam",
    "Kalimantan Barat",
    "Kalimantan Selatan",
    "Kalimantan Utara",
    "Kalimatan Timur",
    "Kalimantan Tengah",
    "Banten",
    "Jawa Barat",
    "Jawa Timur",
    "Jawa Tengah",
    "Daerah Istimewa Yogyakarta",
    "DK Jakarta",
    "Nusa Tenggara Barat",
    "Nusa Tenggara Timur",
    "Bali",
    "Sulawesi Selatan",
    "Sulawesi Utara",
    "Sulawesi Barat",
    "Gorontalo",
    "Sulawesi Tengah",
    "Sulawesi Tenggara",
    "Papua",
    "Papua Barat",
    "Maluku",
    "Maluku Utara",
  ];
  const provinsiOptions = provinsi.map((rank) => ({ value: rank, text: rank }));
  const kotaByProvinsi = {
    Nanggroe_Aceh_Darussalam: [
      "BANDA ACEH",
      "ACEH BESAR",
      "PIDIE",
      "LHOKSEUMAWE",
      "LANGSA",
      "ACEH BARAT",
      "ACEH SELATAN",
      "ACEH TENGAH",
      "ACEH TENGGARA",
      "SABANG",
      "BIREUEN",
      "ACEH SINGKIL",
      "SIMEULUE",
      "ACEH TAMIANG",
      "GAYO LUES",
      "ACEH UTARA",
      "ACEH TIMUR",
      "NAGAN RAYA",
      "ACEH BARAT DAYA",
      "BENER MERIH",
      "ACEH JAYA",
      "SUBULUSSALAM",
      "PIDIE JAYA",
    ],
    Bali: [
      "DENPASAR",
      "BULELENG",
      "TABANAN",
      "GIANYAR",
      "KLUNGKUNG",
      "BANGLI",
      "KARANG ASEM",
      "JEMBRANA",
      "BADUNG",
    ],
    Banten: [
      "SERANG",
      "PANDEGLANG",
      "LEBAK",
      "CILEGON",
      "SERANG KOTA",
      "TANGERANG",
    ],
    Bengkulu: [
      "BENGKULU",
      "BENGKULU UTARA",
      "REJANG LEBONG",
      "BENGKULU SELATAN",
      "MUKO-MUKO",
      "SELUMA",
      "KAUR",
      "KEPAHIANG",
      "LEBONG",
      "BENGKULU TENGAH",
    ],
    Daerah_Istimewa_Yogyakarta: [
      "YOGYAKARTA",
      "BANTUL",
      "KULON PROGO",
      "GUNUNG KIDUL",
      "SLEMAN",
    ],
    Gorontalo: [
      "GORONTALO KOTA",
      "GORONTALO",
      "POHUWATO",
      "BOALEMO",
      "BONEBOLANGO",
      "GORONTALO UTARA",
    ],
    Jambi: [
      "JAMBI",
      "BATANGHARI",
      "BUNGO",
      "KERINCI",
      "TANJUNG JABUNG BARAT",
      "TANJUNG JABUNG TIMUR",
      "MERANGIN",
      "TEBO",
      "SAROLANGUN",
      "MUARO JAMBI",
    ],
    Jawa_Barat: [
      "BANDUNG",
      "BOGOR KOTA",
      "BOGOR",
      "SUKABUMI KOTA",
      "CIANJUR",
      "PURWAKARTA",
      "KARAWANG",
      "SUBANG",
      "CIMAHI",
      "SUMEDANG",
      "GARUT",
      "TASIKMALAYA KOTA",
      "CIAMIS",
      "CIREBON",
      "CIREBON",
      "INDRAMAYU",
      "MAJALENGKA",
      "KUNINGAN",
      "SUKABUMI",
      "BANDUNG",
      "TASIKMALAYA",
      "BANJAR",
    ],
    Jawa_Timur: [
      "SURABAYA",
      "MALANG KOTA",
      "MALANG",
      "PROBOLINGGO",
      "PASURUAN",
      "LUMAJANG",
      "BONDOWOSO",
      "SITUBONDO",
      "JEMBER",
      "BANYUWANGI",
      "KEDIRI KOTA",
      "KEDIRI",
      "BLITAR KOTA",
      "TULUNG AGUNG",
      "NGANJUK",
      "TRENGGALEK",
      "MADIUN KOTA",
      "MADIUN",
      "NGAWI",
      "MAGETAN",
      "PONOROGO",
      "PACITAN",
      "BOJONEGORO",
      "TUBAN",
      "LAMONGAN",
      "PAMEKASAN",
      "BANGKALAN",
      "SAMPANG",
      "SUMENEP",
      "GRESIK",
      "SIDOARJO",
      "MOJOKERTO",
      "JOMBANG",
      "PELABUHAN TANJUNG PERAK",
      "BATU",
      "PROBOLINGGO KOTA",
      "BLITAR",
      "PASURUAN KOTA",
      "MOJOKERTO KOTA",
    ],
    Kalimantan_Barat: [
      "PONTIANAK_KOTA",
      "MEMPAWAH",
      "SAMBAS",
      "SANGGAU",
      "SINTANG",
      "KAPUAS_HULU",
      "KETAPANG",
      "LANDAK",
      "BENGKAYANG",
      "SINGKAWANG",
      "SEKADAU",
      "MELAWI",
      "KAYONG_UTARA",
      "KUBU_RAYA",
    ],
    Kalimantan_Selatan: [
      "BANJARMASIN",
      "BANJARBARU",
      "BANJAR",
      "HULU_SUNGAI_SELATAN",
      "HULU_SUNGAI_TENGAH",
      "HULU_SUNGAI_UTARA",
      "TANAH_LAUT",
      "TABALONG",
      "TAPIN",
      "KOTA_BARU",
      "BARITO_KUALA",
      "TANAH_BUMBU",
      "BALANGAN",
    ],
    Kalimantan_Tengah: [
      "PALANGKARAYA",
      "KAPUAS",
      "BARITO_SELATAN",
      "BARITO_UTARA",
      "KOTIM",
      "KOBAR",
      "BARITO_TIMUR",
      "MURUNG_RAYA",
      "KATINGAN",
      "SERUYAN",
      "GUNUNG_MAS",
      "SUKAMARA",
      "LAMANDAU",
      "PULANG_PISAU",
    ],
    Kalimantan_Timur: [
      "BALIKPAPAN",
      "SAMARINDA",
      "BERAU",
      "KUTAI",
      "PASIR",
      "BONTANG",
      "KUTAI_TIMUR",
      "KUTAI_BARAT",
      "PENAJAM_PASER_UTARA",
    ],
    Kalimantan_Utara: ["BULUNGAN", "TARAKAN", "MALINAU", "NUNUKAN"],
    Bangka_Belitung: [
      "PANGKAL_PINANG",
      "BANGKA",
      "BELITUNG",
      "BELITUNG_TIMUR",
      "BANGKA_SELATAN",
      "BANGKA_TENGAH",
      "BANGKA_BARAT",
    ],
    Kepulauan_Riau: [
      "BARELANG",
      "TANJUNG_PINANG",
      "KARIMUN",
      "NATUNA",
      "LINGGA",
      "BINTAN",
    ],
    Lampung: [
      "BANDAR_LAMPUNG",
      "LAMPUNG_SELATAN",
      "METRO",
      "LAMPUNG_UTARA",
      "LAMPUNG_BARAT",
      "TULANG_BAWANG",
      "TANGGAMUS",
      "LAMPUNG_TIMUR",
      "WAY_KANAN",
      "LAMPUNG_TENGAH",
      "MESUJI",
      "PESAWARAN",
      "PRINGSEWU",
      "TULANG_BAWANG_BARAT",
    ],
    Maluku: [
      "AMBON",
      "MALUKU_TENGGARA",
      "MALUKU_TENGAH",
      "PULAU_BURU",
      "MALUKU_TENGGARA_BARAT",
      "PULAU_ARU",
      "SERAM_BAGIAN_TIMUR",
      "SERAM_BAGIAN_BARAT",
      "MALUKU_BARAT_DAYA",
    ],
    Maluku_Utara: [
      "TERNATE",
      "TIDORE",
      "HALMAHERA_UTARA",
      "HALMAHERA_BARAT",
      "HALMAHERA_SELATAN",
      "KEPULAUAN_SULA",
      "HALMAHERA_TENGAH",
      "HALMAHERA_TIMUR",
      "PULAU_MOROTAI",
    ],
    DK_Jakarta: [
      "METRO_JAKARTA_PUSAT",
      "METRO_JAKARTA_UTARA",
      "METRO_JAKARTA_SELATAN",
      "METRO_JAKARTA_BARAT",
      "METRO_JAKARTA_TIMUR",
      "METRO_TANGERANG_KOTA",
      "BANDARA_SUKARNO_HATTA",
      "METRO_BEKASI_KOTA",
      "METRO_BEKASI",
      "METROPOLITAN_DEPOK",
      "PELABUHAN_TANJUNG_PRIOK",
      "KEPULAUAN_SERIBU",
      "TANGERANG_SELATAN",
    ],
    Nusa_Tenggara_Barat: [
      "MATARAM",
      "LOMBOK_TENGAH",
      "LOMBOK_TIMUR",
      "SUMBAWA",
      "DOMPU",
      "BIMA_KOTA",
      "LOMBOK_BARAT",
      "BIMA",
      "SUMBAWA_BARAT",
      "LOMBOK_UTARA",
    ],
    Nusa_Tenggara_Timur: [
      "KUPANG_KOTA",
      "KUPANG",
      "TIMUR_TENGAH_SELATAN",
      "TIMUR_TENGAH_UTARA",
      "BELU",
      "SUMBA_TIMUR",
      "SUMBA_BARAT",
      "ENDE",
      "SIKKA",
      "FLORES_TIMUR",
      "NGADA",
      "MANGGARAI",
      "ALOR",
      "LEMBATA",
      "MANGGARAI_BARAT",
      "ROTE_NDAO",
      "MALAKA",
      "MANGGARAI_TIMUR",
      "NAGEKEO",
      "SABU_RAIJUA",
      "SUMBA_BARAT_DAYA",
    ],
    Papua: [
      "BIAK_NUMFOR",
      "MERAUKE",
      "PANIAI",
      "KEPULAUAN_YAPEN",
      "MIMIKA",
      "JAYAPURA",
      "PUNCAK_JAYA",
      "SARMI",
      "BOVEN_DIGOEL",
      "KEROM",
      "WAROPEN",
      "YAHUKIMO",
      "ASMAT",
      "MAPPI",
      "PEGUNUNGAN_BINTANG",
      "TOLIKARA",
      "SUPIORI",
      "LANNY_JAYA",
      "MAMBERAMO_RAYA",
      "MAMBERAMO_TENGAH",
      "NDUGA",
      "YALIMO",
      "PUNCAK",
      "INTAN_JAYA",
      "DEIYAI",
    ],
    Papua_Barat: [
      "SORONG_KOTA",
      "SORONG",
      "SORONG_SELATAN",
      "MANOKWARI",
      "RAJA_AMPAT",
      "FAK-FAK",
      "KAIMANA",
      "TELUK_BINTUNI",
      "TELUK_WONDANA",
      "MANOKWARI_SELATAN",
    ],
    Riau: [
      "PEKANBARU",
      "INDRAGIRI_HULU",
      "DUMAI",
      "KAMPAR",
      "INDRAGIRI_HILIR",
      "BENGKALIS",
      "PELALAWAN",
      "ROKAN_HILIR",
      "ROKAN_HULU",
      "SIAK",
      "KUANTAN_SINGINGI",
    ],
    Sulawesi_Barat: [
      "MAMUJU",
      "MAJENE",
      "POLEWALI_MANDAR",
      "MAMASA",
      "MAMUJU_UTARA",
      "MAMUJU_TENGAH",
    ],
    Sulawesi_Selatan: [
      "MAKASSAR",
      "GOWA",
      "TAKALAR",
      "MAROS",
      "PANGKEP",
      "BANTAENG",
      "JENEPONTO",
      "BULUKUMBA",
      "KEPULAUAN_SELAYAR",
      "PARE-PARE",
      "BARRU",
      "SIDRAP",
      "PINRANG",
      "LUWU",
      "LUWU_UTARA",
      "TANAH_TORAJA",
      "ENREKANG",
      "BONE",
      "WAJO",
      "SOPPENG",
      "SINJAI",
      "PELABUHAN_MAKASSAR",
      "PALOPO",
      "LUWU_TIMUR",
      "TORAJA_UTARA",
    ],
    Sulawesi_Tengah: [
      "DONGGALA",
      "TOLI-TOLI",
      "POSO",
      "BANGGAI",
      "PALU",
      "MOROWALI_UTARA",
      "BUOL",
      "PARIGI_MOUTONG",
      "BANGGAI_KEPULAUAN",
      "TOJO_UNA-UNA",
      "SIGI",
      "MOROWALI",
    ],
    Sulawesi_Tenggara: [
      "KONAWE",
      "KOLAKA",
      "BAU-BAU",
      "MUNA",
      "KENDARI",
      "BUTON",
      "WAKATOBI",
      "BOMBANA",
      "KOLAKA_UTARA",
      "KONAWE_SELATAN",
      "BUTON_UTARA",
      "KONAWE_UTARA",
    ],
    Sulawesi_Utara: [
      "MANADO",
      "MINAHASA",
      "BITUNG",
      "KEPULAUAN_SANGIHE",
      "KOTAMOBAGU",
      "KEPULAUAN_TALAUD",
      "MINAHASA_UTARA",
      "MINAHASA_SELATAN",
      "TOMOHON",
      "BOLAANG_MONGONDOW",
      "BOLAANG_MONGONDOW_UTARA",
      "BOLAANG_MONGONDOW_SELATAN",
      "BOLAANG_MONGONDOW_TIMUR",
      "MINAHASA_TENGGARA",
      "KEPULAUAN_SIAU_TAGULANDANG_BIARO",
    ],
    Sumatra_Barat: [
      "PADANG",
      "PESISIR_SELATAN",
      "PASAMAN",
      "PAYAKUMBUH",
      "BUKIT_TINGGI",
      "PADANG_PARIKAMAN",
      "TANAH_DATAR",
      "SAWAH_LUNTO_SIJUNJUNG",
      "SOLOK",
      "AGAM",
      "KEPULAUAN_MENTAWAI",
      "DAMASRAYA",
      "SOLOK_KOTA",
      "SOLOK_SELATAN",
      "PADANG_PANJANG",
      "PASAMAN_BARAT",
      "SAWAH_LUNTO",
      "50_KOTA",
      "PARIAMAN",
    ],
    Sumatra_Selatan: [
      "PALEMBANG",
      "MUSI_BANYUASIN",
      "OGAN_KOMERING_ILIR",
      "MUARA_ENIM",
      "LAHAT",
      "OGAN_KOMERING_ULU",
      "LUBUK_LINGGAU",
      "PAGAR_ALAM",
      "BANYUASIN",
      "PRABUMULIH",
      "MUSIRAWAS",
      "OGAN_KOMERING_ULU_TIMUR",
      "OGAN_KOMERING_ULU_SELATAN",
      "OGAN_ILIR",
      "EMPAT_LAWANG",
      "PENUKAL_ABAB_LEMATANG_ILIR",
      "MUSI_RAWAS_UTARA",
    ],
    Sumatra_utara: [
      "MEDAN",
      "TEBING_TINGGI",
      "BINJAI",
      "DELI_SERDANG",
      "LANGKAT",
      "MANDAILING_NATAL",
      "TANAH_KARO",
      "SIMALUNGUN",
      "ASAHAN",
      "LABUHAN_BATU",
      "TAPANULI_UTARA",
      "DAIRI",
      "TAPANULI_SELATAN",
      "TAPANULI_TENGAH",
      "NIAS",
      "PELABUHAN_BELAWAN",
      "TOBA_SAMOSIR",
      "TANJUNG_BALAI",
      "HUMBANG_HASUNDUTAN",
      "SAMOSIR",
      "PEMATANG_SIANTAR",
      "PAK_PAK_BARAT",
      "NIAS_SELATAN",
      "SERDANG_BEDAGAI",
      "PADANG_SIDEMPUAN",
      "SIBOLGA",
      "BATU_BARA",
      "PADANG_LAWAS",
    ],
  };
  const kotaOptions = getKotaByProvinsi(satker.provinsi);

  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/satker">Satker</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/satker">List Satker</Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form {isEdit ? "Edit" : "Tambah"} Satker
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {mutateAdd.isError && (
                <Alert variant="danger">
                  <ul>
                    {mutateAdd?.error?.response?.data?.detail?.map(
                      (errEdit) => (
                        <li>
                          <b>{errEdit.loc[1]} </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error</b>
                        {mutateAdd?.error?.response?.data?.message ??
                          " Tambah Gagal"}
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateAdd.isSuccess && (
                <Alert variant="success">{mutateAdd.data.data.message}</Alert>
              )}
            </Col>
            <Col sm="12">
              {mutateEdit.isError && (
                <Alert variant="danger">
                  <ul>
                    {mutateEdit?.error?.response?.data?.detail?.map(
                      (errEdit) => (
                        <li>
                          <b>{errEdit.loc[1]} </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error</b>
                        {mutateEdit?.error?.response?.data?.message ??
                          " Update Gagal"}
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateEdit.isSuccess && (
                <Alert variant="success">{mutateEdit.data.data.message}</Alert>
              )}
            </Col>
            <Col sm={isPolres ? "4" : "6"}>
              <Select
                label="Tipe Satker"
                name="type"
                value={satker.type}
                onChange={(e) => {
                  const { name, value } = e.target;
                  handleChange(e);
                  if (value === "POLRES") {
                    setPolres(true);
                  } else {
                    setPolres(false);
                    setSatker((prevSatker) => ({
                      ...prevSatker,
                      nama_satker_polda: "",
                    }));
                  }
                }}
                required
                disabled={isEdit}
                options={[
                  { value: "POLRI", text: "POLRI" },
                  {
                    value: "POLDA",
                    text: "POLDA",
                  },
                  {
                    value: "POLRES",
                    text: "POLRES",
                  },
                ]}
              />
            </Col>
            {isPolres && (
              <Col sm="4">
                <Select
                  label="Satker Polda"
                  name="nama_satker_polda"
                  value={satker.nama_satker_polda}
                  onChange={handleChange}
                  options={listPolda}
                  required
                />
              </Col>
            )}
            <Col sm={isPolres ? "4" : "6"}>
              <Input
                label="Nama Satker"
                name="nama_satker"
                value={satker.nama_satker}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="3">
              <Input label="Level" name="level" value={satker.level} disabled />
            </Col>
            <Col sm="3">
              <Input
                label="Kode"
                name="kode"
                placeholder="Generated by system.."
                disabled
              />
            </Col>
            <Col sm="6">
              <Input
                label="Nama Tahti"
                name="nama_tahti"
                value={satker.nama_tahti}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="6">
              <Select
                label="Provinsi"
                name="provinsi"
                value={satker.provinsi}
                onChange={handleChange}
                options={provinsiOptions}
                required
              />
            </Col>
            <Col sm="6">
              <Select
                label="Kota"
                name="kota"
                value={satker.kota}
                onChange={handleChange}
                options={kotaOptions}
                required
              />
            </Col>
            <Col sm="6">
              <Input
                label="Telepon"
                name="telpon"
                value={satker.telpon}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="6">
              <Input
                label="Nama Rutan"
                name="nama_rutan"
                value={satker.nama_rutan}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="6">
              <Input
                type="number"
                label="Kapasitas"
                name="kapasitas_rutan"
                value={satker.kapasitas_rutan}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="6">
              <Input
                as="textarea"
                label="Alamat Rutan"
                name="alamat_rutan"
                value={satker.alamat_rutan}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="6">
              <Input
                label="Keterangan"
                name="keterangan"
                value={satker.keterangan}
                onChange={handleChange}
                required
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center">
            {!mutateAdd.isLoading || !mutateEdit.isLoading ? (
              <Button variant="primary" onClick={handleSubmit}>
                Simpan
              </Button>
            ) : (
              <Button variant="primary" disabled>
                Loading
              </Button>
            )}
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default Form;
