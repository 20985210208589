import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form, Alert } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  DatePicker,
  Input,
  Select,
  Select2,
} from "../../../components/Components";
import {
  kesatuan,
  pangkat,
  roles,
  superadmin,
  admintahti,
} from "../../../utils";
import {
  useGetSatkerTypeList,
  useGetUserDetail,
  usePutUsers,
  useSatkerTahtiList,
} from "../../../utils/apis/query";
import moment from "moment";
import { AcroFormPasswordField } from "jspdf";
import State from "../../../libraries/State";

function FormEdit() {
  const state = State.getState();
  const userData = state.getItem("profileData", true);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [formDate, setFormDate] = useState({
    awal_masa_aktif: "",
    akhir_masa_aktif: "",
  });
  const [formState, setFormState] = useState({
    username: "",
    password: "",
    role: "",
    nama_lengkap: "",
    nrp: "",
    jabatan: "",
    handphone: "",
    email: "",
    pangkat: "",
    nama_satker: "",
    nama_satker_tahti: "",
    // tipe_satker: "",
    tipe_akun: "",
    is_active: true,
    awal_masa_aktif: "",
    akhir_masa_aktif: "",
    tipe_satker: "POLRI",
  });

  const {
    data: dataDetail,
    isFetching: isDataDetailFetching,
    isError: isDataDetailError,
    error: dataDetailError,
  } = useGetUserDetail(id);

  const { data: dataSatkersType } = useGetSatkerTypeList(
    {
      type: formState.tipe_satker,
    },
    {
      enabled: !!formState.tipe_satker,
    }
  );
  dataSatkersType.map((e) => {
    e.value = e.nama_satker;
    e.label = e.nama_satker;
    return e;
  });

  const { data: dataSatkersAll } = useSatkerTahtiList();
  dataSatkersAll.map((e) => {
    e.value = e.nama_satker;
    e.label = e.nama_satker;
    return e;
  });

  const onSuccess = (response) => {
    navigate("/pengaturan/pengguna/list");
  };
  const formMutate = usePutUsers(onSuccess);

  const handleChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value ?? "";
    if (name === "tipe_akun") {
      setFormDate({ ...formDate, awal_masa_aktif: "", akhir_masa_aktif: "" });
    }
    setFormState({ ...formState, [name]: value });
  };
  const handleSubmit = (e) => {
    let payloadForm = {
      ...formState,
      awal_masa_aktif:
        formDate.awal_masa_aktif &&
        moment(formDate.awal_masa_aktif).format("YYYY-MM-DD"),
      akhir_masa_aktif:
        formDate.akhir_masa_aktif &&
        moment(formDate.akhir_masa_aktif).format("YYYY-MM-DD"),
    };
    formMutate.mutate({ id, payloadForm });
  };

  useEffect(() => {
    if (dataDetail) {
      const detail = dataDetail;
      setFormState({
        username: detail.user.username ?? "",
        role: detail.role.role ?? "",
        nama_lengkap: detail.profile.nama_lengkap ?? "",
        nrp: detail.profile.nrp ?? "",
        jabatan: detail.profile.jabatan ?? "",
        handphone: detail.profile.no_handphone ?? "",
        email: detail.profile.email ?? "",
        pangkat: detail.profile.pangkat ?? "",
        nama_satker: detail.profile.nama_satker ?? "",
        nama_satker_tahti: detail.profile.nama_satker_tahti ?? "",
        tipe_satker: detail?.profile?.level_satker ?? "",
        tipe_akun: detail.profile?.tipe_akun ?? "",
        is_active: detail.user.is_active ?? "",
        awal_masa_aktif: detail.profile.awal_masa_aktif ?? "",
        akhir_masa_aktif: detail.profile.akhir_masa_aktif ?? "",
      });
      setFormDate({
        ...formDate,
        awal_masa_aktif: detail.profile?.awal_masa_aktif
          ? moment(detail.profile?.awal_masa_aktif).toDate()
          : null,
        akhir_masa_aktif: detail.profile?.akhir_masa_aktif
          ? moment(detail.profile?.akhir_masa_aktif).toDate()
          : null,
      });
    }
  }, [dataDetail]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/pengaturan">Pengaturan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/pengaturan/pengguna/list">List Akun Pengguna</Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form Tambah Pengguna
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {formMutate.isError && (
                <Alert variant="danger">
                  <ul className="m-0">
                    {formMutate?.error?.response?.data?.detail?.map(
                      (errEdit, idx) => (
                        <li key={idx}>
                          <b>{errEdit.loc[1]} : </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error : </b>
                        {formMutate?.error?.response?.data?.message ??
                          "Bermasalah"}
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {formMutate.isSuccess && (
                <Alert variant="success">{formMutate.data.data.message}</Alert>
              )}
            </Col>
            <Col sm="12">
              <Row className="g-2">
                <Col sm="3">
                  <Input
                    label="Username"
                    name="username"
                    onChange={handleChange}
                    defaultValue={formState.username}
                    required
                  />
                </Col>
                <Col sm="3">
                  <Input
                    label="Nama Lengkap"
                    name="nama_lengkap"
                    onChange={handleChange}
                    defaultValue={formState.nama_lengkap}
                    required
                  />
                </Col>
                <Col sm="12"></Col>
                <Col sm="3">
                  <Input
                    label="NRP / NIP"
                    type="number"
                    name="nrp"
                    onChange={handleChange}
                    defaultValue={formState.nrp}
                    required
                  />
                </Col>
                <Col sm="3">
                  <Input
                    label="No. HP"
                    type="number"
                    name="handphone"
                    onChange={handleChange}
                    defaultValue={formState.handphone}
                    required
                  />
                </Col>
                <Col sm="12"></Col>
                <Col sm="3">
                  <Input
                    label="Email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    defaultValue={formState.email}
                    required
                  />
                </Col>
                <Col sm="3" className="d-flex flex-column">
                  <Select
                    required
                    label="Tipe Satker"
                    default="Pilih Tipe Satker"
                    name="tipe_satker"
                    onChange={handleChange}
                    value={formState.tipe_satker}
                    options={[
                      {
                        value: "POLRI",
                        text: "POLRI",
                      },
                      {
                        value: "POLDA",
                        text: "POLDA",
                      },
                      {
                        value: "POLRES",
                        text: "POLRES",
                      },
                    ]}
                  />
                </Col>
                <Col sm="12"></Col>
                <Col sm="3">
                  <Select2
                    required
                    label="Nama Satker"
                    placeholder={"Pilih Satker"}
                    name="nama_satker_tahti"
                    value={formState.nama_satker_tahti}
                    // onChange={handleChange}
                    onChange={(selectedOption) => {
                      setFormState({
                        ...formState,
                        nama_satker_tahti: selectedOption.value,
                      });
                    }}
                    options={
                      formState.tipe_satker ? dataSatkersType : dataSatkersAll
                    }
                  />
                </Col>
                <Col sm="3">
                  <Select
                    required
                    label="Pangkat"
                    name="pangkat"
                    onChange={handleChange}
                    value={formState.pangkat}
                    default="Pilih Pangkat"
                    options={pangkat}
                  />
                </Col>
                <Col sm="12"></Col>
                <Col sm="3">
                  <Select
                    required
                    label="Status"
                    default="Pilih Status"
                    name="is_active"
                    onChange={handleChange}
                    value={formState.is_active}
                    options={[
                      {
                        value: true,
                        text: "Aktif",
                      },
                      {
                        value: false,
                        text: "Tidak Aktif",
                      },
                    ]}
                  />
                </Col>
                <Col sm="3">
                  <Select
                    required
                    label="Role Akses"
                    default="Pilih Role"
                    name="role"
                    onChange={handleChange}
                    value={formState.role}
                    options={[
                      {
                        value: "Super Admin",
                        text: "Super Admin",
                        isDisabled:
                          userData?.role?.role === "Super Admin" ? false : true,
                      },
                      {
                        value: "Eksternal",
                        text: "Eksternal",
                        isDisabled:
                          userData?.role?.role === "Super Admin" ? false : true,
                      },
                      {
                        value: "Admin Penyidik",
                        text: "Admin Penyidik",
                        isDisabled:
                          userData?.role?.role === "Super Admin" ? false : true,
                      },
                      {
                        value: "Admin TAHTI",
                        text: "Admin TAHTI",
                        isDisabled:
                          userData?.role?.role === "Super Admin" ? false : true,
                      },

                      {
                        value: "Eksekutif TAHTI",
                        text: "Eksekutif TAHTI",
                        isDisabled:
                          userData?.role?.role === "Admin TAHTI" ? false : true,
                      },
                      {
                        value: "Umum TAHTI",
                        text: "Umum TAHTI",
                        isDisabled:
                          userData?.role?.role === "Admin TAHTI" ? false : true,
                      },
                      {
                        value: "Petugas TAHTI",
                        text: "Petugas TAHTI",
                        isDisabled:
                          userData?.role?.role === "Admin TAHTI" ? false : true,
                      },
                      {
                        value: "Petugas BB",
                        text: "Petugas BB",
                        isDisabled:
                          userData?.role?.role === "Admin TAHTI" ? false : true,
                      },
                    ]}
                  />
                </Col>
                <Col sm="12"></Col>
                <Col sm="3" className="d-flex flex-column">
                  <Form.Label className="form-label fs-sm">
                    Tipe Akun{" "}
                  </Form.Label>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                      <div className="d-flex align-items-center gap-1">
                        <Form.Check
                          type="radio"
                          required
                          id="radioSatker"
                          name="tipe_akun"
                          value={"Tetap"}
                          checked={
                            formState.tipe_akun === "Tetap" ? "checked" : ""
                          }
                          onChange={handleChange}
                        />
                        <span className="fs-medium">Tetap</span>
                      </div>
                      <div className="d-flex align-items-center gap-1">
                        <Form.Check
                          type="radio"
                          required
                          id="radioSatker"
                          name="tipe_akun"
                          checked={
                            formState.tipe_akun === "Sementara" ? "checked" : ""
                          }
                          value="Sementara"
                          onChange={handleChange}
                        />
                        <span className="fs-medium">Sementara</span>
                      </div>
                    </div>
                    <DatePicker
                      label="Periode Aktif"
                      placeholderText="Atur Waktu Range"
                      selectsRange={true}
                      selected={formDate.awal_masa_aktif}
                      startDate={formDate.awal_masa_aktif}
                      endDate={formDate.akhir_masa_aktif}
                      onChange={(date) => {
                        setFormDate({
                          ...formDate,
                          awal_masa_aktif: date[0],
                          akhir_masa_aktif: date[1],
                        });
                      }}
                      disabled={formState.tipe_akun === "Tetap"}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center gap-3">
            <Button variant="warning" as={Link} to="/pengaturan/pengguna/list">
              BATAL
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              SIMPAN
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default FormEdit;
