import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Input, MyPagination } from "../../../../components/Components";
import Alert from "../../../../libraries/Alert";
import debounce from "debounce";
import {
  useDeleteMasterBarangBuktiUnit,
  useGetMasterBarangBuktiUnit,
  useGetMasterMeasure,
} from "../../../../utils/apis/query";

function Unit() {
  const [payloadDebounced, setPayloadDebounced] = useState({
    measure: "",
    page: 1,
    size: 10,
  });
  const [search, setSearch] = useState("");
  const {
    data: dataList,
    pagination,
    isFetching: dataFetching,
    isError: dataIsError,
    error: dataError,
  } = useGetMasterMeasure(payloadDebounced);

  const onError = (data) => {
    Alert.showMessage(
      "Error",
      "error",
      data?.response?.data?.message ?? "Gagal Menghapus"
    );
  };
  const onSuccess = () => {};

  const mutateDelete = useDeleteMasterBarangBuktiUnit(onSuccess, onError);

  const handleDebounce = useCallback(
    debounce((newPayload) => {
      setPayloadDebounced(newPayload);
    }, 300),
    []
  );
  const handleDelete = (id) => {
    Alert.showConfirm("warning", "Hapus").then(({ isConfirmed }) => {
      if (isConfirmed) {
        mutateDelete.mutate(id);
      }
    });
  };
  const handlePagination = (page) =>
    setPayloadDebounced({ ...payloadDebounced, page: page });

  useEffect(() => {
    handleDebounce({ ...payloadDebounced, measure: search });
  }, [search, handleDebounce, payloadDebounced]);
  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/master/barang-bukti/list">Master Barang Bukti</Link>
            </li>
            <li className="breadcrumb-item active fw-bold" aria-current="page">
              List Master Barang Bukti Unit
            </li>
          </ol>
        </div>
      </div>
      <Row>
        <Col xl="12">
          <Card className="card bg-white">
            <Card.Body>
              <Row className="g-1 mb-2">
                <Col sm="12" md="5">
                  <Input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Col>
                <Col
                  sm="12"
                  md="7"
                  className="d-flex justify-content-end gap-3"
                >
                  <Link
                    to="/master/barang-bukti/unit/form"
                    className="d-flex align-items-center justify-content-end"
                  >
                    <Button className="btn btn-primary">Tambah</Button>
                  </Link>
                </Col>
              </Row>
              <div className="table-responsive">
                <Table className="table dems-table">
                  <thead>
                    <tr>
                      <th>Aksi</th>
                      <th>No</th>
                      <th>Unit</th>
                      <th>Keterangan</th>
                      <th>Dibuat Oleh</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataFetching && (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          <Spinner animation="border" variant="primary" />
                        </td>
                      </tr>
                    )}
                    {dataIsError &&
                      (dataError?.response?.data?.detail?.map((err, idx) => (
                        <tr key={idx}>
                          <td
                            colSpan="4"
                            style={{ textAlign: "center" }}
                            className="text-danger"
                          >
                            <span>
                              <b>{err.loc[1]} </b>
                              {err.msg}
                            </span>
                          </td>
                        </tr>
                      )) ?? (
                        <tr>
                          <td
                            colSpan="4"
                            style={{ textAlign: "center" }}
                            className="text-danger"
                          >
                            <span>
                              <b>Opps Error </b>Reload page or please wait until
                              we fix this issue
                            </span>
                          </td>
                        </tr>
                      ))}
                    {dataList.length && !dataFetching && !dataIsError
                      ? dataList.map((data, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center justify-content-start gap-2">
                                <Link
                                  to={
                                    "/master/barang-bukti/unit/edit/" +
                                    data.id +
                                    `?measure=${data.measure}&keterangan=${data.keterangan}`
                                  }
                                >
                                  <Badge className="btn bg-info" as="button">
                                    <i className="ri-eye-line"></i>
                                  </Badge>
                                </Link>
                                <Badge
                                  onClick={() => handleDelete(data.id)}
                                  className="btn bg-danger"
                                  as="button"
                                >
                                  <i className="ri-delete-bin-line"></i>
                                </Badge>
                              </div>
                            </td>
                            <td>{data?.no ?? "-"}</td>
                            <td>{data.measure || "-"}</td>
                            <td>{data.keterangan || "-"}</td>
                            <td>{data.created_by || "-"}</td>
                          </tr>
                        ))
                      : !dataFetching &&
                        !dataList.length &&
                        !dataIsError && (
                          <tr>
                            <td colSpan="4" style={{ textAlign: "center" }}>
                              <span>Tidak Ada Data !</span>
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Card.Footer className="bg-white">
              <MyPagination
                currentPage={payloadDebounced.page}
                totalPages={pagination.total_pages}
                onPageChange={handlePagination}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Unit;
