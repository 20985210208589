import React, { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Accordion,
  Row,
  Spinner,
  Table,
  Form,
  Modal,
  Placeholder,
  Alert,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  DatePicker,
  Image,
  Input,
  Select,
} from "../../../../components/Components";
import {
  useGetDetailTahti,
  useGetMasterDaerah,
  useGetTahtiBonList,
  usePostPengeluaran,
  useSatkerTahtiList,
} from "../../../../utils/apis/query";
import { debounce } from "lodash";
import { useQueryClient } from "react-query";
import moment from "moment";
import { removeEmptyFields } from "../../../../utils";
import State from "../../../../libraries/State";

function FormCreate() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const state = State.getState();
  const profileData = state.getItem("profileData", true);
  const [modalShow, setModalShow] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [payloadCariTahanann, setPayloadCariTahanan] = useState({
    search: "",
  });
  const [namaTahanan, setNamaTahanan] = useState("");
  const [idTahanan, setIdTahanan] = useState("");
  const [penyidik, setPenyidik] = useState([]);
  const [payloadDate, setPayloadDate] = useState({
    tanggal_sprin_pengeluaran: "",
    tanggal_pengeluaran: "",
    awal_penahanan: "",
    akhir_penahanan: "",
  });

  const [payloadForm, setPayloadForm] = useState({
    arsip: [],
    lampiran: [],
    nama_tahanan: "",
    no_tahanan: "",
    no_sprin_pengeluaran: "",
    tanggal_sprin_pengeluaran: "",
    tanggal_pengeluaran: "",
    kategori_pengeluaran: "Pembantaran",
    awal_penahanan: "",
    akhir_penahanan: "",
    kondisi: "",
    status_dokumen: "",
    status_terakhir: "",
    alasan: "",
    jenis_pengalihan: "rutan",
    kota: "",
    provinsi: "",
    alamat_rumah: "",
    rutan_tujuan: "",
    jenis_sp3: "",
    jaminan_penangguhan: "",
    nama_rumah_sakit: "",
    lokasi_rumah_sakit: "", // info
    jenis_pelimpahan: "",
    keterangan: "",
    penyidik_ids: [],
  });
  const [payloadFormDataTahanan, setPayloadFormDataTahanan] = useState({
    no_tahanan: "",
    nama: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    umur: "",
    kelamin: "",
    kewarganegaraan: "",
    asal_negara: "",
    agama: "",
    pekerjaan: "",
    alamat: "",
    foto_tahanan: "",
    foto_sidikjari: "",
    perkara: "",
    penempatan_rutan: "",
    lokasi_rutan: "",
    riwayat: [],
  });

  // END OF STATE GROUP

  const {
    data: dataTahanan,
    isFetching: tahananFetching,
    isError: tahananError,
  } = useGetDetailTahti(idTahanan, { enabled: !!idTahanan });

  const { data: dataTahananList, isFetching: modalFetching } =
    useGetTahtiBonList(payloadCariTahanann);
  const { data: dataRutanList } = useSatkerTahtiList();
  const { data: dataMasterProvinsi } = useGetMasterDaerah(
    {},
    { enabled: true }
  );
  const { data: dataMasterKota } = useGetMasterDaerah(
    {
      nama_provinsi: payloadForm.provinsi,
    },
    { enabled: !!payloadForm.provinsi }
  );

  let provinsi =
    dataMasterProvinsi?.map((data) => {
      data.text = data.nama;
      data.value = data.nama;
      return data;
    }) ?? [];

  let kota = (dataMasterKota?.map((data) => {
    data.opt =
      data?.kota_kabupaten?.map((k) => {
        k.text = k.nama;
        k.value = k.nama;
        return k;
      }) ?? [];

    return data.opt;
  }) ?? [])[0];

  const handleCheckbox = (data, isChecked) => {
    if (isChecked) {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: [...payloadForm.penyidik_ids, data.id_penyidik],
      });
    } else {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: payloadForm.penyidik_ids.filter(
          (selectedId) => selectedId !== data.id_penyidik
        ),
      });
    }
  };

  const handleChange = (e) => {
    setIsFormDirty(true);
    const { name, value } = e.target;
    setPayloadForm({ ...payloadForm, [name]: value });
  };

  const handleDebounce = useCallback(
    debounce((newPayload) => {
      setPayloadCariTahanan(newPayload);
    }, 500),
    []
  );

  const onSuccess = (response) => {
    setIsFormDirty(false);
    setTimeout(() => {
      navigate("/tahanan/pengeluaran/list");
    }, 2500);
  };
  const mutateSubmit = usePostPengeluaran(onSuccess);

  const handleSubmit = (status) => {
    payloadForm.jenis_pelimpahan =
      payloadForm.kategori_pengeluaran === "P21"
        ? payloadForm.jenis_pelimpahan
        : payloadForm.kategori_pengeluaran === "Bebas Demi Hukum"
        ? "Bebas Demi Hukum"
        : "";
    let formData = new FormData();
    let payload = payloadForm;
    payload.status_dokumen = status;
    if (
      payload.kategori_pengeluaran !== "Pembantaran" ||
      payload.kategori_pengeluaran !== "Rehabilitasi Narkoba"
    ) {
      payload = {
        ...payload,
        nama_rumah_sakit: "",
        lokasi_rumah_sakit: "",
      };
    }
    if (payload.kategori_pengeluaran !== "Penangguhan") {
      payload = {
        ...payload,
        jaminan_penangguhan: "",
      };
    }
    if (payload.kategori_pengeluaran !== "Pengalihan") {
      payload = {
        ...payload,
        jenis_pengalihan: "",
      };
    }
    if (payload.kategori_pengeluaran !== "SP3") {
      payload = {
        ...payload,
        jenis_sp3: "",
      };
    }
    if (payload.kategori_pengeluaran !== "Meninggal Dunia") {
      payload = {
        ...payload,
        jenis_sp3: "",
      };
    }
    if (
      payload.kategori_pengeluaran !== "P21" ||
      payload.kategori_pengeluaran !== "Bebas Demi Hukum"
    ) {
      payload = {
        ...payload,
        jenis_pelimpahan: "",
      };
    }

    if (payload.jaminan_penangguhan !== "rutan") {
      payload = {
        ...payload,
        jenis_srutan_tujuanp3: "",
      };
    }
    if (payload.jaminan_penangguhan !== "rumah") {
      payload = {
        ...payload,
        alamat_rumah: "",
        kota: "",
        provinsi: "",
      };
    }
    if (payload.jaminan_penangguhan !== "kota") {
      payload = {
        ...payload,
        alamat_rumah: "",
        kota: "",
        provinsi: "",
      };
    }
    payload = removeEmptyFields(payload);
    Object.keys(payload).forEach((key) => {
      if (
        typeof payload[key] === "string" ||
        typeof payload[key] === "number"
      ) {
        formData.append(key, payload[key]);
      }
    });
    payload.arsip.forEach((file, idx) => {
      formData.append(`arsip`, file);
    });
    payload.lampiran.forEach((file, idx) => {
      formData.append(`lampiran`, file);
    });
    payload.penyidik_ids.forEach((ids, idx) => {
      formData.append(`penyidik_ids`, ids);
    });
    mutateSubmit.mutate(formData);
  };
  // USE EFFECT GROUP

  useEffect(() => {
    handleDebounce({ ...payloadCariTahanann, search: namaTahanan });
  }, [namaTahanan, handleDebounce, payloadCariTahanann]);

  useEffect(() => {
    // if (profileData) {
    //   setPayloadFormDataTahanan({
    //     ...payloadFormDataTahanan,
    //     penempatan_rutan: profileData.satker.nama_rutan,
    //   });
    // }
    if (dataTahanan) {
      const _ = dataTahanan;
      setPayloadFormDataTahanan({
        no_tahanan: _.no_tahanan,
        nama: _.nama_tersangka,
        tempat_lahir: _.tempat_lahir,
        tanggal_lahir: _.tanggal_lahir,
        umur: _.umur,
        kelamin: _.jenis_kelamin,
        kewarganegaraan: _.kebangsaan,
        asal_negara: _.asal_negara,
        agama: _.agama,
        perkara: _.perkara,
        pekerjaan: _.pekerjaan,
        alamat: _.alamat,
        foto_tahanan: _.rumus_wajah,
        foto_sidikjari: _.rumus_sidikjari,
        lokasi_rutan: _.alamat_rutan,
        penempatan_rutan: _.nama_rutan,
        riwayat: _.riwayat_pengeluaran,
      });
      setPayloadForm({
        ...payloadForm,
        // kondisi: _.ko,
        status_dokumen: _.status_dokumen,
        no_tahanan: _.no_tahanan,
        nama_tahanan: _.nama_tersangka,
        awal_penahanan: _.awal_penahanan,
        akhir_penahanan: _.akhir_penahanan,
        status_terakhir: _.status_tahanan,
      });
      setPenyidik(_?.penyidik ?? []);
    }
  }, [dataTahanan, profileData]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/tahanan">Tahanan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/tahanan/pengeluaran/list">
                    List Pengeluaran Tahanan
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form Pengeluaran Tahanan
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {mutateSubmit.isError && (
                <Alert variant="danger">
                  <ul className="m-0">
                    {mutateSubmit?.error?.response?.data?.detail?.map(
                      (errEdit, idx) => (
                        <li key={idx}>
                          <b>{errEdit.loc[1]} : </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error : </b>
                        {mutateSubmit?.error?.response?.data?.message ??
                          "Bermasalah"}
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateSubmit.isSuccess && (
                <Alert variant="success">
                  {mutateSubmit.data.data.message}
                </Alert>
              )}
            </Col>
            <Col sm="12">
              <Accordion
                defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                flush
                alwaysOpen
              >
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="custom-header">
                    Informasi / Profil Tahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Header className="bg-white d-flex justify-content-end">
                        {tahananFetching && !tahananError ? (
                          <Button
                            variant="primary"
                            size="small"
                            className="d-flex align-items-center gap-2"
                            disabled
                          >
                            <Spinner size="sm" variant="light" />
                            Sedang Menerapkan Tahanan
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            size="small"
                            className="d-flex align-items-center gap-2"
                            onClick={() => setModalShow(true)}
                          >
                            <i className="ri-user-search-line fs-6"></i>
                            {dataTahanan ? "Ubah " : "Cari "}
                            Profil Tahanan
                          </Button>
                        )}
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col sm="4" className="d-flex flex-column gap-3">
                            <div className="d-flex flex-column gap-2">
                              <span className="fs-6 fw-medium">
                                Foto Tahanan
                              </span>
                              <Image
                                src={payloadFormDataTahanan.foto_tahanan}
                                height="250px"
                                width="100%"
                              />
                            </div>
                            <div className="d-flex flex-column gap-2">
                              <span className="fs-6 fw-medium">
                                Foto Sidik Jari
                              </span>
                              <Image
                                src={payloadFormDataTahanan.foto_sidikjari}
                                height="250px"
                                width="100%"
                              />
                            </div>
                          </Col>
                          <Col sm="8">
                            {tahananFetching && !tahananError && (
                              <Placeholder
                                as="p"
                                animation="wave"
                                className="m-0 d-flex flex-column gap-2"
                              >
                                <Placeholder sm="12" />
                                <Placeholder sm="12" />
                                <Placeholder sm="12" />
                                <Placeholder sm="12" />
                                <Placeholder sm="12" />
                              </Placeholder>
                            )}
                            {!tahananFetching && tahananError && (
                              <div className="d-flex justify-content-center">
                                <span className="fs-5 text-danger">
                                  <b>Error : </b> Terjadi masalah koneksi
                                </span>
                              </div>
                            )}
                            {!tahananFetching && !tahananError && (
                              <Row className="g-2">
                                <Col sm="6">
                                  <Input
                                    label="No. Tahanan"
                                    name="no_tahanan"
                                    defaultValue={
                                      payloadFormDataTahanan.no_tahanan
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Nama"
                                    name="nama"
                                    defaultValue={payloadFormDataTahanan.nama}
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Tempat Lahir"
                                    defaultValue={
                                      payloadFormDataTahanan.tempat_lahir
                                    }
                                    name="tempat_lahir"
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Tanggal Lahir"
                                    name="tanggal_lahir"
                                    defaultValue={
                                      payloadFormDataTahanan.tanggal_lahir
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    type="number"
                                    label="Umur Saat Ini"
                                    name="umur"
                                    defaultValue={payloadFormDataTahanan.umur}
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Kelamin"
                                    name="jenis_kelamin"
                                    disabled
                                    defaultValue={
                                      payloadFormDataTahanan.kelamin
                                    }
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Kewarganegaraan"
                                    name="kebangsaan"
                                    defaultValue={
                                      payloadFormDataTahanan.kewarganegaraan
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Asal Negara"
                                    name="asal_negara"
                                    defaultValue={
                                      payloadFormDataTahanan.asal_negara
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Agama"
                                    name="agama"
                                    defaultValue={payloadFormDataTahanan.agama}
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Pekerjaan"
                                    name="pekerjaan"
                                    defaultValue={
                                      payloadFormDataTahanan.pekerjaan
                                    }
                                    type="text"
                                    disabled
                                  />
                                </Col>
                                <Col sm="12">
                                  <Input
                                    as="textarea"
                                    label="Alamat"
                                    defaultValue={payloadFormDataTahanan.alamat}
                                    name="alamat"
                                    disabled
                                  />
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="custom-header">
                    Informasi Dokumen Penahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="12">
                            <Row className="g-2 mt-1">
                              <Col sm="5">
                                <Input
                                  label="Nomor Sprin pengeluaran Tahanan"
                                  name="no_sprin_pengeluaran"
                                  required
                                  onChange={handleChange}
                                  defaultValue={
                                    payloadForm.no_sprin_pengeluaran
                                  }
                                />
                              </Col>
                              <Col sm="3">
                                <DatePicker
                                  label="Tanggal Sprin"
                                  name="tanggal_sprin_pengeluaran"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_sprin_pengeluaran: date,
                                      tanggal_pengeluaran: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_sprin_pengeluaran:
                                        moment(date).format("YYYY-MM-DD"),
                                      tanggal_pengeluaran:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_sprin_pengeluaran
                                  }
                                  required
                                />
                              </Col>
                              <Col sm="4">
                                <Input
                                  label="Unggah Arsip"
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip: [files],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                  required
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="custom-header">
                    Informasi Pengeluaran Tahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row>
                          <Col sm="4">
                            <Select
                              label="Pilih Kategori Pengeluaran"
                              name="kategori_pengeluaran"
                              default="-"
                              options={[
                                {
                                  text: "Pembantaran",
                                  value: "Pembantaran",
                                },
                                {
                                  text: "Penangguhan",
                                  value: "Penangguhan",
                                },
                                {
                                  text: "Pengalihan Jenis Tahanan",
                                  value: "Pengalihan",
                                },
                                {
                                  text: "SP3",
                                  value: "SP3",
                                },
                                {
                                  text: "Tahap 2",
                                  value: "P21",
                                },
                                {
                                  text: "Bebas Demi Hukum",
                                  value: "Bebas Demi Hukum",
                                },
                                {
                                  text: "Rehabilitasi Narkoba",
                                  value: "Rehabilitasi Narkoba",
                                },
                                // {
                                //   text: "Meninggal Dunia",
                                //   value: "Meninggal Dunia",
                                // },
                              ]}
                              defaultValue={payloadForm.kategori_pengeluaran}
                              onChange={handleChange}
                            />
                          </Col>
                        </Row>
                        {(payloadForm.kategori_pengeluaran === "Pembantaran" ||
                          payloadForm.kategori_pengeluaran ===
                            "Rehabilitasi Narkoba") && (
                          <Row className="g-2 mt-2">
                            <Col sm="4">
                              <DatePicker
                                label="Tanggal Pengeluaran"
                                name="tanggal_pengeluaran"
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy"
                                minDate={new Date()}
                                onChange={(date) => {
                                  setPayloadDate({
                                    ...payloadDate,
                                    tanggal_pengeluaran: date,
                                  });
                                  setPayloadForm({
                                    ...payloadForm,
                                    tanggal_pengeluaran:
                                      moment(date).format("YYYY-MM-DD"),
                                  });
                                }}
                                selected={payloadDate.tanggal_pengeluaran}
                                required
                                disabled
                              />
                            </Col>
                            <Col sm="4">
                              <Select
                                label="Kondisi Fisik Tahanan"
                                name="kondisi"
                                default="-"
                                options={[
                                  {
                                    text: "Sakit",
                                    value: "Sakit",
                                  },
                                  {
                                    text: "Sehat",
                                    value: "Sehat",
                                  },
                                ]}
                                defaultValue={payloadForm.kondisi}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col sm="4">
                              <Input
                                label={
                                  payloadForm.kategori_pengeluaran ===
                                  "Pembantaran"
                                    ? "Nama Rumah Sakit"
                                    : "Nama Tempat Rehabilitasi"
                                }
                                type="text"
                                name="nama_rumah_sakit"
                                defaultValue={payloadForm.nama_rumah_sakit}
                                onChange={handleChange}
                                required
                              />
                            </Col>
                            {/* <Col sm="4">
                              <Input
                                as="textarea"
                                name="lokasi_rumah_sakit"
                                defaultValue={payloadForm.lokasi_rumah_sakit}
                                onChange={handleChange}
                                label={
                                  payloadForm.kategori_pengeluaran ===
                                  "Pembantaran"
                                    ? "Lokasi Rumah Sakit"
                                    : "Lokasi Tempat Rehabilitasi"
                                }
                              />
                            </Col> */}
                            <Col sm="4">
                              <Input
                                as="textarea"
                                name="alasan"
                                defaultValue={payloadForm.alasan}
                                onChange={handleChange}
                                label={
                                  payloadForm.kategori_pengeluaran ===
                                  "Pembantaran"
                                    ? "Alasan Pembantaran (Masukkan jenis sakit)"
                                    : "Alasan Rehabilitasi"
                                }
                              />
                            </Col>
                          </Row>
                        )}
                        {payloadForm.kategori_pengeluaran === "Penangguhan" && (
                          <Row className="g-2 mt-2">
                            <Col sm="4">
                              <DatePicker
                                label="Tanggal Pengeluaran"
                                name="tanggal_pengeluaran"
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => {
                                  setPayloadDate({
                                    ...payloadDate,
                                    tanggal_pengeluaran: date,
                                  });
                                  setPayloadForm({
                                    ...payloadForm,
                                    tanggal_pengeluaran:
                                      moment(date).format("YYYY-MM-DD"),
                                  });
                                }}
                                selected={payloadDate.tanggal_pengeluaran}
                                required
                                disabled
                              />
                            </Col>
                            <Col sm="4">
                              <Select
                                label="Kondisi Fisik Tahanan"
                                name="kondisi"
                                default="-"
                                options={[
                                  {
                                    text: "Sakit",
                                    value: "Sakit",
                                  },
                                  {
                                    text: "Sehat",
                                    value: "Sehat",
                                  },
                                ]}
                                defaultValue={payloadForm.kondisi}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col sm="4">
                              <Select
                                label="Jaminan Penangguhan"
                                default="-"
                                name="jaminan_penangguhan"
                                options={[
                                  {
                                    text: "Uang",
                                    value: "uang",
                                  },
                                  {
                                    text: "Kerabat",
                                    value: "kerabat",
                                  },
                                ]}
                                defaultValue={payloadForm.jaminan_penangguhan}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col sm="4">
                              <Input
                                as="textarea"
                                name="alasan"
                                defaultValue={payloadForm.alasan}
                                onChange={handleChange}
                                label="Alasan Penangguhan"
                              />
                            </Col>
                          </Row>
                        )}
                        {payloadForm.kategori_pengeluaran === "Pengalihan" && (
                          <Row className="g-2 mt-2">
                            <Col sm="4">
                              <Select
                                label="Jenis Pengalihan"
                                name="jenis_pengalihan"
                                default="-"
                                options={[
                                  {
                                    text: "Penahanan Kota",
                                    value: "kota",
                                  },
                                  {
                                    text: "Penahanan (Pindah) Rutan",
                                    value: "rutan",
                                  },
                                  {
                                    text: "Penahanan Rumah",
                                    value: "rumah",
                                  },
                                ]}
                                defaultValue={payloadForm.jenis_pengalihan}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col sm="4">
                              <Select
                                label="Kondisi Fisik Tahanan"
                                default="-"
                                name="kondisi"
                                options={[
                                  {
                                    text: "Sakit",
                                    value: "Sakit",
                                  },
                                  {
                                    text: "Sehat",
                                    value: "Sehat",
                                  },
                                ]}
                                defaultValue={payloadForm.kondisi}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col sm="4">
                              <DatePicker
                                label="Tanggal Pengeluaran"
                                name="tanggal_pengeluaran"
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => {
                                  setPayloadDate({
                                    ...payloadDate,
                                    tanggal_pengeluaran: date,
                                  });
                                  setPayloadForm({
                                    ...payloadForm,
                                    tanggal_pengeluaran:
                                      moment(date).format("YYYY-MM-DD"),
                                  });
                                }}
                                selected={payloadDate.tanggal_pengeluaran}
                                required
                                disabled
                              />
                            </Col>
                            {payloadForm.jenis_pengalihan === "rutan" && (
                              <Col sm="12">
                                <Row className="g-2">
                                  <Col sm="4">
                                    <Input
                                      disabled
                                      label="Asal Rutan"
                                      defaultValue={
                                        payloadFormDataTahanan.penempatan_rutan
                                      }
                                    />
                                  </Col>
                                  <Col sm="4">
                                    <Select
                                      label="Rutan Tujuan"
                                      default="-"
                                      name="rutan_tujuan"
                                      options={dataRutanList.map((e) => ({
                                        text: e.nama_rutan,
                                        value: e.nama_rutan,
                                      }))}
                                      onChange={handleChange}
                                      defaultValue={payloadForm.rutan_tujuan}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            )}
                            {payloadForm.jenis_pengalihan === "rumah" && (
                              <Col sm="12">
                                <Row className="g-2">
                                  <Col sm="4">
                                    <Select
                                      label="Asal Rutan"
                                      default="-"
                                      options={dataRutanList.map((e) => ({
                                        text: e.nama_rutan,
                                        value: e.nama_rutan,
                                      }))}
                                      disabled
                                      defaultValue={
                                        payloadFormDataTahanan.penempatan_rutan
                                      }
                                    />
                                  </Col>
                                  <Col sm="8">
                                    <Row className="g-2">
                                      <Col sm="12">
                                        <Input
                                          as="textarea"
                                          label="Ke Alamat Rumah"
                                          name="alamat_rumah"
                                          defaultValue={
                                            payloadForm.alamat_rumah
                                          }
                                          onChange={handleChange}
                                        />
                                      </Col>
                                      <Col sm="12">
                                        <Select
                                          label="Provinsi"
                                          default="-"
                                          name="provinsi"
                                          options={provinsi}
                                          onChange={handleChange}
                                          defaultValue={payloadForm.provinsi}
                                        />
                                      </Col>
                                      <Col sm="12">
                                        <Select
                                          label="Kota"
                                          default="-"
                                          name="kota"
                                          options={kota}
                                          onChange={handleChange}
                                          defaultValue={payloadForm.kota}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                            {payloadForm.jenis_pengalihan === "kota" && (
                              <Col sm="12">
                                <Row className="g-2">
                                  <Col sm="12">
                                    <Select
                                      label="Provinsi"
                                      default="-"
                                      name="provinnsi"
                                      options={provinsi}
                                      onChange={handleChange}
                                      defaultValue={payloadForm.provinsi}
                                    />
                                  </Col>
                                  <Col sm="12">
                                    <Input
                                      label="Nama Kota"
                                      name="kota"
                                      defaultValue={payloadForm.kota}
                                      onChange={handleChange}
                                      type="text"
                                    />
                                  </Col>
                                  <Col sm="12">
                                    <Input
                                      as="textarea"
                                      label="Alamat Rumah"
                                      name="alamat_rumah"
                                      handleChange={payloadForm.alamat_rumah}
                                      defaultValue={payloadForm.alamat_rumah}
                                      onChange={handleChange}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            )}
                            <Col sm="12">
                              <Input
                                as="textarea"
                                label="Alasan Pengalihan"
                                name="alasan"
                                defaultValue={payloadForm.alasan}
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>
                        )}
                        {payloadForm.kategori_pengeluaran === "SP3" && (
                          <Row className="g-2 mt-2">
                            <Col sm="4">
                              <DatePicker
                                label="Tanggal Pengeluaran"
                                name="tanggal_pengeluaran"
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => {
                                  setPayloadDate({
                                    ...payloadDate,
                                    tanggal_pengeluaran: date,
                                  });
                                  setPayloadForm({
                                    ...payloadForm,
                                    tanggal_pengeluaran:
                                      moment(date).format("YYYY-MM-DD"),
                                  });
                                }}
                                selected={payloadDate.tanggal_pengeluaran}
                                required
                                disabled
                              />
                            </Col>
                            <Col sm="4">
                              <Select
                                label="Jenis Penghentian"
                                default="-"
                                name="jenis_sp3"
                                options={[
                                  {
                                    text: "⁠Restorative Justice",
                                    value: "⁠Restorative Justice",
                                  },
                                  {
                                    text: "Tidak Cukup Bukti",
                                    value: "Tidak Cukup Bukti",
                                  },
                                  // {
                                  //   text: "Bukan Tindak Pidana",
                                  //   value: "Bukan Tindak Pidana",
                                  // },
                                  // {
                                  //   text: "Kadaluarsa",
                                  //   value: "Kadaluarsa",
                                  // },
                                  {
                                    text: "Meninggal Dunia",
                                    value: "Meninggal Dunia",
                                  },
                                  // {
                                  //   text: "Keadilan Restoratif",
                                  //   value: "Keadilan Restoratif",
                                  // },
                                  {
                                    text: "Diversi",
                                    value: "Diversi",
                                  },
                                  {
                                    text: "Putusan Pra Peradilan",
                                    value: "Putusan Pra Peradilan",
                                  },
                                ]}
                                defaultValue={payloadForm.jenis_sp3}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col sm="4">
                              <Select
                                label="Kondisi Fisik Tahanan"
                                default="-"
                                name="kondisi"
                                options={[
                                  {
                                    text: "Sakit",
                                    value: "Sakit",
                                  },
                                  {
                                    text: "Sehat",
                                    value: "Sehat",
                                  },
                                ]}
                                defaultValue={payloadForm.kondisi}
                                onChange={handleChange}
                              />
                            </Col>

                            <Col sm="4">
                              <Input
                                as="textarea"
                                name="alasan"
                                defaultValue={payloadForm.alasan}
                                onChange={handleChange}
                                label="Alasan Penghentian"
                              />
                            </Col>
                          </Row>
                        )}
                        {payloadForm.kategori_pengeluaran ===
                          "Meninggal Dunia" && (
                          <>
                            {payloadForm.jenis_sp3 !==
                              "Tersangka Meninggal Dunia" &&
                              setPayloadForm({
                                ...payloadForm,
                                jenis_sp3: "Tersangka Meninggal Dunia",
                              })}
                            <Row className="g-2 mt-2">
                              <Col sm="4">
                                <DatePicker
                                  label="Tanggal Pengeluaran"
                                  name="tanggal_pengeluaran"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_pengeluaran: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_pengeluaran:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={payloadDate.tanggal_pengeluaran}
                                  required
                                  disabled
                                />
                              </Col>
                              <Col sm="4">
                                <Input
                                  label="Jenis Penghentian"
                                  name="jenis_sp3"
                                  defaultValue={payloadForm.jenis_sp3}
                                  onChange={handleChange}
                                  disabled
                                />
                              </Col>
                              <Col sm="4">
                                <Select
                                  label="Kondisi Fisik Tahanan"
                                  default="-"
                                  name="kondisi"
                                  options={[
                                    {
                                      text: "Sakit",
                                      value: "Sakit",
                                    },
                                    {
                                      text: "Sehat",
                                      value: "Sehat",
                                    },
                                  ]}
                                  defaultValue={payloadForm.kondisi}
                                  onChange={handleChange}
                                />
                              </Col>

                              <Col sm="4">
                                <Input
                                  as="textarea"
                                  name="alasan"
                                  defaultValue={payloadForm.alasan}
                                  onChange={handleChange}
                                  label="Alasan Penghentian"
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                        {(payloadForm.kategori_pengeluaran === "P21" ||
                          payloadForm.kategori_pengeluaran ===
                            "Bebas Demi Hukum") && (
                          <Row className="g-2 mt-2">
                            <Col sm="4">
                              <DatePicker
                                label="Tanggal Pengeluaran"
                                name="tanggal_pengeluaran"
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => {
                                  setPayloadDate({
                                    ...payloadDate,
                                    tanggal_pengeluaran: date,
                                  });
                                  setPayloadForm({
                                    ...payloadForm,
                                    tanggal_pengeluaran:
                                      moment(date).format("YYYY-MM-DD"),
                                  });
                                }}
                                selected={payloadDate.tanggal_pengeluaran}
                                required
                                disabled
                              />
                            </Col>
                            {payloadForm.kategori_pengeluaran === "P21" ? (
                              <Col sm="4">
                                <Select
                                  label="Jenis Pelimpahan"
                                  default="-"
                                  name="jenis_pelimpahan"
                                  options={[
                                    {
                                      text: "Berkas Perkara Lengkap",
                                      value: "Berkas Perkara Lengkap",
                                    },
                                    {
                                      text: "Dakwaan Sudah Siap",
                                      value: "Dakwaan Sudah Siap",
                                    },
                                  ]}
                                  defaultValue={payloadForm.jenis_pelimpahan}
                                  onChange={handleChange}
                                />
                              </Col>
                            ) : (
                              <Col sm="4">
                                <Input
                                  label="Jenis Pengeluaran"
                                  name="jenis_pelimpahan"
                                  defaultValue="Bebas Demi Hukum"
                                  onChange={handleChange}
                                  disabled
                                />
                              </Col>
                            )}
                            <Col sm="4">
                              <Select
                                label="Kondisi Fisik Tahanan"
                                default="-"
                                name="kondisi"
                                options={[
                                  {
                                    text: "Sakit",
                                    value: "Sakit",
                                  },
                                  {
                                    text: "Sehat",
                                    value: "Sehat",
                                  },
                                ]}
                                defaultValue={payloadForm.kondisi}
                                onChange={handleChange}
                              />
                            </Col>

                            <Col sm="4">
                              <Input
                                as="textarea"
                                name="alasan"
                                defaultValue={payloadForm.alasan}
                                onChange={handleChange}
                                label={
                                  payloadForm.kategori_pengeluaran === "P21"
                                    ? "Alasan Pelimpahan"
                                    : "Alasan Pengeluaran"
                                }
                              />
                            </Col>
                          </Row>
                        )}
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="custom-header">
                    Informasi Penahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2 mt-2">
                          <Col sm="4">
                            <Input
                              label="Perkara"
                              name="perkara"
                              defaultValue={payloadFormDataTahanan.perkara}
                              required
                              disabled
                            />
                          </Col>
                          <Col sm="4">
                            <Input
                              label="Penempatan Rutan"
                              defaultValue={
                                payloadFormDataTahanan.penempatan_rutan
                              }
                              name="nama_rutan"
                              required
                              disabled
                            />
                          </Col>
                          <Col sm="4">
                            <Input
                              as="textarea"
                              label="Lokasi Rutan"
                              defaultValue={payloadFormDataTahanan.lokasi_rutan}
                              name="alamat_rutan"
                              required
                              disabled
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header className="custom-header">
                    Informasi Penyidik
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="12">
                            <Card className="card bg-white">
                              <Card.Body>
                                <Row className="g-1 mb-2 d-flex align-items-center">
                                  <Col sm="12" md="5">
                                    <h6>Daftar Penyidik</h6>
                                  </Col>
                                  <Col sm="12" md="7">
                                    <Link className="d-flex align-items-center justify-content-end"></Link>
                                  </Col>
                                </Row>
                                <Table className="table dems-table">
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>NRP</th>
                                      <th>Nama Penyidik</th>
                                      <th>Asal Satker</th>
                                      <th>Pangkat</th>
                                      <th>Jabatan</th>
                                      <th>Pilih</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {penyidik.map((data, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.nrp}</td>
                                        <td>{data.nama_penyidik}</td>
                                        <td>{data.nama_satker}</td>
                                        <td>{data.pangkat}</td>
                                        <td>Penyidik</td>
                                        <td>
                                          <Form.Check
                                            type="checkbox"
                                            onChange={(e) => {
                                              handleCheckbox(
                                                data,
                                                e.target.checked
                                              );
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header className="custom-header">
                    Informasi Tambahan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="mt-2 g-2">
                          <Col sm="4">
                            <Input
                              label="Lampiran Pendukung"
                              type="file"
                              onChange={(e) => {
                                const files = e.target.files[0];
                                setPayloadForm({
                                  ...payloadForm,
                                  lampiran: [files],
                                });
                              }}
                              accepts={[".pdf"]}
                            />
                          </Col>
                          <Col sm="6">
                            <Input
                              as="textarea"
                              onChange={handleChange}
                              defaultValue={payloadForm.keterangan}
                              col="4"
                              name="keterangan"
                              label="Keterangan"
                              required
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          {!mutateSubmit.isLoading || !mutateSubmit.isLoading ? (
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Button
                variant="warning"
                as={Link}
                to="/tahanan/pengeluaran/list"
              >
                BATAL
              </Button>
              {/* <Button
                variant="secondary"
                disabled={!isFormDirty}
                onClick={() => handleSubmit("DRAFT")}
              >
                SIMPAN DRAFT
              </Button> */}
              <Button variant="primary" onClick={() => handleSubmit("FINAL")}>
                SIMPAN
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-end align-items-center gap-3">
              {/* <Button variant="secondary" disabled>
                Save Sebagai Draft
              </Button> */}
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                disabled
              >
                Sedang Menyimpan <Spinner variant="light" size="sm" />
              </Button>
            </div>
          )}
        </Card.Footer>
      </Card>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Cari Tahanan</Modal.Title>
          <Button
            className="btn btn-danger"
            onClick={() => setModalShow(false)}
          >
            <i class="ri-close-large-fill"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-4">
            <Col sm="12">
              <Input
                label="Nama Tahanan"
                onChange={(e) => {
                  setNamaTahanan(e.target.value);
                }}
              ></Input>
            </Col>
            <Col sm="12">
              <Table className="table dems-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Tahanan</th>
                    <th>Asal Satker</th>
                    <th>Perkara</th>
                    <th>Lokasi</th>
                    <th>Pilih</th>
                  </tr>
                </thead>
                <tbody>
                  {modalFetching && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="small"
                        />
                      </td>
                    </tr>
                  )}
                  {!modalFetching &&
                    dataTahananList.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.nama_tersangka}</td>
                        <td>{data.nama_satker}</td>
                        <td>{data.perkara}</td>
                        <td>{data.nama_rutan}</td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            onClick={() => {
                              setIdTahanan(data.id);
                              setModalShow(false);
                              queryClient.resetQueries("getTahtiList");
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default FormCreate;
