import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useGetMasterBarangBuktiJenis, useGetMasterBarangBuktiSubJenis, usePostMasterBarangBuktiSubJenis } from '../../../../utils/apis/query';
import { AsyncSelectCreatable, Input, Select } from '../../../../components/Components';

function FormLayout() {
    const navigate = useNavigate();
    const [jenisSearch, setJenisSearch] = useState('');
    const [subJenisSearch, setSubJenisSearch] = useState('');
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [formState, setFormState] = useState({ 
      jenis: "",
      sub_jenis: "",
      keterangan: "",
    });

    const { data: listOptionsSubJenis } = useGetMasterBarangBuktiSubJenis({page: 1, size: 30, sub_jenis: subJenisSearch})
    let selecOptionsSubJenis = listOptionsSubJenis.map((e) => ({label: e.sub_jenis, value: e.sub_jenis}))

    const { data: listOptionsJenis } = useGetMasterBarangBuktiJenis({page: 1, size: 30, jenis: jenisSearch})
    let selecOptionsJenis = listOptionsJenis.map((e) => ({label: e.jenis, value: e.jenis}))

    const onSuccess = (response) => {
        setIsFormDirty(false)
        navigate('/master/barang-bukti/sub-jenis')
    }
    const formMutate = usePostMasterBarangBuktiSubJenis(onSuccess)
  
    const handleChange = (e) => {
      const name = e?.target?.name
      const value = e?.target?.value ?? ''
      setFormState({...formState, [name] : value})
      setIsFormDirty(true)
    } 
    const handleSubmit = (e) => {
      formMutate.mutate(formState)
    }
    const handleAsyncOptionsSubjenis = (input) => {
        return new Promise((resolve) => {
            setSubJenisSearch(input)
            resolve(selecOptionsSubJenis)
        })
    }
    const handleAsyncOptionsJenis = (input) => {
        return new Promise((resolve) => {
            setJenisSearch(input)
            resolve(selecOptionsJenis)
        })
    }
    useEffect(() => { 
      const handleBeforeUnload = (event) => {
        if (isFormDirty) {
          const message =
            "You have unsaved changes. Are you sure you want to leave?";
          event.returnValue = message;
          return message;
        }
      };
  
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, [isFormDirty]);
  return (
    <React.Fragment>
        <Card className="bg-white mb-2">
            <Card.Body className="p-2">
            <div className="d-flex align-items-center justify-content-between m-0">
                <div>
                <ol className="breadcrumb fs-sm mb-0">
                    <li className="breadcrumb-item">
                    <Link to="/master/barang-bukti/list">Barang Bukti</Link>
                    </li>
                    <li className="breadcrumb-item">
                    <Link to="/master/barang-bukti/sub-jenis">
                        Barang Bukti Sub Jenis
                    </Link>
                    </li>
                    <li
                    className="breadcrumb-item active fw-bold"
                    aria-current="page"
                    >
                        Form Barang Bukti Sub Jenis
                    </li>
                </ol>
                </div>
            </div>
            </Card.Body>
        </Card>
        <Card className="bg-white">
            <Card.Body>
                <Row className="g-2">
                    <Col sm="12">
                    {formMutate.isError && (
                        <Alert variant="danger">
                        <ul className="m-0">
                            {formMutate?.error?.response?.data?.detail?.map(
                            (errEdit, idx) => (
                                <li key={idx}>
                                <b>{errEdit.loc[1]} : </b>
                                {errEdit.msg}
                                </li>
                            )
                            ) ?? (
                            <li>
                                <b>Error : </b>
                                {formMutate?.error?.response?.data?.message ??
                                "Bermasalah"}
                            </li>
                            )}
                        </ul>
                        </Alert>
                    )}
                    {formMutate.isSuccess && (
                        <Alert variant="success">
                        {formMutate.data.data.message}
                        </Alert>
                    )}
                    </Col>
                    <Col sm="12">
                        <Row className='g-2'>
                            <Col sm="3" className="d-flex flex-column">
                                {/* <Select 
                                    required
                                    label="Pilih Jeis"
                                    default="Pilih Jenis"
                                    name="jenis"
                                    onChange={handleChange}
                                    value={formState.jenis}
                                    options={selectListJenis}
                                />  */}
                                <AsyncSelectCreatable 
                                    className="react-select-dems"
                                    cacheOptions
                                    label="Pilih Jenis"
                                    placeholder=""
                                    required
                                    defaultOptions={selecOptionsJenis || []}
                                    loadOptions={handleAsyncOptionsJenis}
                                    onChange={(e) => setFormState({...formState, jenis: e.value})}
                                />
                            </Col>
                            <Col sm="3">
                                <Input 
                                    label="Sub Jenis Label"
                                    type="text"
                                    name="sub_jenis"
                                    onChange={handleChange}
                                    required
                                />
                                {/* <AsyncSelectCreatable 
                                    className="react-select-dems"
                                    cacheOptions
                                    label="Pilih Sub Jenis"
                                    placeholder=""
                                    required
                                    defaultOptions={selecOptionsSubJenis || []}
                                    loadOptions={handleAsyncOptionsSubjenis}
                                    onChange={(e) => setFormState({...formState, sub_jenis: e.value})}
                                /> */}
                            </Col>
                            <Col sm="3">
                                <Input 
                                    label="Keterangan"
                                    type="text"
                                    name="keterangan"
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className="bg-white">
            <div className="d-flex justify-content-end align-items-center gap-3">
                <Button
                    variant="warning"
                    as={Link}
                    to="/master/barang-bukti/sub-jenis"
                >
                    BATAL
                </Button>
                <Button 
                  variant="primary"
                  onClick={handleSubmit}
                >
                    TAMBAH
                </Button>
            </div>
        </Card.Footer>
        </Card>
    </React.Fragment>
  )
}

export default FormLayout