import React, { useEffect, useMemo } from 'react'
import { Row, Col, Card, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import State from '../../libraries/State'
import { isNotEmptyObject } from '../../utils'

function Index() {
  const state = State.getState()
  const profile = useMemo(() => state?.getItem('profileData', true) ?? {}, [state])
  const navigate = useNavigate()

  useEffect(() => {
    if (isNotEmptyObject(profile)) {
      const role = profile.role

      if (
        [
          'Super Admin', 
          'Admin TAHTI', 
          'Eksekutif POLRI', 
          'Petugas Rutan TAHTI'
        ].includes(role.role)
      ) {
        navigate('/dashboard/tahanan')
      } 
      else if (
        [
          'Petugas BB TAHTI'
        ].includes(role.role)
      ) {
        navigate('/dashboard/barangbukti')
      }
      else if (
        [].includes(role.role)
      ) {
        navigate('/dashboard/admindik')
      }
      else {
        navigate('/dashboard/tahanan')
      }
    }
  }, [profile, navigate])

  return (
    <React.Fragment>
        <Row>
          <Col sm="12">
            <Card className='card card-light'>
              <Card.Body>
                <Row className="g-2">
                  <Col sm="12">
                    <div className="text-align-center d-flex align-items-center justify-content-center flex-column">
                      <Spinner variant='primary' size='md' />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
    </React.Fragment>
  )
}

export default Index