import React, { useState } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";

const Checkbox = ({ children, ...props }) => (
  <label style={{ marginRight: "1em" }}>
    <input type="checkbox" {...props} />
    {children}
  </label>
);

const Select2 = ({
  label,
  options,
  value,
  // isClearable = true,
  isSearchable = true,
  disabled = false,
  isLoading = false,
  isRtl = false,
  name,
  required,
  onChange,
  placeholder = "Select...",
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "35px", // Tinggi minimum input
      height: "35px", // Tinggi tetap input
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      padding: "0 6px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
    }),
  };
  return (
    <div className="custom-select-container">
      {/* Label with optional required marker */}
      {label && (
        <Form.Label className="form-label fs-sm mb-1">
          {required && <span style={{ color: "red" }}>* </span>}
          {label}
        </Form.Label>
      )}
      <Select
        styles={customStyles}
        className="react-select-dems"
        classNamePrefix="select"
        value={options?.filter((opt) => opt.value === value) ?? ''}
        isDisabled={disabled}
        isLoading={isLoading}
        // isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name={name}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Select2;
