import React, { useLayoutEffect, useRef } from 'react';
import { Card, Col, Row } from 'react-bootstrap'
// import Select from '../../../../components/inputs/Select';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { formatNumberIndo } from '../../../../../utils';

function ChartPie({data, title}) {
  const chartRef = useRef(null)
  useLayoutEffect(() => {
    
    const totalValue = data.reduce((sum, item) => sum + item.value, 0);
    const allValuesAreZero = data.every(item => item.value === 0);
    let dataChart = data
    let disableLabelsAndTooltips = false;

    if (allValuesAreZero) {
      dataChart = [
        {
          category: "Tidak ada data untuk ditampilkan",
          value: 1, // Dummy value for rendering
          color: am5.color(0xcccccc) // Grey color
        }
      ];
      disableLabelsAndTooltips = true; // Set flag to disable labels and tooltips
    } 
    
    function formatNumberIDR(amount) {
      if (isNaN(amount)) {
        return 'Invalid number';
      }
      const numberString = amount.toFixed(2);
      const [integerPart, decimalPart] = numberString.split('.');
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      if (decimalPart === '00') {
        return `Rp ${formattedIntegerPart}`;
      }
      return `Rp ${formattedIntegerPart},${decimalPart}`;
    }
    function formatIDR(number) {
        if (!number) return 'unknown'
        if (number >= 1000000000000) {
            return `Rp. ${(number / 1000000000000).toFixed(2)} Triliun`;
        } else if (number >= 1000000000) {
            return `Rp. ${(number / 1000000000).toFixed(2)} Miliar`;
        } else if (number >= 1000000) {
            return `Rp. ${(number / 1000000).toFixed(2)} Juta`;
        } else if (number >= 1000) {
            return `Rp. ${(number / 1000).toFixed(2)} Ribu`;
        } else {
            return `Rp. ${number.toLocaleString('id-ID')}`;
        }
    }
    let root = am5.Root.new(chartRef.current);
    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);
    let chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          layout: root.verticalLayout,
          innerRadius: am5.percent(30) // ieu
        })
    );
    var series = chart.series.push(
        am5percent.PieSeries.new(root, {
          categoryField: "category",
          valueField: "value",
          innerRadius: am5.percent(30), // ieu
          // tooltip: disableLabelsAndTooltips ? null : am5.Tooltip.new(root, {})
        })
    );

    series.labels.template.setAll({
      radius: -10, // Adjust this value to place labels inside the pie
      relativeRadius: 0.8, // Relative size of the label radius
      textType: "radial",
      fontSize: 14,
    });
      
    series.ticks.template.setAll({
        strokeWidth: 4,
        stroke: am5.color(0x000000) // Mengatur warna garis, ganti dengan warna yang diinginkan
    });

    let total = dataChart.reduce((sum, item) => sum + item.value, 0);
    chart.seriesContainer.children.push(am5.Label.new(root, {
        text: disableLabelsAndTooltips ? '0' : formatNumberIndo(total),
        fontSize: 30,
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        fill: am5.color(0x000000)
    }));

    var legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        // layout: root.horizontalLayout,
        // y: am5.percent(100),  // Mengatur posisi legend di bawah
        // centerY: am5.percent(100),
        // nameField: '{categoryX}',
        // marginTop: 10,
    }));
    
    
    // Assign colors to each slice
    series.slices.template.adapters.add("fill", function(fill, target) {
        return target.dataItem.dataContext.color
    });

    series.slices.template.adapters.add("tooltipText", function(fill, target) {
      if (disableLabelsAndTooltips) {
        return 0
      } else {
        const dataCtx = target?.dataItem?.dataContext
        if (dataCtx) {
          const persen = isNaN(((dataCtx.value / totalValue) * 100).toFixed(2)) ? 0 : ((dataCtx.value / totalValue) * 100).toFixed(2)
          if (dataCtx?.type) {
            if (dataCtx?.type === 'Rupiah') {
              return `${formatNumberIDR(dataCtx.value)} (${persen}%)`
            }
            return `${dataCtx.value} ${dataCtx.type} (${persen}%)`
          }
          return `${dataCtx.value} (${persen}%)`
        }
        return 0
      }
    });
    
    series.slices.template.adapters.add("stroke", function(stroke, target) {
        return am5.color(0xFFFFFF); // Set stroke color for better visual separation
    });
    series.labels.template.adapters.add("text", function(text, target) {
      const dataCtx = target?.dataItem?.dataContext
      if (disableLabelsAndTooltips) {
        return text
      } else {
        if (dataCtx) {
          const persen = isNaN(((dataCtx.value / totalValue) * 100).toFixed(2)) ? 0 : ((dataCtx.value / totalValue) * 100).toFixed(2)
          if (dataCtx?.type) {
            if (dataCtx?.type === 'Rupiah') {
              return `${formatIDR(dataCtx.value)} (${persen}%)`
            }
            return `${dataCtx.value} ${dataCtx.type} (${persen}%)`
          }
          return `${dataCtx.value} (${persen}%)`
        }
        
      }
      return 0
    })
    series.data.setAll(dataChart);

    // Disable labels if it's "No Data"
    if (disableLabelsAndTooltips) {
      series.labels.template.set("visible", false); // Hide labels
      series.ticks.template.set("visible", false);  // Hide ticks
    }
    legend.labels.template.adapters.add("text",(text, target) => {
      const dataCtx = target?.dataItem?.dataContext
      
      if (disableLabelsAndTooltips) {
        return text
      } else {
        if (dataCtx) {
          if (dataCtx?.categoryShort) {
            return `${dataCtx.categoryShort} (${formatNumberIndo((dataCtx?.value ?? 0))})`
          }
        }
      }

      return `${text} (${formatNumberIndo((dataCtx?.value ?? 0))})`
    })
    legend.data.setAll(series.dataItems);
    legend.valueLabels.template.set("forceHidden", true);
    
    root._logo.setAll({
        height: -100000000  // Adjust the height as needed
    });
    return () => {
        root.dispose();
    };
  }, [data])
  return (
    <Card className='card-one bg-white'>
      <Card.Body className='d-flex flex-column gap-2'>
        <Card.Text as="label" style={{paddingLeft: 0}} className='fs-5 fw-bold'>{title}</Card.Text>
        <div className="chart-box" ref={chartRef}>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ChartPie;