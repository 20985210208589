import React from "react";
import { Form } from "react-bootstrap";

function Select({ label, required, default: defaultText, options, ...rest }) {
  return (
    <React.Fragment>
      {label && (
        <Form.Label className="form-label fs-sm mb-1">
          {required && <span style={{ color: "red" }}>* </span>}
          {label}
        </Form.Label>
      )}
      <Form.Select required={required} {...rest}>
        <option value="">{defaultText ?? "-"}</option>
        {(options ?? []).map((opt, idx) => (
          <option key={idx} value={opt.value} disabled={opt.isDisabled}>
            {opt.text}
          </option>
        ))}
      </Form.Select>
    </React.Fragment>
  );
}

export default Select;
