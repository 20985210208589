import { Form } from "react-bootstrap";
import React from "react";

function Input({
  style,
  label,
  caption,
  infoCaption,
  required,
  className,
  readOnly,
  width,
  accepts,
  multiple,
  ...rest
}) {
  return (
    <React.Fragment>
      <div className={`${className || ""} ${width || ""}`} style={style}>
        {label && (
          <Form.Label className="form-label fs-sm mb-1">
            {required && <span style={{ color: "red" }}>* </span>}
            {label}
          </Form.Label>
        )}
        <Form.Control
          style={style}
          className={`form-control ${className || ""} ${
            readOnly ? "bg-light" : ""
          }`}
          accept={accepts && accepts.join(",")}
          multiple={multiple}
          required={required}
          {...rest}
        />
        {caption && (
          <Form.Label className="form-label fs-sm mb-1">{caption}</Form.Label>
        )}
        {(infoCaption)&& (
          <Form.Label className="form-label fs-xs mb-1 fw-bold">{infoCaption}</Form.Label>
        )}
      </div>
    </React.Fragment>
  );
}

export default Input;
