import React from 'react'

function PdfViewer({uri}) {
  if (!uri) return null
  let type = uri.split('.')
  type = type[type.length - 1]
  if (type !== 'pdf') return null
  uri = `${uri}#toolbar=0`
  return (
    <div className="iframe-file">
        <iframe
            title={'PDF-Viewer'}
            src={uri}
            style={{ height: '100%', width: '100%' }}>
        </iframe>
    </div>
  )
}

export default PdfViewer