import Swal from "sweetalert2";

function showLoading() {
    Swal.fire({
        title: '',
        html: 'Please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
}

function close() {
    Swal.close();
}

function hideLoading() {
    Swal.hideLoading();
}

function showMessage(title, icon, text) {
    const availableIcons = [
        "success",
        "error",
        "warning",
        "info",
        "question"
    ];

    if(!availableIcons.includes(icon)) icon = "warning"; // set default if icon doesn't found
    Swal.fire({title, icon, text});
}

function showConfirm(type = '', confirmBtnText = 'Simpan') {
    type = ['warning', 'success', 'error', 'info', 'question'].includes(type) ? type : 'question'

    return Swal.fire({
        title: "Apa anda yakin?",
        icon: type,
        showCancelButton: true,
        confirmButtonText: confirmBtnText,
        cancelButtonText: "Batal",
    });
}

export default {
    showLoading,
    close,
    showMessage,
    showConfirm,
    hideLoading
}