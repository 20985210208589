import React, { useState, useEffect, useCallback } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Accordion,
  Row,
  Spinner,
  Table,
  Form,
  Alert,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { DatePicker, Input, Select } from "../../../../components/Components";
import {
  useGetTahtiList,
  useGetDetailTahti,
  useGetDetailPembantaran,
  usePostPembantaran,
  usePutPembantaran,
} from "../../../../utils/apis/query";
import { useQuery } from "react-query";
import { format, parse } from "date-fns";
import moment from "moment";
import { debounce } from "lodash";

function FormPembantaran() {
  const onSuccess = () => {
    setTimeout(() => {
      navigate("/tahanan/pembantaran/list");
    }, 1500);
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [modalShow, setModalShow] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [payloadCariTahanann, setPayloadCariTahanan] = useState({
    nama_tersangka: "",
  });
  const [namaTahanan, setNamaTahanan] = useState("");
  const [idTahanan, setIdTahanan] = useState("");
  const [showForm, setShowForm] = useState(false);
  const mutateAdd = usePostPembantaran(onSuccess);
  const mutateEdit = usePutPembantaran(onSuccess);
  const [penyidik, setPenyidik] = useState([]);

  const [payloadForm, setPayloadForm] = useState({
    nama_tahanan: "",
    no_tahanan: "",
    no_sprin_pembantaran: "",
    nama_rumah_sakit: "",
    lokasi_rumah_sakit: "",
    tanggal_sprin_pembantaran: "",
    awal_bantar: "",
    akhir_bantar: "",
    kondisi: "",
    alasan: "",
    keterangan: "",
    arsip: "",
    lampiran: "",
    penyidik_ids: [],
  });
  const [payloadFormDataTahanan, setPayloadFormDataTahanan] = useState({
    no_tahanan: "",
    nama_tahanan: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    umur: "",
    jenis_kelamin: "",
    agama: "",
    kebangsaan: "",
    asal_negara: "",
    pekerjaan: "",
    alamat: "",
    perkara: "",
    nama_rutan: "",
    alamat_rutan: "",
    rumus_wajah: "",
    rumus_sidikjari: "",
  });

  const {
    data: dataTahanan,
    isFetching: tahananFetching,
    isError: tahananError,
  } = useGetDetailTahti(idTahanan, { enabled: !!idTahanan });

  const {
    data: dataPembantaran,
    isFetching: pembantaranIsFetching,
    isError: pembantaranIsError,
    error: pembantaranError,
  } = useGetDetailPembantaran(id, { enabled: !!id });

  const { data: dataTahananList, isFetching: modalFetching } = useGetTahtiList(
    payloadCariTahanann,
    { enabled: !!namaTahanan }
  );

  const handleDebounce = useCallback(
    debounce((newPayload) => {
      setPayloadCariTahanan(newPayload);
    }, 500),
    []
  );

  const handleChange = (e) => {
    setIsFormDirty(true);
    const { name, value, files } = e.target;
    // setPayloadForm({ ...payloadForm, [name]: value });
    if (["lampiran", "arsip"].includes(name)) {
      setPayloadForm({ ...payloadForm, [name]: files[0] });
    } else {
      setPayloadForm({ ...payloadForm, [name]: value });
    }
  };

  const handleDatePicker = (date, name) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setPayloadForm({ ...payloadForm, [name]: formattedDate });
  };

  const handleCheckbox = (data, isChecked) => {
    if (isChecked) {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: [...payloadForm.penyidik_ids, data.id_penyidik],
      });
    } else {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: payloadForm.penyidik_ids.filter(
          (selectedId) => selectedId !== data.id_penyidik
        ),
      });
    }
  };

  const handleSubmit = () => {
    let formData = new FormData();
    for (const [key, val] of Object.entries(payloadForm)) {
      formData.append(key, val);
    }
    if (isEdit) {
      mutateEdit.mutate({ id, formData });
    } else {
      mutateAdd.mutate(formData);
    }
  };

  useEffect(() => {
    if (id) {
      setModalShow(false);
      setShowForm(true);
      setIsEdit(true);
    }
    if (dataTahanan) {
      const _ = dataTahanan;
      setPayloadFormDataTahanan({
        no_tahanan: _.no_tahanan,
        nama_tahanan: _.nama_tersangka,
        tempat_lahir: _.tempat_lahir,
        tanggal_lahir: _.tanggal_lahir,
        umur: _.umur,
        jenis_kelamin: _.jenis_kelamin,
        agama: _.agama,
        kebangsaan: _.kebangsaan,
        asal_negara: _.asal_negara,
        pekerjaan: _.pekerjaan,
        alamat: _.alamat,
        perkara: _.perkara,
        nama_rutan: _.nama_rutan,
        alamat_rutan: _.alamat_rutan,
        rumus_wajah: _.rumus_wajah,
        rumus_sidikjari: _.rumus_sidikjari,
      });
      setPenyidik(_?.penyidik ?? []);
    }
    if (dataPembantaran) {
      setPayloadForm({
        ...payloadForm,
        nama_tahanan: dataPembantaran.nama_tahanan,
        no_tahanan: dataPembantaran.no_tahanan,
        no_sprin_pembantaran: dataPembantaran.no_sprin_pembantaran,
        nama_rumah_sakit: dataPembantaran.nama_rumah_sakit,
        lokasi_rumah_sakit: dataPembantaran.lokasi_rumah_sakit,
        tanggal_sprin_pembantaran: dataPembantaran.tanggal_sprin_pembantaran,
        awal_bantar: dataPembantaran.awal_bantar,
        akhir_bantar: dataPembantaran.akhir_bantar,
        kondisi: dataPembantaran.kondisi,
        alasan: dataPembantaran.alasan,
        keterangan: dataPembantaran.keterangan,
        arsip: dataPembantaran.pembantaran_tahanan_files[0]?.arsip,
        lampiran: dataPembantaran.pembantaran_tahanan_files[1]?.lampiran,
      });
      setIdTahanan(dataPembantaran.id_tahanan);
    }
  }, [dataTahanan, dataPembantaran]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  useEffect(() => {
    handleDebounce({ ...payloadCariTahanann, nama_tersangka: namaTahanan });
  }, [namaTahanan, handleDebounce, payloadCariTahanann]);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/tahanan">Tahanan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/tahanan/pembantaran/list">
                    List Pembantaran Tahanan
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form Pembantaran Tahanan
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            {
              <Col sm="12">
                {tahananFetching && !tahananError && (
                  <div className="d-flex justify-content-center">
                    <Spinner variant="primary" size="small" />
                  </div>
                )}
                {!tahananFetching && tahananError && (
                  <div className="d-flex justify-content-center">
                    <span className="text-danger text-center">
                      <b>Oops Error</b> please refresh the page / re-login
                    </span>
                  </div>
                )}
                {mutateAdd.isError && (
                  <Alert variant="danger">
                    <ul>
                      {mutateAdd?.error?.response?.data?.detail?.map(
                        (errEdit) => (
                          <li>
                            <b>{errEdit.loc[1]} </b>
                            {errEdit.msg}
                          </li>
                        )
                      ) ?? (
                        <li>
                          <b>Error</b>Sesuatu Bermasalah
                        </li>
                      )}
                    </ul>
                  </Alert>
                )}
                {mutateAdd.isSuccess && (
                  <Alert variant="success">{mutateAdd.data.data.message}</Alert>
                )}
              </Col>
            }
            {
              <Col sm="12">
                {mutateEdit.isError && (
                  <Alert variant="danger">
                    <ul>
                      {mutateEdit?.error?.response?.data?.detail?.map(
                        (errEdit) => (
                          <li>
                            <b>{errEdit.loc[1]} </b>
                            {errEdit.msg}
                          </li>
                        )
                      ) ?? (
                        <li>
                          <b>Error</b>Sesuatu Bermasalah
                        </li>
                      )}
                    </ul>
                  </Alert>
                )}
                {mutateEdit.isSuccess && (
                  <Alert variant="success">
                    {mutateEdit.data.data.message}
                  </Alert>
                )}
              </Col>
            }
            <Col sm="12">
              <Accordion defaultActiveKey="0" flush alwaysOpen>
                {showForm && !tahananFetching && !tahananError && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Informasi / Profil Tahanan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2">
                            <Col sm="4">
                              <Input label="No. Tahanan" required />
                            </Col>
                            <Col sm="4">
                              <Input label="Nama" required />
                            </Col>
                            <Col sm="2">
                              <Input label="Tampat Lahir" required />
                            </Col>
                            <Col sm="2">
                              <DatePicker
                                label="Tgl Lahir"
                                name=""
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy HH:mm:ss"
                                required
                              />
                            </Col>
                            <Col sm="2">
                              <Input
                                label="Umur Saat Ini"
                                type="number"
                                required
                              />
                            </Col>
                            <Col sm="2">
                              <Select
                                required
                                label="Kelamin"
                                default="Pilih"
                                options={[
                                  { value: "Laki-Laki", text: "Laki-Laki" },
                                ]}
                              />
                            </Col>
                            <Col sm="2">
                              <Select
                                required
                                label="Kewarganegaraan"
                                default="Pilih"
                                options={[{ value: "WNI", text: "WNI" }]}
                              />
                            </Col>
                            <Col sm="2">
                              <Select
                                required
                                label="Asal Negara"
                                default="Pilih"
                                options={[
                                  { value: "Indonesia", text: "Indonesia" },
                                ]}
                              />
                            </Col>
                            <Col sm="2">
                              <Select
                                required
                                label="Agama"
                                default="Pilih"
                                options={[{ value: "Islam", text: "Islam" }]}
                              />
                            </Col>
                            <Col sm="2">
                              <Input label="Pekerjaan" type="text" required />
                            </Col>
                            <Col sm="6">
                              <Input as="textarea" label="Alamat" required />
                            </Col>
                            <Col sm="3">
                              <Input type="file" label="Unggah Foto" required />
                            </Col>
                            <Col sm="3">
                              <Input
                                type="file"
                                label="Unggah Rumus Sidik Jari"
                                required
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {showForm && !tahananFetching && !tahananError && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Informasi Penahanan</Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2 mt-2">
                            <Col sm="4">
                              <Select
                                required
                                label="Perkara"
                                default="Pilih"
                                options={[
                                  { value: "Perampokan", text: "Perampokan" },
                                ]}
                              />
                            </Col>
                            <Col sm="4">
                              <Select
                                required
                                label="Penempatan Rutan"
                                default="Pilih"
                                options={[
                                  {
                                    value: "Rutan Polresta Depok",
                                    text: "Rutan Polresta Depok",
                                  },
                                ]}
                              />
                            </Col>
                            <Col sm="4">
                              <Input label="Lokasi Rutan" required />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {showForm && !tahananFetching && !tahananError && (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Informasi Dokumen Pembataran
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2">
                            <Col sm="12">
                              <Row className="g-2 mt-1">
                                <Col sm="5">
                                  <Input
                                    label="Nomor Sprin Pembantaran Tahanan"
                                    required
                                  />
                                </Col>
                                <Col sm="3">
                                  <DatePicker
                                    label="Tanggal"
                                    name=""
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                    required
                                  />
                                </Col>
                                <Col sm="4">
                                  <Input
                                    label="Unggah Arsip"
                                    type="file"
                                    required
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {showForm && !tahananFetching && !tahananError && (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Informasi Penyidik (Pembuat Pembantaran)
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2">
                            <Col sm="12">
                              <Card className="card bg-white">
                                <Card.Body>
                                  <Row className="g-1 mb-2 d-flex align-items-center">
                                    <Col sm="12" md="5">
                                      <h6>Daftar Penyidik</h6>
                                    </Col>
                                    <Col sm="12" md="7">
                                      <Link className="d-flex align-items-center justify-content-end"></Link>
                                    </Col>
                                  </Row>
                                  <Table className="table dems-table">
                                    <thead>
                                      <tr>
                                        <th>No</th>
                                        <th>NRP</th>
                                        <th>Nama Penyidik</th>
                                        <th>Asal Satker</th>
                                        <th>Pangkat</th>
                                        <th>Jabatan</th>
                                        <th>Pilih</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {[1, 1, 1, 1].map((data, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>12345678910</td>
                                          <td>Mr. Jhon Dhol</td>
                                          <td>Polres Jaksel</td>
                                          <td>AKBP</td>
                                          <td>Penyidik</td>
                                          <td>
                                            <Form.Check
                                              type="checkbox"
                                              name="yeah"
                                              id="yeah"
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {showForm && !tahananFetching && !tahananError && (
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Informasi Pembantaran</Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2 mt-2">
                            <Col sm="4">
                              <Select
                                required
                                label="Lokasi Rumah Sakit"
                                default="Pilih"
                                options={[
                                  { value: "Sehat", text: "RSUD DEPOK" },
                                ]}
                              />
                            </Col>
                            <Col sm="4">
                              <Input label="Lokasi Rumah Sakit" required />
                            </Col>
                            <Col sm="4">
                              <Select
                                required
                                label="Kondisi Fisik Tahanan"
                                default="Pilih"
                                options={[{ value: "Sehat", text: "Sehat" }]}
                              />
                            </Col>
                            <Col sm="4">
                              <DatePicker
                                label="Tgl Mulai Pinjam"
                                name=""
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy HH:mm:ss"
                                required
                              />
                            </Col>
                            <Col sm="4">
                              <DatePicker
                                label="Tgl Selesai Pinjam"
                                name=""
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy HH:mm:ss"
                                required
                              />
                            </Col>
                            <Col sm="5">
                              <Input
                                as="textarea"
                                label="Alasan Pembantaran"
                                required
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {showForm && !tahananFetching && !tahananError && (
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Informasi Tambahan</Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="mt-2 g-2">
                            <Col sm="4">
                              <Input
                                label="Lampiran Pendukung"
                                type="file"
                                required
                              />
                            </Col>
                            <Col sm="6">
                              <Input
                                as="textarea"
                                col="4"
                                label="Keterangan"
                                required
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center gap-3">
            {!mutateAdd.isLoading || !mutateEdit.isLoading ? (
              <Button variant="primary" onClick={handleSubmit}>
                {isEdit ? "Save" : "Submit"}
              </Button>
            ) : (
              <Button variant="primary" disabled>
                Loading
              </Button>
            )}
            <Button variant="warning" as={Link} to="/tahanan/pembantaran/list">
              BATAL
            </Button>
          </div>
        </Card.Footer>
      </Card>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Cari Tahanan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-4">
            <Col sm="12">
              <Input
                label="Nama Tahanan"
                onChange={(e) => {
                  setNamaTahanan(e.target.value);
                }}
              ></Input>
            </Col>
            <Col sm="12">
              <Table className="table dems-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Tahanan</th>
                    <th>Asal Satker</th>
                    <th>Perkara</th>
                    <th>Lokasi</th>
                    <th>Pilih</th>
                  </tr>
                </thead>
                <tbody>
                  {modalFetching && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="small"
                        />
                      </td>
                    </tr>
                  )}
                  {!modalFetching &&
                    dataTahananList.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.nama_tersangka}</td>
                        <td>{data.nama_satker}</td>
                        <td>{data.perkara}</td>
                        <td>{data.nama_rutan}</td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            onClick={() => {
                              setIdTahanan(data.id);
                              setShowForm(true);
                              setModalShow(false);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default FormPembantaran;
