import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Accordion,
  Row,
  Spinner,
  Table,
  Form,
  Alert,
  Placeholder,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  DatePicker,
  Image,
  Input,
  Select,
} from "../../../../components/Components";
import { debounce } from "lodash";
import {
  useGetDetailTahti,
  useGetPerpanjanganDetail,
  usePostPerpanjangan,
  usePutPerpanjangan,
} from "../../../../utils/apis/query";
import moment from "moment";
import { useQueryClient } from "react-query";
import {
  encodeToHex,
  formatString,
  pangkat,
  trimFileUrl,
  trimUrlPath,
} from "../../../../utils";

function FormEdit() {
  const [isEdit, setIsEdit] = useState(false);
  const [isFormEditAble, setIsFormEditAble] = useState(true);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [formReady, setFormReady] = useState(false);
  const [payloadCariTahanann, setPayloadCariTahanan] = useState({
    nama_tersangka: "",
  });
  const [namaTahanan, setNamaTahanan] = useState("");
  const [idTahanan, setIdTahanan] = useState("");
  const [fase, setFase] = useState(0);
  const [penyidik, setPenyidik] = useState([]);
  const [payloadDate, setPayloadDate] = useState({
    tanggal_sprin_perpanjangan: "",
    awal_perpanjang: "",
    akhir_perpanjang: "",
    tanggal_ba_perpanjangan_penahanan: "",
    tanggal_surat_kejaksaan_t4: "",
  });

  const [payloadForm, setPayloadForm] = useState({
    nama_tahanan: "",
    no_tahanan: "",
    no_sprin_perpanjangan: "",
    tanggal_sprin_perpanjangan: "",
    awal_perpanjang: "",
    akhir_perpanjang: "",
    kondisi: "",
    alasan: "",
    keterangan: "",
    status_dokumen: "",
    fase,
    penyidik_ids: [],
    no_ba_perpanjangan_penahanan: "",
    no_surat_perpanjangan_penahanan_kejaksaan_t4: "",
    tanggal_ba_perpanjangan_penahanan: "",
    tanggal_surat_kejaksaan_t4: "",
    arsip_sprin_perpanjangan_tahanan: "",
    arsip_ba_perpanjangan_penahanan: "",
    arsip_surat_perpanjangan_penahanan_kejaksaan_t4: "",
    lampiran_pendukung1: "",
    nama_fase_penahanan: "",
  });
  const [payloadFormDataTahanan, setPayloadFormDataTahanan] = useState({
    no_tahanan: "",
    nama: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    umur: "",
    kelamin: "",
    kewarganegaraan: "",
    asal_negara: "",
    agama: "",
    pekerjaan: "",
    alamat: "",
    foto_tahanan: "",
    foto_sidikjari: "",
    perkara: "",
    penempatan_rutan: "",
    lokasi_rutan: "",
    riwayat: [],
  });

  const {
    data: dataTahanan,
    isFetching: tahananFetching,
    isError: tahananError,
  } = useGetDetailTahti(idTahanan, { enabled: !!idTahanan });
  const {
    data: dataDetail,
    isFetching: isDataDetailFetching,
    isError: isDataDetailError,
    error: dataDetailError,
  } = useGetPerpanjanganDetail(id);

  const handleCheckbox = (data, isChecked) => {
    if (isChecked) {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: [...payloadForm.penyidik_ids, data.id_penyidik],
      });
    } else {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: payloadForm.penyidik_ids.filter(
          (selectedId) => selectedId !== data.id_penyidik
        ),
      });
    }
  };

  const handleChange = (e) => {
    setIsFormDirty(true);
    const { name, value } = e.target;
    setPayloadForm({ ...payloadForm, [name]: value });
  };

  const handleDebounce = useCallback(
    debounce((newPayload) => {
      setPayloadCariTahanan(newPayload);
    }, 500),
    []
  );

  const onSuccess = (response) => {
    setIsFormDirty(false);
    setTimeout(() => {
      navigate("/tahanan/perpanjangan/list");
    }, 2500);
  };
  const mutateSubmit = usePutPerpanjangan(onSuccess);

  const handleSubmit = (status) => {
    let formData = new FormData();
    payloadForm.fase = fase;
    payloadForm.status_dokumen = status;
    Object.keys(payloadForm).forEach((key) => {
      if (
        typeof payloadForm[key] === "string" ||
        typeof payloadForm[key] === "number"
      ) {
        formData.append(key, payloadForm[key]);
      }
    });
    payloadForm.penyidik_ids.forEach((ids, idx) => {
      formData.append(`penyidik_ids`, ids);
    });
    payloadForm.arsip_sprin_perpanjangan_tahanan?.forEach((file, idx) => {
      formData.append(`arsip_sprin_perpanjangan_tahanan`, file);
    });
    payloadForm.arsip_ba_perpanjangan_penahanan?.forEach((file, idx) => {
      formData.append(`arsip_ba_perpanjangan_penahanan`, file);
    });
    payloadForm.arsip_surat_perpanjangan_penahanan_kejaksaan_t4?.forEach(
      (file, idx) => {
        formData.append(
          `arsip_surat_perpanjangan_penahanan_kejaksaan_t4`,
          file
        );
      }
    );
    payloadForm.lampiran_pendukung1?.forEach((file, idx) => {
      formData.append(`lampiran_pendukung1`, file);
    });

    mutateSubmit.mutate({ payload: formData, id: id });
  };

  useEffect(() => {
    if (payloadDate.awal_perpanjang && fase) {
      const awal = payloadDate.awal_perpanjang;
      const dateKeluar = moment(awal).add(fase - 1, "days");
      setPayloadDate({ ...payloadDate, akhir_perpanjang: dateKeluar.toDate() });
      setPayloadForm({
        ...payloadForm,
        akhir_perpanjang: dateKeluar.format("YYYY-MM-DD"),
      });
    }
  }, [payloadDate.awal_perpanjang, fase]);

  useEffect(() => {
    handleDebounce({ ...payloadCariTahanann, nama_tersangka: namaTahanan });
  }, [namaTahanan, handleDebounce, payloadCariTahanann]);

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      setIsFormEditAble(false);
    }
    if (dataDetail && !idTahanan) {
      const _ = dataDetail;
      setIdTahanan(_.id_tahanan);
    }
    if (dataTahanan && dataDetail) {
      const tahanan = dataTahanan;
      const detail = dataDetail;

      const _ = dataTahanan;
      setPayloadFormDataTahanan({
        no_tahanan: tahanan.no_tahanan,
        nama: tahanan.nama_tersangka,
        tempat_lahir: tahanan.tempat_lahir,
        tanggal_lahir: tahanan.tanggal_lahir,
        umur: tahanan.umur,
        kelamin: tahanan.jenis_kelamin,
        kewarganegaraan: tahanan.kebangsaan,
        asal_negara: tahanan.asal_negara,
        agama: tahanan.agama,
        perkara: tahanan.perkara,
        pekerjaan: tahanan.pekerjaan,
        alamat: tahanan.alamat,
        foto_tahanan: tahanan.rumus_wajah,
        foto_sidikjari: tahanan.rumus_sidikjari,
        penempatan_rutan: tahanan.nama_rutan,
        lokasi_rutan: tahanan.alamat_rutan,
        riwayat: tahanan.riwayat_perpanjangan,
      });
      setPayloadForm({
        ...payloadForm,
        nama_tahanan: detail.nama_tahanan,
        no_tahanan: detail.no_tahanan,
        no_sprin_perpanjangan: detail.no_sprin_perpanjangan,
        tanggal_sprin_perpanjangan: detail?.tanggal_sprin_perpanjangan,
        awal_perpanjang: detail.awal_perpanjang,
        akhir_perpanjang: detail.akhir_perpanjang,
        kondisi: detail.kondisi,
        alasan: detail.alasan,
        keterangan: detail.keterangan,
        status_dokumen: detail.status_dokumen,
        penyidik_ids: detail?.penyidik?.map((e) => e.id_penyidik) ?? [],
        no_ba_perpanjangan_penahanan: detail.no_ba_perpanjangan_penahanan,
        no_surat_perpanjangan_penahanan_kejaksaan_t4:
          detail.no_surat_perpanjangan_penahanan_kejaksaan_t4,
        tanggal_ba_perpanjangan_penahanan:
          detail.tanggal_ba_perpanjangan_penahanan,
        tanggal_surat_kejaksaan_t4: detail.tanggal_surat_kejaksaan_t4,
        nama_fase_penahanan: detail.nama_fase_penahanan,
      });
      setPayloadDate({
        ...payloadDate,
        tanggal_sprin_perpanjangan: detail?.tanggal_sprin_perpanjangan
          ? moment(detail?.tanggal_sprin_perpanjangan).toDate()
          : null,
        awal_perpanjang: detail?.awal_perpanjang
          ? moment(detail?.awal_perpanjang).toDate()
          : null,
        akhir_perpanjang: detail?.akhir_perpanjang
          ? moment(detail?.akhir_perpanjang).toDate()
          : null,
        tanggal_ba_perpanjangan_penahanan:
          detail?.tanggal_ba_perpanjangan_penahanan
            ? moment(detail?.tanggal_ba_perpanjangan_penahanan).toDate()
            : null,
        tanggal_surat_kejaksaan_t4: detail?.tanggal_surat_kejaksaan_t4
          ? moment(detail?.tanggal_surat_kejaksaan_t4).toDate()
          : null,
      });
      setFase(detail?.fase_penahanan?.split("/")[0] ?? "");
      setPenyidik(_?.penyidik ?? []);
      setFormReady(true);
    }
  }, [dataTahanan, dataDetail]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  useEffect(() => {
    // Reset payloadDate state
    setPayloadDate({
      tanggal_sprin_perpanjangan: "",
      tanggal_permin_perpanjanagan: "",
      awal_perpanjang: "",
      akhir_perpanjang: "",
    });

    // Reset payloadForm state
    setPayloadForm({
      arsip: [],
      lampiran: [],
      nama_tahanan: "",
      no_tahanan: "",
      no_sprin_perpanjangan: "",
      tanggal_sprin_perpanjangan: "",
      awal_perpanjang: "",
      akhir_perpanjang: "",
      kondisi: "",
      alasan: "",
      keterangan: "",
      status_dokumen: "",
      fase: "",
      penyidik_ids: [],
    });

    // Reset payloadFormDataTahanan state
    setPayloadFormDataTahanan({
      no_tahanan: "",
      nama: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      umur: "",
      kelamin: "",
      kewarganegaraan: "",
      asal_negara: "",
      agama: "",
      pekerjaan: "",
      alamat: "",
      foto_tahanan: "",
      foto_sidikjari: "",
      perkara: "",
      penempatan_rutan: "",
      lokasi_rutan: "",
      riwayat: [],
    });
  }, []);

  useEffect(() => {
    let elements = document.querySelectorAll("input, button, select, textarea");

    elements.forEach(function (element) {
      if (element.classList.contains("accordion-button")) {
        element.disabled = false;
      } else {
        element.disabled = true;
      }
    });
  }, []);

  const one =
    payloadForm.no_sprin_perpanjangan &&
    payloadForm.tanggal_sprin_perpanjangan &&
    (dataDetail?.perpanjangan_files[0]?.arsip_sprin_perpanjangan_tahanan
      ?.length > 0 ||
      payloadForm.arsip_sprin_perpanjangan_tahanan);
  const two =
    payloadForm.no_ba_perpanjangan_penahanan &&
    payloadForm.tanggal_ba_perpanjangan_penahanan &&
    (dataDetail?.perpanjangan_files[0]?.arsip_ba_perpanjangan_penahanan
      ?.length > 0 ||
      payloadForm.arsip_ba_perpanjangan_penahanan);
  const three =
    payloadForm.no_surat_perpanjangan_penahanan_kejaksaan_t4 &&
    payloadForm.tanggal_surat_kejaksaan_t4 &&
    (dataDetail?.perpanjangan_files[0]
      ?.arsip_surat_perpanjangan_penahanan_kejaksaan_t4?.length > 0 ||
      payloadForm.arsip_surat_perpanjangan_penahanan_kejaksaan_t4);

  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/tahanan">Tahanan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/tahanan/perpanjangan/list">
                    List Perpanjangan Tahanan
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form Perpanjangan Tahanan
                </li>
              </ol>
            </div>
            {isEdit && (
              <Button
                className="d-flex align-items-center gap-2"
                variant="primary"
                size="normal"
                onClick={() => setIsFormEditAble(!isFormEditAble)}
              >
                <i className="ri-edit-2-line"></i>
                {isFormEditAble ? "Batalkan Edit" : "Edit Form"}
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {mutateSubmit.isError && (
                <Alert variant="danger">
                  <ul className="m-0">
                    {mutateSubmit?.error?.response?.data?.detail?.map(
                      (errEdit, idx) => (
                        <li key={idx}>
                          <b>{errEdit.loc[1]} : </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error : </b>
                        {mutateSubmit?.error?.response?.data?.message ??
                          "Bermasalah"}
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateSubmit.isSuccess && (
                <Alert variant="success">
                  {mutateSubmit.data.data.message}
                </Alert>
              )}
            </Col>
            <Col sm="12">
              {!formReady && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner variant="primary" size="sm" />
                </div>
              )}
              {formReady && (
                <Accordion
                  defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                  flush
                  alwaysOpen
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="custom-header">
                      Informasi / Profil Tahanan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row>
                            <Col sm="4" className="d-flex flex-column gap-3">
                              <div className="d-flex flex-column gap-2">
                                <span className="fs-6 fw-medium">
                                  Foto Tahanan
                                </span>
                                <Image
                                  src={payloadFormDataTahanan.foto_tahanan}
                                  height="250px"
                                  width="100%"
                                />
                              </div>
                              <div className="d-flex flex-column gap-2">
                                <span className="fs-6 fw-medium">
                                  Foto Sidik Jari
                                </span>
                                <Image
                                  src={payloadFormDataTahanan.foto_sidikjari}
                                  height="250px"
                                  width="100%"
                                />
                              </div>
                            </Col>
                            <Col sm="8">
                              {tahananFetching && !tahananError && (
                                <Placeholder
                                  as="p"
                                  animation="wave"
                                  className="m-0 d-flex flex-column gap-2"
                                >
                                  <Placeholder sm="12" />
                                  <Placeholder sm="12" />
                                  <Placeholder sm="12" />
                                  <Placeholder sm="12" />
                                  <Placeholder sm="12" />
                                </Placeholder>
                              )}
                              {!tahananFetching && tahananError && (
                                <div className="d-flex justify-content-center">
                                  <span className="fs-5 text-danger">
                                    <b>Error : </b> Terjadi masalah koneksi
                                  </span>
                                </div>
                              )}
                              {!tahananFetching && !tahananError && (
                                <Row className="g-2">
                                  <Col sm="6">
                                    <Input
                                      label="No. Tahanan"
                                      name="no_tahanan"
                                      defaultValue={
                                        payloadFormDataTahanan.no_tahanan
                                      }
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      label="Nama"
                                      name="nama"
                                      defaultValue={payloadFormDataTahanan.nama}
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      label="Tempat Lahir"
                                      defaultValue={
                                        payloadFormDataTahanan.tempat_lahir
                                      }
                                      name="tempat_lahir"
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      label="Tanggal Lahir"
                                      name="tanggal_lahir"
                                      defaultValue={
                                        payloadFormDataTahanan.tanggal_lahir
                                      }
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      type="number"
                                      label="Umur Saat Ini"
                                      name="umur"
                                      defaultValue={payloadFormDataTahanan.umur}
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      label="Kelamin"
                                      name="jenis_kelamin"
                                      disabled
                                      defaultValue={
                                        payloadFormDataTahanan.kelamin
                                      }
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      label="Kewarganegaraan"
                                      name="kebangsaan"
                                      defaultValue={
                                        payloadFormDataTahanan.kewarganegaraan
                                      }
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      label="Asal Negara"
                                      name="asal_negara"
                                      defaultValue={
                                        payloadFormDataTahanan.asal_negara
                                      }
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      label="Agama"
                                      name="agama"
                                      defaultValue={
                                        payloadFormDataTahanan.agama
                                      }
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Input
                                      label="Pekerjaan"
                                      name="pekerjaan"
                                      defaultValue={
                                        payloadFormDataTahanan.pekerjaan
                                      }
                                      type="text"
                                      disabled
                                    />
                                  </Col>
                                  <Col sm="12">
                                    <Input
                                      as="textarea"
                                      label="Alamat"
                                      defaultValue={
                                        payloadFormDataTahanan.alamat
                                      }
                                      name="alamat"
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="custom-header">
                      Informasi Dokumen Penahanan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2">
                            <Col sm="12">
                              <Row className="g-2 mt-1">
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center me-3"
                                >
                                  1
                                </Col>
                                <Col sm="5">
                                  <Input
                                    label="Nomor Sprin Perpanjangan Tahanan"
                                    name="no_sprin_perpanjangan"
                                    required
                                    onChange={handleChange}
                                    defaultValue={
                                      payloadForm.no_sprin_perpanjangan
                                    }
                                    disabled={!isFormEditAble}
                                  />
                                </Col>
                                <Col sm="2">
                                  <DatePicker
                                    label="Tanggal Spin"
                                    name="tanggal_sprin_perpanjangan"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                      setPayloadDate({
                                        ...payloadDate,
                                        tanggal_sprin_perpanjangan: date,
                                      });
                                      setPayloadForm({
                                        ...payloadForm,
                                        tanggal_sprin_perpanjangan:
                                          moment(date).format("YYYY-MM-DD"),
                                      });
                                    }}
                                    selected={
                                      payloadDate.tanggal_sprin_perpanjangan
                                    }
                                    required
                                    disabled={!isFormEditAble}
                                  />
                                </Col>
                                <Col
                                  sm="4"
                                  className="d-flex flex-column gap-2"
                                >
                                  <Input
                                    label="Arsip Sprin Perpanjangan"
                                    type="file"
                                    onChange={(e) => {
                                      const files = e.target.files[0];
                                      setPayloadForm({
                                        ...payloadForm,
                                        arsip_sprin_perpanjangan_tahanan: [
                                          files,
                                        ],
                                      });
                                    }}
                                    accepts={[".pdf"]}
                                    required
                                    disabled={!isFormEditAble}
                                  />
                                  {dataDetail?.perpanjangan_files[0]
                                    ?.arsip_sprin_perpanjangan_tahanan ? (
                                    (() => {
                                      const {
                                        arsip_sprin_perpanjangan_tahanan,
                                      } = dataDetail.perpanjangan_files[0];
                                      const { p: path, k: kategori } =
                                        trimUrlPath(
                                          arsip_sprin_perpanjangan_tahanan
                                        );
                                      const file = trimFileUrl(
                                        arsip_sprin_perpanjangan_tahanan
                                      );

                                      return (
                                        <Link
                                          target="_blank"
                                          to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                            path
                                          )}`}
                                          className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                        >
                                          <i className="ri-link"></i>
                                          File Yang Sudah Terupload
                                        </Link>
                                      );
                                    })()
                                  ) : (
                                    <Col className="mt-4"> </Col>
                                  )}
                                </Col>
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center ms-3"
                                >
                                  {one ? (
                                    <span
                                      style={{
                                        color: "green",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-check-line"></i>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-close-large-line"></i>
                                    </span>
                                  )}
                                </Col>
                              </Row>
                              <Row className="g-2 mt-1">
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center me-3"
                                >
                                  2
                                </Col>
                                <Col sm="5">
                                  <Input
                                    label="No BA Perpanjangan Penahanan"
                                    name="no_ba_perpanjangan_penahanan"
                                    required
                                    onChange={handleChange}
                                    defaultValue={
                                      payloadForm.no_ba_perpanjangan_penahanan
                                    }
                                    disabled={!isFormEditAble}
                                  />
                                </Col>
                                <Col sm="2">
                                  <DatePicker
                                    label="Tanggal BA Perpanjangan"
                                    name="tanggal_ba_perpanjangan_penahanan"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                      setPayloadDate({
                                        ...payloadDate,
                                        tanggal_ba_perpanjangan_penahanan: date,
                                      });
                                      setPayloadForm({
                                        ...payloadForm,
                                        tanggal_ba_perpanjangan_penahanan:
                                          moment(date).format("YYYY-MM-DD"),
                                      });
                                    }}
                                    selected={
                                      payloadDate.tanggal_ba_perpanjangan_penahanan
                                    }
                                    required
                                    disabled={!isFormEditAble}
                                  />
                                </Col>
                                <Col sm="4">
                                  <Input
                                    label="Arsip BA Perpanjangan"
                                    type="file"
                                    onChange={(e) => {
                                      const files = e.target.files[0];
                                      setPayloadForm({
                                        ...payloadForm,
                                        arsip_ba_perpanjangan_penahanan: [
                                          files,
                                        ],
                                      });
                                    }}
                                    accepts={[".pdf"]}
                                    required
                                    disabled={!isFormEditAble}
                                  />
                                  {dataDetail?.perpanjangan_files[0]
                                    ?.arsip_ba_perpanjangan_penahanan ? (
                                    (() => {
                                      const {
                                        arsip_ba_perpanjangan_penahanan,
                                      } = dataDetail.perpanjangan_files[0];
                                      const { p: path, k: kategori } =
                                        trimUrlPath(
                                          arsip_ba_perpanjangan_penahanan
                                        );
                                      const file = trimFileUrl(
                                        arsip_ba_perpanjangan_penahanan
                                      );

                                      return (
                                        <Link
                                          target="_blank"
                                          to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                            path
                                          )}`}
                                          className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                        >
                                          <i className="ri-link"></i>
                                          File Yang Sudah Terupload
                                        </Link>
                                      );
                                    })()
                                  ) : (
                                    <Col className="mt-4"> </Col>
                                  )}
                                </Col>
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center ms-3"
                                >
                                  {two ? (
                                    <span
                                      style={{
                                        color: "green",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-check-line"></i>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-close-large-line"></i>
                                    </span>
                                  )}
                                </Col>
                              </Row>
                              <Row className="g-2 mt-1">
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center me-3"
                                >
                                  3
                                </Col>
                                <Col sm="5">
                                  <Input
                                    label="No Surat Perpanjangan Penahanan dari Kejaksaan (T-4)"
                                    name="no_surat_perpanjangan_penahanan_kejaksaan_t4"
                                    required
                                    onChange={handleChange}
                                    defaultValue={
                                      payloadForm.no_surat_perpanjangan_penahanan_kejaksaan_t4
                                    }
                                    disabled={!isFormEditAble}
                                  />
                                </Col>
                                <Col sm="2">
                                  <DatePicker
                                    label="Tanggal Surat Perpanjangan"
                                    name="tanggal_surat_kejaksaan_t4"
                                    timeFormat="HH:mm:ss"
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                      setPayloadDate({
                                        ...payloadDate,
                                        tanggal_surat_kejaksaan_t4: date,
                                      });
                                      setPayloadForm({
                                        ...payloadForm,
                                        tanggal_surat_kejaksaan_t4:
                                          moment(date).format("YYYY-MM-DD"),
                                      });
                                    }}
                                    selected={
                                      payloadDate.tanggal_surat_kejaksaan_t4
                                    }
                                    required
                                    disabled={!isFormEditAble}
                                  />
                                </Col>
                                <Col sm="4">
                                  <Input
                                    label="Arsip Surat Perpanjangan Penahanan"
                                    type="file"
                                    onChange={(e) => {
                                      const files = e.target.files[0];
                                      setPayloadForm({
                                        ...payloadForm,
                                        arsip_surat_perpanjangan_penahanan_kejaksaan_t4:
                                          [files],
                                      });
                                    }}
                                    accepts={[".pdf"]}
                                    required
                                    disabled={!isFormEditAble}
                                  />
                                  {dataDetail?.perpanjangan_files[0]
                                    ?.arsip_surat_perpanjangan_penahanan_kejaksaan_t4 ? (
                                    (() => {
                                      const {
                                        arsip_surat_perpanjangan_penahanan_kejaksaan_t4,
                                      } = dataDetail.perpanjangan_files[0];
                                      const { p: path, k: kategori } =
                                        trimUrlPath(
                                          arsip_surat_perpanjangan_penahanan_kejaksaan_t4
                                        );
                                      const file = trimFileUrl(
                                        arsip_surat_perpanjangan_penahanan_kejaksaan_t4
                                      );

                                      return (
                                        <Link
                                          target="_blank"
                                          to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                            path
                                          )}`}
                                          className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                        >
                                          <i className="ri-link"></i>
                                          File Yang Sudah Terupload
                                        </Link>
                                      );
                                    })()
                                  ) : (
                                    <Col className="mt-4"> </Col>
                                  )}
                                </Col>
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center ms-3"
                                >
                                  {three ? (
                                    <span
                                      style={{
                                        color: "green",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-check-line"></i>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      <i className="ri-close-large-line"></i>
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="custom-header">
                      Informasi Perpanjangan Penahanan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2">
                            <Col sm="12">
                              <Card className="card bg-white">
                                <Card.Body>
                                  <Row className="g-1 mb-2 d-flex align-items-center">
                                    <Col sm="12" md="5">
                                      <h6>Riwayat Perpanjangan Penahanan</h6>
                                    </Col>
                                    <Col sm="12" md="7">
                                      <Link className="d-flex align-items-center justify-content-end"></Link>
                                    </Col>
                                  </Row>
                                  <Table className="table dems-table">
                                    <thead>
                                      <tr>
                                        <th>No</th>
                                        <th>Tanggal</th>
                                        <th>No. Sprin Perpanjangan</th>
                                        <th>Fase</th>
                                        <th>Mulai</th>
                                        <th>Selesai</th>
                                        <th>Durasi</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {payloadFormDataTahanan.riwayat.map(
                                        (data, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                              {moment(
                                                data?.created_date
                                              ).format("YYYY-MM-DD")}
                                            </td>
                                            <td>{data.no_sprin}</td>
                                            <td>{data.fase_penahanan}</td>
                                            <td>{data.tanggal_mulai}</td>
                                            <td>{data.tanggal_selesai}</td>
                                            <td>{data.durasi} Hari</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col sm="4">
                              <Select
                                required
                                label="Fase Penahannan"
                                default="Pilih"
                                value={payloadForm.nama_fase_penahanan}
                                onChange={(e) => {
                                  const v = e.target.value;
                                  let result = 0;
                                  if (v === "Penyidik") {
                                    result = 20;
                                  } else if (v === "Jaksa") {
                                    result = 40;
                                  } else if (v === "PN1") {
                                    result = 30;
                                  } else if (v === "PN2") {
                                    result = 30;
                                  } else {
                                    result = 0;
                                  }
                                  setFase(e.target.value);
                                  setPayloadForm({
                                    ...payloadForm,
                                    nama_fase_penahanan: v,
                                  });
                                }}
                                options={[
                                  {
                                    value: "Penyidik",
                                    text: "Penyidik (20)",
                                  },
                                  {
                                    value: "Jaksa",
                                    text: "Jaksa (40)",
                                  },
                                  {
                                    value: "PN1",
                                    text: "PN 1 (30)",
                                  },
                                  {
                                    value: "PN2",
                                    text: "PN 2 (30)",
                                  },
                                ]}
                                disabled={!isFormEditAble}
                              />
                            </Col>
                            <Col sm="4">
                              <DatePicker
                                label="Tanggal Mulai Perpanjangan"
                                name="awal_perpanjang"
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => {
                                  setPayloadDate({
                                    ...payloadDate,
                                    awal_perpanjang: date,
                                  });
                                  setPayloadForm({
                                    ...payloadForm,
                                    awal_perpanjang:
                                      moment(date).format("YYYY-MM-DD"),
                                  });
                                }}
                                selected={payloadDate.awal_perpanjang}
                                required
                                disabled
                              />
                            </Col>
                            <Col sm="4">
                              <DatePicker
                                label="Tanggal Selesai Perpanjangan"
                                name="akhir_perpanjang"
                                timeFormat="HH:mm:ss"
                                dateFormat="dd/MM/yyyy"
                                selected={payloadDate.akhir_perpanjang}
                                required
                                disabled
                              />
                            </Col>
                            <Col sm="4">
                              <Select
                                required
                                label="Kondisi Fisik Tahanan"
                                value={payloadForm.kondisi}
                                name="kondisi"
                                onChange={handleChange}
                                default="Pilih"
                                options={[
                                  { value: "Sehat", text: "Sehat" },
                                  { value: "Sakit", text: "Sakit" },
                                ]}
                                disabled={!isFormEditAble}
                              />
                            </Col>
                            <Col sm="7">
                              <Input
                                as="textarea"
                                onChange={handleChange}
                                name="alasan"
                                defaultValue={payloadForm.alasan}
                                label="Alasan Perpanjangan"
                                required
                                disabled={!isFormEditAble}
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header className="custom-header">
                      Informasi Tambahan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="mt-2 g-2">
                            <Col sm="4" className="d-flex flex-column gap-2">
                              <Input
                                label="Lampiran Pendukung"
                                type="file"
                                onChange={(e) => {
                                  const files = e.target.files[0];
                                  setPayloadForm({
                                    ...payloadForm,
                                    lampiran_pendukung1: [files],
                                  });
                                }}
                                accepts={[".pdf"]}
                                required
                                disabled={!isFormEditAble}
                              />
                              {dataDetail?.perpanjangan_files[0]
                                ?.lampiran_pendukung1 ? (
                                (() => {
                                  const { lampiran_pendukung1 } =
                                    dataDetail.perpanjangan_files[0];
                                  const { p: path, k: kategori } =
                                    trimUrlPath(lampiran_pendukung1);
                                  const file = trimFileUrl(lampiran_pendukung1);

                                  return (
                                    <Link
                                      target="_blank"
                                      to={`/arsip/view/${file}?kategori=${kategori}&p=${encodeToHex(
                                        path
                                      )}`}
                                      className="text-primary fs-6 fw-medium d-flex align-items-center gap-2"
                                    >
                                      <i className="ri-link"></i>
                                      File Yang Sudah Terupload
                                    </Link>
                                  );
                                })()
                              ) : (
                                <Col className="mt-4"> </Col>
                              )}
                            </Col>
                            <Col sm="6">
                              <Input
                                as="textarea"
                                onChange={handleChange}
                                defaultValue={payloadForm.keterangan}
                                col="4"
                                name="keterangan"
                                label="Keterangan"
                                required
                                disabled={!isFormEditAble}
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header className="custom-header">
                      Informasi Penahanan
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2 mt-2">
                            <Col sm="4">
                              <Input
                                label="Perkara"
                                name="perkara"
                                defaultValue={payloadFormDataTahanan.perkara}
                                required
                                disabled
                              />
                            </Col>
                            <Col sm="4">
                              <Input
                                label="Penempatan Rutan"
                                defaultValue={
                                  payloadFormDataTahanan.penempatan_rutan
                                }
                                name="nama_rutan"
                                required
                                disabled
                              />
                            </Col>
                            <Col sm="4">
                              <Input
                                as="textarea"
                                label="Lokasi Rutan"
                                defaultValue={
                                  payloadFormDataTahanan.lokasi_rutan
                                }
                                name="alamat_rutan"
                                required
                                disabled
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header className="custom-header">
                      Informasi Penyidik
                    </Accordion.Header>
                    <Accordion.Body className="py-2 px-0">
                      <Card className="card bg-white">
                        <Card.Body>
                          <Row className="g-2">
                            <Col sm="12">
                              <Card className="card bg-white">
                                <Card.Body>
                                  <Row className="g-1 mb-2 d-flex align-items-center">
                                    <Col sm="12" md="5">
                                      <h6>Daftar Penyidik</h6>
                                    </Col>
                                    <Col sm="12" md="7">
                                      <Link className="d-flex align-items-center justify-content-end"></Link>
                                    </Col>
                                  </Row>
                                  <Table className="table dems-table">
                                    <thead>
                                      <tr>
                                        <th>No</th>
                                        <th>NRP</th>
                                        <th>Nama Penyidik</th>
                                        <th>Asal Satker</th>
                                        <th>Pangkat</th>
                                        <th>Jabatan</th>
                                        <th>Pilih</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {penyidik.map((data, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{data.nrp}</td>
                                          <td>{data.nama_penyidik}</td>
                                          <td>{data.nama_satker}</td>
                                          <td>{data.pangkat}</td>
                                          <td>Penyidik</td>
                                          <td>
                                            <Form.Check
                                              type="checkbox"
                                              onChange={(e) => {
                                                handleCheckbox(
                                                  data,
                                                  e.target.checked
                                                );
                                              }}
                                              checked={payloadForm.penyidik_ids.includes(
                                                data.id_penyidik
                                              )}
                                              disabled={!isFormEditAble}
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center gap-3">
            {!mutateSubmit.isLoading && (
              <Button variant="warning" as={Link} to="/barbuk/penerimaan/list">
                BATAL
              </Button>
            )}
            {/* {!mutateSubmit.isLoading && (
              <Button
                variant="secondary"
                onClick={() => handleSubmit("DRAFT")}
                disabled={!isFormEditAble}
                hidden={!isFormEditAble}
              >
                SIMPAN DRAFT
              </Button>
            )} */}
            {!mutateSubmit.isLoading && (
              <Button
                variant="primary"
                onClick={() => handleSubmit("FINAL")}
                disabled={!isFormEditAble}
                hidden={!isFormEditAble}
              >
                SIMPAN
              </Button>
            )}
            {mutateSubmit.isLoading && (
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                disabled
              >
                Sedang Menyimpan <Spinner variant="light" size="sm" />
              </Button>
            )}
          </div>
          {/* {!mutateSubmit.isLoading ? (
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Link to="/tahanan/perpanjangan/list">
                <Button variant="warning">BATAL</Button>
              </Link>
              <Button
                variant="primary"
                onClick={() => handleSubmit("FINAL")}
                disabled={!isFormEditAble}
              >
                SIMPAN
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Link>
                <Button variant="warning" disabled>
                  BATAL
                </Button>
              </Link>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                disabled
              >
                Sedang Menyimpan <Spinner variant="light" size="sm" />
              </Button>
            </div>
          )} */}
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default FormEdit;
