import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, Form, Badge, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { getBerkas } from "../../../utils/apis";
import {
  decodeFromHex,
  encodeToHex,
  trimUrlPath,
  useQueryPage,
} from "../../../utils";

function Index() {
  const [arsipData, setArsipData] = useState([]);
  const [arsipPath, setArsipPath] = useState([]);
  const [pathString, setPathString] = useState("");
  const [fetchEnabled, setFetchEnabled] = useState(false);
  const location = useLocation();
  const queryPage = useQueryPage();
  const {
    data: arsip,
    isLoading: arsipLoading,
    error: arsipError,
  } = useQuery(["getArsip", {path: pathString}], getBerkas, { enabled: fetchEnabled });

  useEffect(() => {
    setFetchEnabled(true);
    const decodedPath = decodeFromHex(queryPage.get("path") || "");
    setPathString(decodedPath);
  }, [location.search]);

  useEffect(() => {
    const path = pathString.split("/").map((p, idx) => {
      const link = pathString.split("/").splice(0, idx + 1);
      return {
        name: p,
        link: "?path=" + encodeToHex(link.join("/")),
      };
    });

    setArsipPath(path);
  }, [pathString]);

  useEffect(() => {
    if (arsip) {
      const getLastNestedDir = (dirs) => {
        let currentLevel = dirs;
        let lastValidDir = currentLevel;

        while (currentLevel && currentLevel.length > 0) {
          const firstDir = currentLevel.find((item) => item.type === "folder");

          if (!firstDir || !firstDir.dir || firstDir.dir.length === 0) {
            break;
          }

          lastValidDir = firstDir.dir;
          currentLevel = firstDir.dir;
        }

        return lastValidDir.map((dir, idx) => {
          const link = pathString.split("/");
          const path =
            link.toString() === "" ? dir.name : link.join("/") + "/" + dir.name;
          if (dir.type !== "folder") {
            dir.url = `/arsip/view/${dir.name}?p=${encodeToHex(
              trimUrlPath(dir.minio_url).p
            )}&kategori=${trimUrlPath(dir.minio_url).k}`;
            const format = dir.name.split(".").pop();

            if (["jpg", "jpeg", "gif", "png"].includes(format)) {
              dir.iconClass = "ri-image-2-line primary";
            } else if (
              [
                "xls",
                "xlsx",
                "xlsm",
                "xlsb",
                "csv",
                "xlt",
                "xltx",
                "xltm",
                "xml",
                "ods",
              ].includes(format)
            ) {
              dir.iconClass = "ri-file-excel-line";
            } else if (
              [
                "doc",
                "docx",
                "dot",
                "dotx",
                "dotm",
                "rtf",
                "txt",
                "odt",
                "xml",
              ].includes(format)
            ) {
              dir.iconClass = "ri-image-2-line primary";
            } else if (
              [
                "mp4",
                "avi",
                "mov",
                "wmv",
                "flv",
                "mkv",
                "webm",
                "mpg",
                "3gp",
                "m4v",
                "m2ts",
                "m3u8",
              ].includes(format)
            ) {
              dir.iconClass = "ri-file-video-line";
            } else if (
              [
                "mp3",
                "wav",
                "aac",
                "flac",
                "ogg",
                "wma",
                "m4a",
                "alac",
                "aiff",
                "opus",
              ].includes(format)
            ) {
              dir.iconClass = "ri-file-music-line";
            } else if (format === "ppt") {
              dir.iconClass = "ri-file-ppt-line";
            } else if (format === "pdf") {
              dir.iconClass = "ri-file-pdf-2-line text-danger";
            } else {
              dir.iconClass = "ri-file-line";
            }
          } else {
            dir.iconClass = "ri-folder-5-line";
            dir.link = "?path=" + encodeToHex(path);
          }
          return dir;
        });
      };
      setArsipData(getLastNestedDir(arsip.data.value));
    }
  }, [arsip]);

  const copyPath = async () => {
    try {
      await navigator.clipboard.writeText(pathString);
    } catch (error) {
      console.log("caanot copy", error);
    }
  };

  const showFile = (file) => {
    console.log(file);
  };
  return (
    <React.Fragment>
      <Row>
        <Col xl="12">
          <Card className="card p-2">
            <Row className="g-1">
              <Col sm="12" md="12">
                <Card className="card mb-2">
                  <Card.Body className="p-2 d-flex align-items-center gap-2">
                    <ol className="breadcrumb fs-sm mb-0">
                      <li className="breadcrumb-item">
                        <Link to="?path=">{"Berkas"}</Link>
                      </li>
                      {arsipPath.map((ap, idxAp) => (
                        <li key={idxAp} className="breadcrumb-item">
                          <Link to={ap.link}>{ap.name}</Link>
                        </li>
                      ))}
                    </ol>
                    <Badge className="dems-badge secondary" onClick={copyPath}>
                      <i className="ri-file-copy-line"></i>
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Table className="table dems-table">
              <thead>
                <tr>
                  <th style={{ width: "55%" }}>Nama</th>
                  <th>Terakhir Diupdate</th>
                  <th>Ukuran</th>
                </tr>
              </thead>
              <tbody>
                {arsipLoading ? (
                  <tr>
                    <td colSpan="3" style={{ textAlign: "center" }}>
                      <Spinner animation="border" variant="primary" />
                    </td>
                  </tr>
                ) : (
                  <React.Fragment>
                    {arsipData.map((dir, idx) => (
                      <tr key={idx}>
                        <td>
                          <div className="media">
                            <div className={"media-icon primary"}>
                              <i className={dir.iconClass}></i>
                            </div>
                            <h6 className="file-name">
                              {dir.type === "folder" ? (
                                <Link to={dir.link}>
                                  {
                                    (dir?.name ?? '').replaceAll('_', '/')
                                  }</Link>
                              ) : (
                                <Link to={dir.url}>{dir.name}</Link>
                              )}
                            </h6>
                          </div>
                        </td>
                        <td>{dir?.last_modified ?? "-"}</td>
                        <td>{dir?.size ?? "-"}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                )}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Index;
