import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Accordion,
  Row,
  Spinner,
  Table,
  Form,
  Modal,
  Alert,
  Placeholder,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  DatePicker,
  Image,
  Input,
  Select,
} from "../../../../components/Components";
import { debounce } from "lodash";
import {
  useGetDetailTahti,
  useGetTahtiBonList,
  usePostPerpanjangan,
} from "../../../../utils/apis/query";
import moment from "moment";
import { useQueryClient } from "react-query";

function FormMenu() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [payloadCariTahanann, setPayloadCariTahanan] = useState({
    search: "",
  });
  const [namaTahanan, setNamaTahanan] = useState("");
  const [idTahanan, setIdTahanan] = useState("");
  const [fase, setFase] = useState(0);
  const [penyidik, setPenyidik] = useState([]);
  const [faseOptions, setFaseOptions] = useState([]);
  const [payloadDate, setPayloadDate] = useState({
    tanggal_sprin_perpanjangan: "",
    awal_perpanjang: "",
    akhir_perpanjang: "",
    tanggal_ba_perpanjangan_penahanan: "",
    tanggal_surat_kejaksaan_t4: "",
  });
  const [payloadForm, setPayloadForm] = useState({
    nama_tahanan: "",
    no_tahanan: "",
    no_sprin_perpanjangan: "",
    tanggal_sprin_perpanjangan: "",
    awal_perpanjang: "",
    akhir_perpanjang: "",
    kondisi: "",
    alasan: "",
    keterangan: "",
    status_dokumen: "",
    fase: "",
    penyidik_ids: [],
    no_ba_perpanjangan_penahanan: "",
    no_surat_perpanjangan_penahanan_kejaksaan_t4: "",
    tanggal_ba_perpanjangan_penahanan: "",
    tanggal_surat_kejaksaan_t4: "",
    arsip_sprin_perpanjangan_tahanan: [],
    arsip_ba_perpanjangan_penahanan: [],
    arsip_surat_perpanjangan_penahanan_kejaksaan_t4: [],
    lampiran_pendukung1: [],
    nama_fase_penahanan: "",
  });
  const [payloadFormDataTahanan, setPayloadFormDataTahanan] = useState({
    no_tahanan: "",
    nama: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    umur: "",
    kelamin: "",
    kewarganegaraan: "",
    asal_negara: "",
    agama: "",
    pekerjaan: "",
    alamat: "",
    foto_tahanan: "",
    foto_sidikjari: "",
    perkara: "",
    penempatan_rutan: "",
    lokasi_rutan: "",
    riwayat: [],
  });

  const {
    data: dataTahanan,
    isFetching: tahananFetching,
    isError: tahananError,
  } = useGetDetailTahti(idTahanan, { enabled: !!idTahanan });

  const { data: dataTahananList, isFetching: modalFetching } =
    useGetTahtiBonList(payloadCariTahanann);

  const handleCheckbox = (data, isChecked) => {
    if (isChecked) {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: [...payloadForm.penyidik_ids, data.id_penyidik],
      });
    } else {
      setPayloadForm({
        ...payloadForm,
        penyidik_ids: payloadForm.penyidik_ids.filter(
          (selectedId) => selectedId !== data.id_penyidik
        ),
      });
    }
  };

  const handleChange = (e) => {
    setIsFormDirty(true);
    const { name, value } = e.target;
    setPayloadForm({ ...payloadForm, [name]: value });
  };

  const handleDebounce = useCallback(
    debounce((newPayload) => {
      setPayloadCariTahanan(newPayload);
    }, 500),
    []
  );

  const onSuccess = (response) => {
    setIsFormDirty(false);
    setTimeout(() => {
      navigate("/tahanan/perpanjangan/list");
    }, 2500);
  };
  const mutateSubmit = usePostPerpanjangan(onSuccess);

  const handleSubmit = (status) => {
    let formData = new FormData();
    payloadForm.nama_tahanan = payloadFormDataTahanan.nama;
    payloadForm.no_tahanan = payloadFormDataTahanan.no_tahanan;
    payloadForm.fase = fase;
    payloadForm.status_dokumen = status;
    Object.keys(payloadForm).forEach((key) => {
      if (
        typeof payloadForm[key] === "string" ||
        typeof payloadForm[key] === "number"
      ) {
        formData.append(key, payloadForm[key]);
      }
    });
    payloadForm.penyidik_ids.forEach((ids, idx) => {
      formData.append(`penyidik_ids`, ids);
    });
    payloadForm.arsip_sprin_perpanjangan_tahanan?.forEach((file, idx) => {
      formData.append(`arsip_sprin_perpanjangan_tahanan`, file);
    });
    payloadForm.arsip_ba_perpanjangan_penahanan?.forEach((file, idx) => {
      formData.append(`arsip_ba_perpanjangan_penahanan`, file);
    });
    payloadForm.arsip_surat_perpanjangan_penahanan_kejaksaan_t4?.forEach(
      (file, idx) => {
        formData.append(
          `arsip_surat_perpanjangan_penahanan_kejaksaan_t4`,
          file
        );
      }
    );
    payloadForm.lampiran_pendukung1?.forEach((file, idx) => {
      formData.append(`lampiran_pendukung1`, file);
    });

    mutateSubmit.mutate(formData);
  };

  useEffect(() => {
    if (payloadDate.awal_perpanjang && fase) {
      const awal = new Date(payloadDate.awal_perpanjang);
      const dateKeluar = moment(awal).add(fase, "days");
      setPayloadDate({ ...payloadDate, akhir_perpanjang: dateKeluar.toDate() });
      setPayloadForm({
        ...payloadForm,
        akhir_perpanjang: dateKeluar.format("YYYY-MM-DD"),
      });
    }
  }, [payloadDate.awal_perpanjang, fase]);

  useEffect(() => {
    handleDebounce({ ...payloadCariTahanann, search: namaTahanan });
  }, [namaTahanan, handleDebounce, payloadCariTahanann]);

  useEffect(() => {
    if (dataTahanan) {
      const _ = dataTahanan;
      setPayloadFormDataTahanan({
        no_tahanan: _.no_tahanan,
        nama: _.nama_tersangka,
        tempat_lahir: _.tempat_lahir,
        tanggal_lahir: _.tanggal_lahir,
        umur: _.umur,
        kelamin: _.jenis_kelamin,
        kewarganegaraan: _.kebangsaan,
        asal_negara: _.asal_negara,
        agama: _.agama,
        perkara: _.perkara,
        pekerjaan: _.pekerjaan,
        alamat: _.alamat,
        foto_tahanan: _.rumus_wajah,
        foto_sidikjari: _.rumus_sidikjari,
        penempatan_rutan: _.nama_rutan,
        lokasi_rutan: _.alamat_rutan,
        riwayat: _.riwayat_perpanjangan,
      });
      // setPayloadForm({
      //   ...payloadForm,
      //   no_tahanan: _.no_tahanan,
      //   nama_tahanan: _.nama_tersangka,
      // });
      setPenyidik(_?.penyidik ?? []);

      const lastIndex = dataTahanan.riwayat_perpanjangan.length - 1;
      const dataRiwayat = dataTahanan.riwayat_perpanjangan;
      if (dataRiwayat[lastIndex] && dataRiwayat[lastIndex].tanggal_selesai) {
        const awalPerpanjang = moment(
          dataRiwayat[lastIndex].tanggal_selesai
        ).add(1, "days");
        setPayloadDate({
          ...payloadDate,
          awal_perpanjang: new Date(awalPerpanjang),
        });

        setPayloadForm({
          ...payloadForm,
          awal_perpanjang: awalPerpanjang.format("YYYY-MM-DD"),
        });
      }

      const master = [
        [
          {
            value: "Penyidik",
            text: "Penyidik (20)",
          },
          {
            value: "Jaksa",
            text: "Jaksa (40)",
          },
          {
            value: "PN1",
            text: "PN 1 (30)",
          },
          {
            value: "PN2",
            text: "PN 2 (30)",
          },
        ],
        [
          {
            value: "Jaksa",
            text: "Jaksa (40)",
          },
          {
            value: "PN1",
            text: "PN 1 (30)",
          },
          {
            value: "PN2",
            text: "PN 2 (30)",
          },
        ],
        [
          {
            value: "PN1",
            text: "PN 1 (30)",
          },
          {
            value: "PN2",
            text: "PN 2 (30)",
          },
        ],
        [
          {
            value: "PN2",
            text: "PN 2 (30)",
          },
        ],
      ];

      if (dataRiwayat[lastIndex]?.fase_penahanan === "Penyidik") {
        setFaseOptions([...master[1]]);
      } else if (dataRiwayat[lastIndex]?.fase_penahanan === "Jaksa") {
        setFaseOptions([...master[2]]);
      } else if (dataRiwayat[lastIndex]?.fase_penahanan === "PN1") {
        setFaseOptions([...master[3]]);
      }
    }
  }, [dataTahanan]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  const one =
    payloadForm.no_sprin_perpanjangan &&
    payloadForm.tanggal_sprin_perpanjangan &&
    payloadForm?.arsip_sprin_perpanjangan_tahanan?.length > 0;
  const two =
    payloadForm.no_ba_perpanjangan_penahanan &&
    payloadForm.tanggal_ba_perpanjangan_penahanan &&
    payloadForm?.arsip_ba_perpanjangan_penahanan?.length > 0;
  const three =
    payloadForm.no_surat_perpanjangan_penahanan_kejaksaan_t4 &&
    payloadForm.tanggal_surat_kejaksaan_t4 &&
    payloadForm?.arsip_surat_perpanjangan_penahanan_kejaksaan_t4?.length > 0;

  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/tahanan">Tahanan</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/tahanan/perpanjangan/list">
                    List Perpanjangan Tahanan
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form Perpanjangan Tahanan
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {mutateSubmit.isError && (
                <Alert variant="danger">
                  <ul className="m-0">
                    {mutateSubmit?.error?.response?.data?.detail?.map(
                      (errEdit, idx) => (
                        <li key={idx}>
                          <b>{errEdit.loc[1]} : </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error : </b>
                        {mutateSubmit?.error?.response?.data?.message ??
                          "Bermasalah"}
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateSubmit.isSuccess && (
                <Alert variant="success">
                  {mutateSubmit.data.data.message}
                </Alert>
              )}
            </Col>
            <Col sm="12">
              <Accordion
                defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                flush
                alwaysOpen
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="custom-header">
                    Informasi / Profil Tahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Header className="bg-white d-flex justify-content-end">
                        {tahananFetching && !tahananError ? (
                          <Button
                            variant="primary"
                            size="small"
                            className="d-flex align-items-center gap-2"
                            disabled
                          >
                            <Spinner size="sm" variant="light" />
                            Sedang Menerapkan Tahanan
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            size="small"
                            className="d-flex align-items-center gap-2"
                            onClick={() => setModalShow(true)}
                          >
                            <i className="ri-user-search-line fs-6"></i>
                            {dataTahanan ? "Ubah " : "Cari "}
                            Profil Tahanan
                          </Button>
                        )}
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col sm="4" className="d-flex flex-column gap-3">
                            <div className="d-flex flex-column gap-2">
                              <span className="fs-6 fw-medium">
                                Foto Tahanan
                              </span>
                              <Image
                                src={payloadFormDataTahanan.foto_tahanan}
                                height="250px"
                                width="100%"
                              />
                            </div>
                            <div className="d-flex flex-column gap-2">
                              <span className="fs-6 fw-medium">
                                Foto Sidik Jari
                              </span>
                              <Image
                                src={payloadFormDataTahanan.foto_sidikjari}
                                height="250px"
                                width="100%"
                              />
                            </div>
                          </Col>
                          <Col sm="8">
                            {tahananFetching && !tahananError && (
                              <Placeholder
                                as="p"
                                animation="wave"
                                className="m-0 d-flex flex-column gap-2"
                              >
                                <Placeholder sm="12" />
                                <Placeholder sm="12" />
                                <Placeholder sm="12" />
                                <Placeholder sm="12" />
                                <Placeholder sm="12" />
                              </Placeholder>
                            )}
                            {!tahananFetching && tahananError && (
                              <div className="d-flex justify-content-center">
                                <span className="fs-5 text-danger">
                                  <b>Error : </b> Terjadi masalah koneksi
                                </span>
                              </div>
                            )}
                            {!tahananFetching && !tahananError && (
                              <Row className="g-2">
                                <Col sm="6">
                                  <Input
                                    label="No. Tahanan"
                                    name="no_tahanan"
                                    defaultValue={
                                      payloadFormDataTahanan.no_tahanan
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Nama"
                                    name="nama"
                                    defaultValue={payloadFormDataTahanan.nama}
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Tempat Lahir"
                                    defaultValue={
                                      payloadFormDataTahanan.tempat_lahir
                                    }
                                    name="tempat_lahir"
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Tanggal Lahir"
                                    name="tanggal_lahir"
                                    defaultValue={
                                      payloadFormDataTahanan.tanggal_lahir
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    type="number"
                                    label="Umur Saat Ini"
                                    name="umur"
                                    defaultValue={payloadFormDataTahanan.umur}
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Kelamin"
                                    name="jenis_kelamin"
                                    disabled
                                    defaultValue={
                                      payloadFormDataTahanan.kelamin
                                    }
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Kewarganegaraan"
                                    name="kebangsaan"
                                    defaultValue={
                                      payloadFormDataTahanan.kewarganegaraan
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Asal Negara"
                                    name="asal_negara"
                                    defaultValue={
                                      payloadFormDataTahanan.asal_negara
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Agama"
                                    name="agama"
                                    defaultValue={payloadFormDataTahanan.agama}
                                    disabled
                                  />
                                </Col>
                                <Col sm="6">
                                  <Input
                                    label="Pekerjaan"
                                    name="pekerjaan"
                                    defaultValue={
                                      payloadFormDataTahanan.pekerjaan
                                    }
                                    type="text"
                                    disabled
                                  />
                                </Col>
                                <Col sm="12">
                                  <Input
                                    as="textarea"
                                    label="Alamat"
                                    defaultValue={payloadFormDataTahanan.alamat}
                                    name="alamat"
                                    disabled
                                  />
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="custom-header">
                    Informasi Dokumen Penahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="12">
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                1
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="Nomor Sprin Perpanjangan Tahanan"
                                  name="no_sprin_perpanjangan"
                                  required
                                  onChange={handleChange}
                                  defaultValue={
                                    payloadForm.no_sprin_perpanjangan
                                  }
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal Sprin"
                                  name="tanggal_sprin_perpanjangan"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_sprin_perpanjangan: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_sprin_perpanjangan:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_sprin_perpanjangan
                                  }
                                  required
                                />
                              </Col>
                              <Col sm="4">
                                <Input
                                  label="Arsip Sprin Perpanjangan"
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip_sprin_perpanjangan_tahanan: [files],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                  required
                                />
                                <Col className="mt-4"> </Col>
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center ms-3"
                              >
                                {one ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    <i className="ri-check-line"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontSize: "1.5rem" }}
                                  >
                                    <i className="ri-close-large-line"></i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                2
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="No BA Perpanjangan Penahanan"
                                  name="no_ba_perpanjangan_penahanan"
                                  required
                                  onChange={handleChange}
                                  defaultValue={
                                    payloadForm.no_ba_perpanjangan_penahanan
                                  }
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal BA Perpanjangan"
                                  name="tanggal_ba_perpanjangan_penahanan"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_ba_perpanjangan_penahanan: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_ba_perpanjangan_penahanan:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_ba_perpanjangan_penahanan
                                  }
                                  required
                                />
                              </Col>
                              <Col sm="4">
                                <Input
                                  label="Arsip BA Perpanjangan"
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip_ba_perpanjangan_penahanan: [files],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                  required
                                />
                                <Col className="mt-4"> </Col>
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center ms-3"
                              >
                                {two ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    <i className="ri-check-line"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontSize: "1.5rem" }}
                                  >
                                    <i className="ri-close-large-line"></i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                            <Row className="g-2 mt-1">
                              <Col
                                xs="auto"
                                className="d-flex align-items-center me-3"
                              >
                                3
                              </Col>
                              <Col sm="5">
                                <Input
                                  label="No Surat Perpanjangan Penahanan dari Kejaksaan (T-4)"
                                  name="no_surat_perpanjangan_penahanan_kejaksaan_t4"
                                  required
                                  onChange={handleChange}
                                  defaultValue={
                                    payloadForm.no_surat_perpanjangan_penahanan_kejaksaan_t4
                                  }
                                />
                              </Col>
                              <Col sm="2">
                                <DatePicker
                                  label="Tanggal Surat Perpanjangan"
                                  name="tanggal_surat_kejaksaan_t4"
                                  timeFormat="HH:mm:ss"
                                  dateFormat="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setPayloadDate({
                                      ...payloadDate,
                                      tanggal_surat_kejaksaan_t4: date,
                                    });
                                    setPayloadForm({
                                      ...payloadForm,
                                      tanggal_surat_kejaksaan_t4:
                                        moment(date).format("YYYY-MM-DD"),
                                    });
                                  }}
                                  selected={
                                    payloadDate.tanggal_surat_kejaksaan_t4
                                  }
                                  required
                                />
                              </Col>
                              <Col sm="4">
                                <Input
                                  label="Arsip Surat Perpanjangan Penahanan"
                                  type="file"
                                  onChange={(e) => {
                                    const files = e.target.files[0];
                                    setPayloadForm({
                                      ...payloadForm,
                                      arsip_surat_perpanjangan_penahanan_kejaksaan_t4:
                                        [files],
                                    });
                                  }}
                                  accepts={[".pdf"]}
                                  required
                                />
                                <Col className="mt-4"> </Col>
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center ms-3"
                              >
                                {three ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    <i className="ri-check-line"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontSize: "1.5rem" }}
                                  >
                                    <i className="ri-close-large-line"></i>
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="custom-header">
                    Informasi Penahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2 mt-2">
                          <Col sm="4">
                            <Input
                              label="Perkara"
                              name="perkara"
                              defaultValue={payloadFormDataTahanan.perkara}
                              required
                              disabled
                            />
                          </Col>
                          <Col sm="4">
                            <Input
                              label="Penempatan Rutan"
                              defaultValue={
                                payloadFormDataTahanan.penempatan_rutan
                              }
                              name="nama_rutan"
                              required
                              disabled
                            />
                          </Col>
                          <Col sm="4">
                            <Input
                              as="textarea"
                              label="Lokasi Rutan"
                              defaultValue={payloadFormDataTahanan.lokasi_rutan}
                              name="alamat_rutan"
                              required
                              disabled
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="custom-header">
                    Informasi Perpanjangan Penahanan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="12">
                            <Card className="card bg-white">
                              <Card.Body>
                                <Row className="g-1 mb-2 d-flex align-items-center">
                                  <Col sm="12" md="5">
                                    <h6>Riwayat Perpanjangan Penahanan</h6>
                                  </Col>
                                  <Col sm="12" md="7">
                                    <Link className="d-flex align-items-center justify-content-end"></Link>
                                  </Col>
                                </Row>
                                <Table className="table dems-table">
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>Tanggal</th>
                                      <th>No. Dokumen</th>
                                      <th>Fase</th>
                                      <th>Mulai</th>
                                      <th>Selesai</th>
                                      <th>Durasi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {payloadFormDataTahanan.riwayat.map(
                                      (data, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>
                                            {moment(data?.created_date).format(
                                              "YYYY-MM-DD"
                                            )}
                                          </td>
                                          <td>{data.no_sprin}</td>
                                          <td>{data.fase_penahanan}</td>
                                          <td>{data.tanggal_mulai}</td>
                                          <td>{data.tanggal_selesai}</td>
                                          <td>{data.durasi} Hari</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col sm="4">
                            <Select
                              required
                              label="Fase Penahanan"
                              default="Pilih"
                              // value={fase}
                              onChange={(e) => {
                                const v = e.target.value;
                                let result = 0;
                                if (v === "Penyidik") {
                                  result = 20;
                                } else if (v === "Jaksa") {
                                  result = 40;
                                } else if (v === "PN1") {
                                  result = 30;
                                } else if (v === "PN2") {
                                  result = 30;
                                } else {
                                  result = 0;
                                }
                                setFase(result);
                                setPayloadForm({
                                  ...payloadForm,
                                  nama_fase_penahanan: v,
                                });
                              }}
                              options={faseOptions}
                            />
                          </Col>
                          <Col sm="4">
                            <DatePicker
                              label="Tanggal Mulai Perpanjangan"
                              name="awal_perpanjang"
                              timeFormat="HH:mm:ss"
                              dateFormat="dd/MM/yyyy"
                              // onChange={(date) => {
                              //   setPayloadDate({
                              //     ...payloadDate,
                              //     awal_perpanjang: date,
                              //   });
                              //   setPayloadForm({
                              //     ...payloadForm,
                              //     awal_perpanjang:
                              //       moment(date).format("YYYY-MM-DD"),
                              //   });
                              // }}
                              selected={payloadDate.awal_perpanjang}
                              required
                              disabled
                            />
                          </Col>
                          <Col sm="4">
                            <DatePicker
                              label="Tanggal Selesai Perpanjangan"
                              name="akhir_perpanjang"
                              timeFormat="HH:mm:ss"
                              dateFormat="dd/MM/yyyy"
                              selected={payloadDate.akhir_perpanjang}
                              required
                              disabled
                            />
                          </Col>
                          <Col sm="4">
                            <Select
                              required
                              label="Kondisi Fisik Tahanan"
                              value={payloadForm.kondisi}
                              name="kondisi"
                              onChange={handleChange}
                              default="Pilih"
                              options={[
                                { value: "Sehat", text: "Sehat" },
                                { value: "Sakit", text: "Sakit" },
                              ]}
                            />
                          </Col>
                          <Col sm="7">
                            <Input
                              as="textarea"
                              onChange={handleChange}
                              name="alasan"
                              defaultValue={payloadForm.alasan}
                              label="Alasan Perpanjangan"
                              required
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header className="custom-header">
                    Informasi Penyidik
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="g-2">
                          <Col sm="12">
                            <Card className="card bg-white">
                              <Card.Body>
                                <Row className="g-1 mb-2 d-flex align-items-center">
                                  <Col sm="12" md="5">
                                    <h6>Daftar Penyidik</h6>
                                  </Col>
                                  <Col sm="12" md="7">
                                    <Link className="d-flex align-items-center justify-content-end"></Link>
                                  </Col>
                                </Row>
                                <Table className="table dems-table">
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>NRP</th>
                                      <th>Nama Penyidik</th>
                                      <th>Asal Satker</th>
                                      <th>Pangkat</th>
                                      <th>Jabatan</th>
                                      <th>Pilih</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {penyidik.map((data, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.nrp}</td>
                                        <td>{data.nama_penyidik}</td>
                                        <td>{data.nama_satker}</td>
                                        <td>{data.pangkat}</td>
                                        <td>Penyidik</td>
                                        <td>
                                          <Form.Check
                                            type="checkbox"
                                            onChange={(e) => {
                                              handleCheckbox(
                                                data,
                                                e.target.checked
                                              );
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header className="custom-header">
                    Informasi Tambahan
                  </Accordion.Header>
                  <Accordion.Body className="py-2 px-0">
                    <Card className="card bg-white">
                      <Card.Body>
                        <Row className="mt-2 g-2">
                          <Col sm="4">
                            <Input
                              label="Lampiran Pendukung"
                              type="file"
                              onChange={(e) => {
                                const files = e.target.files[0];
                                setPayloadForm({
                                  ...payloadForm,
                                  lampiran_pendukung1: [files],
                                });
                              }}
                              accepts={[".pdf"]}
                            />
                          </Col>
                          <Col sm="6">
                            <Input
                              as="textarea"
                              onChange={handleChange}
                              defaultValue={payloadForm.keterangan}
                              col="4"
                              name="keterangan"
                              label="Keterangan"
                              required
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          {!mutateSubmit.isLoading || !mutateSubmit.isLoading ? (
            <div className="d-flex justify-content-end align-items-center gap-3">
              <Button
                variant="warning"
                as={Link}
                to="/tahanan/perpanjangan/list"
              >
                BATAL
              </Button>
              {/* <Button
                variant="secondary"
                disabled={!isFormDirty}
                onClick={() => handleSubmit("DRAFT")}
              >
                SIMPAN DRAFT
              </Button> */}
              <Button variant="primary" onClick={() => handleSubmit("FINAL")}>
                SIMPAN
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-end align-items-center gap-3">
              {/* <Button variant="secondary" disabled>
                SIMPAN DRAFT
              </Button> */}
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                disabled
              >
                Sedang Menyimpan <Spinner variant="light" size="sm" />
              </Button>
            </div>
          )}
        </Card.Footer>
      </Card>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Cari Tahanan</Modal.Title>
          <Button
            className="btn btn-danger"
            onClick={() => setModalShow(false)}
          >
            <i class="ri-close-large-fill"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-4">
            <Col sm="12">
              <Input
                label="Nama Tahanan"
                onChange={(e) => {
                  setNamaTahanan(e.target.value);
                }}
              ></Input>
            </Col>
            <Col sm="12">
              <Table className="table dems-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Tahanan</th>
                    <th>Asal Satker</th>
                    <th>Perkara</th>
                    <th>Lokasi</th>
                    <th>Pilih</th>
                  </tr>
                </thead>
                <tbody>
                  {modalFetching && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="small"
                        />
                      </td>
                    </tr>
                  )}
                  {!modalFetching &&
                    dataTahananList.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.nama_tersangka}</td>
                        <td>{data.nama_satker}</td>
                        <td>{data.perkara}</td>
                        <td>{data.nama_rutan}</td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            onClick={() => {
                              setIdTahanan(data.id);
                              setModalShow(false);
                              queryClient.resetQueries("getTahtiList");
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default FormMenu;
