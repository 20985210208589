import React, { useEffect, useState } from 'react'
import tagsIcon from './tags.json'
import { Button, Card, Col, Row } from 'react-bootstrap'
function Index({onClickTag}) {
 const [icons, setIcons] = useState([])
 useEffect(() => {
    let tags = []
    for (let [key, val] of Object.entries(tagsIcon)) {
        if (!['Editor'].includes(key)) {
            let tmp = {label: key, tags: []}
            for (let [key2, val2] of Object.entries(val)) {
                tmp.tags.push(`ri-${key2}-line`)
            }
            tags.push(tmp)
        }
    }
    setIcons(tags)
 }, [])
 const clickTag = (tag) => {
    if (onClickTag) {
        onClickTag(tag)
    }
 }
  return (
    <React.Fragment>
        <div className="wrapper-icon-picker">
            <Row className='g-2 p-2'>
                {
                    icons.map((icon, idx) => (
                        <Col sm="12" key={idx}>
                            <Card>
                                <Card.Header>
                                    <Card.Title as='label' className='fw-medium fs-5'>
                                        {icon.label}
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row className='g-2'>
                                    {
                                        icon.tags.map((tag, idxtag) => (
                                            <Col sm="2" key={idxtag} className='d-flex justify-content-center'>
                                                <Button onClick={()=> clickTag(tag)} variant='outline-secondary' size='sm'>
                                                    <i className={`${tag} fs-5`} />
                                                </Button>
                                            </Col>
                                        ))
                                    }
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </div>
    </React.Fragment>
  )
}

export default Index