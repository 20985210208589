import React, { useState } from "react";
import AsyncCreatableSelect from "react-select/async";
import { Form } from "react-bootstrap";


const ReactSelect = ({
  label,
  required,
  defaultOptions,
  onChange,
  loadOptions,
  isClearable,
  value,
  ...rest
}) => {
    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          minHeight: '35px', // Tinggi minimum input
          height: '35px',    // Tinggi tetap input
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          height: '35px',
          padding: '0 6px',
        }),
        input: (provided, state) => ({
          ...provided,
          margin: '0px',
        }),
        indicatorSeparator: (state) => ({
          display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '35px',
        }),
    };
  return (
    <div className="custom-select-container">
      {/* Label with optional required marker */}
      {label && (
        <Form.Label className="form-label fs-sm mb-1">
          {required && <span style={{ color: "red" }}>* </span>}
          {label}
        </Form.Label>
      )}
        <AsyncCreatableSelect 
            className="react-select-dems"
            cacheOptions
            styles={customStyles}
            defaultOptions={defaultOptions || []}
            loadOptions={loadOptions}
            onChange={onChange}
            isClearable={isClearable}
            value={defaultOptions.filter((opt) => opt.value === value)}
            {...rest}
        />
    </div>
  );
};

export default ReactSelect;
