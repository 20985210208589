import React, { useLayoutEffect, useRef } from 'react';
import { Card, Col, Row } from 'react-bootstrap'
// import Select from '../../../../components/inputs/Select';
import * as d3 from "d3-shape";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { kesatuan } from '../../../../../utils';

function ChartPie(props) {
  const chartRef = useRef(null)
  useLayoutEffect(() => {
    let root = am5.Root.new(chartRef.current);
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      paddingLeft: 0,
      paddingRight: 0,
      layout: root.verticalLayout
    }));

    chart.set("wheelY", "none");
    const data = props?.dataSeries ?? []

    const maxValue = Math.max(
      ...data.map(item => Math.max(item.ideal, item.jumlah))
    );

    if (data.length > 60) {
      chart.set("scrollbarX", am5.Scrollbar.new(root, {
        orientation: "horizontal",
      }));

      // Atur lebar chart agar dapat discroll
      chart.children.push(am5.Container.new(root, {
        width: am5.p100,
        maxWidth: data.length * 80, // Sesuaikan 80 dengan lebar rata-rata kolom
      }));
    }
    
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 10,
        minorGridEnabled: true
    });
    
    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {
          themeTags: ["axis"],
          animationDuration: 200
        })
    }));
    
    xRenderer.grid.template.setAll({
        location: 1,
    })
    xRenderer.labels.template.setAll({
      rotation: -45, // Miringkan label sebesar -45 derajat
      centerY: am5.p50,
      centerX: am5.p100,
      paddingTop: 7,
      fontSize: 10,
      maxWidth: 100,  // Maximum width before truncating
      oversizedBehavior: "truncate",
      text: '{category}',
    })
    xAxis.data.setAll(data);
    xAxis.set("minZoomCount", 1);
    xAxis.set("start", 0);
    xAxis.set("end", 1);
    
    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        max: maxValue,
        paddingRight: 20,
        renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1
        })
    }));
    var yAxisRightRenderer = am5xy.AxisRendererY.new(root, { opposite: true });
    var yAxisRight = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: yAxisRightRenderer,
        min: 0,
        max: 120,
        strictMinMax: true
    }));

    yAxisRightRenderer.grid.template.set("forceHidden", true);
    yAxisRight.set("numberFormat", "#'%");
    
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    
    var seriesIdeal = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Ideal",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "ideal",
        categoryXField: "category",
        clustered: false,
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]Jumlah Ideal :[/] {valueY} Tahanan"
        })
    }));
    
    seriesIdeal.columns.template.setAll({
        width: am5.percent(90), 
        maxWidth: 40, 
        tooltipY: 0,
        strokeOpacity: 0,
        // tooltipText: "{categoryX}: {valueY}",
        cornerRadiusTL: 6,
        cornerRadiusTR: 6
    });
    
    seriesIdeal.columns.template.adapters.add("fill", (fill, target) => {
      return am5.color(0xfdd294); // Blue for positive, Tomato for negative
    });
    
    seriesIdeal.data.setAll(data);
    
    
    var seriesJumlah = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Jumlah",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "jumlah",
        categoryXField: "category",
        clustered: false,
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]Jumlah Aktual :[/] {valueY} Tahanan"
        })
    }));
    
    seriesJumlah.columns.template.setAll({
        width: am5.percent(60), 
        maxWidth: 30,
        tooltipY: 0,
        strokeOpacity: 0,
        // tooltipText: "{categoryX}: {valueY}",
        cornerRadiusTL: 6,
        cornerRadiusTR: 6
    });

    seriesJumlah.columns.template.adapters.add("fill", (fill, target) => {
      return am5.color(0xd08164) // Blue for positive, Tomato for negative
    });
    
    seriesJumlah.data.setAll(data);

    // Create jumlah series with smoother line
    const seriesPresentase = chart.series.push(am5xy.LineSeries.new(root, {
        name: "presentaseY",
        xAxis: xAxis,
        yAxis: yAxisRight,
        valueYField: "presentase",
        categoryXField: "category",
        stroke: am5.color(0xef4f5b),
        curveFactory: d3.curveBumpX,
        tensionX: 1, // Tension for line curvature (0 to 1)
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]Persentase :[/] {valueY}%"
        })
    }));

    seriesPresentase.strokes.template.setAll({
      strokeWidth: 2
    });

    seriesPresentase.bullets.push(() => {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 5,
          stroke: am5.color(0xef4f5b),
          strokeWidth: 3,
          fill: am5.color("#fff")
        })
      });
    });

    seriesPresentase.data.setAll(data);
    
    root._logo.setAll({
        height: -100000000  // Adjust the height as needed
    });
    
    chart.set("cursor", am5xy.XYCursor.new(root, {}));
    
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
    seriesIdeal.appear();
    seriesJumlah.appear();
    return () => {
        root.dispose();
    };
  }, [props.dataSeries])
  return (
    <Card className='card-one bg-white'>
      <Card.Body className='d-flex flex-column gap-2'>
        <Card.Text as="label" style={{paddingLeft: 0}} className='fs-5 fw-bold'>{props?.title ?? ''}</Card.Text>
        <div className="chart-box" ref={chartRef}>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ChartPie;