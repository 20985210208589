import React, { useCallback, useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconPicker, Input, Select } from "../../../components/Components";
import {
  useGetLokasiBarbukDetail,
  usePostLokasiBarbuk,
  usePutLokasiBarbuk,
  useSatkerTahtiList,
} from "../../../utils/apis/query";

function Form() {
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [lokasiBarbuk, setLokasiBarbuk] = useState({
    lokasi: "",
    alamat: "",
    keterangan: "",
    satker_tahti: "",
    type: "",
  });
  const onSuccess = () => {
    setTimeout(() => {
      navigate("/penyimpanan-barbuk");
    }, 1500);
  };

  const { data: masterListSatkers } = useSatkerTahtiList();
  const satkersFilter = masterListSatkers.map((e) => ({
    text: e.nama_satker,
    value: e.nama_satker,
  }));

  const mutateAdd = usePostLokasiBarbuk(onSuccess);
  const mutateEdit = usePutLokasiBarbuk(onSuccess);
  const {
    data: detailData,
    isFetching: detailIsFetching,
    isError: detailIsError,
    error: detailError,
  } = useGetLokasiBarbukDetail(id, { enabled: !!id });

  const handleChange = (e) => {
    let { name, value } = e.target;
    setLokasiBarbuk({ ...lokasiBarbuk, [name]: value });
  };
  const handleSubmit = () => {
    if (isEdit) {
      mutateEdit.mutate({ id, lokasiBarbuk });
    } else {
      mutateAdd.mutate(lokasiBarbuk);
    }
  };

  useEffect(() => {
    setIsEdit(false);
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  useEffect(() => {
    if (detailData) {
      setLokasiBarbuk({
        ...lokasiBarbuk,
        lokasi: detailData.lokasi,
        alamat: detailData.alamat,
        keterangan: detailData.keterangan,
        satker_tahti: detailData.nama_satker,
        type: detailData.tipe_satker,
      });
    }
  }, [detailData]);

  useEffect(() => {
    return () => {
      setLokasiBarbuk({
        menu: "",
        alamat: "",
        keterangan: "",
        icon: "",
        satker_tahti: "",
        type: "",
      });
    };
  }, []);
  return (
    <React.Fragment>
      <Card className="bg-white mb-2">
        <Card.Body className="p-2">
          <div className="d-flex align-items-center justify-content-between m-0">
            <div>
              <ol className="breadcrumb fs-sm mb-0">
                <li className="breadcrumb-item">
                  <Link to="/penyimpanan-barbuk">Penyimpanan Barang Bukti</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/penyimpanan-barbuk">
                    List Penyimpanan Barang Bukti
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active fw-bold"
                  aria-current="page"
                >
                  Form {isEdit ? "Edit" : "Add"} Penyimpanan Barang Bukti
                </li>
              </ol>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="bg-white">
        <Card.Body>
          <Row className="g-2">
            <Col sm="12">
              {mutateAdd.isError && (
                <Alert variant="danger">
                  <ul>
                    {mutateAdd?.error?.response?.data?.detail?.map(
                      (errEdit) => (
                        <li>
                          <b>{errEdit.loc[0]} </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error</b>Sesuatu Bermasalah
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateAdd.isSuccess && (
                <Alert variant="success">{mutateAdd.data.data.message}</Alert>
              )}
            </Col>
            <Col sm="12">
              {mutateEdit.isError && (
                <Alert variant="danger">
                  <ul>
                    {mutateEdit?.error?.response?.data?.detail?.map(
                      (errEdit) => (
                        <li>
                          <b>{errEdit.loc[0]} </b>
                          {errEdit.msg}
                        </li>
                      )
                    ) ?? (
                      <li>
                        <b>Error</b>Sesuatu Bermasalah
                      </li>
                    )}
                  </ul>
                </Alert>
              )}
              {mutateEdit.isSuccess && (
                <Alert variant="success">{mutateEdit.data.data.message}</Alert>
              )}
            </Col>
            <Col sm="6">
              <Select
                default="-"
                label="Satker"
                name="satker_tahti"
                options={satkersFilter}
                value={lokasiBarbuk.satker_tahti}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="6">
              <Select
                default="-"
                label="Tipe Satker"
                name="type"
                options={[
                  {
                    text: "POLRI",
                    value: "POLRI",
                  },
                  {
                    text: "POLDA",
                    value: "POLDA",
                  },
                  {
                    text: "POLRES",
                    value: "POLRES",
                  },
                ]}
                value={lokasiBarbuk.type}
                onChange={handleChange}
                required
              />
            </Col>
            <Col sm="6">
              <Input
                value={lokasiBarbuk.lokasi}
                name="lokasi"
                onChange={handleChange}
                label="Nama Lokasi"
                required
              />
            </Col>
            <Col sm="6">
              <Input
                as="textarea"
                value={lokasiBarbuk.alamat}
                name="alamat"
                onChange={handleChange}
                label="Alamat"
                required
              />
            </Col>
            <Col sm="6">
              <Input
                as="textarea"
                value={lokasiBarbuk.keterangan}
                name="keterangan"
                onChange={handleChange}
                label="Keterangan"
                required
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-white">
          <div className="d-flex justify-content-end align-items-center">
            {!mutateAdd.isLoading || !mutateEdit.isLoading ? (
              <Button variant="primary" onClick={handleSubmit}>
                {isEdit ? "Save" : "Submit"}
              </Button>
            ) : (
              <Button variant="primary" disabled>
                Loading
              </Button>
            )}
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default Form;
