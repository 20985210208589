import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import '@tensorflow/tfjs';
import { canvas } from 'leaflet';

function MediaView({uri}) {
  const [progress, setProgress] = useState(0)
  const [model, setModel] = useState(null)
  const videoRef = useRef(null)
  const canvasRef = useRef(null)

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const handleProgres = async (state) => {
    setProgress(formatTime(state.playedSeconds))
  }
  const handleReady = () => {
    const video = videoRef.current.getInternalPlayer();
    canvasRef.current.width = video.clientWidth
    canvasRef.current.height = video.clientHeight
  }

  const renderPredictions = (predictions) => {
    const video = videoRef.current.getInternalPlayer();
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d");

    console.log(predictions);
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    predictions.map((prediction) => ({
      class: prediction.class,
      score: prediction.score,
      bbox: transformBbox(prediction.bbox, video.videoWidth, video.videoHeight, canvas.width, canvas.height)
    }))

    predictions.forEach((prediction, idx) => {
      const score = Math.round(prediction.score * 100)
      if (score > 50) {
        drawPrediction(prediction)
      }
    })
  }

  const transformBbox = (bbox, originalWidth, originalHeight, scaledWidth, scaledHeight) => {
    const [x, y, width, height] = bbox;

    // Hitung faktor skala untuk x, y, width, dan height
    const scaleX = scaledWidth / originalWidth;
    const scaleY = scaledHeight / originalHeight;

    // Transformasi koordinat bbox
    const transformedX = (x * scaleX);
    const transformedY = (y * scaleY);
    const transformedWidth = width * scaleX;
    const transformedHeight = height * scaleY;

    return [transformedX, transformedY, transformedWidth, transformedHeight];
  };

  const drawPrediction = (prediction) => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d");
    const [x,y,w,h] = prediction.bbox

    ctx.beginPath();
    ctx.rect(x, y, w, h);
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'red';
    ctx.fillStyle = 'red';
    ctx.stroke();
    ctx.fillText(
        `${prediction.class} (${Math.round(prediction.score * 100)}%)`,
        x,
        y > 10 ? y - 5 : 10
    );
  } 

  useEffect(() => {
    const loadModel = async () => {
        const loadedModel = await cocoSsd.load();
        setModel(loadedModel);
    };
    loadModel();
  }, []);

  useEffect(() => {
    const detectFrame = async () => {
        if (videoRef.current && model) {
            const video = videoRef.current.getInternalPlayer();
            const predictions = await model.detect(video);
            renderPredictions(predictions);
            requestAnimationFrame(detectFrame);
        }
    };

    if (model && videoRef.current) {
      console.log('ready');
      handleReady()
      detectFrame()
    }
  }, [model]);

  return (
    <div className='wrapp-media-view'>
        <canvas ref={canvasRef}/>
        <ReactPlayer
            url={'/objectDetect.mp4'} 
            playing={true} 
            controls={true}
            width={'100%'}
            height={'auto'}
            ref={videoRef}
            onProgress={handleProgres}
            onReady={handleReady}
        />
    </div>
  )
}

export default MediaView