import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {  useGetDetailMasterBarangBuktiSubJenis, useGetDetailMasterBarangBuktiTipe, useGetDetailMasterBarangBuktiUnit, useGetMasterBarangBuktiJenis, useGetMasterBarangBuktiSubJenis, useGetMasterBarangBuktiTipe, useGetMasterMeasure, usePutMasterBarangBuktiSubJenis, usePutMasterBarangBuktiTipe, usePutMasterBarangBuktiUnit, usePutMasterMeasure } from '../../../../utils/apis/query';
import { AsyncSelectCreatable, Input, Select2 } from '../../../../components/Components';
import { getMasterDetailBarangBuktiJenis } from '../../../../utils/apis';
import { useQueryPage } from '../../../../utils';

function EditLayout() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [measureSearch, setMeasureSearch] = useState('');
    const [formState, setFormState] = useState({ 
      measure: "",
      keterangan: "",
    });
    const qp = useQueryPage()
    const measureParams = qp.get('measure')
    const keteranganParamas = qp.get('keterangan')
    const {
        data: listOptions,
        pagination,
        isFetching: dataFetching,
        isError: dataIsError,
        error: dataError,
      } = useGetMasterMeasure({measure: measureSearch});
    let selecOptionsMeasure = listOptions.map((e) => ({label: e.measure, value: e.measure}))

    const onSuccess = (response) => {
        setIsFormDirty(false)
        navigate('/master/barang-bukti/unit')
    }
    const formMutate = usePutMasterMeasure(onSuccess)
    const handleChange = (e) => {
      const name = e?.target?.name
      const value = e?.target?.value ?? ''
      setFormState({...formState, [name] : value})
      setIsFormDirty(true)
    } 
    const handleSubmit = (e) => {
        e.preventDefault()
      formMutate.mutate({id: id, formData: formState})
    }
    const handleAsyncOptionMeasure = (input) => {
        return new Promise((resolve) => {
            setMeasureSearch(input)
            resolve(selecOptionsMeasure)
        })
    }

    useEffect(() => {
        if (measureParams) {
            setFormState(
                {
                    measure: measureParams,
                    keterangan: keteranganParamas  === 'null' ? '' : keteranganParamas
                }
            )
        }
    }, [measureParams, keteranganParamas])
    useEffect(() => { 
      const handleBeforeUnload = (event) => {
        if (isFormDirty) {
          const message =
            "You have unsaved changes. Are you sure you want to leave?";
          event.returnValue = message;
          return message;
        }
      };
  
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, [isFormDirty]);
  return (
    <React.Fragment>
        <Card className="bg-white mb-2">
            <Card.Body className="p-2">
            <div className="d-flex align-items-center justify-content-between m-0">
                <div>
                <ol className="breadcrumb fs-sm mb-0">
                    <li className="breadcrumb-item">
                    <Link to="/master/barang-bukti/list">Barang Bukti</Link>
                    </li>
                    <li className="breadcrumb-item">
                    <Link to="/master/barang-bukti/unit">
                        Barang Bukti Unit
                    </Link>
                    </li>
                    <li
                    className="breadcrumb-item active fw-bold"
                    aria-current="page"
                    >
                        Edit Barang Bukti Unit
                    </li>
                </ol>
                </div>
            </div>
            </Card.Body>
        </Card>
        <Card className="bg-white">
            <Card.Body>
                <Row className="g-2">
                    <Col sm="12">
                    {formMutate.isError && (
                        <Alert variant="danger">
                        <ul className="m-0">
                            {formMutate?.error?.response?.data?.detail?.map(
                            (errEdit, idx) => (
                                <li key={idx}>
                                <b>{errEdit.loc[1]} : </b>
                                {errEdit.msg}
                                </li>
                            )
                            ) ?? (
                            <li>
                                <b>Error : </b>
                                {formMutate?.error?.response?.data?.message ??
                                "Bermasalah"}
                            </li>
                            )}
                        </ul>
                        </Alert>
                    )}
                    {formMutate.isSuccess && (
                        <Alert variant="success">
                        {formMutate.data.data.message}
                        </Alert>
                    )}
                    </Col>
                    <Col sm="12">
                        <Row className='g-2'>
                            <Col sm="3" className="d-flex flex-column">
                                {/* <AsyncSelectCreatable
                                    className="react-select-dems"
                                    cacheOptions
                                    label="Pilih Measure"
                                    placeholder=""
                                    required
                                    value={formState.measure}
                                    defaultOptions={selecOptionsMeasure || []}
                                    loadOptions={handleAsyncOptionMeasure}
                                    onChange={(e) => setFormState({...formState, measure: e.value})}
                                /> */}
                                <Input 
                                    label="Measure"
                                    type="text"
                                    name="measure"
                                    value={formState.measure}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>
                            <Col sm="3">
                                <Input 
                                    label="Keterangan"
                                    type="text"
                                    name="keterangan"
                                    onChange={handleChange}
                                    defaultValue={formState.keterangan}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className="bg-white">
            <div className="d-flex justify-content-end align-items-center gap-3">
                <Button
                    variant="warning"
                    as={Link}
                    to="/master/barang-bukti/unit"
                >
                    BATAL
                </Button>
                <Button 
                  variant="primary"
                  onClick={handleSubmit}
                >
                    SIMPAN
                </Button>
            </div>
        </Card.Footer>
        </Card>
    </React.Fragment>
  )
}

export default EditLayout