import React from 'react'
import { Badge, Button, Card, Col, Row, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Input, MyPagination, Select } from '../../../../components/Components'

function List() {
  return (
    <React.Fragment>
        <div className="d-flex align-items-center justify-content-between mb-4">
            <div>
                <ol className="breadcrumb fs-sm mb-1">
                    <li className="breadcrumb-item">
                        <Link to="/tahanan/penangguhan/list">Tahanan</Link>
                    </li>
                    <li className="breadcrumb-item active fw-bold" aria-current="page">
                        Penangguhan List
                    </li>
                </ol>
            </div>
        </div>
        <Row>
            <Col xl="12">
                <Card className="card bg-white">
                    <Card.Body>
                      <Row className='g-1 mb-2'>
                          <Col sm="12" md="5">
                              <Input
                                  type="text"
                                  placeholder="Search"
                              />
                          </Col>
                          <Col sm="12" md="7">
                              <Row className='g-2'>
                                <Col sm="3">
                                  <Select 
                                    required
                                    default="Pilih Satker"
                                    options={[{text: 'POLRES JAKSEL', value: 'Laki-Laki'}]}
                                  />
                                </Col>
                                <Col sm="3">
                                  <Select 
                                    required
                                    default="Pilih Perkara"
                                    options={[{text: 'Pengalihan Tahanan', value: 'Laki-Laki'}]}
                                  />
                                </Col>
                                <Col sm="3">
                                  <Select 
                                    required
                                    default="Pilih Status"
                                    options={[{text: 'Ditahan', value: 'Laki-Laki'}]}
                                  />
                                </Col>
                                <Col sm="3">
                                    <Link to='/tahanan/penangguhan/form' className="d-flex align-items-center justify-content-end">
                                        <Button className='btn btn-primary'>
                                            TAMBAH
                                        </Button>
                                    </Link>
                                </Col>
                              </Row>
                          </Col>
                      </Row>
                      <Table className="table dems-table">
                          <thead>
                          <tr>    
                              <th>No</th>
                              <th>Tgl Sprin</th>
                              <th>No. Sprin Penangguhan</th>
                              <th>No Tahanan</th>
                              <th>Nama Tahanan</th>
                              <th>Asal Satker</th>
                              <th>Perkara</th>
                              <th>Awal Penangguhan</th>
                              <th>Akhir Penangguhan</th>
                              <th style={{textAlign: 'right'}}>Aksi</th>
                          </tr>
                          </thead>
                          <tbody>
                          {/* {
                            dataFetching && (
                                <tr>
                                    <td colSpan="4" style={{ textAlign: 'center' }}>
                                        <Spinner animation="border" variant="primary" />
                                    </td>
                                </tr>
                            )
                          }
                          {
                            dataIsError && (
                                dataError?.response?.data?.detail?.map((err, idx) => (
                                    <tr key={idx}>
                                        <td colSpan="4" style={{ textAlign: 'center' }} className='text-danger'>
                                            <span><b>{err.loc[1]} </b>{err.msg}</span>
                                        </td>
                                    </tr>
                                )) ?? (
                                    <tr>
                                        <td colSpan="4" style={{ textAlign: 'center' }} className='text-danger'>
                                            <span><b>Opps Error </b>Reload page or please wait until we fix this issue</span>
                                        </td>
                                    </tr>
                                )
                            )
                          } */}
                          {/* {
                            (dataList.length && !dataFetching && !dataIsError) ? (
                                dataList.map((data, index) => (
                                    <tr key={index}>
                                        <td>
                                            {data.menu}
                                        </td>
                                        <td>
                                            {data.label}
                                        </td>
                                        <td>
                                            {data.path}
                                        </td>
                                        <td>
                                            {data.deskripsi}
                                        </td>
                                        <td>
                                            <i className={`${data.icon} text-primary fs-3`} />
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-end gap-2">
                                                <Link to={'/menus/form/'+data.id}>
                                                    <Badge className="btn bg-success" as="button">
                                                        <i className="ri-edit-2-line"></i>
                                                    </Badge>
                                                </Link>
                                                <Badge onClick={() => handleDelete(data.id)} className="btn bg-danger" as="button">
                                                    <i className="ri-delete-bin-line"></i>
                                                </Badge>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )
                            :
                            !dataFetching && !dataList.length && !dataIsError && (
                                <tr>
                                    <td colSpan="4" style={{ textAlign: 'center' }}>
                                     <span>Tidak Ada Data !</span>
                                    </td>
                                </tr>
                            )
                           } */}
                           {
                            [1,1,1,1].map((data, index) => (
                                <tr key={index}>
                                    <td>
                                        {index + 1}
                                    </td>
                                    <td>
                                        10/04/2023
                                    </td>
                                    <td>
                                        Sprin.B/02 1/IV/2023/Reskrim
                                    </td>
                                    <td>
                                        176688990
                                    </td>
                                    <td>
                                        Mr. Jhon Dol
                                    </td>
                                    <td>
                                        Polres Bekasi
                                    </td>
                                    <td>
                                      Penganiayaan
                                    </td>
                                    <td>
                                        11/04/2023
                                    </td>
                                    <td>
                                        17/04/2023
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center justify-content-end gap-2">
                                            <Link to={'/tahanan/penangguhan/form/'}>
                                                <Badge className="btn bg-success" as="button">
                                                    <i className="ri-edit-2-line"></i>
                                                </Badge>
                                            </Link>
                                            <Badge className="btn bg-danger" as="button">
                                                <i className="ri-delete-bin-line"></i>
                                            </Badge>
                                        </div>
                                    </td>
                                </tr>
                            ))
                           }
                          </tbody>
                      </Table>
                    </Card.Body>
                    <Card.Footer className='bg-white'>
                        <MyPagination currentPage={1} totalPages={5} onPageChange={() => {}}/>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    </React.Fragment>
  )
}

export default List