import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Card, Table } from 'react-bootstrap'

function ViewImage({payload}) {
  const imgRef = useRef()
  const canvasRef = useRef()
  const [showPrediction, setShowPrediction] = useState(false)
  const [metaData, setMetaData] = useState({
    frameWidth: 0,
    frameHeight: 0,
  })

  const handlePrediction = (event) => {
    canvasRef.current.width = imgRef.current.width
    canvasRef.current.height = imgRef.current.height
    setShowPrediction(event.target.checked)
  }
  const handleImgOnLoad = () => {
    const readImg = imgRef.current
    setMetaData({imgLoaded: true, frameWidth: readImg.width, frameHeight: readImg.height})
  }
  const drawPredictions = (predictions) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (!predictions.length) return false

    predictions.forEach(prediction => {
      const {x1, y1, x2, y2} = prediction.box;
      const w = x2 - x1
      const h = y2 - y1
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 4;
      ctx.strokeRect(x1, y1, w, h);
      ctx.fillStyle = 'red';
      ctx.font = '18px Arial';
      ctx.fillText(
        `${prediction.name} (${Math.round(prediction.score * 100)}%)`,
        x1,
        y1 > 10 ? y1 - 5 : 10
      );
    });
  };

  useEffect(() => {
      if (showPrediction && payload.payload.length) {
        drawPredictions(payload.payload)
      }
  }, [showPrediction, payload])

  return (
    <React.Fragment>
        <Row className='g-3'>
            <Col sm="3">
                <Card className='card-one bg-white'>
                  <Card.Header>
                    Metadata File
                  </Card.Header>
                  <Card.Body>
                  <Table className='bg-white'>
                    <thead>
                      <tr>
                        <th scope="col" style={{width: '40%'}}>Name</th>
                        <th scope="col">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Frame Width</td>
                        <td>{metaData.frameWidth}</td>
                      </tr>
                      <tr>
                        <td>Frame Height</td>
                        <td>{metaData.frameHeight}</td>
                      </tr>
                      <tr>
                          <td>File Name</td>
                          <td>{payload.filename}</td>
                      </tr>
                      <tr>
                          <td>File Type</td>
                          <td>{payload.file_type}</td>
                      </tr>
                      <tr>
                          <td>File Size</td>
                          <td>{payload.size}</td>
                      </tr>
                      <tr>
                          <td>Date</td>
                          <td>{moment(payload.last_modified).format('DD-MM-YYYY HH:MM:SS')}</td>
                      </tr>
                    </tbody>
                  </Table>
                  </Card.Body>
                </Card>
            </Col>
            <Col sm="9" className="pictureBox">
                <Card className='card-one bg-white'>
                    <Card.Body>
                        <div className="img-ocr-wrapper">
                          <img 
                              id="imgOcr"
                              ref={imgRef}
                              src={payload.minio_url}
                              onLoad={handleImgOnLoad}
                              alt="img" 
                              crossOrigin="anonymous"
                          />
                          {
                              payload.payload.length ? (
                              <div className="control-img">
                                <div className="control">
                                  <input type='checkbox' checked={showPrediction} onChange={handlePrediction}/>
                                  <span className="text-dark fw-bold">Show Detection</span>
                                </div>
                              </div>
                            ) 
                            :(<span>Data Prediksi Tidak Diketahui</span>)
                          }
                          <canvas ref={canvasRef}/>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </React.Fragment>
  )
}

export default ViewImage