// [
//   "Super Admin",
//   "Eksekutif POLRI",
//   "Eksternal",
//   "Eksekutif PENYIDIK",
//   "Admin PENYIDIK",
//   "Atasan PENYIDIK",
//   "PENYIDIK",
//   "Eksekutif TAHTI",
//   "Admin TAHTI",
//   "Umum TAHTI",
//   "Petugas Rutan TAHTI",
//   "Petugas BB TAHTI"
// ]

const dashboardMenu = [
  {
    label: "Dashboard",
    link: "/dashboard",
    icon: "ri-pie-chart-2-line",
    role: [
      "Super Admin",
      "Admin TAHTI",
      "Petugas Rutan TAHTI",
      "Petugas BB TAHTI",
      "Eksekutif POLRI",
    ],
    submenu: [
      {
        label: "Tahanan",
        link: "/dashboard/tahanan",
        role: [
          "Super Admin", 
          "Admin TAHTI",
          "Eksekutif POLRI",
          "Petugas Rutan TAHTI",
        ],
      },
      {
        label: "Barang Bukti",
        link: "/dashboard/barangbukti",
        role: [
          "Super Admin", 
          "Admin TAHTI",
          "Eksekutif POLRI",
          "Petugas BB TAHTI",
        ],
      },
      {
        label: "Admindik",
        link: "/dashboard/tindak-pidana",
        role: [
          "Super Admin",
          "Eksekutif POLRI",
          "Admin TAHTI",
        ],
      },
    ],
  },
];
const masterMenu = [
  {
    label: "Master BB",
    icon: "ri-file-info-line",
    role: ["Super Admin"],
    submenu: [
      {
        label: "Barang Bukti",
        link: "/master/barang-bukti/list",
        role: ["Super Admin"],
      },
      {
        label: "Jenis",
        link: "/master/barang-bukti/jenis",
        role: ["Super Admin"],
      },
      {
        label: "Sub Jenis",
        link: "/master/barang-bukti/sub-jenis",
        role: ["Super Admin"],
      },
      {
        label: "Tipe",
        link: "/master/barang-bukti/tipe",
        role: ["Super Admin"],
      },
      {
        label: "Unit",
        link: "/master/barang-bukti/unit",
        role: ["Super Admin"],
      },
    ],
  },
  {
    label: "Penyimpanan Barang Bukti",
    icon: "ri-building-line",
    link: "/penyimpanan-barbuk",
    role: ["Super Admin"],
  },
  {
    label: "Satuan Kerja",
    icon: "ri-building-4-line",
    link: "/satker",
    role: ["Super Admin"],
  },
];
const laporanMenu = [
  {
    label: "Tahanan",
    icon: "ri-file-chart-2-line",
    link: "/laporan/tahanan",
    role: [
      "Super Admin", 
      "Admin TAHTI", 
      "Petugas Rutan TAHTI", 
      "Eksekutif POLRI",
    ],
  },
  {
    label: "Barang Bukti",
    role: [
      "Super Admin",
      "Eksekutif TAHTI",
      "Admin TAHTI",
      "Petugas BB TAHTI",
      "Eksekutif POLRI",
    ],
    icon: "ri-file-chart-2-line",
    link: "/laporan/barang-bukti",
  },
];
const arsipMenu = [
  {
    label: "Folder Berkas",
    icon: "ri-archive-line",
    link: "/arsip/list",
    role: [
      "Super Admin",
    ],
  },
  {
    label: "Dibagikan Kepada Saya",
    icon: "ri-file-transfer-line",
    link: "/shared-file",
    role: [
      "Super Admin",
    ],
  },
  {
    label: "Pencarian Berkas",
    icon: "ri-menu-search-line",
    link: "/search",
    role: [
      "Super Admin",
    ],
  },
];
const tahtiMenu = [
  {
    label: "Tahanan",
    icon: "ri-group-line",
    role: [
      "Super Admin",
      "Admin TAHTI",
      "Petugas Rutan TAHTI",
      "Eksekutif POLRI",
    ],
    submenu: [
      {
        label: "Daftar Tahanan",
        link: "/tahanan/list",
        role: [
          "Super Admin", 
          "Eksekutif POLRI", 
          "Admin TAHTI",
          "Petugas Rutan TAHTI",
        ],
      },
      {
        label: "Registrasi Tahanan",
        link: "/tahanan/registrasi/list",
        role: [
          "Super Admin",
          "Admin TAHTI",
          "Petugas Rutan TAHTI",
        ],
      },
      {
        label: "Bon Tahanan",
        link: "/tahanan/bon/list",
        role: [
          "Super Admin",
          "Admin TAHTI",
          "Petugas Rutan TAHTI",
        ],
      },
      {
        label: "Perpanjangan",
        link: "/tahanan/perpanjangan/list",
        role: [
          "Super Admin",
          "Admin TAHTI",
          "Petugas Rutan TAHTI",
        ],
      },
      {
        label: "Pengeluaran",
        link: "/tahanan/pengeluaran/list",
        role: [
          "Super Admin",
          "Admin TAHTI",
          "Petugas Rutan TAHTI",
        ],
      },
    ],
  },
  {
    label: "Barang Bukti",
    role: [
      "Super Admin",
      "Admin TAHTI",
      "Petugas BB TAHTI",
      "Eksekutif POLRI",
    ],
    icon: "ri-article-line",
    submenu: [
      {
        label: "Daftar Barang Bukti",
        link: "/barbuk/list",
        role: [
          "Super Admin", 
          "Eksekutif POLRI",
          "Admin TAHTI",
          "Petugas BB TAHTI",
        ],
      },
      {
        label: "Penerimaan",
        link: "/barbuk/penerimaan/list",
        role: [
          "Super Admin",
          "Admin TAHTI",
          "Petugas BB TAHTI",
        ],
      },
      {
        label: "Penyimpanan",
        link: "/barbuk/penyimpanan/list",
        role: [
          "Super Admin",
          "Admin TAHTI",
          "Petugas BB TAHTI",
        ],
      },
      {
        label: "Pengeluaran",
        link: "/barbuk/pengeluaran/list",
        role: [
          "Super Admin",
          "Admin TAHTI",
          "Petugas BB TAHTI",
        ],
      },
      {
        label: "Pemusnahan",
        link: "/barbuk/pemusnahan/list",
        role: [
          "Super Admin",
          "Admin TAHTI",
          "Petugas BB TAHTI",
        ],
      },
    ],
  },
];
const configMenu = [
  {
    label: "Pengaturan",
    icon: "ri-tools-line",
    role: ["Super Admin"],
    submenu: [
      {
        label: "Menu",
        link: "/menus",
        role: ["Super Admin"],
      },
      {
        label: "Role & Akses",
        link: "/role",
        role: ["Super Admin"],
      },
      // {
      //   label: "Role Akses",
      //   link: "/pengaturan/role/list",
      //   role: ["Super Admin"],
      // },
    ],
  },
];
const penggunaMenu = [
  {
    label: "Manajemen Pengguna",
    icon: "ri-tools-line",
    role: ["Super Admin", "Admin TAHTI", "Eksekutif POLRI",],
    link: "/pengaturan/pengguna/list",
  },
  {
    label: "Riwayat Log",
    icon: "ri-chat-history-line",
    role: ["Super Admin", "Admin TAHTI"],
    link: "/pengaturan/riwayat/list",
  },
];

export const labelMenuGroup = [
  {
    label: "Dashboard",
    role: [
      "Super Admin", 
      "Petugas Rutan TAHTI", 
      "Admin TAHTI", 
      "Petugas BB TAHTI",
      "Eksekutif POLRI",
    ],
    menu: dashboardMenu,
  },
  {
    label: "Berkas",
    role: ["Super Admin"],
    menu: arsipMenu,
  },
  {
    label: "Tahti",
    role: [
      "Super Admin", 
      "Petugas Rutan TAHTI", 
      "Admin TAHTI", 
      "Petugas BB TAHTI",
      "Eksekutif POLRI",
    ],
    menu: tahtiMenu,
  },
  {
    label: "Laporan",
    role: [
      "Super Admin", 
      "Petugas Rutan TAHTI", 
      "Admin TAHTI", 
      "Petugas BB TAHTI",
      "Eksekutif POLRI",
    ],
    menu: laporanMenu,
  },
  {
    label: "Pengguna",
    role: [
      "Super Admin", 
      "Admin TAHTI"
    ],
    menu: penggunaMenu,
  },
  {
    label: "Master",
    role: ["Super Admin"],
    menu: masterMenu,
  },
  {
    label: "Konfigurasi",
    role: ["Super Admin"],
    menu: configMenu,
  },
];
export {
  dashboardMenu,
  masterMenu,
  arsipMenu,
  tahtiMenu,
  configMenu,
  laporanMenu,
  penggunaMenu,
};